/**
 * Member coverage Dental
 */

import dentalSpecialtyEN from './../../objects/benefit_dental_specialties.json';
import dentalSpecialtyFR from './../../objects/benefit_dental_specialties_fr.json';

import dentalCodeEN from './../../objects/benefit_dental_code_results.json';
import dentalCodeFR from './../../objects/benefit_dental_code_results_fr.json';

export default function (server) {
	/**
	 * Dental Coverage flow
	 *
	 * returns list of specialty
	 */
	server.get('/members/:id/coverages/dental/specialties', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en'
			? dentalSpecialtyEN
			: dentalSpecialtyFR;
	});

	/**
	 * Dental Coverage flow
	 *
	 * returns list of Provinces available
	 */
	server.get('/members/:id/coverages/dental/provinces', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en'
			? {
					ab: 'Alberta',
					bc: 'British Columbia',
					mb: 'Manitoba',
					nb: 'New Brunswick',
					nf: 'Newfoundland and Labrador',
					tno: 'Northwest Territories',
					ns: 'Nova Scotia',
					nu: 'Nunavut',
					on: 'Ontario',
					pei: 'Prince Edward Island',
					qc: 'Quebec',
					sk: 'Saskatchewan',
					yu: 'Yukon'
			  }
			: {
					ab: 'Alberta',
					bc: 'Colombie-Britannique',
					pei: 'Île-du-Prince-Édouard',
					mb: 'Manitoba',
					nb: 'Nouveau-Brunswick',
					ns: 'Nouvelle-Écosse',
					nu: 'Nunavut',
					on: 'Ontario',
					qc: 'Québec',
					sk: 'Saskatchewan',
					nf: 'Terre-Neuve-et-Labrador',
					tno: 'Territoires du Nord-Ouest',
					yu: 'Yukon'
			  };
	});

	/**
	 * Dental Coverage flow
	 *
	 * returns list of specialty codes
	 */
	server.get('/members/:id/coverages/dental', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en' ? dentalCodeEN : dentalCodeFR;
	});
}
