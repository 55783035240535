/**
 * Member coverage booklet (PDF)
 */

import { Response } from 'miragejs';

export default function (server) {
	server.get('/members/:id/coverages/booklets', () => {
		return {
			bookletId: 50
		};
	});
	server.get('/members/:id/coverages/booklet', () => {
		return new Response(200);
	});
}
