/**
 * Member coverage booklet (PDF)
 */

import { Response } from 'miragejs';

export default function (server) {
	// Service call was ok, but returned "no data" response code
	server.get('/members/:id/coverages/booklets', () => {
		const httpStatusCode = 204;
		return new Response(httpStatusCode);
	});
}
