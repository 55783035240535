export default `<section>
	<h2>Conditions d'utilisation</h2>
	<p>Croix Bleue Medavie vous offre ce site Web pour votre information, votre utilisation et votre agrément personnels, pourvu que vous respectiez les conditions ci-dessous. Nous vous prions de lire ces conditions attentivement avant d'utiliser le site. En accédant au site et en l'utilisant, vous acceptez d'être lié par les conditions stipulées ci-dessous, sans limite ni précisions.
	</p>
	<p>Croix Bleue Medavie se réserve le droit de modifier ces conditions par une mise à jour du présent avis. Puisque vous êtes tenu de respecter ces modifications, vous devriez revenir à cette page à l'occasion pour relire les conditions en vigueur.
	</p>
	<ol>
		<li>
			<h3>Avertissement</h3>
			<p>Croix Bleue Medavie offre ce site et son contenu « TELS QUELS », sans aucune assertion ni garantie d'aucune sorte à cet égard, ni explicite ni implicite, y compris mais sans s'y limiter, toute garantie implicite de qualité marchande, d'adaptation à un usage particulier ou d'absence de contrefaçon.
			</p>
		</li>
		<li>
			<h3>Dégagement de responsabilité</h3>
			<p>Croix Bleue Medavie et ses compagnies affiliées, dirigeants, directeurs, agents, employés ou toute personne associée à la création ou à la production du présent site ne doivent en aucun cas être tenus responsables des préjudices, pertes ou dommages quelconques (relevant d'un contrat, d'un tort, de négligence ou autre) relatifs à l'accès ou à l'utilisation du site, y compris les dommages directs, indirects, accessoires, spéciaux, punitifs ou relatifs à un tiers.
			</p>
		</li>
		<li>
			<h3>Utilisation du site</h3>
			<p>Le contenu du site est offert à titre informatif seulement. Aucune partie de ce site ne constitue une offre d'achat ou de vente des produits et services de Croix Bleue Medavie. Les renseignements que contient ce site ne sont pas des conseils personnalisés sur des questions financières, comptables, juridiques, fiscales ou d'assurances, et ne doivent pas être considérés comme tel.
			</p>
			<p>L'information sur la santé fournie sur le site est offerte à titre informatif uniquement et ne doit en aucun cas remplacer les services d'un professionnel de la santé qualifié ni les conseils de médecins. En cas de trouble de santé ou de symptômes annonciateurs, il faut consulter un fournisseur de soins de santé.
			</p>
		</li>
		<li>
			<h3>Exactitude de l'information</h3>
			<p>Croix Bleue Medavie déploie des efforts raisonnables pour afficher de l'information exacte et à jour sur le site, mais elle n'offre aucune garantie et ne fait aucune déclaration à cet égard. Le contenu du site peut être modifié en tout temps, sans préavis.
			</p>
		</li>
		<li>
			<h3>Confidentialité et sécurité</h3>
			<p>Croix Bleue Medavie a pris et continuera de prendre toutes les précautions raisonnables pour éviter que des tiers n'accèdent de manière frauduleuse au site ou à vos renseignements personnels, mais elle ne peut garantir l'entière sécurité du site. Croix Bleue Medavie ne peut pas être tenue responsable des pertes ou des préjudices subis par vous-même ou une autre personne, en rapport avec l'information sauvegardée sur le site, ou envoyée ou reçue via l'Internet, à cause d'un manque de confidentialité ou de sécurité, ou d'une atteinte à la vie privée.
			</p>
		</li>
		<li>
			<h3>Services offerts uniquement là où la loi le permet</h3>
			<p>Les produits, services et renseignements relatifs aux assurances décrits dans le site ne sont pas nécessairement offerts dans toutes les régions du Canada, ni aux personnes qui ne résident pas au Canada. Les assurances de Croix Bleue Medavie ne sont offertes que dans les régions où la vente en est légalement autorisée.
			</p>
		</li>
		<li>
			<h3>Responsabilité à l'égard des liens hypertextes</h3>
			<p>Les autres sites Web auxquels on peut accéder grâce à des liens hypertextes, via le présent site, sont entièrement distincts de ce site. Ils n'impliquent en aucun cas que Croix Bleue Medavie approuve ou accepte de tels sites, groupes ou renseignements figurant sur les pages de ces sites, ou qu'elle s'y associe. Croix Bleue Medavie n'assume aucune responsabilité quant au contenu des sites liés à son propre site par lien hypertexte;\nl'utilisateur assume seul les risques liés à l'utilisation de ces liens.
			</p>
		</li>
		<li>
			<h3>Droit d'auteur</h3>
			<p>Le contenu intégral du site est protégé en vertu des lois sur le droit d'auteur du Canada et d'autres pays. Croix Bleue Medavie détient les droits de propriété intellectuelle sur les éléments constitutifs du site ou a obtenu la permission du titulaire de tels droits d'utiliser ces éléments sur son site.
			</p>
			<p>Le téléchargement des éléments figurant sur ce site est autorisé à titre personnel et pour un usage non commercial. L'utilisateur doit conserver toute marque de droit d'auteur ou de propriété sur les éléments téléchargés ou copiés. Tout élément copié du site de Croix Bleue Medavie et distribué pour quelque raison que ce soit doit être accompagné des conditions d'utilisation du site. Il est interdit de distribuer, de modifier, de transmettre, d'utiliser ou de réutiliser des parties du site à titre personnel ou public sans avoir obtenu au préalable le consentement écrit de Croix Bleue Medavie.
			</p>
		</li>
		<li>
			<h3>Marques de commerce</h3>
			<p>Croix Bleue Medavie et ses compagnies affiliées détiennent les marques de commerce utilisées avec les produits et services qu'elles offrent ou en rapport avec ceux-ci. Les marques de commerce sont protégées par des lois canadiennes et étrangères sur les marques de commerce. L'utilisation et l'affichage de marques de commerces sur ce site ne signifie pas qu'un permis quelconque ait été délivré. Les noms des produits mentionnés sur ce site sont les marques de commerce de leurs détenteurs respectifs.
			</p>
		</li>
		<li>
			<h3>Communiquez avec nous</h3>
			<address>
				Croix Bleue Medavie<br />
				644, rue Main, C.P. 220<br />
				Moncton (Nouveau-Brunswick) E1C 8L3<br />
				Téléphone : <a href="tel:+18006674511"><b>1-800-667-4511<b></a>
			</address>
		</li>
	</ol>
</section>
`;
