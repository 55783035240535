/**
 * Submit Claim / Claim Type
 *
 * Note: PWA and HSA are benefits under LOB 03.  The same as chiro, physio etc
 */
export default function (server) {
	server.get('/members/:id/claim-types', () => {
		return [
			{
				claimType: 'XX-TRAV',
				description: 'Travel',
				lob: '02',
				isRta: false,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-HEAL',
				description: 'Health (General)',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-PHAR',
				description: 'Drugs',
				lob: '04',
				isRta: false,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-DENT',
				description: 'Dental services',
				lob: '05',
				isRta: false,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-PSA',
				description: 'PSA',
				lob: '03',
				isRta: false,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-HSA',
				description: 'Health Spending Account',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-DCI',
				description: 'Disability and Critical Illness',
				lob: '07',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-LADD',
				description: 'Life and Accidental Death & Dismemberment',
				lob: '08',
				isRta: true,
				isDisabledForProv: false
			}
		];
	});
}
