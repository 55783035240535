import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default class HospitalBenefitsScepter extends Model {
	static entity = 'hospitalBenefitsScepter';

	static primaryKey = 'locale';

	static fields() {
		return {
			username: this.string(null).nullable(),
			locale: this.string(null).nullable(),
			benefitFamilies: this.attr(null).nullable()
		};
	}

	static async getBenefits(username, token, locale) {
		// Check to see if the benefits are in vuex-orm.

		const benefitFamilies = await super.find(locale)?.benefitFamilies;
		if (benefitFamilies) {
			return benefitFamilies;
		}

		// Nothing found in vuex-orm, make an api call to get the benefits for the member.
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/hospital`;

		startSpinner();

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			stopSpinner();

			if (response?.status === 200) {
				// Api call is good, add data to vuex-orm.
				let benefitFamilies = response.data['01']?.benFamilies;

				benefitFamilies = benefitFamilies.map((benefitFamily) => {
					return this._transforData(benefitFamily);
				});

				await super.insertOrUpdate({
					data: {
						locale,
						benefitFamilies
					}
				});

				return benefitFamilies;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static _transforData(benefitFamily) {
		let transformed = {};

		const parenthesisPosition = benefitFamily.shortDesc.indexOf('(');

		if (parenthesisPosition > 0) {
			// Get text before parenthesis
			transformed.title = benefitFamily.shortDesc.substring(0, parenthesisPosition);
			// Get text after parenthesis
			transformed.subTitle = benefitFamily.shortDesc.substring(parenthesisPosition);
			// Capitalize first letter in sub title text.  Ex: "(Some ambulance benefits ..."
			transformed.subTitle = transformed.subTitle.replace(/\((.)/, (x) => x.toUpperCase());
		} else {
			// No parenthesis in the description, just use it as-is
			transformed.title = benefitFamily.shortDesc;
		}

		// Add space before and after any "/" to allow words to line break.
		transformed.title = transformed.title.replaceAll('/', ' / ');

		transformed.benefits = benefitFamily.benefits;

		return transformed;
	}
}
