import {
	faCalendarAlt,
	faCheck,
	faCheckSquare,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faChevronLeft,
	faChevronRight,
	faExclamationTriangle as falExclamationTriangle,
	faExternalLinkAlt,
	faPrint,
	faUpload,
	faAddressCard as falAddressCard,
	faAlarmClock as falAlarmClock,
	faAmbulance as falAmbulance,
	faAmericanSignLanguageInterpreting as falAmericanSignLanguageInterpreting,
	faAngleDown as falAngleDown,
	faAngleLeft as falAngleLeft,
	faAngleRight as falAngleRight,
	faAngleUp as falAngleUp,
	faBars as falBars,
	faTimes as falTimes,
	faPlusCircle as falPlusCircle,
	faInfoCircle as falInfoCircle,
	faFileAlt as falFileAlt,
	faFilePdf as falFilePdf,
	faHeartbeat as falHeartbeat,
	faTooth as falTooth,
	faCapsules as falCapsules,
	faCheckCircle as falCheckCircle,
	faEllipsisH as falEllipsisH,
	faExclamationCircle as falExclamationCircle,
	faEye as falEye,
	faEyeSlash as falEyeSlash,
	faFileInvoiceDollar as falFileInvoiceDollar,
	faGlobe as falGlobe,
	faHandHoldingUsd as falHandHoldingUsd,
	faHistory as falHistory,
	faHorizontalRule as falHorizontalRule,
	faLifeRing as falLifeRing,
	faMobileAlt as falMobileAlt,
	faPlaneDeparture as falPlaneDeparture,
	faQuestionCircle as falQuestionCircle,
	faSignIn as falSignIn,
	faTimesCircle as falTimesCircle,
	faUmbrella as falUmbrella,
	faUser as falUser,
	faHospitalSymbol as falHospitalSymbol,
	faUserMdChat as falUserMdChat,
	faShieldAlt as falShieldAlt,
	faSearch as falSearch,
	faFileImport as falFileImport,
	faNewspaper as falNewspaper,
	faExternalLink as falExternalLink,
	faHospital as falHospital,
	faBriefcaseMedical as falBriefcaseMedical,
	faHandHoldingMedical as falHandHoldingMedical,
	faEnvelopeOpenDollar as falEnvelopeOpenDollar,
	faUserCircle,
	faCheck as falCheck,
	faGlasses as falGlasses,
	faUsersMedical as falUsersMedical
} from '@fortawesome/pro-light-svg-icons';
import {
	faEye as farEye,
	faEyeSlash as farEyeSlash,
	faQuestionCircle as farQuestionCircle,
	faClock as farClock
} from '@fortawesome/pro-regular-svg-icons';
import {
	faGlobe,
	faSave,
	faSpinner,
	faUserEdit,
	faUserPlus,
	faUserTimes,
	faCheckCircle,
	faTimesCircle,
	faExclamationCircle,
	faExclamationTriangle,
	faCaretUp,
	faCaretDown,
	faArrowCircleRight,
	faClock
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faChevronCircleRight, faChevronLeft, faChevronRight, faChevronUp, faChevronDown);

library.add(faSpinner);
library.add(faUserPlus);
library.add(faUserEdit);
library.add(faUserTimes);
library.add(faSave);
library.add(faGlobe);
library.add(falGlobe);
library.add(falSignIn);
library.add(falUmbrella);
library.add(falHistory);
library.add(falAmericanSignLanguageInterpreting);
library.add(falAmbulance);
library.add(falHorizontalRule);
library.add(falQuestionCircle);
library.add(falUser);
library.add(falEye);
library.add(falEyeSlash);
library.add(farEye);
library.add(farEyeSlash);
library.add(faExclamationTriangle);
library.add(falTimesCircle);
library.add(farQuestionCircle);
library.add(falCheckCircle);
library.add(falAngleUp);
library.add(falAngleDown);
library.add(falExclamationCircle);
library.add(falBars);
library.add(falTimes);
library.add(falPlusCircle);
library.add(falInfoCircle);
library.add(falFileAlt);
library.add(falFilePdf);
library.add(faCalendarAlt);
library.add(falHeartbeat);
library.add(falTooth);
library.add(falCapsules);
library.add(falPlaneDeparture);
library.add(falHandHoldingUsd);
library.add(falFileInvoiceDollar);
library.add(falEllipsisH);
library.add(falAlarmClock);
library.add(faCheck);
library.add(faCheckSquare);
library.add(faExternalLinkAlt);
library.add(faPrint);
library.add(falMobileAlt);
library.add(falAddressCard);
library.add(falLifeRing);
library.add(falAngleRight);
library.add(falAngleLeft);
library.add(faUpload);
library.add(falHospitalSymbol);
library.add(falUserMdChat);
library.add(falShieldAlt);
library.add(falSearch);
library.add(falFileImport);
library.add(falNewspaper);
library.add(falExternalLink);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faExclamationCircle);
library.add(falExclamationTriangle);
library.add(falHospital);
library.add(falBriefcaseMedical);
library.add(falHandHoldingMedical);
library.add(falEnvelopeOpenDollar);
library.add(faCaretUp);
library.add(faCaretDown);
library.add(faUserCircle);
library.add(falCheck);
library.add(faArrowCircleRight);
library.add(farClock);
library.add(faClock);
library.add(falGlasses);
library.add(falUsersMedical);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
