<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseInput
		:value="value"
		class="p-0 password-input"
		:type="type"
		:label="label"
		:min-length="min"
		:max-length="max"
		:validation-id="vid"
		:validation-rules="{ required: true, min: min, max: max, regex: passwordRegex }"
		:validation-messages="{
			required: requiredMsg,
			min: $t('error.passwordComplexity'),
			max: $t('error.passwordMaxLength', { 0: max }),
			regex: $t('error.passwordComplexity')
		}"
		:automation-id="getAutomationId('password')"
		aria-required="true"
		@keyup="onKeyUp"
		@input="updatePassword"
	>
		<template #labelButton class="passwordButton">
			<BaseButton
				class="m-0 p-0 float-right password-button"
				:label="buttonText"
				data-tracking-id="password-field-visibility-button"
				:aria-label="buttonText"
				:automation-id="getAutomationId('password')"
				variant="link"
				icon-position="left"
				:icon="icon"
				@click="ClickIcon"
			/>
		</template>
	</BaseInput>
</template>
<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseInput from '@/components/common/base/BaseInput';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id.js';
import { required, confirmed, min, max, regex } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('confirmed', confirmed);
extend('min', min);
extend('max', max);
extend('regex', regex);

@Component({
	name: 'PasswordInput',

	components: {
		BaseInput,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		label: {
			type: String,
			default: ''
		},
		vid: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: true
		},
		requiredMessage: {
			type: String,
			default: ''
		},
		automationId: {
			type: String,
			default: ''
		},
		requireComplexity: {
			type: Boolean,
			default: false
		},
		min: {
			type: Number,
			default: 8
		},
		max: {
			type: Number,
			default: 50
		}
	}
})
export default class PasswordInput extends Vue {
	isShowing = false;
	data() {
		return {
			value: '',
			icon: ['fal', 'eye'],
			type: 'password'
		};
	}

	/**
	 * Returns a locale value to prevent accessing a property from a null object.
	 */
	get localValue() {
		return this.value ? this.value : {};
	}

	get LoadedLabel() {
		return this.label ? this.label : this.$t('title.password');
	}

	get passwordRegex() {
		// eslint-disable-next-line no-useless-escape
		return this.requireComplexity ? /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/ : '';
	}

	get requiredMsg() {
		return this.requiredMessage ? this.requiredMessage : this.$t('error.passwordRequired');
	}

	get buttonText() {
		return this.isShowing ? this.$t('button.invisible') : this.$t('button.visible');
	}
	/**
	 *handles passing  of value from child to parent
	 */
	updatePassword(password) {
		this.value = password;
		this.$emit('input', password);
	}

	onKeyUp(value) {
		if (value.length >= this.max) {
			this.value = value.substring(0, max);
		}
	}
	/**
	 * handles click event for text visibility
	 */
	ClickIcon() {
		this.isShowing = !this.isShowing;
		if (this.isShowing) {
			this.icon = ['fal', 'eye-slash'];
			this.type = 'text';
		} else {
			this.icon = ['fal', 'eye'];
			this.type = 'password';
		}
	}
}
</script>
<!-- *************************************************************************
	STYLE
	************************************************************************* -->
<style lang="scss" scoped>
* ::v-deep .input-group-append {
	height: 20px;
	max-height: 20px;
	width: 20px;
	max-width: 20px;
	position: absolute;
	right: 0;
	margin-top: 5px;
	margin-right: 15px;
	z-index: 4;
}
* ::v-deep .btn-link {
	height: 25px;
	color: $blue;
	text-decoration: none;
	& p {
		padding-top: 0;
		&:hover {
			color: $blue;
			text-decoration: none;
		}
	}
}
* ::v-deep ::-ms-reveal {
	display: none;
}
.password-input ::v-deep .label-div {
	justify-content: space-between;
}
</style>

<i18n>
{
	"en": {
		"title": {
			"password": "Password"
		},
		"button": {
			"visible": "Show",
			"invisible": "Hide"
		},
		"error": {
			"passwordRequired": "Please enter a password.",
			"passwordMaxLength": "Your password cannot be longer than {0} characters.",
			"passwordComplexity": "This password isn't complex enough to keep your account safe. Please refer to the password requirements shown to create a secure password."
		}
	},
	"fr": {
		"title": {
			"password": "Mot de passe"
		},
		"button": {
			"visible": "Afficher",
			"invisible": "Masquer"
		},
		"error": {
			"passwordRequired": "Veuillez entrer un mot de passe.",
			"passwordMaxLength": "Votre mot de passe ne peut pas comporter plus de {0} caractères.",
			"passwordComplexity": "Ce mot de passe n'est pas assez complexe pour que votre compte soit sécurisé. Veuillez vous référer aux critères pour les mots de passe pour créer un mot de passe sécuritaire."
		}
	}
}
</i18n>
