<template>
	<errorBoundary id="app" tabindex="-1" :error-msg="error">
		<SkipToContentLink v-if="!hideContent || isLoading" />
		<AppHeader v-if="!hideContent || isLoading" @showLogoutModal="showLogoutModal" />
		<AppBody id="main-content" class="position-relative">
			<div :class="['position-absolute', { 'background-img': hasBackgroundImage }]"></div>
			<router-view></router-view>
		</AppBody>
		<AppFooter v-if="!hideContent || isLoading" automation-id="appFooter" />
		<TimeOutModal
			automation-id="timeOutModal"
			:modal-show="displayTimeOut"
			@click="extendUserTime(true)"
			@close="closeTimeOutModal"
		/>
		<LogoutModal :show-logout-modal="isLogoutModalVisible" @close="closeLogoutModal" />
	</errorBoundary>
</template>

<!-- ************************************************************************* -->

<script>
import Vue from 'vue';
import IdMixin from '@/mixins/id';
import AppHeader from '@/components/layout/AppHeader.vue';
import AppBody from '@/components/layout/AppBody.vue';
import AppFooter from '@/components/layout/AppFooter.vue';
import SkipToContentLink from '@/components/layout/SkipToContentLink';
import Component from 'vue-class-component';
import errorBoundary from '@/components/error/errorBoundary';
import TimeOutModal from '@/components/common/TimeOutModal';
import Navigation from '@/mixins/navigation';
import Authentication from '@/models/Authentication';
import * as Routes from './constants/Routes.js';
import jwtDecode from 'jwt-decode';
import LogoutModal from '@/components/common/LogoutModal';
import { makeDemoServer } from './mirage/demoServer';

Component.registerHooks(['beforeRouteEnter']);

// @vue/component
@Component({
	name: 'App',
	components: {
		AppHeader,
		AppBody,
		AppFooter,
		SkipToContentLink,
		errorBoundary,
		TimeOutModal,
		LogoutModal
	},
	mixins: [IdMixin, Navigation],
	watch: {
		locale() {
			this.updateTitle();
		},
		$route: async function (to) {
			Object.values(this.$route.meta).length !== 0
				? (this.hideContent = false)
				: (this.hideContent = true);
			this.$nextTick(() => {
				/**
				 * because of a weird issue you need to wait a server tick than
				 * set a very small timeout for focus to happen correctly
				 */
				setTimeout(() => {
					let app = document.querySelector('#app');
					if (app !== document.activeElement && to.hash !== '#main-content') {
						app.focus();
					}
				}, 0);
			});
			if (!window.ENV.LOCAL_DEV_DISABLE_TIMEOUT && this.currentRouteRequiresAuth) {
				// attach listeners for user timeout
				this.activateActivityTracker();
				if (!this.userActivityTimeout) {
					this.resetUserActivityTimeout();
				}
			}
			if (this.currentRouteRequiresAuth) {
				//check if demo mode and add query param if true
				if (sessionStorage.getItem('demoActive')) {
					this.$router.replace({
						query: { ...this.$route.query, demoMode: true }
					});
				}
				this.isAuthTokenExpired();
				if (!this.userTokenRefreshTimeOut) {
					this.refreshTokenTimeOut();
				}
			} else {
				this.removeActivityTracker();
			}
		}
	}
})
export default class App extends Vue {
	userActivityTimestamp = null;
	userActivityTimeout = null;
	userActivityLastChanceTimeOut = null;
	userTokenRefreshTimeOut = null;
	//set to 10 minutes before refresh of token
	USER_TOKEN_REFRESH_THRESHOLD = 600000;
	regular_user = 29;
	ip_user = 29;
	displayTimeOut = false;
	hideContent = true;
	isLogoutModalVisible = false;

	mounted() {
		if (sessionStorage.getItem('displayTimeoutModal') === 'true') {
			this.inactiveUserAction();
		}

		if (sessionStorage.getItem('demoActive') && !this.$demoServer) {
			Vue.prototype.$demoServer = makeDemoServer();
		}
		// Check to see if the screen is an iframe. If it is this then don't show loading animation
		if (!sessionStorage.getItem('isIframe')) {
			this.$store.dispatch('updatePageLoadingAnimation', true);
		} else {
			// Remove value once it has been used.
			sessionStorage.removeItem('isIframe');
		}

		this.updateTitle();
		this.updateVersion();
	}
	// This will display the header and footer if the page is loading.
	get isLoading() {
		return this.$store.state.loadingPageAnimation;
	}

	get hasBackgroundImage() {
		return this.$store.state.backgroundImage;
	}

	/**
	 * detach the listeners for user timeout
	 */
	async beforeDestroy() {
		await this.$store.dispatch('clearSession');
		sessionStorage.clear();
		this.removeActivityTracker();
		if (this.$demoServer) this.$demoServer.shutdown();
		localStorage.clear();
	}

	updated() {
		this.$store.dispatch('UpdateLogin', sessionStorage.getItem('loggedIn'));
	}

	get locale() {
		return this.$root.$i18n.locale;
	}
	get error() {
		return {
			name: this.$store.state.error.name,
			text: this.$store.state.error.text,
			isCustomAPIMsg: this.$store.state.error.isCustomAPIMsg
		};
	}

	/**
	 * This returns if the meta data for the given route requires authenticaiton.
	 * If the route does require Auth there will be check to see if the session/token has expired
	 */
	get currentRouteRequiresAuth() {
		return this.$route.meta.requiresAuth;
	}

	updateTitle() {
		document.title = this.$i18n.t('title');
	}

	/** Set the application version information in the html head meta. */
	updateVersion() {
		document
			.querySelector('head meta[name="app-version"]')
			.setAttribute('content', window.VERSION.PACKAGE_VERSION);
		document
			.querySelector('head meta[name="build-number"]')
			.setAttribute('content', window.VERSION.BUILD_NUMBER);
		document
			.querySelector('head meta[name="build-date"]')
			.setAttribute('content', window.VERSION.BUILD_DATE);
	}

	resetUserActivityTimeout() {
		this.userActivityTimestamp = new Date();
		if (!this.userActivityTimeout) {
			this.userActivityTimeout = setInterval(() => {
				this.checkSessionTimeout();
			}, 10000);
		}
	}

	checkSessionTimeout() {
		if (this.userActivityTimestamp) {
			var minutes = Math.abs((this.userActivityTimestamp - new Date()) / 1000 / 60);
			if (!sessionStorage.getItem('token')) {
				this.removeActivityTracker();
				return;
			}
			let token = jwtDecode(sessionStorage.getItem('token'));
			let checkTime = this.regular_user;
			if (token.groups.includes('MEMBER')) {
				checkTime = this.regular_user;
			}
			if (minutes >= checkTime) {
				this.inactiveUserAction();
			}
		}
	}

	refreshTokenTimeOut() {
		if (this.userTokenRefreshTimeOut) {
			clearInterval(this.userTokenRefreshTimeOut);
		}
		this.userTokenRefreshTimeOut = setInterval(() => {
			this.extendUserTime(false);
		}, this.USER_TOKEN_REFRESH_THRESHOLD);
	}

	activateActivityTracker() {
		window.addEventListener('mousedown', this.resetUserActivityTimeout);
		window.addEventListener('scroll', this.resetUserActivityTimeout);
		window.addEventListener('keydown', this.resetUserActivityTimeout);
	}

	removeActivityTracker() {
		window.removeEventListener('mousedown', this.resetUserActivityTimeout);
		window.removeEventListener('scroll', this.resetUserActivityTimeout);
		window.removeEventListener('keydown', this.resetUserActivityTimeout);

		if (this.userActivityTimeout) clearInterval(this.userActivityTimeout);
		if (this.userTokenRefreshTimeOut) clearInterval(this.userTokenRefreshTimeOut);
	}

	inactiveUserAction() {
		sessionStorage.setItem('displayTimeoutModal', true);
		this.displayTimeOut = true;
		clearInterval(this.userActivityTimeout);
		this.userActivityTimeout = null;
		this.userActivityLastChanceTimeOut = setTimeout(() => {
			// This dispatch is here so if the user times out in the claim submission they won't recieve the claim modal asking if they want to abandon the claim.
			this.$store.dispatch('isOkToLeaveClaimSubmission', true);
			this.timeoutRedirect();
			this.displayTimeOut = false;
			sessionStorage.setItem('displayTimeoutModal', false);
		}, 300000);
	}

	async extendUserTime(isFromClick) {
		// If the user has the timeout modal display and that is counting down
		// then do not extend the time.
		if (!this.userActivityLastChanceTimeOut || isFromClick) {
			this.isAuthTokenExpired();
			if (this.$route.name === Routes.LOGIN) {
				sessionStorage.setItem('displayTimeoutModal', false);
				this.displayTimeOut = false;
			} else {
				if (isFromClick) {
					this.resetUserActivityTimeout();
					clearTimeout(this.userActivityLastChanceTimeOut);
				}
				let user = sessionStorage.getItem('email');
				if (user) {
					Authentication.refreshToken(
						user,
						sessionStorage.getItem('apiToken'),
						this.$root.$i18n.locale
					);
				}
			}
			sessionStorage.setItem('displayTimeoutModal', false);
			this.displayTimeOut = false;
		}
	}
	showLogoutModal() {
		this.isLogoutModalVisible = true;
	}
	closeLogoutModal() {
		this.isLogoutModalVisible = false;
	}
	closeTimeOutModal() {
		this.displayTimeOut = false;
		sessionStorage.setItem('displayTimeoutModal', false);
	}
}
</script>

<!-- ************************************************************************* -->

<style lang="scss">
/* 
  We use flex to make a "conditional" sticky footer.  The footer is only sticky when the content is less than 
  the viewport height.  This way, on mobile, more of the main content will be visible instead of being hidden
  by the footer.
*/
#app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.background-img {
	background-image: url('./assets/dashboard-back.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	left: 0;
	right: 0;
	height: 250px;
	z-index: -1;
}

header,
footer {
	flex-grow: 0; /* Header and footer do not grow in height */
}

main {
	flex-grow: 1; /* The main content grows to take all available height. */
}
/* End of "conditional" sticky footer styling. */

/*
  NOTE: Please do not add any more css to this file.
        Any future "global" css should be part of the theme files in src/style.
*/
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
		"title": "Member Services Site"

	},
	"fr": {
		"title": "Site Web des adhérents"

	}
}
</i18n>
