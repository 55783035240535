<template>
	<div>
		<BSkeletonWrapper
			v-bind="$attrs"
			:loading="isLoading"
			:automation-id="getAutomationId('skeletonWrapper')"
		>
			<template #loading>
				<slot name="loading"></slot>
			</template>
			<template #default>
				<slot></slot>
			</template>
		</BSkeletonWrapper>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BSkeletonWrapper } from 'bootstrap-vue';

@Component({
	name: 'BaseSkeletonWrapper',
	components: {
		BSkeletonWrapper
	},
	inheritAttrs: false,
	mixins: [IdMixin]
})
export default class BaseSkeletonWrapper extends Vue {
	get isLoading() {
		return this.$store.state.loadingPageAnimation;
	}
}
</script>
