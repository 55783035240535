export default function (server) {
	server.get('/members/:id/hsa-eligible-claims', () => {
		return {
			'01': [
				{
					serviceDate: '20210317',
					registrationNum: '21076T0035',
					lob: '05',
					paidAmount: 66.35,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 208.65,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037180,
							claimSource: 'SCP',
							sourceRefNum: 20,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210317',
							serviceDate: '20210317',
							lob: '05',
							benefitCode: 'D43411',
							toothCD: '00',
							submittedAmount: 150,
							paidAmount: 40.72,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21076T0035',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '00',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 109.28
						},
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037181,
							claimSource: 'SCP',
							sourceRefNum: 21,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210317',
							serviceDate: '20210317',
							lob: '05',
							benefitCode: 'D01200',
							toothCD: '00',
							submittedAmount: 125,
							paidAmount: 25.63,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21076T0035',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '00',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 99.37
						}
					]
				},
				{
					serviceDate: '20210318',
					registrationNum: '21077T0052',
					lob: '05',
					paidAmount: 19.19,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 70.81,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037320,
							claimSource: 'SCP',
							sourceRefNum: 50,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210318',
							serviceDate: '20210318',
							lob: '05',
							benefitCode: 'D01200',
							toothCD: '00',
							submittedAmount: 30,
							paidAmount: 0,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21077T0052',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '00',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 30
						},
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037321,
							claimSource: 'SCP',
							sourceRefNum: 52,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210318',
							serviceDate: '20210318',
							lob: '05',
							benefitCode: 'D11111',
							toothCD: '00',
							submittedAmount: 60,
							paidAmount: 19.19,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21077T0052',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '00',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 40.81
						}
					]
				},
				{
					serviceDate: '20210224',
					registrationNum: '21078T0046',
					lob: '03',
					paidAmount: 3.2,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 0.8,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037386,
							claimSource: 'WPP',
							sourceRefNum: 110,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210319',
							serviceDate: '20210224',
							lob: '03',
							benefitCode: '02802',
							submittedAmount: 4,
							paidAmount: 3.2,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21078T0046',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 0.8
						}
					]
				},
				{
					serviceDate: '20210305',
					registrationNum: '21081T0099',
					lob: '03',
					paidAmount: 12,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 3,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037497,
							claimSource: 'SCP',
							sourceRefNum: 160,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210322',
							serviceDate: '20210305',
							lob: '03',
							benefitCode: '02261',
							submittedAmount: 15,
							paidAmount: 12,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21081T0099',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 3
						}
					]
				},
				{
					serviceDate: '20210101',
					registrationNum: '21083T0041',
					lob: '03',
					paidAmount: 20,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 5,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037722,
							claimSource: 'SCP',
							sourceRefNum: 260,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210324',
							serviceDate: '20210101',
							lob: '03',
							benefitCode: '02221',
							submittedAmount: 25,
							paidAmount: 20,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21083T0041',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 5
						}
					]
				},
				{
					serviceDate: '20210228',
					registrationNum: '21113T0127',
					lob: '03',
					paidAmount: 8,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 2,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5038360,
							claimSource: 'WPP',
							sourceRefNum: 450,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210423',
							serviceDate: '20210228',
							lob: '03',
							benefitCode: '02221',
							submittedAmount: 10,
							paidAmount: 8,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21113T0127',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 2
						}
					]
				},
				{
					serviceDate: '20210609',
					registrationNum: '21161T0032',
					lob: '03',
					paidAmount: 2,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 0.5,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5039578,
							claimSource: 'WPP',
							sourceRefNum: 590,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20210610',
							serviceDate: '20210609',
							lob: '03',
							benefitCode: '02802',
							submittedAmount: 2.5,
							paidAmount: 2,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21161T0032',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 0.5
						}
					]
				},
				{
					serviceDate: '20211004',
					registrationNum: '21277T0033',
					lob: '03',
					paidAmount: 0,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 10,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5042098,
							claimSource: 'WPP',
							sourceRefNum: 600,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20211004',
							serviceDate: '20211004',
							lob: '03',
							benefitCode: '0263',
							submittedAmount: 10,
							paidAmount: 0,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21277T0033',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 10
						}
					]
				},
				{
					serviceDate: '20210922',
					registrationNum: '21277T0035',
					lob: '03',
					paidAmount: 0,
					eligibleAmount: 7,
					unclaimedEligibleAmount: 7,
					calculatedEligibleAmount: 7,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5042120,
							claimSource: 'WPP',
							sourceRefNum: 620,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20211004',
							serviceDate: '20210922',
							lob: '03',
							benefitCode: '0263',
							submittedAmount: 10,
							paidAmount: 0,
							hsaEligibleAmount: 7,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 7,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21277T0035',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '        CC',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 7
						}
					]
				},
				{
					serviceDate: '20210913',
					registrationNum: '21277T0037',
					lob: '03',
					paidAmount: 0,
					eligibleAmount: 6.2,
					unclaimedEligibleAmount: 6.2,
					calculatedEligibleAmount: 6.2,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5042125,
							claimSource: 'WPP',
							sourceRefNum: 640,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20211004',
							serviceDate: '20210913',
							lob: '03',
							benefitCode: '0263',
							submittedAmount: 7,
							paidAmount: 0,
							hsaEligibleAmount: 6.2,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 6.2,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21277T0037',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '        CC',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 6.2
						}
					]
				},
				{
					serviceDate: '20211005',
					registrationNum: '21278T0033',
					lob: '03',
					paidAmount: 0,
					eligibleAmount: 28.89,
					unclaimedEligibleAmount: 28.89,
					calculatedEligibleAmount: 28.89,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5042158,
							claimSource: 'WPP',
							sourceRefNum: 650,
							groupId: '17350',
							section: '659',
							participant: '02',
							datePaid: '20211005',
							serviceDate: '20211005',
							lob: '03',
							benefitCode: '0263',
							submittedAmount: 30,
							paidAmount: 0,
							hsaEligibleAmount: 28.89,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 28.89,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21278T0033',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '        CC',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 28.89
						}
					]
				}
			],
			'02': [
				{
					serviceDate: '20210317',
					registrationNum: '21076T0034',
					lob: '03',
					paidAmount: 16,
					eligibleAmount: 4,
					unclaimedEligibleAmount: 4,
					calculatedEligibleAmount: 4,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037179,
							claimSource: 'SCP',
							sourceRefNum: 11,
							groupId: '17350',
							section: '659',
							participant: '03',
							datePaid: '20210317',
							serviceDate: '20210317',
							lob: '03',
							benefitCode: '02222',
							submittedAmount: 20,
							paidAmount: 16,
							hsaEligibleAmount: 4,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 4,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21076T0034',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 4
						}
					]
				},
				{
					serviceDate: '20210323',
					registrationNum: '21083T0032',
					lob: '03',
					paidAmount: 40,
					eligibleAmount: 5,
					unclaimedEligibleAmount: 5,
					calculatedEligibleAmount: 5,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037698,
							claimSource: 'WPP',
							sourceRefNum: 210,
							groupId: '17350',
							section: '659',
							participant: '03',
							datePaid: '20210324',
							serviceDate: '20210323',
							lob: '03',
							benefitCode: '02264',
							submittedAmount: 50,
							paidAmount: 40,
							hsaEligibleAmount: 5,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 5,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21083T0032',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '        CC',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 5
						}
					]
				},
				{
					serviceDate: '20210319',
					registrationNum: '21083T0033',
					lob: '03',
					paidAmount: 20,
					eligibleAmount: 5,
					unclaimedEligibleAmount: 5,
					calculatedEligibleAmount: 5,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037718,
							claimSource: 'WPP',
							sourceRefNum: 220,
							groupId: '17350',
							section: '659',
							participant: '03',
							datePaid: '20210324',
							serviceDate: '20210319',
							lob: '03',
							benefitCode: '02264',
							submittedAmount: 25,
							paidAmount: 20,
							hsaEligibleAmount: 5,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 5,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21083T0033',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 5
						}
					]
				},
				{
					serviceDate: '20210324',
					registrationNum: '21084T0042',
					lob: '03',
					paidAmount: 40,
					eligibleAmount: 10,
					unclaimedEligibleAmount: 10,
					calculatedEligibleAmount: 10,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037840,
							claimSource: 'SCP',
							sourceRefNum: 290,
							groupId: '17350',
							section: '659',
							participant: '03',
							datePaid: '20210325',
							serviceDate: '20210324',
							lob: '03',
							benefitCode: '02261',
							submittedAmount: 50,
							paidAmount: 40,
							hsaEligibleAmount: 10,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 10,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21084T0042',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 10
						}
					]
				},
				{
					serviceDate: '20211004',
					registrationNum: '21277T0034',
					lob: '03',
					paidAmount: 0,
					eligibleAmount: 5,
					unclaimedEligibleAmount: 5,
					calculatedEligibleAmount: 5,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5042118,
							claimSource: 'WPP',
							sourceRefNum: 610,
							groupId: '17350',
							section: '659',
							participant: '03',
							datePaid: '20211004',
							serviceDate: '20211004',
							lob: '03',
							benefitCode: '0263',
							submittedAmount: 5,
							paidAmount: 0,
							hsaEligibleAmount: 5,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 5,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21277T0034',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 5
						}
					]
				},
				{
					serviceDate: '20210903',
					registrationNum: '21277T0036',
					lob: '03',
					paidAmount: 0,
					eligibleAmount: 9.1,
					unclaimedEligibleAmount: 9.1,
					calculatedEligibleAmount: 9.1,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5042123,
							claimSource: 'WPP',
							sourceRefNum: 630,
							groupId: '17350',
							section: '659',
							participant: '03',
							datePaid: '20211004',
							serviceDate: '20210903',
							lob: '03',
							benefitCode: '0263',
							submittedAmount: 10,
							paidAmount: 0,
							hsaEligibleAmount: 9.1,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 9.1,
							hsaRequestedFlag: false,
							hsaAvailableFlag: true,
							claimEligibleFlag: 'Y',
							registrationNumber: '21277T0036',
							adjustmentFlag: false,
							payProvider: false,
							claimInfo: '        CC',
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 9.1
						}
					]
				}
			],
			'03': [
				{
					serviceDate: '20210303',
					registrationNum: '21082T0070',
					lob: '03',
					paidAmount: 32,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 8,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037600,
							claimSource: 'SCP',
							sourceRefNum: 180,
							groupId: '17350',
							section: '659',
							participant: '04',
							datePaid: '20210323',
							serviceDate: '20210303',
							lob: '03',
							benefitCode: '02261',
							submittedAmount: 40,
							paidAmount: 32,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21082T0070',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 8
						}
					]
				},
				{
					serviceDate: '20210304',
					registrationNum: '21082T0071',
					lob: '03',
					paidAmount: 32,
					eligibleAmount: 0,
					unclaimedEligibleAmount: 0,
					calculatedEligibleAmount: 8,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037601,
							claimSource: 'SCP',
							sourceRefNum: 190,
							groupId: '17350',
							section: '659',
							participant: '04',
							datePaid: '20210323',
							serviceDate: '20210304',
							lob: '03',
							benefitCode: '02261',
							submittedAmount: 40,
							paidAmount: 32,
							hsaEligibleAmount: 0,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 0,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'C',
							registrationNumber: '21082T0071',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 8
						}
					]
				},
				{
					serviceDate: '20210324',
					registrationNum: '21084T0043',
					lob: '03',
					paidAmount: 40,
					eligibleAmount: 8,
					unclaimedEligibleAmount: 8,
					calculatedEligibleAmount: 8,
					services: [
						{
							regionCd: '01',
							certNum: '000000023',
							claimId: 5037841,
							claimSource: 'SCP',
							sourceRefNum: 300,
							groupId: '17350',
							section: '659',
							participant: '04',
							datePaid: '20210325',
							serviceDate: '20210324',
							lob: '03',
							benefitCode: '02261',
							submittedAmount: 50,
							paidAmount: 40,
							hsaEligibleAmount: 8,
							hsaPaidAmount: 0,
							hsaUnclaimedEligibleAmount: 8,
							hsaRequestedFlag: false,
							hsaAvailableFlag: false,
							claimEligibleFlag: 'Y',
							registrationNumber: '21084T0043',
							adjustmentFlag: false,
							payProvider: false,
							craDepFlag: 'N',
							updatedCobAmount: 2,
							amountPaidByOtherCarrier: 0,
							hsaUnclaimedEligibleUpd: 'N',
							calculatedEligibleAmount: 8
						}
					]
				}
			]
		};
	});
}
