/**
 * Handler for pwa coverage api
 */

import pwaList_en from './../../objects/benefit_pwa_list.json';
import pwaList_fr from './../../objects/benefit_pwa_list_fr.json';
import { Response } from 'miragejs';

export default function (server) {
	/**
	 * returns list of available pwa
	 */

	server.get('/members/:id/coverages/personal-wellness-benefits', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en' ? pwaList_en : pwaList_fr;
	});

	server.get('/members/:id/coverages/personal-wellness-benefits/reports/annual/pdf', () => {
		return new Response(204);
	});
}
