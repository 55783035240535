import Vue from 'vue';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'mutationobserver-shim';
import './plugins/bootstrap-vue';
import './plugins/vee-validate';
import './plugins/vue-fontawesome';
import './plugins/vue-fuse';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/plugins/vue-i18n';
import { initEnvVars } from './utils/LoadEnvConfig';
import { initEnvVars as initAppVersionVars } from './utils/LoadAppVersion';
import { initMemberServiceApiClient } from '@/services/MemberService';
import { makeServer } from './mirage/server';
import scrollTo from '@/directives/scrollTo.js';
import axios from 'axios';

Vue.config.productionTip = false;
Vue.directive('scroll-to', scrollTo);

// Load the application versions to be displayed in the html.
try {
	initAppVersionVars();
} catch (error) {
	throw new Error(error);
}

/** Initialize environment variables and then start the Vue instance. */
initEnvVars().then(() => {
	/** Initialize the Enrolments API client only once. */
	try {
		initMemberServiceApiClient();
	} catch (error) {
		throw new Error(error);
	}

	// Start the Miragejs mock server
	if (window.ENV.LOCAL_DEV_USE_MIRAGE) {
		makeServer();
	}

	/* VWO and AppDynamics.  Deploy pipeline config is currently set to
     enables for acceptance and production only. */
	(async () => {
		if (window.ENV.ENABLE_GTM) {
			await import('./assets/js/vwo');
			await import('./assets/js/app-dynamics.js');
		}
	})();

	axios.interceptors.request.use(
		(config) => {
			config.headers['Client'] = 'MemberSite';
			return config;
		},
		// reject promise if an error is thrown.
		(error) => Promise.reject(error)
	);
	Vue.prototype.$demoServer = undefined;

	new Vue({
		router,
		store,
		i18n,
		render: (h) => h(App)
	}).$mount('#app');
});
