/**
 * Handler for external coverage api
 */

import { Response } from 'miragejs';

export default function (server) {
	/**
	 *
	 */
	server.get('members/:id/external-coverages', () => {
		return new Response(204);
	});
}
