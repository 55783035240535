import { Response } from 'miragejs';

/**
 * resource urls end point
 */
export default function (server) {
	server.get('/members/:id/resource-urls', () => {
		return {
			policyId: '0016702',
			divisionId: '001',
			claimRequirementsUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/requirements',
			claimTermsUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/terms',
			contactUsUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/contact',
			eulaUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/eula',
			healthResourcesUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/healthresources',
			helpUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/help',
			hsaDisclaimerUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/hsadisclaimer',
			hsaInfoUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/hsainfo',
			legalUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/legal',
			privacyUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/privacy',
			travelContactUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/travelcontact',
			json_contactUrl: `${window.ENV.VUE_APP_RAINFOREST_SERVICE}/rainforest/members/contact`,
			json_claimTermsUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/terms.json',
			json_eulaUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/eula.json',
			json_helpUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/help.json',
			json_hsaDisclaimerUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/hsadisclaimer.json',
			json_hsaInfoUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/hsainfo.json',
			json_legalUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/legal.json',
			json_privacyUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/privacy.json',
			json_travelContactUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/travel.json',
			json_psaInfoUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/psainfo.json',
			json_padUrl: 'https://d3h2ws3410wq1f.cloudfront.net/MEDAVIE/pad.json',
			json_claimNonRtaUrl: 'https://d3h2ws3410wq1f.cloudfront.net/Claims/non_rta_health_types.json'
		};
	});

	server.post('/members/:id/contact-us-email', () => {
		return new Response(200);
	});

	server.get('/members/contact', () => {
		return {
			tollFreeText: 'Toll-free Customer Support',
			tollFree: '18006674511',
			email: 'inquiry@medavie.bluecross.ca',
			hrsOfOperationRegions: [
				{
					name: 'Alberta',
					value: 'Monday to Friday, 6 a.m. to 6 p.m. local time'
				},
				{
					name: 'Atlantic Canada',
					value: 'Monday to Friday, 8 a.m. to 8 p.m. local time'
				},
				{
					name: 'British Columbia',
					value: 'Monday to Friday, 8 a.m. to 4:30 p.m. local time'
				},
				{
					name: 'Manitoba',
					value: 'Monday to Friday, 8 a.m. to 5:30 p.m. local time'
				},
				{
					name: 'Ontario',
					value: 'Monday to Friday, 8 a.m. to 8 p.m. local time'
				},
				{
					name: 'Quebec',
					value: 'Monday to Friday, 8 a.m. to 8 p.m. local time'
				},
				{
					name: 'Saskatchewan',
					value: 'Monday to Friday, 8:30 a.m. to 5 p.m. local time'
				}
			],
			website: 'www.medaviebc.ca'
		};
	});
}
