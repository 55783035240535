/**
 * Submit Claim / service Type
 *
 * Note: PWA and HSA are benefits under LOB 03.  The same as chiro, physio etc
 */

import paraServices_en from './../../objects/claim_services_para.json';
import paraServices_fr from './../../objects/claim_services_para_fr.json';

import pwaServices_en from './../../objects/claim_services_pwa.json';
import pwaServices_fr from './../../objects/claim_services_pwa_fr.json';

export default function (server) {
	server.get('/members/:id/service-types', (schema, request) => {
		if (!request.queryParams['specialties'].includes('-PARA', '-INDI')) {
			return request.requestHeaders['Locale'] === 'en' ? pwaServices_en : pwaServices_fr;
		}
		return request.requestHeaders['Locale'] === 'en' ? paraServices_en : paraServices_fr;
	});
}
