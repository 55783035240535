/**
 * Extended Health Coverage Details
 */
export default function (server) {
	server.get('/members/:id/coverages/extended-health-benefits/:benefitCode/details', () => {
		return {
			overallProvisions: [
				{
					description: '- 80% reimbursement.'
				},
				{
					description: '- Claim submission deadline: 24 months from date of service.'
				}
			],
			healthCareProductsMap: {
				'0730IC': {
					shortDescription: 'Air transportation stretcher case in Canada',
					provisions: [
						{
							description:
								'Please refer to the Toll Free number on your card and contact your local Blue Cross to confirm your benefit provisions.  When placing your call, please quote reference number M006.'
						},
						{
							description:
								'- Maximum reimbursement is $420 payable for each participant every calendar year in combination with ground transportation.'
						}
					],
					claimRequirements: [
						{
							description: '- Original paid-in-full receipt and prescription from a medical doctor.'
						},
						{
							description:
								'- Expense eligible based on the required number of seats to a maximum of six on a regularly scheduled flight.'
						}
					],
					longDescription:
						'The cost of airfare for six economy seats to accommodate a stretcher on a commercial airline to transport a patient to the nearest hospital within Canada'
				}
			}
		};
	});
}

/*  Other examples
{
  "overallProvisions": [
    {
      "description": "- 80% reimbursement."
    },
    {
      "description": "- Claim submission deadline: 24 months from date of service."
    }
  ],
  "healthCareProductsMap": {
    "0731IP": {
      "shortDescription": "Ground transportation in province",
      "provisions": [
        {
          "description": "Please refer to the Toll Free number on your card and contact your local Blue Cross to confirm your benefit provisions.  When placing your call, please quote reference number M006."
        },
        {
          "description": "- Maximum reimbursement is $420 payable for each participant every calendar year in combination with ground transportation."
        }
      ],
      "claimRequirements": [
        {
          "description": "- Click on the highlighted word to obtain the benefit / product description. Certain conditions may apply."
        },
        {
          "description": "- Original paid-in-full receipt."
        },
        {
          "description": "- Points of origin and destination."
        }
      ],
      "longDescription": "A licensed ground vehicle, containing first aid equipment, oxygen and resuscitators, used for transporting sick and injured persons to and/or from the nearest hospital or home within their province of residence. The provider must be licensed by the Department of Health as an emergency transportation service provider."
    }
  }
}
{
  "overallProvisions": [
    {
      "description": "- 80% reimbursement."
    },
    {
      "description": "- Claim submission deadline: 24 months from date of service."
    }
  ],
  "healthCareProductsMap": {
    "0731OP": {
      "shortDescription": "Ground transportation out of province",
      "provisions": [
        {
          "description": "Please refer to the Toll Free number on your card and contact your local Blue Cross to confirm your benefit provisions.  When placing your call, please quote reference number M006."
        },
        {
          "description": "- Maximum reimbursement is $420 payable for each participant every calendar year in combination with ground transportation."
        }
      ],
      "claimRequirements": [
        {
          "description": "- Click on the highlighted word to obtain the benefit / product description. Certain conditions may apply."
        },
        {
          "description": "- Original paid-in-full receipt."
        },
        {
          "description": "- Points of origin and destination."
        }
      ],
      "longDescription": "A licensed ground vehicle containing first aid equipment, oxygen and resuscitators, used for transporting sick and injured persons to and/or from the nearest hospital or home outside of their province of residence. The provider must be licensed by the Department of Health as an emergency transportation service provider."
    }
  }
}
  {
  "overallProvisions": [
    {
      "description": "- 80% reimbursement."
    },
    {
      "description": "- Claim submission deadline: 24 months from date of service."
    }
  ],
  "healthCareProductsMap": {
    "0733IC": {
      "shortDescription": "Special ambulance attendant in Canada",
      "provisions": [
        {
          "description": "- Maximum eligible expense is $350."
        },
        {
          "description": "- Maximum reimbursement is $280 payable for each participant every calendar year."
        }
      ],
      "claimRequirements": [
        {
          "description": "- Click on the highlighted word to obtain the benefit / product description. Certain conditions may apply."
        },
        {
          "description": "- Original paid-in-full receipt and prescription from a medical doctor."
        }
      ],
      "longDescription": "Charges for traveling expenses of a qualified registered nurse to accompany a patient in an ambulance within Canada."
    }
  }
}

    {
  "overallProvisions": [
    {
      "description": "- 80% reimbursement."
    },
    {
      "description": "- Claim submission deadline: 24 months from date of service."
    }
  ],
  "healthCareProductsMap": {
    "0507PB": {
      "shortDescription": "Back brace purchase",
      "provisions": [
        {
          "description": "- Frequency of one occurrence in a lifetime in combination with back/neck brace."
        },
        {
          "description": "- Maximum eligible expense is subject to the usual, customary and reasonable fee established by Blue Cross for the participant's province of residence."
        }
      ],
      "claimRequirements": [
        {
          "description": "- Click on the highlighted word to obtain the benefit / product description. Certain conditions may apply."
        },
        {
          "description": "- Original paid-in-full receipt and prescription from a medical doctor."
        },
        {
          "description": "- Pharmacy purchases require either a computerized pharmacy receipt or a hand-written receipt from the pharmacy accompanied by a cash register slip."
        }
      ],
      "longDescription": "A rigid or semi-rigid support appliance worn to support the lower back to maintain good posture and correct and/or prevent deformities."
    }
  }
}
*/

/* 

Samples in FR:

{
  "overallProvisions": [
    {
      "description": "- Remboursement à 80 %."
    },
    {
      "description": "- Date limite de soumission des demandes de règlement : 24 mois après la date de service."
    }
  ],
  "healthCareProductsMap": {
    "0733IC": {
      "shortDescription": "Ambulance auxiliaire spéciale au Canada",
      "provisions": [
        {
          "description": "- Les frais maximums admissibles sont de 350 $."
        },
        {
          "description": "- Le remboursement maximal se chiffre à 280 $ par participant par année civile."
        }
      ],
      "claimRequirements": [
        {
          "description": "- Cliquez sur le mot ombré pour obtenir la description du service ou produit assuré. Certaines conditions peuvent s'appliquer."
        },
        {
          "description": "- Reçu original de paiement intégral et ordonnance d'un médecin."
        }
      ],
      "longDescription": "Frais de déplacement d'un infirmier immatriculé qualifié accompagnant un patient dans une ambulance au Canada."
    }
  }
}

*/
