/**
 * handles claim history api
 */
import { modifyTheData, sortTheData } from '@/mirage/helpers';
import claimHistory_en from './../../objects/claim_history.json';
import claimHistory_fr from './../../objects/claim_history_fr.json';

export default function (server) {
	/**
	 * returns list of claim
	 */
	server.get('members/:id/claim-histories', (schema, request) => {
		let dataToSendBack = '';
		if (request.requestHeaders['Accept-Language'] === 'en') {
			dataToSendBack = claimHistory_en;
		} else {
			dataToSendBack = claimHistory_fr;
		}

		for (let j = 0; j < dataToSendBack.length; j++) {
			modifyTheData(dataToSendBack[j].claimLines, 'serviceDate', 10, 0);
			sortTheData(dataToSendBack[j].claimLines, 'serviceDate');
		}

		return dataToSendBack;
	});
}
