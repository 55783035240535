import axios from 'axios';
import { Model } from '@vuex-orm/core';

/**
 * This class represent a Member
 */
export default class Forms extends Model {
	static async getForm(username, formId, token, locale) {
		try {
			let response = await axios.get(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/forms/${formId}`,
				{
					headers: {
						'content-type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					}
				}
			);
			return response;
		} catch (err) {
			this.store().dispatch('error', {
				name: `get${formId}Form`,
				err
			});
		}
	}

	static async getUpdateParticipantsForm(username, bookOfBusiness, token, locale) {
		try {
			let response = await axios.get(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/forms/DEP?bookOfBusiness=${bookOfBusiness}`,
				{
					headers: {
						'content-type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					}
				}
			);
			return response;
		} catch (err) {
			this.store().dispatch('error', {
				name: `get${bookOfBusiness}Form`,
				err
			});
		}
	}
}
