/**
 * Submit claim - is there any HSA or PWA amount left?  True means they have a balance and false they don't.
 *
 * zero-balances?type=hsa
 * zero-balances?type=pwa
 */
export default function (server) {
	server.get('/members/:id/coverages/health-spending-products/balances', () => {
		return 'true';
	});
}
