/**
 * Vuex-orm model to save comparable drug search parameters and search results.
 */
import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default class DrugCoverageComparable extends Model {
	static entity = 'drugCoverageComparable';
	static primaryKey = ['locale', 'id', 'dins'];

	static fields() {
		return {
			locale: this.string(null).nullable(),
			id: this.string(null).nullable(), // comparable drug index, the name of the drug which comparable drugs are being searched.
			dins: this.attr(null).nullable(),
			drugDetailsResult: this.attr(null).nullable(), // Saved found results
			searchParameters: this.attr(null).nullable() //Saved search parameters
		};
	}

	/**
	 * Make a service call to get drug details.  Store the reponse in the model.
	 * @param {String} drugDin
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {String} drugIndex
	 * @param {Object} searchParameters
	 * @returns {Array} Claims for a member.
	 */
	static async getComparableDetail(username, token, locale, searchParameters) {
		if (this._isMissingSearchParameters(searchParameters, 3)) {
			return [];
		}

		const { participant, drugDins, provinceOfAdjudication, drugIndex } = searchParameters;
		// all the drug dins are converted to comma separated strings and are passed to the api to get all the comparable drug details in one call
		const drugDin = drugDins.toString();

		const localizedDrugDetails = await DrugCoverageComparable.find([locale, drugIndex, drugDin]);

		if (localizedDrugDetails) {
			return localizedDrugDetails.drugDetailsResult;
		}

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/drug-benefits?dins=${drugDin}&participant=${participant}&province=${provinceOfAdjudication}`;

		startSpinner();

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			stopSpinner();

			if (response?.status === 200) {
				// Api call is good, transform data and add it to vuex-orm.
				const drugDetails = this._transformData(response.data, searchParameters);

				await DrugCoverageComparable.insertOrUpdate({
					data: {
						locale: locale,
						id: drugIndex,
						dins: drugDin,
						drugDetailsResult: drugDetails,
						searchParameters
					}
				});
				stopSpinner();
				return drugDetails;
			} else if (response?.status === 204) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await DrugCoverageComparable.insertOrUpdate({
					data: {
						locale: locale,
						id: drugIndex,
						dins: drugDin,
						drugDetailsResult: response?.data,
						searchParameters
					}
				});
				stopSpinner();
				// Return response instead of querying Vuex-orm for better performance.
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			return error.response;
		}
	}

	/**
	 * Transform from web service response format to UI object format.  We do this to make it easier to loop through
	 * data to display UI components.  We also manipulate data to remove unwanted characters.  If any object keys
	 * are missing we return a null value.
	 * eg: in this case, the response (data) has a key 'eligibility' which does not exist in searchParameters.drugArray.
	 * similarly searchParameters.drugArray does not have 'isGeneric'. So here, all the data that are needed to display in the UI are extracted and returned as transformed.
	 * @param {Object} data - The raw response object from the comparable drug details service call.
	 * @param {Object} searchParameters
	 * @returns {Array} - An array transformed into a UI friendly format
	 */

	static _transformData(data, searchParameters) {
		let transformed = [];
		data.forEach((val) => {
			let obj = {};
			searchParameters.drugArray.forEach((d) => {
				if (val.drugDin === d.drugDin) {
					obj.drugDin = val?.drugDin || null;
					obj.drugName = d?.drugName || null;
					obj.eligibility = val?.eligibility || null;
					obj.isGeneric = d?.isGeneric || false;
					obj.tier = val?.tier || null;
					obj.webCode = val?.webCode || null;
				}
			});
			transformed.push(obj);
		});

		return transformed;
	}

	/**
	 * Utility function to check if there are any missing search parameters.
	 *
	 * @param {Object} searchParameters
	 * @param {Number} expectedNumberOfParameters - How many parameters should there be?
	 * @returns {Boolean}
	 * @example _isMissingSearchParameters({ participant: '03'}, 1)
	 */
	static _isMissingSearchParameters(searchParameters, expectedNumberOfParameters) {
		return Object.keys(searchParameters).length < expectedNumberOfParameters;
	}
}
