/**
 * Return a PDF of a member's benefits.
 */

import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class BenefitsBooklet extends Model {
	static entity = 'benefitsBooklet';

	static fields() {
		return {
			id: this.string(null).nullable(), // Member email
			bookletId: this.string(null).nullable() // Member Booklet Id
		};
	}

	/**
	 * Call web service to get a member's benefits booklet.  Parameters are optional.  If none provided,
	 * the method will get them from the session.
	 */
	static async getBenefitsBookletId({ username, token, locale } = this._getParameters()) {
		let bookletId = await BenefitsBooklet.find(username)?.bookletId;
		if (!bookletId) {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/booklets`;

			try {
				let response = await axios.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					},
					responseType: 'json'
				});
				// Service call is good, add data to vuex-orm (even when empty response).
				if (response?.status === 200 && response.data != null && response.data.bookletId != null) {
					await BenefitsBooklet.create({
						data: {
							id: username,
							bookletId: response.data.bookletId
						}
					});
					// Return response instead of querying Vuex-orm for better performance.
					return response?.data.bookletId;
				} else {
					// Anything else we want to return null so that we don't show anything to the user
					return null;
				}
			} catch (error) {
				throw null;
			}
		} else {
			return bookletId;
		}
	}

	/**
	 * Call web service to get a member's benefits booklet.  Parameters are optional.  If none provided,
	 * the method will get them from the session.
	 */
	static async getBenefitsBooklet({ username, token, locale } = this._getParameters()) {
		let bookletId = await BenefitsBooklet.find(username)?.bookletId;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/booklet?id=${bookletId}`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/pdf',
					'Accept-Language': locale,
					Authorization: token
				},
				responseType: 'blob'
			});

			if (response?.status === 200) {
				this._downloadBooklet(response.data, locale);
				return true;
			} else {
				throw new Error();
			}
		} catch (error) {
			// This catch is a bit different from others in the MSS app.  The booklet download component has it's own
			// warning/error modal.  So we don't trigger the usual global error flag in Vuex.
			return false;
		}
	}

	/**
	 * Create a temp link in the DOM.  Then programmatically "click" that link to download the PDF in the
	 * member's browser.
	 */
	static _downloadBooklet(data, locale) {
		const demoMode = sessionStorage.getItem('demoActive');
		let fileURL = require('@/assets/blank-demo-document.pdf');
		if (!demoMode) {
			fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
		}
		const fileLink = document.createElement('a');
		const fileName = locale === 'fr' ? 'brochure-de-garanties.pdf' : 'benefits-booklet.pdf';

		fileLink.href = fileURL;
		fileLink.setAttribute('download', fileName);
		document.body.appendChild(fileLink);

		fileLink.click();
		fileLink.remove();
	}

	/**
	 * Get the parameters necessary for the webservice call.
	 */
	static _getParameters() {
		// Get username and jwt token from session
		const username = sessionStorage.getItem('email');
		const token = sessionStorage.getItem('apiToken');

		// Get locale from local storage.  It is stored as a stringified object.  Ex: "vuex": {"i18n":{"locale":"en"}}
		const locale = JSON.parse(localStorage.getItem('vuex'))?.i18n?.locale || 'en';

		return { username, token, locale };
	}
}
