/**
 * Handlers for the members api
 */
import resourceUrls from './members/resource-urls';
import securityPolicy from './members/security-policy';
import cardholderDetails from './members/cardholder-details';
import contactInfoDetails from './members/contact-info-details';
import preferredLanguage from './members/preferred-language';
import participants from './members/participants';
import memberCardsDetails from './members/member-cards-details';
import premiums from './members/premiums';
import premiumsPdf from './members/premiums-pdf';
import bankingDetails from './members/banking-details';
import providerTypes from './members/provider-types';
import claimTypes from './members/claim-types';
import healthProfessionals from './members/health-professionals';
import benefits from './members/benefits';
import booklet from './members/coverages/booklet';
import balances from './members/coverages/balances';
import hsaEligibleClaims from './members/hsa-eligible-claims';
import labels from './members/labels';
//Claim submission
import submitClaim from './members/submit-claim';
import submitClaimMulti from './members/submit-claim-multi';
import serviceTypes from './members/submit-claim/service-types';
import externalCoverage from './members/submit-claim/external-coverage';
import cobReason from './members/submit-claim/cob-reason';
import externalApi from './members/submit-claim/external-api';

//Claim History
import recentClaims from './members/recent-claims';
import historyDetails from './members/history/history';
import claimHistory from './members/claim-history/claim-history';

//Payment history
import paymentHistory from './members/claim-history/payment-history';
import paymentHistoryPdf from '../members/payment-history-pdf';
import paymentDetailsPdf from '../members/payment-details-pdf';
import terms from './members/terms';
import recentProvider from './members/submit-claim/recent-provider';
import premiumPayment from './members/premium-payment';

export default function (server) {
	/**
	 * Digital registration
	 *
	 * Note:  No need for a real token.  Following URL works http://localhost:8080/en/digital-validate?token=123
	 */
	server.get('/members/validate-digital-expiry', () => {
		return {
			emailExpired: false,
			emailVerified: true,
			emailLocked: false,
			alreadyRegistered: false,
			language: 'EN'
		};
	});

	/**
	 * Digital registration
	 */
	server.get('/members/validate-digital-member', () => {
		return {
			emailVerified: true,
			emailLocked: false
		};
	});

	terms(server);
	resourceUrls(server);

	/**
	 * Part of login flow
	 *
	 * Change to "false" to show the screen to accept the terms and conditions
	 */
	server.get('/members/:id/terms-and-conditions', () => {
		return { termsAndConditionsAccept: true };
	});

	/**
	 *  Part of login flow
	 *
	 * Returns a token for connected care
	 */
	server.get('members/:id/connected-care-tokens', () => {
		return { jwt: '2u3ghjvhbvdvhgqcwehgc2h13fg4vnrbeqfw dhfhjfhg' };
	});

	/**
	 * Part of Member card flow
	 *
	 * returns a pdf of the members card
	 */
	server.get('/members/:id/member-cards', () => {
		return {
			status: 200,
			data: ''
		};
	});

	securityPolicy(server);

	// Dashboard
	paymentHistory(server);
	recentClaims(server);

	// My Account web services
	cardholderDetails(server);
	contactInfoDetails(server);
	preferredLanguage(server);
	participants(server);
	memberCardsDetails(server);
	premiums(server);
	premiumsPdf(server);
	bankingDetails(server);

	// Submit claim web services
	providerTypes(server);
	claimTypes(server);
	healthProfessionals(server);
	hsaEligibleClaims(server);
	labels(server);
	submitClaim(server);
	submitClaimMulti(server);
	serviceTypes(server);
	externalCoverage(server);
	cobReason(server);
	recentProvider(server);
	externalApi(server);

	//Claim history web services
	claimHistory(server);

	// My Coverage web services
	benefits(server);
	booklet(server);
	balances(server);

	// History
	historyDetails(server);
	paymentHistoryPdf(server);
	paymentDetailsPdf(server);

	// Premiums
	premiumPayment(server);
}
