/**
 * Handler for optional benefit api
 */
import optionalBenefits_en from './../../objects/benefit_optional_benefits.json';
import optionalBenefits_fr from './../../objects/benefit_optional_benefits_fr.json';

export default function (server) {
	/**
	 * return list of optional benefits
	 */
	server.get('members/:id/coverages/optional-benefits', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en'
			? optionalBenefits_en
			: optionalBenefits_fr;
	});
}
