/**
 * recent claims for claims tracker
 */

export default function (server) {
	server.get('/members/:id/recent-claims', () => {
		return [
			{
				receivedDate: '2022-09-11',
				serviceDate: '20220911',
				status: 'Submitted',
				member: 'Agatha',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2019-01-24',
				serviceDate: '20220912',
				status: 'Submitted',
				member: 'Bill',
				statusUpdate: '2019-01-24'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Brenda',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Bill',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Bill',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Brenda',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Brenda',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Nick',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Nick',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-06-11',
				serviceDate: '20220911',
				status: 'In Progress',
				claimType: 'Both',
				member: 'Tom',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-09-11',
				serviceDate: '20220911',
				status: 'Declined',
				claimType: 'Prescription',
				member: 'Zoya',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-12-13',
				serviceDate: '20220911',
				status: 'Pending',
				claimType: 'Health',
				member: 'Nick',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-09-11',
				serviceDate: '20220911',
				status: 'Submitted',
				claimType: 'Health',
				member: 'Agatha',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2019-01-24',
				serviceDate: '20220912',
				claimType: 'Dental',
				member: 'Bill',
				statusUpdate: '2019-01-24'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Brenda',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-06-11',
				serviceDate: '20220911',
				status: 'In Progress',
				claimType: 'Dental',
				member: 'Tom',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-09-11',
				serviceDate: '20220911',
				status: 'Declined',
				claimType: 'Prescription',
				member: 'Zoya',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-12-13',
				serviceDate: '20220911',
				status: 'Pending',
				claimType: 'Health',
				member: 'Nick',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-09-11',
				serviceDate: '20220911',
				status: 'Submitted',
				claimType: 'Health',
				member: 'Agatha',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2019-01-24',
				serviceDate: '20220912',
				status: 'Submitted',
				claimType: 'Dental',
				member: 'Bill',
				statusUpdate: '2019-01-24'
			},
			{
				receivedDate: '2022-08-11',
				serviceDate: '20220911',
				status: 'Processed',
				claimType: 'Health',
				member: 'Brenda',
				statusUpdate: '2022-09-15',
				paymentId: '2023313T00051'
			},
			{
				receivedDate: '2022-06-11',
				serviceDate: '20220911',
				status: 'In Progress',
				claimType: 'Dental',
				member: 'Tom',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-09-11',
				serviceDate: '20220911',
				status: 'Declined',
				claimType: 'Prescription',
				member: 'Zoya',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-12-13',
				serviceDate: '20220911',
				status: 'Pending',
				claimType: 'Health',
				member: 'Nick',
				statusUpdate: '2022-09-15'
			},
			{
				receivedDate: '2022-09-11',
				serviceDate: '20220911',
				status: 'Submitted',
				claimType: 'Health',
				member: 'Agatha',
				statusUpdate: '2022-09-15'
			}
		];
	});
}
