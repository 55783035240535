import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

/**
 * This class represent a Member
 */
export default class CoordinationOfBenefit extends Model {
	static entity = 'coordinationOfBenefits';

	static loaded = false;

	static primarykey = 'id';

	static fields() {
		return {
			id: this.uid(),
			claimId: this.string(),
			doesMemberHaveOtherCov: this.boolean(null).nullable(),
			cobCovChanged: this.boolean(null).nullable(),
			cobNewInfoEffDate: this.string(null).nullable(),
			cobNewInfoIdNumber: this.string(null).nullable(),
			cobNewInfoMemberName: this.string(null).nullable(),
			cobNewInfoPolicyId: this.string(null).nullable(),
			cobNewInfoIsIndividual: this.boolean(null).nullable(),
			cobNewInfoHasHospital: this.boolean(null).nullable(),
			cobNewInfoHasDental: this.boolean(null).nullable(),
			cobNewInfoHasVision: this.boolean(null).nullable(),
			cobNewInfoHasDrugs: this.boolean(null).nullable(),
			cobNewInfoHasHealth: this.boolean(null).nullable(),
			cobNewInfoHasTravel: this.boolean(null).nullable(),
			anyPastCovThatEnded: this.boolean(null).nullable(),
			cobPrevInfoTermDate: this.string(null).nullable(),
			infoOnFileValid: this.boolean(null).nullable(),
			wasSubmittedToOtherFirst: this.boolean(null).nullable(),
			extCovCarrierCode: this.string(null).nullable(),
			extCovPrimaryInd: this.string(null).nullable(),
			extCovTermDate: this.string(null).nullable()
		};
	}

	static async updateCoordinationOfBenefits(email, COB) {
		if (COB.cobNewInfoEffDate) {
			COB.cobNewInfoEffDate = format(
				new Date(
					COB.cobNewInfoEffDate.split('-')[0],
					COB.cobNewInfoEffDate.split('-')[1] - 1,
					COB.cobNewInfoEffDate.split('-')[2]
				),
				'yyyyMMdd'
			);
		}
		if (COB.extCovTermDate) {
			COB.extCovTermDate = format(
				new Date(
					COB.extCovTermDate.split('-')[0],
					COB.extCovTermDate.split('-')[1] - 1,
					COB.extCovTermDate.split('-')[2]
				),
				'yyyyMMdd'
			);
		}
		if (COB.cobPrevInfoTermDate) {
			COB.cobPrevInfoTermDate = format(
				new Date(
					COB.cobPrevInfoTermDate.split('-')[0],
					COB.cobPrevInfoTermDate.split('-')[1] - 1,
					COB.cobPrevInfoTermDate.split('-')[2]
				),
				'yyyyMMdd'
			);
		}
		await super.update({
			where: (cob) => {
				return cob.claimId === email;
			},
			data: {
				doesMemberHaveOtherCov: COB.doesMemberHaveOtherCov,
				cobCovChanged: COB.cobCovChanged,
				cobNewInfoEffDate: COB.cobNewInfoEffDate,
				cobNewInfoIdNumber: COB.cobNewInfoIdNumber,
				cobNewInfoMemberName: COB.cobNewInfoMemberName,
				cobNewInfoPolicyId: COB.cobNewInfoPolicyId,
				cobNewInfoIsIndividual: COB.cobNewInfoIsIndividual,
				cobNewInfoHasHospital: COB.cobNewInfoHasHospital,
				cobNewInfoHasDental: COB.cobNewInfoHasDental,
				cobNewInfoHasVision: COB.cobNewInfoHasVision,
				cobNewInfoHasDrugs: COB.cobNewInfoHasDrugs,
				cobNewInfoHasHealth: COB.cobNewInfoHasHealth,
				cobNewInfoHasTravel: COB.cobNewInfoHasTravel,
				anyPastCovThatEnded: COB.anyPastCovThatEnded,
				cobPrevInfoTermDate: COB.cobPrevInfoTermDate,
				infoOnFileValid: COB.infoOnFileValid,
				wasSubmittedToOtherFirst: COB.wasSubmittedToOtherFirst,
				extCovCarrierCode: COB.extCovCarrierCode,
				extCovPrimaryInd: COB.extCovPrimaryInd,
				extCovTermDate: COB.extCovTermDate
			}
		});
	}

	static async createNewCOB(email) {
		await super.create({
			data: {
				claimId: email
			}
		});
		return await super.query().withAll().where('claimId', email).first();
	}

	static async clearCOB() {
		await super.deleteAll();
	}

	static async getCOBByEmail(email) {
		let cob = await super.query().withAll().where('claimId', email).first();
		if (!cob) {
			cob = await this.createNewCOB(email);
		}
		return this.formatCobDates(cob);
	}

	static async getCOBInfoAPI(email, token, locale, claimDate, lob, participantId) {
		const cDate = format(parseISO(claimDate), 'yyyyMMdd');
		let response = await axios
			.get(`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/external-coverages`, {
				headers: {
					'content-type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				},
				params: {
					claimDate: cDate,
					lob,
					participantId
				}
			})
			.catch(() => {
				this.store().dispatch('error', { name: 'generic' });
			});
		return response.data[0];
	}

	static async getMaaxCob(email, token, locale, claimDate, lob, benefitCode, participantId) {
		const cDate = format(parseISO(claimDate), 'yyyy-MM-dd');
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/${participantId}/cob?lob=${lob}&benefit=${benefitCode}&claimDate=${cDate}`;
		let response = await axios.get(url, {
			headers: {
				'content-type': 'application/json',
				'Accept-Language': locale,
				Authorization: token
			}
		});
		return response.data;
	}

	static async getCOBReasons(email, isHSA, locale) {
		let response = await axios.get(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/cob-reasons`,
			{
				headers: {
					'content-type': 'application/json',
					'Accept-Language': locale,
					Authorization: sessionStorage.getItem('apiToken')
				},
				params: {
					hsaType: isHSA
				}
			}
		);
		return response.data;
	}

	static async formatCobDates(cob) {
		const formatted = (strDate) =>
			strDate
				? format(
						new Date(strDate.substr(0, 4), strDate.substr(4, 2) - 1, strDate.substr(6, 2)),
						'yyyy-MM-dd'
				  )
				: null;

		cob.cobPrevInfoTermDate = formatted(cob.cobPrevInfoTermDate);
		cob.cobNewInfoEffDate = formatted(cob.cobNewInfoEffDate);
		cob.extCovTermDate = formatted(cob.extCovTermDate);
		return cob;
	}
}
