import { Model } from '@vuex-orm/core';

/**
 * This class represent a Member
 */
export default class ManualClaimServiceItems extends Model {
	static entity = 'manualClaimServiceItems';

	static loaded = false;

	/**
	 * benefit services fields
	 */
	static primaryKey = 'id';
	static fields() {
		return {
			id: this.uid(),
			claimId: this.string(),
			dateOfService: this.string(null).nullable(),
			whatIsClaimFor: this.string(null).nullable(),
			specify: this.string(null).nullable(),
			participant: this.string(null).nullable()
		};
	}

	static async createNewManualClaimService(service, email) {
		var manualClaimService = await super.new();
		manualClaimService.claimId = email;
		manualClaimService.dateOfService = service.dateOfService;
		manualClaimService.whatIsClaimFor = service.whatIsClaimFor;
		manualClaimService.specify = service.specify;
		manualClaimService.participant = service.participant;
		manualClaimService.$save();
		return manualClaimService;
	}

	static async getManualClaimServiceByEmail(email) {
		return await super.query().withAll().where('claimId', email);
	}

	static async updateManualClaimServiceByEmail(email, manualClaimService) {
		await super.update({
			where: (service) => {
				return service.claimId === email && service.id === manualClaimService.id;
			},
			data: {
				dateOfService: manualClaimService.dateOfService,
				whatIsClaimFor: manualClaimService.whatIsClaimFor,
				specify: manualClaimService.specify,
				participant: manualClaimService.participant
			}
		});
	}

	static async updateManualClaimServiceById(manualClaimService) {
		await super.update({
			where: manualClaimService.$id,
			data: {
				dateOfService: manualClaimService.dateOfService,
				whatIsClaimFor: manualClaimService.whatIsClaimFor,
				specify: manualClaimService.specify,
				participant: manualClaimService.participant
			}
		});
	}

	static async removeManualClaimService(email) {
		ManualClaimServiceItems.delete((service) => {
			return service.claimId === email;
		});
	}

	// Remove a specific benefit service using the ID.
	static async removeBenefitServiceById(id) {
		ManualClaimServiceItems.delete(id);
	}

	static async clearBenefitService() {
		await super.deleteAll();
	}
}
