/**
 * Member coverage drugs
 */

import drugList_en from './../../objects/benefit_drug_search_result_nexium.json';
import drugList_fr from './../../objects/benefit_drug_search_result_nexium_fr.json';
//drug returns
import din_00002244521_en from './../../objects/benefit_drug_details_00002244521.json';
import din_00002244521_fr from './../../objects/benefit_drug_details_00002244521_fr.json';
import din_00002244522_en from './../../objects/benefit_drug_details_00002244522.json';
import din_00002244522_fr from './../../objects/benefit_drug_details_00002244522_fr.json';
import din_00002444712_en from './../../objects/benefit_drug_details_00002444712.json';
import din_00002444712_fr from './../../objects/benefit_drug_details_00002444712_fr.json';
//comparable drugs return
import comparableDrugEN from './../../objects/benefit_drug_benefit_drug_comparables_00002244522.json';
import comparableDrugFR from './../../objects/benefit_drug_benefit_drug_comparables_00002244522_fr.json';

export default function (server) {
	/**
	 * Drug coverage flow
	 *
	 * returns a list of drugs
	 */
	server.get('members/:id/coverages/drugs', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en' ? drugList_en : drugList_fr;
	});

	/**
	 * Drug coverage flow
	 *
	 * returns drug details for selected dins
	 */
	server.get('members/:id/coverages/drug-details', (schema, request) => {
		const din = request.queryParams.dins;
		const language = request.requestHeaders['Accept-Language'];
		if (din === '00002244521') {
			return language === 'en' ? din_00002244521_en : din_00002244521_fr;
		} else if (din === '00002244522') {
			return language === 'en' ? din_00002244522_en : din_00002244522_fr;
		} else {
			return language === 'en' ? din_00002444712_en : din_00002444712_fr;
		}
	});

	/**
	 * Drug coverage flow
	 *
	 * returns list of comparable drugs
	 */
	server.get('members/:id/coverages/drug-benefits', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en' ? comparableDrugEN : comparableDrugFR;
	});
}
