import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class TermsCondition extends Model {
	static entity = 'TermsConditions';

	static async hasAcceptedTAC(user, locale) {
		let response = await axios.get(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}` +
				'/' +
				user.email +
				'/terms-and-conditions',
			{
				headers: {
					'content-type': 'application/json',
					'Accept-Language': locale,
					Authorization: 'Bearer ' + user.token
				}
			}
		);
		return response;
	}

	/**
	 * set if the user has accepted terms and conditions
	 */
	static async confirmTermAndCon(email, token, locale) {
		let response = await axios.put(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + email + '/terms-and-conditions',
			{
				termsAndConditionsAccept: true
			},
			{
				headers: {
					'content-type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			}
		);
		return response;
	}
}
