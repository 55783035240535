<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<header :id="getUniqueId('pageHeader')" class="pageHeader">
		<BNavbar
			:id="getUniqueId('navBar')"
			:aria-label="$t('navLabel')"
			class="m-0 pt-0 pb-0 pr-0 navBar"
		>
			<BNavbarBrand>
				<Logo with-nav @logoClick="toDashboard" />
			</BNavbarBrand>
			<MenuAccountDropdown
				v-if="!isMenuHidden"
				class="ml-auto menu_account"
				:automation-id="getAutomationId('header')"
				@showLogoutModal="showLogoutModal"
			/>
			<MenuMobileDropdown
				v-if="!isMenuHidden"
				:class="['menu_mobile', isEnglish ? 'd-lg-none' : 'mobile-menu-french']"
				@toSubmitClaim="toSubmitClaim()"
				@toClaimHistory="toClaimHistory()"
				@toPaymentHistory="toPaymentHistory()"
				@toHistory="toHistory()"
				@toMyCoverage="toMyCoverage()"
				@toContactUs="toContactUs()"
				@toDashboard="toDashboard()"
			/>
			<!--want it to show if not logged in or size bigger then lg -->
			<LocaleToggle
				v-if="isMenuHidden"
				:class="localeToggleClass"
				data-test-automation-id="headerLocaleToggle"
				data-tracking-id="headerLocaleToggle"
				:disabled="$store.state.isLocaleToggleDisabled"
				@locale-change="localeChanged"
			/>
			<LocaleToggle
				v-if="!isMenuHidden"
				class="btn-locale d-none d-lg-block h-100"
				:class="[localeToggleClass, isEnglish ? 'd-none' : 'menu-french', 'mx-1']"
				data-test-automation-id="headerLocaleToggle"
				data-tracking-id="headerLocaleToggle"
				:disabled="$store.state.isLocaleToggleDisabled"
				@locale-change="localeChanged"
			/>
		</BNavbar>
		<MenuSubHeader
			v-if="!isMenuHidden"
			:id="getUniqueId('sub-navBar')"
			:class="['d-lg-flex', 'sub-nav', 'm-0', 'p-0', isEnglish ? 'd-none' : 'menu-french']"
			:automation-id="getAutomationId('header')"
			@toSubmitClaim="toSubmitClaim()"
			@toClaimHistory="toClaimHistory()"
			@toHistory="toHistory()"
			@toPaymentHistory="toPaymentHistory()"
			@toMyCoverage="toMyCoverage()"
			@toContactUs="toContactUs()"
		/>
		<DirectDepositMissingModal
			:show-direct-deposit-modal="showDirectDepositModal"
			:update-banking-capable="updateBankingCapable"
			@closeModal="closeModal"
		/>
	</header>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import LocaleToggle from '@/components/layout/AppHeaderLocaleToggle';
import { BNavbar, BNavbarBrand } from 'bootstrap-vue';
import MenuAccountDropdown from '@/components/menu/MenuAccountDropdown';
import MenuMobileDropdown from '@/components/menu/MenuMobileDropdown';
import MenuSubHeader from '@/components/menu/MenuSubHeader';
import * as Routes from '@/constants/Routes.js';
import {
	LOGIN,
	DASHBOARD,
	COVERAGE,
	PAYMENT_HISTORY,
	CLAIM_HISTORY,
	CONTACT_US,
	HISTORY
} from '@/constants/Routes.js';
import SecurityPolicy from '@/models/SecurityPolicy';
import DirectDepositInfoCall from '@/models/DirectDepositInfoCall.js';
import DirectDepositMissingModal from '@/components/common/DirectDepositMissingModal.vue';
import Logo from '@/components/common/Logo.vue';

// @vue/component
@Component({
	name: 'AppHeader',
	components: {
		LocaleToggle,
		BNavbar,
		BNavbarBrand,
		MenuAccountDropdown,
		MenuMobileDropdown,
		MenuSubHeader,
		DirectDepositMissingModal,
		Logo
	},
	mixins: [IdMixin]
})
export default class AppHeader extends Vue {
	helpLink = 'https://www.medaviebc.ca/member-enrolment-faq';
	showDirectDepositModal = false;
	securityPolicy = {};
	updateBankingCapable = false;

	get isMenuHidden() {
		return this.$route.meta.menuHidden;
	}

	get localeToggleClass() {
		return !this.$route.meta.menuHidden ? ['iconButtons'] : ['iconButtons', 'ml-auto'];
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	async updated() {
		this.$store.state.i18n.locale === 'fr'
			? (this.helpLink = 'https://www.cbmedavie.ca/faq-inscription-des-adherents')
			: (this.helpLink = 'https://www.medaviebc.ca/member-enrolment-faq');

		if (!this.isMenuHidden) {
			if (sessionStorage.getItem('email')) {
				this.securityPolicy = await SecurityPolicy.getSecurityPolicy(
					sessionStorage.getItem('email')
				);
				if (this.securityPolicy === null) {
					this.alreadyGotSecurityPolicy = true;
					await SecurityPolicy.getUserSecurityPolicies(
						sessionStorage.getItem('email'),
						sessionStorage.getItem('apiToken'),
						this.$root.$i18n.locale
					);
					this.securityPolicy = await SecurityPolicy.getSecurityPolicy(
						sessionStorage.getItem('email')
					);
				}
				this.updateBankingCapable = this.securityPolicy?.updateBanking;
			}
		}
	}

	openHelp() {
		window.open(this.helpLink, '_blank');
	}
	localeChanged(evt) {
		this.$emit('locale-change', evt);
	}
	async toSubmitClaim() {
		this.$store.dispatch('updateLoading', true);
		await DirectDepositInfoCall.getDirectDepositInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			if (response?.status === 204) {
				this.showDirectDepositModal = true;
			} else {
				this.$router.push({ name: Routes.CLAIM_TYPE });
			}
		});
		this.$store.dispatch('updateLoading', false);
	}
	toMyCoverage() {
		this.$router.push({ name: COVERAGE.MY_COVERAGE });
	}
	toDashboard() {
		if (sessionStorage.getItem('loggedIn')) {
			if (this.$router.currentRoute.name !== DASHBOARD) {
				this.$router.push({ name: DASHBOARD });
			}
		} else {
			if (this.$router.currentRoute.name !== LOGIN) {
				this.$router.push({ name: LOGIN });
			}
		}
	}
	toContactUs() {
		this.$router.push({ name: CONTACT_US });
	}
	// Close the no direct depost modal.
	closeModal() {
		this.showDirectDepositModal = false;
	}
	toPaymentHistory() {
		this.$router.push({ name: PAYMENT_HISTORY });
	}
	toClaimHistory() {
		this.$router.push({ name: CLAIM_HISTORY });
	}
	toHistory() {
		this.$router.push({ name: HISTORY });
	}
	showLogoutModal() {
		this.$emit('showLogoutModal');
	}
}
</script>

<style lang="scss" scoped>
.no-show {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
header {
	z-index: 10;
	box-shadow: 0px 3px 3px #2525251a;
}
.mobile-menu-french {
	display: none !important;

	@include media-breakpoint-down(lg) {
		display: inherit !important;
	}
}
.menu-french {
	@include media-breakpoint-down(lg) {
		display: none !important;
	}
}
/* Needed for IE11 */
div.navbar-brand > img {
	max-width: 100%;
}
.dropdown-submenu ::v-deep .btn-secondary {
	background-color: $very-light-grey;
	border: 0;
	height: 34px;
	min-height: 34px;
	padding-top: 0;
	padding-bottom: 0;
}

.navbar {
	background-color: $white;
	height: 40px;
}
.btn-locale {
	height: calc(100% - 6px);
	& ::v-deep > div {
		align-items: center;
	}
}
.sub-nav {
	height: 34px;
	background-color: $very-light-grey;
}
.menu_account {
	height: 100%;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
		"navLabel": "Main navigation"
	},
	"fr": {
		"navLabel": "Navigation principale"
	}
}
</i18n>
