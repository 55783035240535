let uuid = 1;
export default {
	props: {
		automationId: {
			type: String,
			required: false
		}
	},
	/**
	 * Before create hook
	 */
	beforeCreate() {
		this.componentId = `vue${uuid}`;
		uuid += 1;
	},
	methods: {
		/**
		 * Returns unique id for component
		 * @param {string} suffix The suffix of the id
		 */
		getUniqueId(suffix) {
			return `${this.componentId}-${suffix}`;
		},

		/**
		 * Get an automation ID.  It is made up of a prefix and a suffix.  The prefix is passed
		 * to components via props and the suffix is hard coded where the automation ID is required.
		 *
		 * The automation ID is not randomly generated.  It needs to remain the same each time the
		 * application starts so that the automation test suite can find components.
		 *
		 * @param {string} suffix
		 * @returns {string} The automation ID
		 */
		getAutomationId(suffix) {
			return `${this.automationId}${suffix ? `-${suffix}` : ''}`;
		}
	}
};
