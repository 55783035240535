export default `
<section>
    <div>
        <p>Chez Croix Bleue Medavie, nous comprenons qu'un service à la clientèle accessible est synonyme d'une expérience sans entrave pour nos clients. L'accessibilité égale de tous les Canadiens à nos produits et à nos services est importante à nos yeux.</p>
        <h2>Loi sur l'accessibilité pour les personnes handicapées de l'Ontario</h2>
        <p>La Loi sur l’accessibilité pour les personnes handicapées de l’Ontario établit les normes visant l’accessibilité aux renseignements, aux communications, à l’emploi et aux transports. Elle touche non seulement l'Ontario, mais aussi tout le Canada.</p>
        <p>Croix Bleue Medavie se conforme à la Norme d’accessibilité pour les services à la clientèle, qui stipule que nous devons mettre en place des politiques, des pratiques et des formations visant à rendre nos biens et services accessibles aux personnes handicapées.</p>
        <p>Pour en savoir plus : Lisez notre <a href="https://docs.medaviebc.ca/AccessibleCustomerServicePolicy.FR.2016.pdf" target="_blank">politique sur l'accessiblé Ontario</a> ou <a href="https://www.medaviebc.ca/fr/service-a-la-clientele" target="_blank">communiquez avec un de nos bureaux</a>.</p>
        <p>Pour en savoir plus sur la Loi sur l'accessibilité pour les personnes handicapées de l'Ontario, visitez le site Web du <a href="https://www.ontario.ca/fr/page/accessibilite-en-ontario" target="_blank">ministère des Services sociaux et communautaires</a>.</p>
    </div>
</section>`;
