import i18n from '@/plugins/vue-i18n';

export default {
	namespaced: true,
	state: {
		locale: null
	},
	mutations: {
		updateLocale: function (state, locale) {
			state.locale = locale;
			document.documentElement.lang = locale;
			i18n.locale = locale;
		}
	},
	actions: {
		changeLocale({ commit }, locale) {
			commit('updateLocale', locale);
		}
	}
};
