<template>
	<BModal
		:visible="isLoadingVisible"
		:data-test-automation-id="getAutomationId('loading-modal')"
		:aria-label="AriaLabel"
		class="m-0"
		no-close-on-esc
		no-close-on-backdrop
		:centered="true"
	>
		<FontAwesomeIcon spin class="icon" :icon="['fas', 'spinner']"></FontAwesomeIcon>
		<p v-if="modalText">{{ modalText }}</p>
	</BModal>
</template>

<script>
/**
 * This component displays a model.
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import IdMixin from '@/mixins/id';
import isMobile from '@/mixins/mobile';
import { BModal } from 'bootstrap-vue';
// @vue/component
@Component({
	name: 'LoadingModal',
	components: {
		BModal,
		FontAwesomeIcon
	},
	mixins: [IdMixin, isMobile],
	props: {
		/**
		 * This prop assigns the Title to be displayed in the modal
		 */
		modalText: {
			type: String,
			default: null
		},
		/**
		 * sets if the modal is visible or not
		 */
		isVisible: {
			type: Boolean,
			default: false
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class LoadingModal extends Vue {
	get isLoadingVisible() {
		return this.$store.state.loading || this.isVisible;
	}

	get AriaLabel() {
		return this.modalText ? this.modalText : this.$t('ariaLabel');
	}
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
	width: fit-content;
	margin: auto;
	max-width: 600px;
}
::v-deep .modal-body {
	font-size: 21px;
	font-family: 'Josefin Sans', sans-serif;
	// hortizontally align svg to the center
	text-align: center;
	// weird margin issue happening on default margin ( goes past screen). Using this one help center the spinner
	margin: 20px 40px 10px 35px;
	@media (max-width: 768px) {
		margin: 20px 30px 10px 35px;
	}
	@media (max-width: 576px) {
		margin: 10px 25px 4px 25px;
	}
}
::v-deep .modal-content {
	box-shadow: 0px 0px 10px #3b3b3b1a;
	border-radius: 10px;
}
::v-deep .modal-header,
::v-deep .modal-footer {
	display: none;
}
.svg-inline--fa {
	//need to remove the offset that is on by deault (easier to center)
	vertical-align: 0;
}
svg path {
	fill: $blue;
}
</style>
<i18n>
{
	"en": {
		"ariaLabel": "Loading content"
	},
	"fr": {
		"ariaLabel": "Chargement du contenu"
	}
}
</i18n>
