import * as Routes from '@/constants/Routes.js';
import SecurityPolicy from '@/models/SecurityPolicy';
import store from '@/store';

export default {
	path: Routes.MANAGE_YOUR_ACCOUNT,
	component: () =>
		import(
			/* webpackChunkName: "manage-your-account" */ '@/pages/manage-your-account/ManageYourAccount.vue'
		),
	meta: { requiresAuth: true },
	children: [
		{
			path: '',
			name: Routes.MANAGE_YOUR_ACCOUNT,
			component: () =>
				import(
					/* webpackChunkName: 'manage-your-account' */ '@/pages/manage-your-account/ManageYourAccountPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: Routes.PERSONAL_INFORMATION,
			name: Routes.PERSONAL_INFORMATION,
			props: true,
			component: () =>
				import(
					/* webpackChunkName: 'personal-information' */ '@/pages/manage-your-account/personal-information/PersonalInformationPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: Routes.SHARE_MEMBER_CARD,
			name: Routes.SHARE_MEMBER_CARD,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "member-card"*/ '@/pages/manage-your-account/member-card/ShareMemberCardPage.vue'
				)
		},
		{
			path: Routes.MEMBER_CARD,
			name: Routes.MEMBER_CARD,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "member-card"*/ '@/pages/manage-your-account/member-card/MemberCardPage.vue'
				)
		},
		{
			path: Routes.CHANGE_ADDRESS,
			name: Routes.CHANGE_ADDRESS,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: 'change-address' */ '@/pages/manage-your-account/personal-information/ChangeAddressPage.vue'
				)
		},
		{
			path: Routes.CHANGE_EMAIL,
			name: Routes.CHANGE_EMAIL,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: 'change-email' */ '@/pages/manage-your-account/personal-information/ChangeEmailPage.vue'
				)
		},
		{
			path: Routes.CHANGE_LANGUAGE,
			name: Routes.CHANGE_LANGUAGE,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: 'change-language' */ '@/pages/manage-your-account/personal-information/ChangeLanguagePage.vue'
				)
		},
		{
			path: Routes.PEOPLE_ON_YOUR_PLAN,
			name: Routes.PEOPLE_ON_YOUR_PLAN,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "people-on-your-plan"*/ '@/pages/manage-your-account/people-on-your-plan/PeopleOnYourPlanPage.vue'
				)
		},
		{
			path: Routes.DIRECT_DEPOSIT,
			name: Routes.DIRECT_DEPOSIT,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "direct-deposit"*/ '@/pages/manage-your-account/direct-deposit/DirectDepositPage.vue'
				)
		},
		{
			path: Routes.CHANGE_PASSWORD,
			name: Routes.CHANGE_PASSWORD,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "change-password"*/ '@/pages/manage-your-account/change-password/ChangePasswordPage.vue'
				)
		},
		{
			path: Routes.CHANGE_PASSWORD_SUCCESS,
			name: Routes.CHANGE_PASSWORD_SUCCESS,
			meta: { requiresAuth: false, menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "change-password"*/ '@/pages/manage-your-account/change-password/ChangePasswordSuccessPage.vue'
				)
		},
		{
			path: Routes.PRIVACY_AND_TERMS,
			name: Routes.PRIVACY_AND_TERMS,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "terms-and-conditions" */ '@/pages/manage-your-account/terms-and-conditions/PrivacyAndTermsPage.vue'
				)
		},
		{
			path: Routes.TERMS_AND_CONDITIONS,
			name: Routes.TERMS_AND_CONDITIONS,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "terms-and-conditions" */ '@/pages/manage-your-account/terms-and-conditions/TermsAndConditionsPage.vue'
				)
		},
		{
			path: Routes.PREMIUM_PAYMENT_METHOD,
			name: Routes.PREMIUM_PAYMENT_METHOD,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "premium-payment-method"*/ '@/pages/manage-your-account/premium-payment-method/PremiumPaymentMethodPage.vue'
				),
			beforeEnter: async (to, from, next) => {
				try {
					// Get the security policy.
					var securityPolicy = await SecurityPolicy.getSecurityPolicy(
						sessionStorage.getItem('email')
					);

					if (securityPolicy === null) {
						await SecurityPolicy.getUserSecurityPolicies(
							sessionStorage.getItem('email'),
							sessionStorage.getItem('apiToken'),
							store.state.i18n.locale
						);
					}
					securityPolicy = await SecurityPolicy.getSecurityPolicy(sessionStorage.getItem('email'));
					// Check if the member has access to view this screen. If not cancel the navigation, and go to previous page.
					if (securityPolicy.premiumPayment) {
						// Enter this page
						next();
					} else {
						next({ name: Routes.MANAGE_YOUR_ACCOUNT, params: { ...to.params } });
					}
				} catch (err) {
					store.dispatch('error', { name: 'navigation', err });
				}
			}
		},
		{
			path: Routes.PREMIUM_STATEMENTS,
			name: Routes.PREMIUM_STATEMENTS,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "premium-statements"*/ '@/pages/manage-your-account/premium-statements/PremiumStatementsPage.vue'
				)
		},
		{
			path: Routes.MONERIS,
			name: Routes.MONERIS,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "premium-statements"*/ '@/pages/manage-your-account/premium-payment-method/MonerisPage.vue'
				)
		}
	]
};
