/**
 * Returns a list of coverages for a member.
 *
 * Sample api response:
 *
 *   {
 *     "OOP": "Travel",
 *     "HOSP": "Hospital",
 *     "DRUG": "Drugs",
 *     "DNTL": "Dental",
 *     "CHLTH": "Extended Health Benefit",
 *   }
 *   or
 *   {
 *	   "OOP": "Voyage",
 *	   "HOSP": "Hôpital",
 *	   "DRUG": "Médicaments",
 *	   "DNTL": "Dentaire",
 *	   "CHLTH": "Soins de santé complémentaires"
 *	 }
 *
 */

import { Model } from '@vuex-orm/core';
// import axios from 'axios';

export default class Benefit extends Model {
	static entity = 'benefits';

	static primaryKey = ['id', 'locale'];

	static fields() {
		return {
			id: this.string(),
			locale: this.string(),
			benefits: this.attr(null).nullable()
		};
	}

	static async hasBenefits(email, locale) {
		const benefits = await this.find([email, locale]);
		return benefits ? true : false;
	}

	static async getBenefits(username, locale) {
		// Check to see if the benefits are in vuex-orm.
		const benefits = await Benefit.find([username, locale]);

		if (benefits) {
			return benefits.benefits;
		}

		try {
			const objBenefits =
				locale === 'fr'
					? {
							OOP: 'Voyage',
							HOSP: 'Hôpital',
							DRUG: 'Médicaments',
							DNTL: 'Dentaire',
							CHLTH: 'Soins de santé complémentaires'
					  }
					: {
							OOP: 'Travel',
							HOSP: 'Hospital',
							DRUG: 'Drugs',
							DNTL: 'Dental',
							CHLTH: 'Extended Health Benefit'
					  };
			await this.insert({
				data: {
					id: username,
					locale,
					benefits: objBenefits
				}
			});
			return objBenefits;
		} catch (error) {
			this.store().dispatch('error');
		}
	}
}
