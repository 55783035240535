/**
 * Part of login flow
 *
 * This is the group configuration.  It determines what the member can do in the UI.  Change boolean values
 * to drive desired screen behaviour.
 *
 * When updating the security policy, we need to login again to pickup the changes.  This is because the appplication
 * only reads this information on login.
 */
export default function (server) {
	server.get('/members/:id/security-policy', () => {
		return {
			demo: false,
			forms: false,
			banking: true,
			updateMemberProfile: true,
			submitClaim: true,
			easyHsa: false,
			autoHSA: false,
			analytics: false,
			benefitUsage: true,
			coverage: true,
			memberProfile: false,
			statements: false,
			rta: true,
			drugAdherence: true,
			storeFront: true,
			premiumPayment: true,
			premiums: true,
			suppressCoverage: false,
			filterOnKeywords: false,
			medicalExclusion: true,
			claimTracker: true,
			updateBanking: true
		};
	});
}
