/**
 * My Account / Manage your Account / Review and print your premium statements
 *
 * The full call with live API is /members/:id/premiums?fromDate=20200101&toDate=20211106
 * In our case, we don't bother with data params as we return static data.
 */
export default function (server) {
	server.get('/members/:id/premiums/statement', () => {
		return 'Random text';
	});
}
