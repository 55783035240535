/**
 * handler for online doctor api
 */

import benefitOnlineDoctor_en from './../../objects/benefit_online_doctors.json';
import benefitOnlineDoctor_fr from './../../objects/benefit_online_doctors_fr.json';

export default function (server) {
	/**
	 *
	 *
	 */
	server.get('members/:id/coverages/online-doctors', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en'
			? benefitOnlineDoctor_en
			: benefitOnlineDoctor_fr;
	});
}
