import { COVERAGE } from '@/constants/Routes.js';

export default {
	path: COVERAGE.COVERAGE,
	component: () => import(/* webpackChunkName: "coverage" */ '@/pages/coverage/Coverage.vue'),
	meta: { requiresAuth: true },
	children: [
		{
			path: COVERAGE.MY_COVERAGE,
			name: COVERAGE.MY_COVERAGE,
			component: () =>
				import(/* webpackChunkName: "coverage"*/ '@/pages/coverage/MyCoveragePage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.DENTAL,
			name: COVERAGE.DENTAL,
			beforeEnter: (to, from, next) => {
				const { maaxClaims } = JSON.parse(sessionStorage.getItem('securityPolicy'));
				if (maaxClaims) {
					next();
				} else {
					next({ name: `${COVERAGE.DENTAL}Scepter`, params: to.params, query: to.query });
				}
			},
			component: () =>
				import(/* webpackChunkName: "coverage-dental"*/ '@/pages/coverage/DentalPage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.DENTAL,
			name: `${COVERAGE.DENTAL}Scepter`,
			component: () =>
				import(/* webpackChunkName: "coverage-dental"*/ '@/pages/coverage/DentalPageScepter.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.HOSPITAL,
			name: COVERAGE.HOSPITAL,
			beforeEnter: (to, from, next) => {
				const { suppressCoverage, maaxClaims } = JSON.parse(
					sessionStorage.getItem('securityPolicy')
				);
				if (suppressCoverage) {
					next({ name: `${COVERAGE.HOSPITAL}Suppress`, params: to.params, query: to.query });
				} else if (maaxClaims) {
					next();
				} else {
					next({ name: `${COVERAGE.HOSPITAL}Scepter`, params: to.params, query: to.query });
				}
			},
			component: () => {
				return import(
					/* webpackChunkName: "coverage-hospital"*/ '@/pages/coverage/HospitalPage.vue'
				);
			},
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.HOSPITAL,
			name: `${COVERAGE.HOSPITAL}Suppress`,
			component: () => {
				return import(
					/* webpackChunkName: "coverage-hospital"*/ '@/pages/coverage/HospitalSuppressPage.vue'
				);
			},
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.HOSPITAL,
			name: `${COVERAGE.HOSPITAL}Scepter`,
			component: () => {
				return import(
					/* webpackChunkName: "coverage-hospital"*/ '@/pages/coverage/HospitalPageScepter.vue'
				);
			},
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.HOSPITAL_DETAILS,
			name: COVERAGE.HOSPITAL_DETAILS,
			component: () =>
				import(
					/* webpackChunkName: "coverage-hospital"*/ '@/pages/coverage/HospitalDetailsPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.PWA,
			name: COVERAGE.PWA,
			component: () =>
				import(
					/* webpackChunkName: "coverage-pwa"*/ '@/pages/coverage/personal-wellness-account/PwaPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.DRUGS,
			name: COVERAGE.DRUGS,
			component: () =>
				import(/* webpackChunkName: "coverage-drugs"*/ '@/pages/coverage/drugs/DrugsPage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.COMPARABLE_DRUGS,
			name: COVERAGE.COMPARABLE_DRUGS,
			component: () =>
				import(
					/* webpackChunkName: "coverage-drugs"*/ '@/pages/coverage/drugs/ComparableDrugsPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.GENERIC_EQUIVALENT,
			name: COVERAGE.GENERIC_EQUIVALENT,
			component: () =>
				import(
					/* webpackChunkName: "coverage-drugs"*/ '@/pages/coverage/drugs/GenericEquivalentDrugsPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.LIFE_DISABILITY,
			name: COVERAGE.LIFE_DISABILITY,
			component: () =>
				import(
					/* webpackChunkName: "coverage-disability"*/ '@/pages/coverage/LifeDisabilityPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.TRAVEL,
			name: COVERAGE.TRAVEL,
			component: () =>
				import(/* webpackChunkName: "coverage-travel"*/ '@/pages/coverage/TravelPage.vue'),
			meta: { requiresAuth: true }
		},
		/**
		 * EHB can be the full page or a simplified "suppressed" page.  The URL stays the same.
		 */
		{
			path: COVERAGE.EXTENDED_HEALTH,
			name: COVERAGE.EXTENDED_HEALTH,
			beforeEnter: (to, from, next) => {
				const { suppressCoverage, maaxClaims } = JSON.parse(
					sessionStorage.getItem('securityPolicy')
				);
				if (suppressCoverage) {
					next({ name: `${COVERAGE.EXTENDED_HEALTH}Suppress`, params: to.params, query: to.query });
				} else if (maaxClaims) {
					next();
				} else {
					next({ name: `${COVERAGE.EXTENDED_HEALTH}Scepter`, params: to.params, query: to.query });
				}
			},
			component: () => {
				return import(
					/* webpackChunkName: "coverage-health"*/ `@/pages/coverage/ExtendedHealthPage.vue`
				);
			},
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.EXTENDED_HEALTH,
			name: `${COVERAGE.EXTENDED_HEALTH}Suppress`,
			component: () => {
				return import(
					/* webpackChunkName: "coverage-health"*/ '@/pages/coverage/ExtendedHealthSuppressPage.vue'
				);
			},
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.EXTENDED_HEALTH,
			name: `${COVERAGE.EXTENDED_HEALTH}Scepter`,
			component: () => {
				return import(
					/* webpackChunkName: "coverage-health"*/ '@/pages/coverage/ExtendedHealthPageScepter.vue'
				);
			},
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.EXTENDED_HEALTH_DETAILS,
			name: COVERAGE.EXTENDED_HEALTH_DETAILS,
			component: () =>
				import(
					/* webpackChunkName: "coverage-health-details" */ '@/pages/coverage/ExtendedHealthDetailsPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.OPTIONAL_BENEFITS,
			name: COVERAGE.OPTIONAL_BENEFITS,
			component: () =>
				import(
					/* webpackChunkName: "coverage-optional"*/ '@/pages/coverage/OptionalBenefitsPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.HSA,
			name: COVERAGE.HSA,
			component: () =>
				import(/* webpackChunkName: "coverage-hsa"*/ '@/pages/coverage/hsa/HsaPage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.ONLINE_DOCTORS,
			name: COVERAGE.ONLINE_DOCTORS,
			component: () =>
				import(/* webpackChunkName: "coverage-doctors"*/ '@/pages/coverage/OnlineDoctorsPage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.EFAP,
			name: COVERAGE.EFAP,
			component: () =>
				import(
					/* webpackChunkName: "coverage-doctors"*/ '@/pages/coverage/EmployeeAndFamilyAssistance.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.MEDICAL_EXCLUSION,
			name: COVERAGE.MEDICAL_EXCLUSION,
			component: () =>
				import(/* webpackChunkName: "coverage-doctors"*/ '@/pages/coverage/MedicalExclusion.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: COVERAGE.RHIP,
			name: COVERAGE.RHIP,
			component: () =>
				import(
					/* webpackChunkName: "coverage-rhip"*/ '@/pages/coverage/RetirementHealthInsuranceProgramPage.vue'
				),
			meta: { requiresAuth: true }
		}
	]
};
