<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<div ref="modal-container" @click="closeBlur($event)">
		<BModal
			:visible="modalShow"
			:size="size"
			:class="cssClass"
			:dialog-class="cssDialogClass"
			:header-class="cssHeaderClass"
			:body-class="cssBodyClass"
			:content-class="cssContentClass"
			:static="isStatic"
			:data-test-automation-id="getAutomationId('modal')"
			:hide-footer="hideFooter"
			:hide-header="hideHeader"
			:aria-label="$t('label.modal')"
			:no-close-on-backdrop="noHideOnBlur"
			:no-close-on-esc="cantCloseOnEsc"
			:centered="true"
			@change="change"
		>
			<template #modal-header="{ close }">
				<div v-if="showExclamation">
					<FontAwesomeIcon
						class="modal-icon"
						color="#EB5E00"
						:icon="['fal', 'exclamation-triangle']"
						aria-hidden="true"
					/>
					<div v-if="showTitle">
						<h1 v-if="isHeaderOne" class="mb-0 mt-3">{{ modalTitle }}</h1>
						<p v-else class="mb-0 mt-3">
							{{ modalTitle }}
						</p>
					</div>
				</div>
				<div v-else>
					<div v-if="showTitle">
						<h1 v-if="isHeaderOne" class="mb-0">{{ modalTitle }}</h1>
						<p v-else class="mb-0">
							{{ modalTitle }}
						</p>
					</div>
				</div>
				<BButton
					v-if="closeButton"
					class="close-button"
					variant="light"
					v-bind="$attrs"
					:aria-label="$t('label.close')"
					@click="close()"
				>
					<FontAwesomeIcon :icon="icon" aria-hidden="true" />
				</BButton>
			</template>
			<slot name="contentBody"></slot>
			<slot name="modal-footer"></slot>
		</BModal>
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component is used to display a modal
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import isMobile from '@/mixins/mobile';
import { BButton, BModal } from 'bootstrap-vue';

/**
 * This component renders a button.
 */
// @vue/component
@Component({
	name: 'BaseModal',
	components: {
		BButton,
		BModal
	},
	mixins: [IdMixin, isMobile],
	inheritAttrs: false,
	props: {
		/**
		 * This prop is used by the parent component to identify the modal.
		 * This will allow the parent to open and close the modal.
		 */
		id: {
			type: String,
			default: ''
		},
		/**
		 * Modal Title is used to set the title displayed on Modal popup
		 */
		modalTitle: {
			type: String,
			default: ''
		},
		/**
		 * Show modal title is used to show the title
		 */
		showTitle: {
			type: Boolean,
			default: true
		},
		/**
		 * Enables or disables clicking outside modal will trigger close
		 */
		noHideOnBlur: {
			type: Boolean,
			default: false
		},
		/**
		 * Enables or disbaled the use of escape key
		 */
		cantCloseOnEsc: {
			type: Boolean,
			default: false
		},
		/**
		 * Hide Footer is used to show or display the footer at the bottom of the modal that display the 'Ok" and 'Close' buttons.
		 * These buttons can change.
		 */
		hideFooter: {
			type: Boolean,
			default: true
		},
		/**
		 * Hide header is used to show or display the header at the top of the modal that display tile and  'Close' buttons.
		 */
		hideHeader: {
			type: Boolean,
			default: false
		},
		/**
		 * set if the header title is contained in a h1 tag or p tag
		 */
		isHeaderOne: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop is used to determine if the modal should be displayed or not.
		 */
		modalShow: {
			type: Boolean,
			default: false
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		},
		/**
		 * This value is used to determine the css class of the modal component to allow for
		 * multiple components to use this component.
		 */
		cssClass: {
			type: String,
			default: 'infoModal'
		},
		cssDialogClass: {
			type: String,
			default: null
		},
		cssHeaderClass: {
			type: String,
			default: null
		},
		cssBodyClass: {
			type: String,
			default: null
		},
		cssContentClass: {
			type: String,
			default: null
		},
		closeButton: {
			type: Boolean,
			default: true
		},
		size: {
			type: String,
			default: 'lg'
		},
		isStatic: {
			type: Boolean,
			default: false
		},
		icon: {
			type: Array,
			default: () => ['fal', 'times-circle']
		},
		showExclamation: {
			type: Boolean,
			default: false
		}
	}
})
export default class BaseModal extends Vue {
	change(isVisible) {
		this.$emit('change', isVisible);
	}

	updated() {
		// we want to close on escape but the "noCloseOnEscape" prop is needed and this code is
		// needed otherwise we get a prop mutating console error.
		this.$refs['modal-container'].onkeydown = (e) => {
			if (e.keyCode === 27) {
				e = e || window.event;
				this.close();
			}
		};
	}

	/**
	 * Emits a closed event to the parent component to toggle
	 * displaying the modal
	 */
	close() {
		this.$emit('change', false);
	}

	/**
	 * Emits a closed event to the parent component to toggle
	 * displaying the modal
	 * Requires separate event due to mutating prop problem with bootstrap vue.
	 */
	closeBlur(e) {
		if (!this.noHideOnBlur) {
			if (!this.$refs['modal-container']?.querySelector('.modal-dialog').contains(e.target)) {
				this.$emit('change', false);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.btn-light {
	color: $blue;
	font-size: 21px;
	background-color: $white;
	border: none;
	margin-top: -30px;
	@media (max-width: 768px) {
		font-size: 18px;
	}
}

.btn-light:hover {
	background-color: $white;
	color: $dark-blue;
	border: none;
}

.btn-light:active {
	background-color: $white;
	color: $dark-blue;
	border: none;
}

.close-button {
	margin-right: 5px;
}
.modal-icon {
	width: 36px;
	height: 32px;
	display: block;
}
</style>

<i18n>
{
  "en": {
    "label": {
      "modal": "Modal",
      "close": "Close"
  }
  },
  "fr": {
    "label": {
        "modal": "Modal",
        "close": "Fermer"
      }
  }
}
</i18n>
