/**
 * Vuex-orm model to demonstrate the MSS pattern for saving search parameters and search results.
 */
import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class ClaimHistory extends Model {
	static entity = 'claimHistory';

	static fields() {
		return {
			id: this.string(null).nullable(), // Member email
			claimHistories: this.attr(null).nullable(), // Saved claims search results
			searchParameters: this.attr(null).nullable() // Saved search parameters
		};
	}

	static async getSavedSearchParameters(username) {
		return await ClaimHistory.find(username)?.searchParameters;
	}

	static async getSavedSearchResults(username) {
		return await ClaimHistory.find(username)?.claimHistories;
	}

	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {Object} searchParameters
	 * @returns {Array} Claims for a member.
	 */
	static async getClaimHistory(
		username,
		token,
		locale,
		searchParameters,
		allClaimTypes,
		allParticipants
	) {
		// Member clicked "search" without entering all search parameters (should be 4 in this case).
		// Note: The page should perform validation and not allow this to happen.
		if (this._isMissingSearchParameters(searchParameters, 4)) {
			return [];
		}

		const { participant, claimTypes, fromDate, toDate } = searchParameters;

		var finalClaimTypes = null;
		var finalParticipants = null;
		if (claimTypes === 'all') {
			finalClaimTypes = allClaimTypes;
		} else {
			finalClaimTypes = claimTypes;
		}
		if (participant === 'all') {
			finalParticipants = allParticipants;
		} else {
			finalParticipants = participant;
		}

		const queryString = `participants=${finalParticipants}&claimTypes=${finalClaimTypes}&fromDate=${fromDate.replaceAll(
			'-',
			''
		)}&toDate=${toDate.replaceAll('-', '')}`;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/claim-histories?${queryString}`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await ClaimHistory.create({
					data: {
						id: username,
						claimHistories: response?.data,
						searchParameters
					}
				});
				// Return response instead of querying Vuex-orm for better performance.
				return response?.data;
			} else if (response?.status === 204) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await ClaimHistory.create({
					data: {
						id: username,
						claimHistories: response?.data,
						searchParameters: null
					}
				});
				// Return response instead of querying Vuex-orm for better performance.
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {Object} searchParameters
	 * @returns {Array} Claims for a member.
	 */
	static async getClaimHistoryPDF(
		username,
		token,
		locale,
		searchParameters,
		allClaimTypes,
		allParticipants
	) {
		// Member clicked "search" without entering all search parameters (should be 4 in this case).
		// Note: The page should perform validation and not allow this to happen.
		if (this._isMissingSearchParameters(searchParameters, 4)) {
			return [];
		}

		const { participant, claimTypes, fromDate, toDate } = searchParameters;

		var finalClaimTypes = null;
		var finalParticipants = null;
		if (claimTypes === 'all') {
			finalClaimTypes = allClaimTypes;
		} else {
			finalClaimTypes = claimTypes;
		}
		if (participant === 'all') {
			finalParticipants = allParticipants;
		} else {
			finalParticipants = participant;
		}

		const claimHistoryPreGetStuff = axios.create({
			baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
			timeout: 120000,
			withCredentials: false,
			headers: {
				'Content-Type': 'application/pdf',
				Accept: 'application/pdf',
				Authorization: token,
				'Accept-Language': locale
			},
			responseType: 'blob'
		});

		const queryString = `participants=${finalParticipants}&claimTypes=${finalClaimTypes}&fromDate=${fromDate.replaceAll(
			'-',
			''
		)}&toDate=${toDate.replaceAll('-', '')}`;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/claim-histories/statement?${queryString}`;

		try {
			const response = await claimHistoryPreGetStuff.get(url);

			if (response?.status === 200) {
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Utility function to check if there are any missing search parameters.
	 *
	 * @param {Object} searchParameters
	 * @param {Number} expectedNumberOfParameters - How many parameters should there be?
	 * @returns {Boolean}
	 * @example _isMissingSearchParameters({ participant: '03'}, 1)
	 */
	static _isMissingSearchParameters(searchParameters, expectedNumberOfParameters) {
		return Object.keys(searchParameters).length < expectedNumberOfParameters;
	}
}
