/**
 * Extended Health Coverage (suppressed)
 */
export default function (server) {
	server.get('/members/:id/suppressed-coverages/ehb', () => {
		return [
			'HEALTH B (FAMILY) Extended Health Benefits - 80% reimb. after $100 accumulated (family) deductible per cal yr Vision Care (HealthWise) - 80% to $160 payable every two/four years (child/adult) for eye exam and frames and 100% reimb. for lenses, after satisfying EHB deductible. See Plan/Fee Guide. '
		];
	});
}
