/**
 * handler for travel coverage api
 */

import travelInfo_en from './../../objects/benefit_travel_contact_info.json';
import travelInfo_fr from './../../objects/benefit_travel_contact_info.json';

export default function (server) {
	/**
	 * returns object with phone numbers for contact info
	 */
	server.get('https://mobileassets.medaviebc.ca/MEDAVIE/travel.json', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en' ? travelInfo_en : travelInfo_fr;
	});
}
