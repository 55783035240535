/**
 * Handler for terms and condition file call
 */
import terms_en from './../objects/terms.json';
import terms_fr from './../objects/terms_fr.json';

export default function (server) {
	server.get('https://mobileassets.medaviebc.ca/MEDAVIE/terms.json', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en' ? terms_en : terms_fr;
	});
}
