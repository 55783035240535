<template class="mobile-row">
	<span>
		<BaseDropDown
			id="menu-account"
			class="account-dropdown"
			:data-test-automation-id="getAutomationId('menu-drop-down')"
			float-right
			:title="$t('title.myAccount')"
			:icon="icon"
			:icon-size="leftIconsize"
			mobile-text-class-left="menu-icon-left"
			menu-account
			variant="navigation"
			@show="show"
			@hide="hide"
		>
			<BDropdownItem
				:data-test-automation-id="getAutomationId('manage-account')"
				@click="manageAccount"
				>{{ $t('nav.manageAccount') }}</BDropdownItem
			>
			<BDropdownDivider />
			<BDropdownItem
				:data-test-automation-id="getAutomationId('member-card')"
				@click="toMemberCard"
				>{{ $t('nav.memberCard') }}</BDropdownItem
			>
			<BDropdownDivider />
			<BDropdownItem :data-test-automation-id="getAutomationId('payment')" @click="manageAccount">{{
				$t('nav.payment')
			}}</BDropdownItem>
			<BDropdownDivider />
			<BDropdownItem
				:data-test-automation-id="getAutomationId('logout')"
				@click="showLogoutModal"
				>{{ $t('nav.logout') }}</BDropdownItem
			>
		</BaseDropDown>

		<BaseDropDown
			id="menu-account-mobile"
			class="account-dropdown mobile-account-dropdown"
			:data-test-automation-id="getAutomationId('menu-drop-down')"
			:title="menuTitle"
			show-icon
			:icon="menuIcon"
			mobile-text-class-left="mobile-menu-icon-left"
			no-caret
			variant="navigation"
			btn-stack-mobile
			@show="show"
			@hide="hide"
		>
			<BDropdownItem
				:data-test-automation-id="getAutomationId('manage-account')"
				@click="manageAccount"
				>{{ $t('nav.manageAccount') }}</BDropdownItem
			>
			<BDropdownDivider />
			<BDropdownItem
				:data-test-automation-id="getAutomationId('member-card')"
				@click="toMemberCard"
				>{{ $t('nav.memberCard') }}</BDropdownItem
			>
			<BDropdownDivider />
			<BDropdownItem :data-test-automation-id="getAutomationId('payment')" @click="manageAccount">{{
				$t('nav.payment')
			}}</BDropdownItem>
			<BDropdownDivider />
			<BDropdownItem
				:data-test-automation-id="getAutomationId('logout')"
				@click="showLogoutModal"
				>{{ $t('nav.logout') }}</BDropdownItem
			>
		</BaseDropDown>
	</span>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseDropDown from '@/components/common/base/BaseDropDown';
import { BDropdownDivider, BDropdownItem } from 'bootstrap-vue';
import SecurityPolicy from '@/models/SecurityPolicy';
import * as Routes from '@/constants/Routes';

// @vue/component
@Component({
	name: 'MenuAccountDropdown',
	components: {
		BDropdownDivider,
		BaseDropDown,
		BDropdownItem
	},
	mixins: [IdMixin],
	props: {
		icon: {
			type: String,
			default: 'user'
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class MenuAccountDropdown extends Vue {
	ddOpen = false;
	securityPolicy = null;

	get leftIconsize() {
		return '1x';
	}

	get menuTitle() {
		return !this.ddOpen ? this.$t('title.myAccountShort') : this.$t('title.close');
	}
	get menuIcon() {
		return !this.ddOpen ? 'user' : 'times';
	}

	show() {
		this.ddOpen = true;
	}
	hide() {
		this.ddOpen = false;
	}

	manageAccount() {
		this.$router.push({ name: Routes.MANAGE_YOUR_ACCOUNT });
	}

	toMemberCard() {
		this.$router.push({ name: Routes.MEMBER_CARD });
	}

	async mounted() {
		if (this.$store.state.loggedIn) {
			var tempSecuritypolicy = await SecurityPolicy.getSecurityPolicy(
				sessionStorage.getItem('email')
			);

			if (tempSecuritypolicy === null) {
				await SecurityPolicy.getUserSecurityPolicies(
					sessionStorage.getItem('email'),
					sessionStorage.getItem('apiToken'),
					this.$root.$i18n.locale
				);
				this.securityPolicy = await SecurityPolicy.getSecurityPolicy(
					sessionStorage.getItem('email')
				);
			} else {
				this.securityPolicy = tempSecuritypolicy;
			}
		}
	}

	showLogoutModal() {
		this.$emit('showLogoutModal');
	}
}
</script>
<style lang="scss" scoped>
.account-dropdown {
	height: 100%;
	font-family: $josefin-font-family;
	@include media-breakpoint-down(xs) {
		display: none;
	}
}
.account-dropdown ::v-deep .menu-icon-left {
	padding: 2px 2px 0px 0px;
}
.mobile-account-dropdown {
	font-family: $font-family-headline;
	display: none;
	@include media-breakpoint-down(xs) {
		display: block;
	}
}
.dropdown-divider {
	margin: 0;
}
.account-dropdown ::v-deep .dropdown-menu {
	padding-top: 0px;
	padding-bottom: 0px;
	& li:hover {
		text-decoration: underline;
		color: $blue-dark;
		background-color: $gray-very-light;
	}
	& li:last-child:hover {
		border-radius: 0 0 5px 5px;
	}
	& li:first-child:hover {
		border-radius: 5px 5px 0 0;
	}
}
.account-dropdown ::v-deep .dropdown-item {
	padding-top: 15px;
	padding-bottom: 15px;
	color: $blue;
	background-color: white;
	width: auto;
	font-family: $josefin-font-family;
	&:hover {
		color: $blue-dark;
		background-color: $gray-very-light;
	}
}
.col-mobile-icon {
	height: 12px;
}
::v-deep .dropdown-toggle {
	font-family: $font-family-headline;
	color: $blue;
	font-size: 16px;
	transition: none;
	height: 100%;
	@include media-breakpoint-down(xs) {
		font-size: 14px;
	}
	padding: 0;
	margin: 0 3px;
}
.base-dropdown ::v-deep a,
.base-dropdown ::v-deep span {
	font-family: $font-family-headline;
	font-size: 16px;
	font-weight: 400;
	color: $blue;
}
.base-dropdown ::v-deep svg path {
	color: $blue;
}
.base-dropdown ::v-deep .nav-link {
	font-family: $font-family-headline;
	font-size: 16px;
	font-weight: 400;
}
.base-dropdown ::v-deep .dropdown-menu {
	@include media-breakpoint-down(xs) {
		position: fixed;
		padding: 0;
		width: 100vw;
		overflow: hidden;
		left: 0;
		top: 40px;
	}
}
</style>
<i18n>
{
  "en": {
    "title": {
      "myAccount": "My Account",
      "myAccountShort": "Account",
	  "close": "Close"
    },
    "nav": {
      "manageAccount": "Manage your Account",
      "memberCard": "Member Card",
      "payment": "Payment Information",
      "logout": "Logout"
    }
  },
  "fr": {
    "title": {
      "myAccount": "Mon compte",
      "myAccountShort": "Compte",
	   "close": "Fermer"
    },
    "nav": {
      "manageAccount": "Gérer votre compte",
      "memberCard": "Carte d’identification",
      "payment": "Renseignements de paiement",
      "logout": "Déconnexion"
    }
  }
}
</i18n>
