import { Model } from '@vuex-orm/core';
import BenefitService from '@/models/claims/BenefitService';
import BenefitProvider from '@/models/claims/BenefitProvider';
import ManualClaimServiceItems from '@/models/claims/ManualClaimServiceItems';
import CoordinationOfBenefits from '@/models/claims/CoordinationOfBenefits';
import ProviderSearchCalls from '@/models/ProviderSearchCalls';
import HsaEligibleClaims from '@/models/claims/HsaClaims';
import ClaimTypes from '@/models/claims/ClaimTypes';
import SecurityPolicy from '@/models/SecurityPolicy';
import axios from 'axios';
import FormData from 'form-data';
import * as claimTypes from '@/constants/ClaimTypes.js';

/**
 * This class represent a Member
 */
export default class Claim extends Model {
	static entity = 'claims';

	static loaded = false;

	/**
	 * Claim fields
	 */
	static primaryKey = 'id';
	static fields() {
		return {
			id: this.string(),
			email: this.string(),
			dateOfBirth: this.string(null).nullable(),
			planCode: this.string(null).nullable(),
			regionCode: this.string(null).nullable(),
			participantId: this.string(null).nullable(),
			startedRTA: this.boolean(null).nullable(),
			isHsa: this.boolean(null).nullable(),
			isAutoHsa: this.boolean(false),
			isRta: this.boolean(null).nullable(),
			comments: this.string(null).nullable(),
			hasComments: this.boolean(null).nullable(),
			isAccidentThirdParty: this.boolean(null).nullable(),
			isFirstClaimForAccident: this.boolean(null).nullable(),
			isWorkersCompEligible: this.boolean(null).nullable(),
			claimType: this.string(null).nullable(),
			lob: this.string(null).nullable(),
			isProviderNotFound: this.boolean(null).nullable(),
			benefitProviderSearchInfo: this.attr(null).nullable(),
			hasDCIShortTermDisability: this.boolean(null).nullable(),
			hasDCILongTermDisability: this.boolean(null).nullable(),
			hasDCICriticalIllness: this.boolean(null).nullable(),
			hasLADDAccidentalDeath: this.boolean(null).nullable(),
			hasLADDAccidentalDismemberment: this.boolean(null).nullable(),
			hasLADDTerminalIllness: this.boolean(null).nullable(),
			benefitServices: this.hasMany(BenefitService, 'claimId', 'id'),
			manualClaimServiceItems: this.hasMany(ManualClaimServiceItems, 'claimId', 'id'),
			benefitProvider: this.hasOne(BenefitProvider, 'claimId', 'id'),
			coordinationOfBenefits: this.hasOne(CoordinationOfBenefits, 'claimId', 'id'),
			hsaEligibleServices: this.hasMany(HsaEligibleClaims, 'mssClaimId', 'id') // can't use claimId because it's used in data
		};
	}

	static async createNewClaim(email) {
		await super.create({
			data: {
				id: email,
				email: email
			},
			insert: [
				'benefitServices',
				'benefitProvider',
				'coordinationOfBenefits',
				'hsaEligibleServices'
			]
		});
		return await super.query().withAll().where('id', email).first();
	}

	static async updateClaim(claim, email) {
		const updatedClaim = await super.update({
			where: (claims) => {
				return claims.id === email;
			},
			data: {
				dateOfBirth: claim.dateOfBirth,
				planCode: claim.planCode,
				regionCode: claim.regionCode,
				participantId: claim.participantId,
				startedRTA: claim.startedRTA,
				isHsa: claim.isHsa,
				isRta: claim.isRta,
				comments: claim.comments,
				hasComments: claim.hasComments,
				isAutoHsa: claim.isAutoHsa,
				isAccidentThirdParty: claim.isAccidentThirdParty,
				isFirstClaimForAccident: claim.isFirstClaimForAccident,
				isWorkersCompEligible: claim.isWorkersCompEligible,
				claimType: claim.claimType,
				lob: claim.lob,
				isProviderNotFound: claim.isProviderNotFound,
				hasDCIShortTermDisability: claim.hasDCIShortTermDisability,
				hasDCILongTermDisability: claim.hasDCILongTermDisability,
				hasDCICriticalIllness: claim.hasDCICriticalIllness,
				hasLADDAccidentalDeath: claim.hasLADDAccidentalDeath,
				hasLADDAccidentalDismemberment: claim.hasLADDAccidentalDismemberment,
				hasLADDTerminalIllness: claim.hasLADDTerminalIllness,
				benefitServices: claim.benefitService,
				benefitProvider: claim.benefitProvider,
				coordinationOfBenefits: claim.coordinationOfBenefits
			},
			update: [
				'benefitServices',
				'benefitProviders',
				'coordinationOfBenefits',
				'hsaEligibleServices'
			]
		});
		return updatedClaim;
	}

	static async getClaimByEmail(email) {
		let claim = await super.query().withAll().where('id', email).first();
		if (!claim) {
			claim = await this.createNewClaim(email);
		}

		return claim;
	}

	static async hasClaimType() {
		const claim = await Claim.query().first();
		return claim?.claimType ? true : false;
	}

	static async getClaimType(email, token, locale) {
		const claim = Claim.query().where('id', email).first();
		if (claim) {
			const claimTypes = await ClaimTypes.loadClaimType(email, token, locale);
			// If the member doesn't have claimTypes then skip this. Non-rta members won't have any returned.
			if (claimTypes.length !== 0) {
				const claimType = claimTypes.filter((cType) => cType.claimType === claim.claimType)[0]
					?.description;
				return claimType;
			}
		}
		return null;
	}

	static async clearClaim() {
		await BenefitProvider.clearBenefitProvider();
		await ProviderSearchCalls.clearProviderSearch();
		await BenefitService.clearBenefitService();
		await HsaEligibleClaims.clearServices();
		await CoordinationOfBenefits.clearCOB();
		await super.deleteAll();
		return true;
	}

	static async submitClaim(claim, files, email, locale, token) {
		let data = new FormData();
		// If the claim is practitioner update the claim type to the provider type in the benefit service.
		if (claim.claimType === claimTypes.PRACTITIONER) {
			claim.claimType = claim.benefitServices[0]?.specialtyCode;
		}
		const apiReadyClaim = { ...claim };

		const securityPolicy = await SecurityPolicy.getSecurityPolicy(sessionStorage.getItem('email'));
		if (securityPolicy.autoHSA) {
			apiReadyClaim.isAutoHsa = true;
		}

		// remove null and id entries
		Object.keys(apiReadyClaim).forEach((key) => {
			if (apiReadyClaim[key] === null) {
				delete apiReadyClaim[key];
			}
		});

		// delete junk from claim object
		delete apiReadyClaim.id;
		delete apiReadyClaim.$id;
		delete apiReadyClaim.pageId;
		delete apiReadyClaim.lob;
		delete apiReadyClaim.hasComments;
		delete apiReadyClaim.caseManager;

		// delete junk from benefitProvider
		if (apiReadyClaim?.benefitProvider && Object.keys(apiReadyClaim.benefitProvider).length > 0) {
			delete apiReadyClaim.benefitProvider.id;
			delete apiReadyClaim.benefitProvider.$id;
			delete apiReadyClaim.benefitProvider.claimId;
		} else {
			delete apiReadyClaim.benefitProvider;
		}

		// delete junk from benefitServices
		if (apiReadyClaim.benefitServices.length > 0) {
			apiReadyClaim.benefitServices.forEach((benefitService) => {
				delete benefitService.id;
				delete benefitService.$id;
				delete benefitService.claimId;
				delete benefitService.serviceRow;
				delete benefitService.hasPrescription;
			});
		} else {
			delete apiReadyClaim.benefitServices;
		}

		// delete junk from hsaEligibleServices
		if (apiReadyClaim.hsaEligibleServices.length > 0) {
			apiReadyClaim.hsaEligibleServices.forEach((hsaService) => {
				delete hsaService.id;
				delete hsaService.$id;
				delete hsaService.mssClaimId;
			});
		} else {
			delete apiReadyClaim.hsaEligibleServices;
		}

		// delete junk from manualClaimServices
		if (apiReadyClaim.manualClaimServiceItems.length > 0) {
			apiReadyClaim.manualClaimServiceItems.forEach((manualClaimService) => {
				delete manualClaimService.id;
				delete manualClaimService.$id;
			});
		} else {
			delete apiReadyClaim.manualClaimServiceItems;
		}

		// remove null and id entries from COB
		if (
			apiReadyClaim?.coordinationOfBenefits &&
			Object.keys(apiReadyClaim.coordinationOfBenefits).length > 0
		) {
			Object.keys(apiReadyClaim.coordinationOfBenefits).forEach((key) => {
				if (apiReadyClaim.coordinationOfBenefits[key] === null) {
					delete apiReadyClaim.coordinationOfBenefits[key];
				}
			});
			delete apiReadyClaim.coordinationOfBenefits.id;
			delete apiReadyClaim.coordinationOfBenefits.$id;
			delete apiReadyClaim.coordinationOfBenefits.claimId;
		}

		data.append('claimExtraInfo', JSON.stringify(apiReadyClaim));
		files.forEach((file, index) => {
			let fileName = `file-${index > 9 ? index + 1 : `0${index + 1}`}`;
			data.append('file', file, `${fileName}.${file.name.split('.').pop()}`);
		});

		let config = {
			method: 'post',
			url: `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/multi-claims`,
			headers: {
				Locale: locale,
				Authorization: token,
				'Content-Type': 'multipart/form-data'
			},
			data: data
		};

		const response = await axios(config);
		return response;
	}
}
