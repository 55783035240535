import { render, staticRenderFns } from "./BaseDropDown.vue?vue&type=template&id=cf453f76&scoped=true&v-slot=%7B%20variant%3A%20variant%20%7D&"
import script from "./BaseDropDown.vue?vue&type=script&lang=js&"
export * from "./BaseDropDown.vue?vue&type=script&lang=js&"
import style0 from "./BaseDropDown.vue?vue&type=style&index=0&id=cf453f76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf453f76",
  null
  
)

export default component.exports