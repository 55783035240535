import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default class HospitalBenefitDetailsScepter extends Model {
	static entity = 'hospitalBenefitDetailsScepter';

	static primaryKey = ['locale', 'benefitCode'];

	static fields() {
		return {
			locale: this.string(null).nullable(),
			benefitCode: this.string(null).nullable(),
			benefitDetails: this.attr(null).nullable()
		};
	}

	static async getBenefitDetails(username, token, locale, benefitCode) {
		// Check to see if the benefit details are in vuex-orm.
		const localizedBenefitDetails = await super.find([locale, benefitCode]);

		if (localizedBenefitDetails) {
			return localizedBenefitDetails.benefitDetails;
		}

		// Nothing found in vuex-orm, make an api call to get the benefit details.
		const urlBase = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/hospital`;
		const url = `${urlBase}/${benefitCode}/details`;

		startSpinner();

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			stopSpinner();

			if (response?.status === 200) {
				// Api call is good, transform data and add it to vuex-orm.
				const benefitDetails = this._transformData(response.data, benefitCode);

				await super.insertOrUpdate({
					data: {
						locale,
						benefitCode,
						benefitDetails
					}
				});
				return benefitDetails;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Transform from web service response format to UI object format.  We do this to make it easier to loop through
	 * data to display UI components.  We also manipulate data to remove unwanted characters.  If any object keys
	 * are missing we return a null value.
	 *
	 * @param {Object} data - The raw response object from the benefit details service call.
	 * @param {String} benefitCode - The benefit code.  Ex: '0304S'
	 * @returns {Object} - An object transformed into a UI friendly format
	 */
	static _transformData(data, benefitCode) {
		let transformed = {};

		transformed.heading = data?.healthCareProductsMap[benefitCode]?.shortDescription || null;
		transformed.description = data?.healthCareProductsMap[benefitCode]?.longDescription || null;
		transformed.provisions = this._objectToString(data?.overallProvisions);
		transformed.eligibility = this._objectToString(
			data?.healthCareProductsMap[benefitCode]?.provisions
		);
		transformed.claimRequirements = this._objectToString(
			data?.healthCareProductsMap[benefitCode]?.claimRequirements
		);

		return transformed;
	}

	/**
	 * Transform an array of objects to an array of strings.
	 * We are looking specifically for the "description" key and returning it's value.
	 *
	 * @param {Array} inArray - An array of Objects
	 * @returns {Array} - An array of Strings
	 */
	static _objectToString(inArray) {
		if (!inArray || inArray.length === 0) {
			return null;
		}

		return inArray.map((element) => {
			return this._removeBullet(element.description);
		});
	}

	/**
	 * Remove leading "- " from some items in the web service response.
	 */
	static _removeBullet(inString) {
		return inString.replace(/^-\s{1}/, '');
	}
}
