/**
 * Error Object
 * @param {Object} error
 */
export default function getError(name, err, title) {
	if (err?.response && err.response.data && Object.keys(err?.response.data).length > 0) {
		const errorObj = Array.isArray(err.response.data) ? err.response.data[0] : err.response.data;
		const apiError = errorObj[
			`message${document.documentElement.lang.replace(
				document.documentElement.lang[0],
				document.documentElement.lang[0].toUpperCase()
			)}`
		]
			? errorObj[
					`message${document.documentElement.lang.replace(
						document.documentElement.lang[0],
						document.documentElement.lang[0].toUpperCase()
					)}`
			  ]
			: errorObj[`message`];

		return {
			name,
			title,
			text: apiError,
			isCustomAPIMsg: true
		};
	} else return { name, title, text: err?.toString(), isCustomAPIMsg: false };
}
