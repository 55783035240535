export default `
<section>
	<h2>Terms and Conditions of Use</h2>
	<p>Medavie Blue Cross maintains this Medavie Blue Cross website for your personal information, enjoyment and use, subject to your compliance with the terms and conditions below. Please carefully read this notice before you use the site. By accessing and using the site, you agree to be bound by the terms and conditions below without limitation or qualification.
	</p>
	<p>Medavie Blue Cross may at any time revise these terms and conditions by updating this posting. You are bound by such revisions and should therefore visit this page to review the current terms and conditions from time to time.
	</p>
	<ol>
		<li>
			<h3>Disclaimer</h3>
			<p>Medavie Blue Cross is providing this site and its contents on an "AS IS" basis without representation or warranty of any kind as to the site or its contents, either express or implied, including but not limited to, the implied warranty of merchantability, fitness for a particular purpose or non-infringement.
			</p>
		</li>
		<li>
			<h3>No Liability</h3>
			<p>Medavie Blue Cross and its affiliated companies, officers, directors, agents, employees, or any person associated with the creation or delivery of this site, shall not be liable or responsible for any harm, loss or damage (whether arising in contract, tort, negligence or otherwise) that may arise in connection with your access or use of this site, including any direct, indirect, incidental, special, third party, punitive or consequential damages.
			</p>
		</li>
		<li>
			<h3>Use of Site Content</h3>
			<p>All site content is for information purposes only. Nothing on this site constitutes an offer to buy or sell products or services of Medavie Blue Cross. The information on this site is not intended to provide you with any personalized insurance, financial, accounting, legal or tax advice and should not be relied upon in that regard.
			</p>
			<p>The health information contained in this site is for informational purposes only and is not intended in any way to replace the services of a trained health care professional or to substitute for the medical advice of physicians. If you have, or suspect that you may have, a health problem you should consult with your health care provider.
			</p>
		</li>
		<li>
			<h3>Information Accuracy</h3>
			<p>While Medavie Blue Cross uses reasonable efforts to post accurate and up-to-date information on the site, Medavie Blue Cross makes no warranties or representations as to the site's accuracy. The information found on the site is subject to change at any time without prior notice.
			</p>
		</li>
		<li>
			<h3>Privacy and Security</h3>
			<p>While Medavie Blue Cross has and will take all reasonable precautions to avoid third persons interfering with the site, or your private information, complete security cannot be guaranteed. Medavie Blue Cross shall not be held responsible or liable for any harm or loss that you or any other person may suffer with connection to any information you input on the site or sent to/from us on the Internet due to a breach of confidentiality, privacy, or security.
			</p>
		</li>
		<li>
			<h3>Available Only Where Permitted by Law</h3>
			<p>The insurance services, products and information described in this site may not be available in all regions of Canada, or to persons who are residents of jurisdictions outside of Canada. Medavie Blue Cross insurance services are only available in jurisdictions where they may be legally provided.
			</p>
		</li>
		<li>
			<h3>Hypertext Links are not Endorsements</h3>
			<p>Other worldwide websites that may be accessed from this site, by hypertext links, are entirely independent of this site and do not indicate any approval or endorsement or affiliation of any such site, entity or material contained thereon by Medavie Blue Cross. Medavie Blue Cross is not responsible for the contents of any site linked by hypertexts to its site; your connection to any such linked site is at your own risk.
			</p>
		</li>
		<li>
			<h3>Copyright</h3>
			<p>All material on this site is protected under the copyright laws of Canada and in other countries. Medavie Blue Cross either owns the intellectual property rights in the material on this site or has obtained the permission of the owner of the intellectual property in such material to use the material on its site.
			</p>
			<p>You may download material displayed on the site for personal non-commercial use only. You must retain all copyright and other proprietary notices on downloaded and/or copied material. Any copy of material from the Medavie Blue Cross site which you make and distribute for any purpose must also include the terms and conditions of use for the site. You may not distribute, modify, transmit, use or re-use any of the contents of the site for any public or personal purposes without written consent of Medavie Blue Cross.
			</p>
		</li>
		<li>
			<h3>Trademarks</h3>
			<p>Medavie Blue Cross and its affiliated companies respectively own or are licensed to use all of the trademarks used on or in connection with the goods and services they provide. The trademarks are protected by Canadian and foreign trademark laws. The use and display of trademarks in this site does not imply that a license of any kind has been granted. All product names mentioned on this site are the trademarks of the respective owners.
			</p>
		</li>
		<li>
			<h3>To Contact Us</h3>
			<address>
				Medavie Blue Cross<br>
				644 Main Street PO Box 220<br>
				Moncton, New Brunswick E1C 8L3<br>
				Phone: <a href="tel:+18006674511"><b>1-800-667-4511</b></a>
			</address>
		</li>
	</ol>
</section>
`;
