import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class HsaClaims extends Model {
	static entity = 'hsaEligibleServices';

	static loaded = false;

	static primarykey = 'id';

	static fields() {
		return {
			id: this.uid(),
			mssClaimId: this.string(),
			claimId: this.string(null).nullable(),
			regionCd: this.string(null).nullable(),
			certNum: this.string(null).nullable(),
			groupId: this.string(null).nullable(),
			section: this.string(null).nullable(),
			participant: this.string(null).nullable(),
			datePaid: this.string(null).nullable(),
			dateOfService: this.string(null).nullable(),
			lob: this.string(null).nullable(),
			benefitCode: this.string(null).nullable(),
			din: this.string(null).nullable(),
			submittedAmount: this.string('0.00').nullable(),
			amountPaid: this.string('0.00').nullable(),
			hsaEligibleAmount: this.string('0.00').nullable(),
			wasSubmittedToOtherFirst: this.boolean(null).nullable(),
			calculatedEligibleAmount: this.string('0.00').nullable(),
			hsaPaidAmount: this.string('0.00').nullable(),
			hsaUnclaimedEligibleAmount: this.string(null).nullable(),
			hsaRequestedFlag: this.boolean(null).nullable(),
			hsaAvailableFlag: this.boolean(null).nullable(),
			claimEligibleFlag: this.string(null).nullable(),
			registrationNumber: this.string(null).nullable(),
			updatedCobAmount: this.string('0.00').nullable(),
			hsaUnclaimedEligibleUpd: this.string(null).nullable(),
			amountPaidByOtherCarrier: this.string(null).nullable()
		};
	}

	static async insertHsaEligibleService(service, email) {
		await super.insert({
			data: {
				mssClaimId: email,
				claimId: service.claimId,
				regionCd: service.regionCd,
				certNum: service.certNum,
				groupId: service.groupId,
				section: service.section,
				participant: service.participant,
				datePaid: service.datePaid,
				dateOfService: service.dateOfService,
				lob: service.lob,
				benefitCode: service.benefitCode,
				din: service.din,
				submittedAmount: service.submittedAmount,
				amountPaid: service.amountPaid,
				hsaEligibleAmount: service.hsaEligibleAmount,
				calculatedEligibleAmount: service.calculatedEligibleAmount,
				hsaPaidAmount: service.hsaPaidAmount,
				hsaUnclaimedEligibleAmount: service.hsaUnclaimedEligibleAmount,
				hsaRequestedFlag: service.hsaRequestedFlag,
				hsaAvailableFlag: service.hsaAvailableFlag,
				claimEligibleFlag: service.claimEligibleFlag,
				registrationNumber: service.registrationNumber,
				updatedCobAmount: service.updatedCobAmount,
				hsaUnclaimedEligibleUpd: service.hsaUnclaimedEligibleUpd,
				wasSubmittedToOtherFirst: service.wasSubmittedToOtherFirst,
				amountPaidByOtherCarrier: service.amountPaidByOtherCarrier
			}
		});
	}

	static async updateHsaEligibleService(email, newService) {
		await super.update({
			where: (service) => {
				return service.mssClaimId === email && newService.id === service.id;
			},
			data: {
				mssClaimId: email,
				claimId: newService.claimId,
				regionCd: newService.regionCd,
				certNum: newService.certNum,
				groupId: newService.groupId,
				section: newService.section,
				participant: newService.participant,
				datePaid: newService.datePaid,
				dateOfService: newService.dateOfService,
				lob: newService.lob,
				benefitCode: newService.benefitCode,
				din: newService.din,
				submittedAmount: newService.submittedAmount,
				amountPaid: newService.amountPaid,
				hsaEligibleAmount: newService.hsaEligibleAmount,
				calculatedEligibleAmount: newService.calculatedEligibleAmount,
				hsaPaidAmount: newService.hsaPaidAmount,
				hsaUnclaimedEligibleAmount: newService.hsaUnclaimedEligibleAmount,
				hsaRequestedFlag: newService.hsaRequestedFlag,
				hsaAvailableFlag: newService.hsaAvailableFlag,
				claimEligibleFlag: newService.claimEligibleFlag,
				registrationNumber: newService.registrationNumber,
				updatedCobAmount: newService.updatedCobAmount,
				hsaUnclaimedEligibleUpd: newService.hsaUnclaimedEligibleUpd,
				wasSubmittedToOtherFirst: newService.wasSubmittedToOtherFirst,
				amountPaidByOtherCarrier: newService.amountPaidByOtherCarrier
			}
		});
	}

	static async getHSAServices(email) {
		return await super.query().withAll().where('mssClaimId', email).get();
	}

	/**
	 * Check if member has HSA funds.  True means they have a balance and false they don't.
	 */
	static async hasHsaFunds(username, token, locale) {
		try {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/health-spending-products/balances?type=hsa`;

			let response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});
			return response.data;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Gets eligible hsa claims
	 */
	static async getHsaEligibleClaims(username, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/hsa-eligible-claims`;
			let response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Gets lob codes
	 */
	static async getLobLabels(username, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/labels`;
			let response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: 'Bearer ' + token
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});
			return response.data;
		} catch (err) {
			this.store().dispatch('error');
		}
	}
	/**
	 * Gets cobs for hsa eligible claim
	 */
	static async getCob(username, date, lob, participantId, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/external-coverages?claimDate=${date}&lob=${lob}&participantId=${participantId}`;
			let response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: 'Bearer ' + token
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});
			return response.data;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	static async clearServices() {
		await super.deleteAll();
	}
}
