import * as Routes from '@/constants/Routes.js';

import Authentication from '@/models/Authentication.js';

export default {
	path: '/:locale/' + Routes.DIGITAL_VALIDATE,
	name: Routes.DIGITAL_VALIDATE,
	meta: { menuHidden: true },
	beforeEnter: async (to, from, next) => {
		try {
			// replace all the spaces with +'s because the encoding will remove all +'s from the token param before hitting this.
			var token = to.query.token.replaceAll(' ', '+');
			// Call the API with the members token to check the status.
			const language = to.params.locale;
			if (to.query?.brand) {
				sessionStorage.setItem('brand', to.query.brand.toUpperCase());
			} else {
				sessionStorage.setItem('brand', 'MBC');
			}
			sessionStorage.setItem('mslToken', token);
			Authentication.validateMslToken(token).then((response) => {
				if (response.status === 200) {
					if (response?.data) {
						if (response.data.emailExpired) {
							next({
								name: Routes.DIGITAL_EXPIRED,
								params: { token: token, locale: language }
							});
						} else if (response.data.emailLocked) {
							next({
								name: Routes.DIGITAL_LOCKED,
								params: { token: token, locale: language }
							});
						} else if (response.data.alreadyRegistered) {
							// check for password here
							if (localStorage.getItem('mss_hasNoPassword')) {
								next({
									name: Routes.DIGITAL_PASSWORD,
									params: {
										token: token,
										dob: localStorage.getItem('mss_member_dob'),
										locale: language
									}
								});
							} else {
								// -----------------------
								next({
									name: Routes.DIGITAL_SUCCESS,
									params: {
										token: token,
										locale: language
									}
								});
							}
						} else {
							next({
								name: Routes.DIGITAL_VERIFICATION,
								params: {
									token: token,
									locale: language
								}
							});
						}
					} else {
						next({
							name: Routes.DIGITAL_ERROR,
							params: { locale: language, token: token }
						});
					}
				} else {
					next({
						name: Routes.DIGITAL_ERROR,
						params: { locale: language, token: token }
					});
				}
			});
		} catch (err) {
			next({
				name: Routes.DIGITAL_ERROR,
				params: { locale: to.params.locale, token: token }
			});
		}
	}
};
