import axios from 'axios';
import { Model } from '@vuex-orm/core';

/**
 * This class represent a Member
 */
export default class PaymentDetailsScepter extends Model {
	static entity = 'paymentDetailsScepter';

	static fields() {
		return {
			id: this.string(null).nullable(), // Member email
			paymentDetails: this.attr(null).nullable(), // Saved payment details search results
			searchParameters: this.attr(null).nullable() // Saved search parameters
		};
	}

	static async getSavedSearchParameters(username) {
		return await PaymentDetailsScepter.find(username)?.searchParameters;
	}

	static async getSavedSearchResults(username) {
		return await PaymentDetailsScepter.find(username)?.paymentDetails;
	}

	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {Object} searchParameters
	 * @returns {Array} Claims for a member.
	 */
	static async getPaymentDetails(username, token, locale, searchParameters) {
		// Member clicked "search" without entering all search parameters (should be 4 in this case).
		// Note: The page should perform validation and not allow this to happen.
		if (this._isMissingSearchParameters(searchParameters, 3)) {
			return [];
		}
		const { paymentId, fromDate, toDate } = searchParameters;
		const queryString = `paymentId=${paymentId}&fromDate=${fromDate.replaceAll(
			'-',
			''
		)}&toDate=${toDate.replaceAll('-', '')}`;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/payment-details?${queryString}`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await PaymentDetailsScepter.create({
					data: {
						id: username,
						paymentDetails: response?.data,
						searchParameters
					}
				});
				// Return response instead of querying Vuex-orm for better performance.
				return response?.data;
			} else if (response?.status === 204) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await PaymentDetailsScepter.create({
					data: {
						id: username,
						paymentDetails: response?.data,
						searchParameters: null
					}
				});
				// Return response instead of querying Vuex-orm for better performance.
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {Object} searchParameters
	 * @returns {Array} Claims for a member.
	 */
	static async getPaymentDetailsPDF(username, token, locale, searchParameters) {
		// Member clicked "search" without entering all search parameters (should be 4 in this case).
		// Note: The page should perform validation and not allow this to happen.
		if (this._isMissingSearchParameters(searchParameters, 1)) {
			return [];
		}

		const { paymentId } = searchParameters;

		const paymentDetailsPreGetStuff = axios.create({
			baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
			timeout: 120000,
			withCredentials: false,
			headers: {
				'Content-Type': 'application/pdf',
				Accept: 'application/pdf',
				Authorization: token,
				'Accept-Language': locale
			},
			responseType: 'blob'
		});

		const queryString = `paymentId=${paymentId}`;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/payment-details-pdf?${queryString}`;

		try {
			const response = await paymentDetailsPreGetStuff.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200 || response?.status === 204) {
				// Service call is good, add data to vuex-orm (even when empty response).
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	/**
	 * Utility function to check if there are any missing search parameters.
	 *
	 * @param {Object} searchParameters
	 * @param {Number} expectedNumberOfParameters - How many parameters should there be?
	 * @returns {Boolean}
	 * @example _isMissingSearchParameters({ participant: '03'}, 1)
	 */
	static _isMissingSearchParameters(searchParameters, expectedNumberOfParameters) {
		return Object.keys(searchParameters).length < expectedNumberOfParameters;
	}
}
