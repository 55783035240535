<!--
	SuccessBanner is a jumbotron with blue background and centered white text.  Used to indicate success to a 
  user after things such as saving information.
-->
<template>
	<BJumbotron
		id="dashboardInfoBanner"
		:class="['text-center', 'position-relative']"
		fluid
		bg-variant="primary"
		text-variant="white"
		role="alert"
		:aria-label="`${$t('headerText')}, ${leadText}`"
	>
		<template #header>
			<div aria-hidden="true" class="header-class">
				<h1>{{ $t('headerText') }}</h1>
			</div>
		</template>

		<template #lead>
			<p class="lead" aria-hidden="true">{{ leadText }}</p>
			<BaseButton
				id="close-dashboard-info-banner-button"
				ref="triggerButton"
				class="close-dashboard-info"
				:pill="false"
				variant="link"
				:automation-id="getAutomationId('closeDashboardInfoBannerButton')"
				:icon="['fal', 'times']"
				type="button"
				@click="closeModal"
			/>
			<BaseButton
				:label="$t('buttonText')"
				:aria-label="$t('buttonText')"
				variant="secondary"
				:automation-id="getAutomationId('dashboardInfoBannerButton')"
				type="submit"
				@click="toMemberCard"
			/>
		</template>
	</BJumbotron>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BJumbotron } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import { MEMBER_CARD } from '@/constants/Routes';

export default {
	name: 'DashboardInfoBanner',
	components: {
		BJumbotron,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: '',
			required: true
		}
	},
	computed: {
		brand() {
			const { brand } = JSON.parse(sessionStorage.getItem('securityPolicy'));
			return brand ? brand : '';
		},
		leadText() {
			if (this.brand === 'SBC') {
				return this.$t('leadTextSBC');
			} else {
				return this.$t('leadTextMBC');
			}
		}
	},
	methods: {
		closeModal() {
			this.$store.dispatch('dashboardInfoBanner', { show: false });
		},
		toMemberCard() {
			this.$router.push({ name: MEMBER_CARD });
		}
	}
};
</script>

<style lang="scss" scoped>
.jumbotron {
	margin-bottom: 0;
	padding: 30px 0 34px 0;
}
.lead {
	margin-bottom: 0;
	& > p {
		margin-bottom: 15px;
		font-size: 17px;
	}
}
.close-dashboard-info {
	font-size: 21px;
	position: absolute;
	top: 30px;
	right: 85px;
	@include media-breakpoint-down(md) {
		right: 30px;
	}
	margin: 0;
	padding: 0;
	line-height: 0;
}
.close-dashboard-info ::v-deep path {
	color: $white !important;
}
</style>

<i18n>
{
  "en": {
	"headerText": "Don't forget",
	"leadTextMBC": "If your health provider is submitting a claim for you, they should select Medavie Blue Cross",
	"leadTextSBC": "If your health provider is submitting a claim for you, they should select Saskatchewan Blue Cross",
	"buttonText": "Go to your new digital card"
  },
  "fr": {
	"headerText": "N’oubliez pas!",
	"leadTextMBC": "Si votre fournisseur de soins de santé soumet une demande de règlement en votre nom, il doit sélectionner Croix Bleue Medavie.",
	"leadTextSBC": "Si votre fournisseur de soins de santé soumet une demande de règlement en votre nom, il doit sélectionner Croix Bleue de la Saskatchewan.",
	"buttonText": "Consulter votre nouvelle carte électronique"
  }
}
</i18n>
