export function modifyTheData(dataToChange, dataToChangeName, first, second) {
	for (let i = 0; i < dataToChange.length; i++) {
		let obj = dataToChange[i];
		obj[`${dataToChangeName}`] = randomDateBetween(first, second);
	}
}

export function sortTheData(dataToSort, sortedPropertyName) {
	dataToSort.sort(function (a, b) {
		//return a.attributes - b.attributes;
		if (a[`${sortedPropertyName}`] === b[`${sortedPropertyName}`]) {
			return 0;
		}
		if (a[`${sortedPropertyName}`] < b[`${sortedPropertyName}`]) {
			return 1;
		}
		if (a[`${sortedPropertyName}`] > b[`${sortedPropertyName}`]) {
			return -1;
		}
	});
}

function randomDateBetween(first, second) {
	var dateT = new Date();
	dateT.setDate(dateT.getDate() - first);
	var date = new Date();
	date.setDate(date.getDate() - second);
	return randomDate(dateT, date).toISOString().replace(/T.*/, '').replaceAll('-', '');
}

function randomDate(start, end) {
	return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
