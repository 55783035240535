import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class HospitalBenefit extends Model {
	static entity = 'hospitalBenefits';

	static primaryKey = ['participantId', 'locale'];

	static fields() {
		return {
			participantId: this.string(),
			locale: this.string(),
			data: this.attr(null)
		};
	}

	static async hasBenefits(participantId, locale) {
		const benefits = await this.find([participantId, locale]);
		return benefits ? true : false;
	}

	static async getBenefitInfoByServiceCode(email, token, participantId, locale, code) {
		let benefits = await this.find([participantId, locale]);
		if (!benefits) {
			benefits = await this.getBenefits(email, participantId, token, locale);
		}
		const info = benefits.data.reduce((result, benefit) => {
			const subcategory = benefit.subcategories.find((subcategory) =>
				subcategory.services.some((service) => service.code === code)
			);
			if (subcategory) {
				result.category = { description: benefit.category.description };
				result.subcategory = { description: subcategory.description };
			}
			return result;
		}, {});
		return info;
	}

	static async getBenefits(email, participantId, token, locale) {
		let benefits = await this.find([participantId, locale]);
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/${participantId}/benefits/hospital`;
		try {
			if (benefits) return benefits;
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			switch (response?.status) {
				case 200: {
					const data = response.data.map((category, index) => {
						category.id = index + 1;
						if (!('subcategories' in category)) {
							category.subcategories = [];
						}
						return category;
					});
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, data }
					});
					return benefits.hospitalBenefits[0];
				}
				case 204:
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, data: [] }
					});
					return benefits.hospitalBenefits[0];
				default:
					throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static async getRelatedBenefits(participantId, locale, code) {
		let benefits = await this.find([participantId, locale]);
		if (!benefits) {
			const email = sessionStorage.getItem('email');
			const token = sessionStorage.getItem('apiToken');
			benefits = await this.getBenefits(email, participantId, token, locale);
		}
		let relatedServices;
		benefits.data.find((benefit) =>
			benefit.subcategories.find((subcategory) => {
				const related = subcategory.services.find((svc) => svc.code === code);
				if (related) relatedServices = subcategory.services.filter((svc) => svc.code !== code);
			})
		);

		return relatedServices;
	}
}
