/**
 * My Account / Manage your Account / Update premium payment method
 *
 */
export default function (server) {
	server.get('/members/:id/credit-card', () => {
		return {
			cardId: '426734e3-c5ea-4ca1-89fa-80686bf4cf12',
			maskedPan: '4242***4242',
			expiryDate: '0226'
		};
	});
}
