import { createServer } from 'miragejs';
import apiAccountsRouteHandlers from './routes/demo/accounts';
import apiMembersRouteHandlers from './routes/demo/members';
import apiSuppressedCoveragesRouteHandlers from './routes/demo/suppressed-coverages';
import apiCoverageRouteHandler from './routes/demo/coverage';

export function makeDemoServer({ environment = 'production' } = {}) {
	let server = createServer({
		environment,
		routes() {
			this.urlPrefix = `${window.ENV.VUE_APP_RAINFOREST_SERVICE}`;
			this.namespace = 'rainforest';

			apiAccountsRouteHandlers(this);
			apiMembersRouteHandlers(this);
			apiSuppressedCoveragesRouteHandlers(this);
			apiCoverageRouteHandler(this);

			// My Account / Manage your Account / Review and update premium payment method
			// Only api call on save?

			// Submit a Claim
			// Only api call on save?

			// Allow unhandled requests to pass through
			// Handles things like hotjar requests https://script.hotjar.com/sentry.472495a0c190fd25675c.js
			this.passthrough(); //this might be too much

			// passthrough for other domains
			this.passthrough('https://appd-eum.medavie.ca/**');
			this.passthrough('http://col.eum-appdynamics.com/**');
			this.passthrough('https://dev.visualwebsiteoptimizer.com/**');
			this.passthrough('https://mobileassets.medaviebc.ca/**');
			this.passthrough('https://d3h2ws3410wq1f.cloudfront.net/**');
			this.passthrough('https://*.visualwebsiteoptimizer.com/**');
			this.passthrough('https://www.google-analytics.com/**');
		}
	});

	return server;
}
