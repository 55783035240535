<template>
	<div v-if="withNav">
		<BButton variant="link" @click="$emit('logoClick')">
			<img
				:id="getUniqueId(`logo-${$store.state.i18n.locale}`)"
				:src="logo"
				:alt="logoAlt"
				:data-test-automation-id="`header-logo-${$store.state.i18n.locale}`"
				:class="imgClass"
			/>
		</BButton>
	</div>
	<div v-else>
		<img
			:id="getUniqueId(`logo-${$store.state.i18n.locale}`)"
			:src="logo"
			:alt="logoAlt"
			:data-test-automation-id="`header-logo-${$store.state.i18n.locale}`"
			:class="imgClass"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BButton } from 'bootstrap-vue';

@Component({
	name: 'Logo',
	mixins: [IdMixin],
	watch: {
		$route() {
			this.getLogo();
		}
	},
	props: {
		withNav: {
			type: Boolean,
			default: false
		}
	},
	components: {
		BButton
	}
})
export default class Logo extends Vue {
	logo = '';
	logoAlt = '';
	imgClass = '';

	created() {
		this.getLogo();
	}

	getLogo() {
		const securityPolicy = sessionStorage.getItem('securityPolicy');
		const locale = this.$store.state.i18n.locale;
		const setLogo = (brand) => {
			if (brand) brand = brand.toLowerCase();
			if (brand === 'sbc') {
				this.logo = require('@/assets/sbc-logo-blue.svg');
				this.logoAlt = this.$t('saskLogo');
			} else if (brand === 'qbc' || brand === 'obc') {
				locale === 'en'
					? (this.logo = require('@/assets/BlueCross-En.svg'))
					: (this.logo = require('@/assets/BlueCross-Fr.svg'));
				this.logoAlt = this.$t('genericLogo');
			} else {
				locale === 'en'
					? (this.logo = require('@/assets/medavie-blue-cross-logo-standard-en.svg'))
					: (this.logo = require('@/assets/medavie-blue-cross-logo-standard-fr.svg'));
				this.logoAlt = this.$t('medavieLogo');
			}
		};

		if (securityPolicy) {
			setLogo(JSON.parse(securityPolicy).brand);
		} else {
			// not logged in
			if (sessionStorage.getItem('brand')) {
				setLogo(sessionStorage.getItem('brand'));
			} else {
				setLogo(this.$router.currentRoute.query.brand);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
button {
	background-color: $white;
	border: none;
	padding: 0;
	margin: 4px;
	height: stretch;
}
button:focus {
	box-shadow: none;
}
button:focus-visible {
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
img {
	width: 200px;
	max-width: 100%;
}
</style>

<i18n>
{
	"en": {
		"medavieLogo": "Medavie Blue Cross",
		"saskLogo": "Saskatchewan Blue Cross",
		"genericLogo": "Blue Cross"
	},
	"fr": {
		"medavieLogo": "Croix Bleue Medavie",
		"saskLogo": "Saskatchewan Blue Cross",
		"genericLogo": "Croix Bleue"
	}
}
</i18n>
