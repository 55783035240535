<template v-slot="{ variant: variant }">
	<BDropdown
		:id="id"
		no-caret
		:size="size"
		:data-test-automation-id="getAutomationId('base-drop-down')"
		:right="floatRight"
		class="base-dropdown"
		:toggle-class="[variant, 'p-0']"
		:menu-class="[variant, menuClass]"
		variant="none"
		:disabled="disabled"
		@show="eventShow"
		@hide="eventHide"
	>
		<template
			#button-content
			class="p-0"
			:data-test-automation-id="getAutomationId('base-drop-down-button')"
		>
			<div
				class="btn-container"
				:class="[variant, { 'btn-stack': btnStackMobile }, { open: isOpen }]"
			>
				<div v-if="icon" class="left-icon px-1">
					<FontAwesomeIcon
						:icon="['fal', icon]"
						:size="iconSize"
						aria-hidden="true"
						:data-test-automation-id="getAutomationId('base-drop-down-icon-1')"
					/>
				</div>
				<div class="flex-grow-1 overflow-hidden text-nowrap px-2 line-normal">
					<div class="d-flex flex-column" :class="btnStackMobile ? 'text-center' : 'text-left'">
						<div class="label">
							{{ title }}
						</div>
						<div v-if="subTitle" class="sub-label">{{ subTitle }}</div>
					</div>
				</div>
				<div v-if="!noCaret" class="pr-2">
					<FontAwesomeIcon
						class="right-icon"
						:class="{ open: isOpen }"
						:icon="[caretStyle === 'solid' ? 'fas' : 'fal', computedIcon]"
						aria-hidden="true"
					/>
				</div>
			</div>
		</template>
		<slot :variant="variant"> </slot>
	</BDropdown>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BDropdown } from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// @vue/component
@Component({
	name: 'BaseDropDown',
	components: {
		BDropdown,
		FontAwesomeIcon
	},
	mixins: [IdMixin],
	props: {
		id: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		subTitle: {
			type: String,
			default: null
		},
		/**
		 * from bootstrap-vue:
		 * Set the size of the component's appearance. 'sm' (we default to sm), 'md' (bootstrap-vue default), or 'lg'
		 */
		size: {
			type: String,
			default: 'sm'
		},
		icon: {
			type: String,
			default: null
		},
		iconSize: {
			type: String,
			default: '1x'
		},
		menuAccount: {
			type: Boolean,
			default: false
		},
		menuClass: {
			type: String,
			default: ''
		},
		mobileIconClassRight: {
			type: String,
			default: null
		},
		mobileTextClassLeft: {
			type: String,
			default: null
		},
		floatRight: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			default: '',
			require: true
		},
		noCaret: {
			type: Boolean,
			default: false
		},
		caretStyle: {
			type: String,
			default: 'light',
			validator(prop) {
				return ['light', 'solid'].includes(prop);
			}
		},
		variant: {
			type: String,
			default: 'primary',
			validator(prop) {
				return ['primary', 'secondary', 'navigation'].includes(prop);
			}
		},
		btnStackMobile: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	}
})
export default class BaseDropDown extends Vue {
	isOpen = false;

	get mobileIconViewClassRight() {
		return this.mobileIconClassRight ? this.mobileIconClassRight : ['iconColour', 'px-1 pt-1'];
	}

	get mobileIconViewClassLeft() {
		return this.mobileTextClassLeft ? this.mobileTextClassLeft : ['iconColour', 'px-1'];
	}

	get mobileTextViewClass() {
		return this.mobileTextClass ? this.mobileTextClass : ['px-1'];
	}

	get computedIcon() {
		if (this.caretStyle === 'solid') return 'caret-down';
		return 'angle-down';
	}

	eventShow(e) {
		this.isOpen = true;
		this.$emit('show', e);
	}

	eventHide(e) {
		this.isOpen = false;
		this.$emit('hide', e);
	}
}
</script>

<style lang="scss" scoped>
::v-deep button {
	width: 100%;
}
::v-deep .label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
::v-deep .dropdown-menu.primary,
::v-deep .dropdown-menu.secondary {
	min-width: 100%;
	border: none;
	box-shadow: 0px 0px 10px #e5e5e5;
	border-radius: 10px;
	overflow: hidden;
	padding: 0;
	& .dropdown-item {
		padding: 0;
		&:focus-visible {
			outline: none;
		}
	}
}
::v-deep .dropdown-menu.navigation {
	margin: 0;
}
.btn-container {
	display: flex;
	align-items: center;
	font-size: 15px;
	height: 100%;
	max-height: 100%;
	&.btn-stack {
		@include media-breakpoint-down(xs) {
			display: flex;
			flex-direction: column;
			width: 90px;
			& .left-icon {
				padding-bottom: 2px;
			}
			& div {
				line-height: 1rem;
			}
			&.navigation.open {
				padding-top: 0px;
			}
		}
	}
	&.primary {
		padding: 10px;
		color: $black-text;
		background-color: $white;
		border: 1px solid $gray-dark;
		border-radius: 5px;
		line-height: 1rem;
		font-family: $lato-font-family;
		&.open {
			border: 1px solid $blue;
		}
		& .label {
			font-weight: 500;
		}
		& .sub-label {
			font-size: 14px;
			margin-top: 2px;
		}
	}
	&.secondary {
		// code duplication from primary but leaving it in case we need to customize
		padding: 10px;
		color: $black-text;
		background-color: $very-light-grey;
		border: 1px solid $gray-dark;
		border-radius: 5px;
		line-height: 1rem;
		font-family: $lato-font-family;
		&.open {
			border: 1px solid $blue;
		}
		& .label {
			font-weight: bold;
		}
		& .sub-label {
			font-size: 14px;
			margin-top: 2px;
		}
	}
	&.navigation {
		height: 100%;
		padding-left: 7px;
		padding-right: 7px;
		border-bottom: 3px solid transparent;
		padding-top: 3px;
		&:hover {
			border-bottom: 3px solid $border-orange;
		}
		&.open {
			padding-top: 3px !important;
			border-bottom: 3px solid $border-orange;
			background-color: $very-light-grey;
		}
	}
	& .left-icon {
		display: flex;
		&svg {
			width: 15px;
		}
	}
	& .right-icon {
		transition: transform 200ms ease;
		transform: scaleY(1);
		&.open {
			transform: scaleY(-1);
		}
	}
}
::v-deep .btn.navigation {
	border-radius: 0;
	margin: 0;
	border: none;
	&:focus {
		box-shadow: none !important;
	}
	&:hover {
		background-color: $very-light-grey;
		color: $blue-dark;
	}
	& .btn-container {
		padding-top: 3px;
	}
}
</style>
