/**
 * Returns a text of suppressed extended health benefits (EHB) for a member.
 */

import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default class DentalBenefitSuppress extends Model {
	static entity = 'dentalBenefitsSuppress';

	static primaryKey = 'locale';

	static fields() {
		return {
			locale: this.string(null).nullable(),
			benefitsDescriptions: this.attr(null).nullable()
		};
	}

	static async getSuppressedDentalBenefits(username, token, locale) {
		// Check to see if the benefits are in vuex-orm.

		const benefitsDescriptions = await DentalBenefitSuppress.find(locale)?.benefitsDescriptions;

		if (benefitsDescriptions) {
			return benefitsDescriptions;
		}

		// Nothing found in vuex-orm, make an api call to get the benefits for the member.
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/suppressed-coverages/dental`;

		startSpinner();

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			stopSpinner();

			if (response?.status === 200) {
				// Api call is good, add data to vuex-orm.
				let benefitsDescriptions = response.data;

				await DentalBenefitSuppress.insertOrUpdate({
					data: {
						locale,
						benefitsDescriptions
					}
				});

				return benefitsDescriptions;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
			stopSpinner();
		}
	}
}
