/**
 * Handlers for the members api
 */
import resourceUrls from './members/resource-urls';
import securityPolicy from './members/security-policy';
import cardholderDetails from './members/cardholder-details';
import contactInfoDetails from './members/contact-info-details';
import preferredLanguage from './members/preferred-language';
import participants from './members/participants';
import memberCardsDetails from './members/member-cards-details';
import premiums from './members/premiums';
import premiumsPdf from './demo/members/premiums-pdf';
import bankingDetails from './members/banking-details';
import providerTypes from './members/provider-types';
import claimTypes from './members/claim-types';
import healthProfessionals from './members/health-professionals';
import benefits from './members/benefits';
import extendedHealthBenefits from './members/coverages/extended-health-benefits';
import extendedHealthBenefitDetails from './members/coverages/extended-health-benefit-details';
import booklet from './members/coverages/booklet';
import balances from './members/coverages/balances';
import hsaEligibleClaims from './members/hsa-eligible-claims';
import paymentHistory from './members/payment-history';
import paymentHistoryPdf from './members/payment-history-pdf';
import paymentDetailsPdf from './members/payment-details-pdf';
import labels from './members/labels';
import submitClaim from './members/submit-claim';
import submitClaimMulti from './demo/members/submit-claim-multi';
import serviceTypes from './members/service-types';
import recentClaims from './members/recent-claims';
import historyDetails from './members/history/history';
import premiumPayment from './demo/members/premium-payment';

export default function (server) {
	/**
	 * Digital registration
	 *
	 * Note:  No need for a real token.  Following URL works http://localhost:8080/en/digital-validate?token=123
	 */
	server.get('/members/validate-digital-expiry', () => {
		return {
			emailExpired: false,
			emailVerified: false,
			emailLocked: false,
			alreadyRegistered: false,
			language: 'EN'
		};
	});

	/**
	 * Digital registration
	 */
	server.get('/members/validate-digital-member', () => {
		return {
			emailVerified: true,
			emailLocked: false
		};
	});

	resourceUrls(server);

	/**
	 * Part of login flow
	 *
	 * Change to "false" to show the screen to accept the terms and conditions
	 */
	server.get('/members/:id/terms-and-conditions', () => {
		return { termsAndConditionsAccept: true };
	});

	securityPolicy(server);

	// Dashboard
	paymentHistory(server);
	recentClaims(server);

	// My Account web services
	cardholderDetails(server);
	contactInfoDetails(server);
	preferredLanguage(server);
	participants(server);
	memberCardsDetails(server);
	premiums(server);
	premiumsPdf(server);
	bankingDetails(server);

	// Submit claim web services
	providerTypes(server);
	claimTypes(server);
	healthProfessionals(server);
	hsaEligibleClaims(server);
	labels(server);
	submitClaim(server);
	submitClaimMulti(server);
	serviceTypes(server);

	// My Coverage web services
	benefits(server);
	extendedHealthBenefits(server);
	extendedHealthBenefitDetails(server);
	booklet(server);
	balances(server);

	// History
	historyDetails(server);
	paymentHistoryPdf(server);
	paymentDetailsPdf(server);

	// Premium
	premiumPayment(server);
}
