import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class DirectDepositInfoCall extends Model {
	/**
	 * This method will call the api to return all the idirect deposit information.
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async getDirectDepositInfo(username, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + username + '/banking-details';
			let response = await axios
				.get(url, {
					headers: {
						'content-type': 'application/json',
						Authorization: token,
						'Accept-Language': locale
					},
					data: {}
				})
				.catch((err) => {
					this.store().dispatch('error', { name: 'getDirectDeposit', err });
				});
			return response;
		} catch (err) {
			this.store().dispatch('error', { name: 'getDirectDeposit', err });
		}
	}

	/**
	 * This method will call the api to update the direct deposit information.
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async updateDirectDepositInfo(branch, bank, account, username, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + username + '/banking-details';
			let response = await axios
				.put(
					url,
					{
						branch: branch,
						bank: bank,
						account: account
					},
					{
						headers: {
							'content-type': 'application/json',
							Authorization: token,
							'Accept-Language': locale
						},
						data: {}
					}
				)
				.catch((err) => {
					this.store().dispatch('error', { name: 'changeDirectDeposit', err });
				});
			return response;
		} catch (err) {
			this.store().dispatch('error', { name: 'changeDirectDeposit', err });
		}
	}

	/**
	 * This method will call the api to delete the direct deposit information.
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async deleteDirectDepositInfo(username, token, locale) {
		try {
			var url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + username + '/banking-details';
			let response = await axios
				.delete(url, {
					headers: {
						'content-type': 'application/json',
						Authorization: token,
						'Accept-Language': locale
					},
					data: {}
				})
				.catch((err) => {
					this.store().dispatch('error', { name: 'deleteeDirectDeposit', err });
				});
			return response;
		} catch (err) {
			this.store().dispatch('error', { name: 'deleteDirectDeposit', err });
		}
	}
}
