/**
 * My Account / Manage your Account / People on your plan
 */
export default function (server) {
	server.get('/members/:id/participants', () => {
		return [
			{
				participantId: '01',
				certificateId: '00000000801',
				firstName: 'Joe',
				lastName: 'Smith',
				isCardholder: true,
				terminationDate: '00000000',
				active: true,
				medicalExclusion: false,
				privacyConsent: false,
				dateOfBirth: '19630707'
			},
			{
				participantId: '02',
				certificateId: '00000000802',
				firstName: 'Monica',
				lastName: 'Smith',
				isCardholder: false,
				terminationDate: '00000000',
				active: true,
				medicalExclusion: false,
				privacyConsent: false,
				dateOfBirth: '19680405'
			},
			{
				participantId: '03',
				certificateId: '00000000803',
				firstName: 'Liam',
				lastName: 'Smith',
				isCardholder: false,
				terminationDate: '00000000',
				active: true,
				medicalExclusion: false,
				privacyConsent: false,
				dateOfBirth: '20050905'
			},
			{
				participantId: '04',
				certificateId: '00000000804',
				firstName: 'Debbie',
				lastName: 'Smith',
				isCardholder: false,
				terminationDate: '00000000',
				active: true,
				medicalExclusion: false,
				privacyConsent: false,
				dateOfBirth: '20020905'
			}
		];
	});
}
