/**
 * My Account / Manage your Account / Personal Information
 */

import { Response } from 'miragejs';

export default function (server) {
	server.get('/members/:id/contact-info-details', () => {
		return {
			address: {
				addressLine1: '1952 Medavie',
				addressLine2: 'line 2',
				addressLine3: 'line 3',
				postalCode: 'E1E1E2',
				province: 'NB',
				city: 'Dieppe',
				country: 'CAN',
				addressType: 'maax-unstructured'
			},
			phoneNumber: '5555558888'
		};
	});

	server.put('/members/:id/contact-info-details', () => {
		const httpStatusCode = 200; // MSS API returns a 200 on successful PUT.
		return new Response(httpStatusCode);
	});
}
