import { SUBMIT_CLAIM, DASHBOARD } from '@/constants/Routes.js';
import store from '@/store';
import Benefits from '@/models/coverage/BenefitsScepter.js';

export default {
	path: SUBMIT_CLAIM.SUBMIT_CLAIM,
	component: () =>
		import(/* webpackChunkName: "submitClaim" */ '@/pages/submit-claim/SubmitClaim.vue'),
	meta: { requiresAuth: true },

	beforeEnter: (to, from, next) => {
		try {
			// Get the security policy from the session to see if the member can submit claims
			const { submitClaim } = JSON.parse(sessionStorage.getItem('securityPolicy'));

			if (submitClaim) {
				next();
			} else {
				next({ name: DASHBOARD, params: { ...to.params } });
			}
		} catch (err) {
			store.dispatch('error', { name: 'navigation', err });
		}
	},

	children: [
		{
			path: SUBMIT_CLAIM.BEFORE_START_GENERIC,
			name: SUBMIT_CLAIM.BEFORE_START_GENERIC,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/before-start/BeforeStartGenericPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.BEFORE_START_DENTAL,
			name: SUBMIT_CLAIM.BEFORE_START_DENTAL,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/before-start/BeforeStartDentalPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.BEFORE_START_DRUGS,
			name: SUBMIT_CLAIM.BEFORE_START_DRUGS,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/before-start/BeforeStartDrugsPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.BEFORE_START_DCI_LADD,
			name: SUBMIT_CLAIM.BEFORE_START_DCI_LADD,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/before-start/BeforeStartDCILADDPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.BEFORE_START_HSA,
			name: SUBMIT_CLAIM.BEFORE_START_HSA,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/before-start/BeforeStartHSAPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.BEFORE_START_PWA,
			name: SUBMIT_CLAIM.BEFORE_START_PWA,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/before-start/BeforeStartPWAPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.BEFORE_START_TRAVEL,
			name: SUBMIT_CLAIM.BEFORE_START_TRAVEL,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/before-start/BeforeStartTravelPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT,
			name: SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT,
			component: () =>
				import(
					/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/TreatmentForAccidentPage.vue'
				),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.PROVIDER_TYPE_CHOICE,
			name: SUBMIT_CLAIM.PROVIDER_TYPE_CHOICE,
			component: () =>
				import(/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/ProviderTypeChoice.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.PROVIDER_SEARCH,
			name: SUBMIT_CLAIM.PROVIDER_SEARCH,
			meta: { requiresAuth: true },
			component: () =>
				import(/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/ProviderSearch.vue')
		},
		{
			path: SUBMIT_CLAIM.SERVICE_LIST,
			name: SUBMIT_CLAIM.SERVICE_LIST,
			component: () =>
				import(/* webpackChunkName: 'submitClaim' */ '@/pages/submit-claim/ServiceListPage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.CLAIM_DETAILS,
			name: SUBMIT_CLAIM.CLAIM_DETAILS,
			component: () =>
				import(/* webpackChunkName: 'submitClaim' */ '@/pages/submit-claim/ClaimDetailsPage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.OTHER_COVERAGE,
			name: SUBMIT_CLAIM.OTHER_COVERAGE,
			component: () =>
				import(/* webpackChunkName: 'servicetype' */ '@/pages/submit-claim/OtherCoverage.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.ATTACH_DOCUMENTS,
			name: SUBMIT_CLAIM.ATTACH_DOCUMENTS,
			component: () =>
				import(/* webpackChunkName: 'servicetype' */ '@/pages/submit-claim/AttachDocuments.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.HSA_ELIGIBLE_CLAIMS,
			name: SUBMIT_CLAIM.HSA_ELIGIBLE_CLAIMS,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "submitclaim"*/ '@/pages/submit-claim/hsa/HealthSpendingAccountPage.vue'
				),
			props: true
		},
		{
			path: SUBMIT_CLAIM.HSA,
			name: SUBMIT_CLAIM.HSA,
			component: () =>
				import(/* webpackChunkName: "submitclaim"*/ '@/pages/submit-claim/UseYourHsa.vue'),
			meta: { requiresAuth: true },
			beforeEnter: async (to, from, next) => {
				const benefits = await Benefits.getBenefits(
					sessionStorage.getItem('email'),
					sessionStorage.getItem('apiToken'),
					to.params.locale
				);
				if (benefits?.HSA) {
					next();
				} else {
					next({ name: SUBMIT_CLAIM.OTHER_COVERAGE });
				}
			}
		},
		{
			path: SUBMIT_CLAIM.VERIFY_INFORMATION,
			name: SUBMIT_CLAIM.VERIFY_INFORMATION,
			meta: { requiresAuth: true },
			component: () =>
				import(
					/* webpackChunkName: "submitclaim"*/ '@/pages/submit-claim/VerifyInformationPage.vue'
				),
			props: true
		},
		{
			path: SUBMIT_CLAIM.CLAIM_SUMMARY,
			name: SUBMIT_CLAIM.CLAIM_SUMMARY,
			component: () =>
				import(/* webpackChunkName: "submitclaim"*/ '@/pages/submit-claim/ClaimSummary.vue'),
			meta: { requiresAuth: true }
		},
		{
			path: SUBMIT_CLAIM.COMMENTS,
			name: SUBMIT_CLAIM.COMMENTS,
			component: () =>
				import(/* webpackChunkName: "submitclaim"*/ '@/pages/submit-claim/CommentsPage.vue'),
			meta: { requiresAuth: true },
			props: true
		}
	]
};
