<template>
	<div class="error-modal">
		<slot></slot>
		<ErrorModal
			:id="getUniqueId('error-boundary')"
			class="error-modal"
			name="error"
			:modal-show="isError"
			:automation-id="getAutomationId('error-boundary')"
			:error-title="errTitle"
			:error-message="errMsg"
			@change="change"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import IdMixin from '@/mixins/id';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';

// @vue/component
@Component({
	name: 'ErrorBoundary',
	components: {
		ErrorModal
	},
	mixins: [IdMixin]
})
export default class ErrorBoundary extends Vue {
	err = false;
	errMsg = this.$store.state.error.text;
	errTitle = '';

	updated() {
		//Check if the error has a name
		if (this.$store.state.error.name) {
			//check if it is an API error. If it is display it.
			if (this.$store.state.error.isCustomAPIMsg) {
				this.errMsg = this.$store.state.error.text;
			} else {
				//Check for a specific error, if none exist then use the text from the error/or generic
				if (this.$store.state.error.name === 'NOLOGIN') {
					this.errTitle = this.$t('noLogin');
				} else if (this.$store.state.error.name === 'NoToken') {
					this.errTitle = this.$t('noToken');
				} else if (
					this.$store.state.error.name !== '' &&
					this.$store.state.error.name !== 'generic'
				) {
					this.errMsg = this.$store.state.error.text;
				} else {
					this.errTitle = this.$t('generic');
				}
			}
			// Show the modal and hide the loading modal.
			this.err = true;
			this.$store.dispatch('updateLoading', false);
		}
		// If a error title exists then set it.
		if (this.$store.state.error.title) {
			this.errTitle = this.$store.state.error.title;
		}
	}

	get isError() {
		return this.err;
	}

	set isError(value) {
		this.err = value;
	}

	change() {
		this.err = false;
		this.$store.dispatch('disableNext', { disabled: false });
		this.$store.dispatch('clearError');
	}
}
</script>

<style lang="scss" scoped>
.modal-header {
	margin: 0em;
}

.modal-body {
	margin: 0em;
	padding-left: 0em;
	padding-top: 0em;
}
div ::v-deep .error-modal-body {
	padding: 0.75em 0em;
}

.modal-icon {
	font-size: 32px;
	margin-bottom: 15px;
	& ::v-deep path {
		color: $border-orange;
	}
}
</style>

<i18n>
{
	"en": {
		"generic": "Oops, something went wrong. Please try again later.",
		"noToken": "(WC)To use the member application you are required to login",
		"noLogin": "(WC)To use the member application you are required to login"
	},
	"fr": {
		"generic": "Nous sommes désolés, Une erreur s’est produite Veuillez réessayer plus tard.",
		"noToken": "(WC)(TODO FR)To use the member application you are required to login",
		"noLogin": "(WC)(TODO FR)To use the member application you are required to login"
  }
}
</i18n>
