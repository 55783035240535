<!-- eslint-disable @intlify/vue-i18n/no-v-html vue/no-v-html 
	** this is required to help bold certain words that is inside i18n only and should not be used with user inputs
-->
<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<footer :id="getUniqueId('appFooter')" class="footer footer-container">
		<div class="footer-wrapper">
			<div :id="getUniqueId('bcbsImage')" aria-hidden="true" class="footer-logo">
				<span class="footer-logo-padding-holder">
					<img src="@/assets/BCBC-Logo.svg" :alt="$t('a1ly.logo')" />
				</span>
			</div>
			<div :id="getUniqueId('trademark')" class="footer-text">
				<p
					class="showHideText"
					:class="{ showText: showFooterText }"
					:data-test-automation-id="getAutomationId('appFooter-content')"
					v-html="$t('trademark.textLine1')"
				></p>
				<div class="footer-links" :data-test-automation-id="getAutomationId('appFooter')">
					<span class="copyright-text">{{ $t('trademark.textLine2', { year: year }) }}</span>
					<span class="mx-1">|</span>
					<BaseButton
						:id="getUniqueId('legalNotice')"
						variant="link"
						:pill="false"
						type="button"
						class="flex-shrink-1 footer-links"
						data-tracking-id="footerLegalLink"
						:automation-id="getAutomationId('footerLegalLink')"
						:label="$t('footerLink.legal')"
						@click="openPopUp('legalNotice')"
					>
					</BaseButton>
					<span class="mx-1">|</span>
					<BaseButton
						:id="getUniqueId('privacy')"
						:pill="false"
						variant="link"
						type="button"
						class="flex-shrink-1 footer-links"
						data-tracking-id="footerPrivacyLink"
						:icon="['fal', 'external-link-alt']"
						icon-position="right"
						:automation-id="getAutomationId('footerPrivacyLink')"
						:label="$t('footerLink.privacy')"
						@click="goToPrivacyPolicy()"
					/>
					<span class="mx-1">|</span>
					<BaseButton
						:id="getUniqueId('cookies')"
						:pill="false"
						variant="link"
						type="button"
						class="flex-shrink-1 footer-links"
						data-tracking-id="footerCookiesLink"
						:automation-id="getAutomationId('footerCookiesLink')"
						:label="$t('footerLink.cookies')"
						@click="openPopUp('cookies')"
					/>
					<span class="mx-1">|</span>
					<BaseButton
						:id="getUniqueId('accessibility')"
						:pill="false"
						variant="link"
						type="button"
						class="flex-shrink-1 footer-links"
						data-tracking-id="footerCookiesLink"
						:automation-id="getAutomationId('footerAccessibilityLink')"
						:label="$t('footerLink.accessibility')"
						@click="openPopUp('accessibility')"
					/>
				</div>
			</div>
		</div>
		<BaseModal
			:id="getUniqueId('footer-info-modal')"
			class="legal-modal"
			:modal-show="modalShow"
			:modal-title="ModalTitle"
			is-header-one
			css-header-class="footer-modal"
			:is-static="true"
			:automation-id="getAutomationId('footer-info-modal')"
			@change="changeVisibility"
		>
			<template #contentBody>
				<slot>
					<div class="legalContent" v-html="legalContent"></div>
					<div class="leftMargeroonie">
						<BaseButton
							variant="primary"
							class="thebtn"
							:automation-id="getAutomationId('footer-info-modal-close-button')"
							:label="$t('modal.close')"
							:aria-label="$t('modal.close')"
							@click="changeVisibility(false)"
						>
						</BaseButton>
					</div>
				</slot>
			</template>
		</BaseModal>
	</footer>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import IdMixin from '@/mixins/id';
import isMobile from '@/mixins/mobile';
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseModal from '@/components/common/base/BaseModal';
import BaseButton from '@/components/common/base/BaseButton';
import legalNoticeEn from '@/content/legal/legal-notice-en.js';
import legalNoticeFr from '@/content/legal/legal-notice-fr.js';
import cookiesEn from '@/content/legal/cookies-en.js';
import cookiesFr from '@/content/legal/cookies-fr.js';
import accessibilityEn from '@/content/accessibility-en.js';
import accessibilityFr from '@/content/accessibility-fr.js';
// @vue/component
@Component({
	name: 'AppFooter',
	components: {
		BaseModal,
		BaseButton
	},
	mixins: [IdMixin, isMobile],
	props: {
		/** Used by the test automation suite and the Google tag manager to identify components. */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class AppFooter extends Vue {
	displayContent = 'legalNotice';
	toggleModalShow = false;
	showFooterText = true;

	get footerTextClass() {
		return this.showFooterText ? 'active' : '';
	}

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get year() {
		return new Date().getFullYear();
	}

	get ModalTitle() {
		if (this.displayContent === 'legalNotice') {
			return this.$t('modal.legal');
		} else if (this.displayContent === 'cookies') {
			return this.$t('modal.cookie');
		} else if (this.displayContent === 'accessibility') {
			return this.$t('modal.accessibility');
		}
		return this.$t('modal.legal');
	}
	get legalContent() {
		if (this.displayContent === 'legalNotice') {
			if (this.$store.state.i18n.locale === 'fr') {
				return legalNoticeFr;
			} else {
				return legalNoticeEn;
			}
		} else if (this.displayContent === 'cookies') {
			if (this.$store.state.i18n.locale === 'fr') {
				return cookiesFr;
			} else {
				return cookiesEn;
			}
		} else if (this.displayContent === 'accessibility') {
			if (this.$store.state.i18n.locale === 'fr') {
				return accessibilityFr;
			} else {
				return accessibilityEn;
			}
		}

		return legalNoticeEn;
	}

	get modalShow() {
		return this.toggleModalShow;
	}

	openPopUp(contentToDisplay) {
		this.toggleModalShow = true;
		this.displayContent = contentToDisplay;
	}

	changeVisibility(isVisible) {
		this.toggleModalShow = isVisible;
	}

	toggleFooterText() {
		this.showFooterText = !this.showFooterText;
	}

	/**
	 * Navigate to the external corporate site for the privacy Policy.
	 * Determine which site to go to based on the members brand.
	 * If no brand is found then go to default Medavie site.
	 */
	async goToPrivacyPolicy() {
		let brand = await JSON.parse(sessionStorage.getItem('securityPolicy'))?.brand;

		//If the brand doesn't exist and there is a brand value in the route use that.
		if (!brand && this.$router.currentRoute.query.brand) {
			brand = this.$router.currentRoute.query.brand.toUpperCase();
		}

		if (brand && brand === 'SBC') {
			window.open('https://prodwww.sk.bluecross.ca/legal/privacy', '_blank');
		} else if (brand && brand === 'OBC') {
			window.open('https://www.medaviebc.ca/en/ontarioplans/privacy', '_blank');
		} else if (brand && brand === 'QBC') {
			if (this.$store.state.i18n.locale === 'en') {
				window.open('https://www.medaviebc.ca/en/quebecplans/privacy', '_blank');
			} else {
				window.open(
					'https://www.medaviebc.ca/fr/regimesquebec/renseignements-personnels',
					'_blank'
				);
			}
		} else {
			if (this.$store.state.i18n.locale === 'en') {
				window.open('https://www.medaviebc.ca/en/legal/privacy', '_blank');
			} else {
				window.open(
					'https://www.medaviebc.ca/fr/mentions-legales/protection-des-renseignements-personnels',
					'_blank'
				);
			}
		}
	}
}
</script>

<!-- *************************************************************************
	STYLES
	************************************************************************* -->
<style lang="scss" scoped>
.footer .container,
.footer p,
.footer a {
	font-size: 12px;
	line-height: 18px;
}

.footer p {
	margin: 0;
}
.btn-link ::v-deep p {
	padding: 0;
}
.footer {
	background-color: $light-grey;
	height: 100%;
	& ::v-deep .btn-link {
		color: $dark-blue;
		font-weight: bold;
		padding: 0;
		font-size: 12px;
		min-width: 0;
		font-family: 'Lato', sans-serif;
		& .svg-right {
			margin: 0;
		}
	}
	& ::v-deep .modal-dialog {
		margin-top: 4%;
	}
}

.footer a {
	color: $blue;
}
.footer-links {
	padding-top: 0;
	padding-bottom: 0;
	& > button {
		height: 20px;
	}
}
.footer-links ::v-deep p {
	@media (max-width: 576px) {
		margin-bottom: 0;
	}
}

.footer-links-wrap {
	display: inline-block;
}

.footer-container {
	padding: 30px 0 30px 0;
	height: 100%;
}

.footer-expand-button {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-left: 20px;
}

.footer-wrapper {
	margin-left: auto;
	margin-right: auto;
	width: 1100px;
	@media (max-width: 1199px) {
		width: 80%;
	}
	@media (max-width: 910px) {
		margin-top: 0px;
	}
	@media (max-width: 767px) {
		width: 90%;
	}
}

.showHideText {
	// note that we're transitioning transform, not height!
	animation: hide 0.3s ease;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	transition: all;
}

.showHideText.showText {
	animation: show 0.5s ease;
	max-height: 999px;
	opacity: 1;
	height: auto;
}

@keyframes show {
	0% {
		max-height: 0;
		opacity: 0;
	}
	65% {
		max-height: 999px;
		opacity: 0;
	}
	100% {
		opacity: 1;
		max-height: 999px;
	}
}

@keyframes hide {
	0% {
		max-height: 999px;
		opacity: 1;
	}
	50% {
		opacity: 0;
		max-height: 999px;
	}
	100% {
		max-height: 0;
	}
}

* ::v-deep .footer-expand-button svg {
	transition: all 0.5s ease;
}

* ::v-deep .footer-expand-button .active svg {
	transform: rotate(180deg);
}

.footer-logo {
	display: inline;
}

.footer-logo-padding-holder {
	padding-bottom: 50px;
	float: left;
	padding-right: 20px;
	padding-top: 4px;
	@media (max-width: 1199px) {
		padding-bottom: 60px;
	}
	@media (max-width: 991px) {
		padding-bottom: 80px;
	}
	@media (max-width: 910px) {
		display: block;
		float: none;
		padding-top: 0;
		padding-bottom: 15px;
	}
}

.footer-logo img {
	width: 80px;
	height: 34px;
	@media (max-width: 576px) {
		height: 32px;
		width: 76px;
	}
}

/* Needed for IE11 */
.footer div.row {
	width: 100%;
}

.footer-text {
	flex: 1;
	font-size: 14px;
	font-weight: 400;
	display: inline;
}

.copyright-text {
	font-size: 12px;
	font-weight: 400;
}

.legal-modal ::v-deep .footer-modal {
	font-size: 2em;
	margin-top: 1.5em;
}
.legal-modal ::v-deep h2 {
	font-size: 24px;
}
.legal-modal ::v-deep h3 {
	font-size: 21px;
}
.legal-modal ::v-deep li h3 {
	display: inline;
}
::v-deep ol {
	list-style: none;
	counter-reset: item;
}
::v-deep ol li {
	counter-increment: item;
}
::v-deep ol li::before {
	content: counter(item) '.';
	font-size: 21px;
	font-family: $josefin-font-family;
	display: inline-block;
	margin-left: -20px;
}
.thebtn {
	font-size: 16px;
	line-height: 23px;
	font-weight: 400;
	min-width: 0px;
	word-wrap: break-word;
	white-space: normal;
	border: none;

	&.btn-primary {
		/* Font Awesome icon color */

		&:hover,
		&:focus {
			border: 1px solid $dark-blue;
			background-color: $dark-blue;
			color: $white;
			box-shadow: none;
		}
	}

	&.btn-link {
		color: $blue;
	}
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
    "a1ly": {
			"logo": "Blue Cross Blue Shield Logo"
		},
		"trademark": {
      "textLine1": "&trade; The Blue Cross symbol and name are registered trademarks of the Canadian Association of Blue Cross Plans, used under licence by Medavie Blue Cross, an independent licensee of the Canadian Association of Blue Cross Plans. * Trade-mark of the Canadian Association of Blue Cross Plans. † Trade-mark of the Blue Cross Blue Shield Association. Accidental Death and Dismemberment benefits, Life Benefits and Critical Illness will be underwritten by Blue Cross Life Insurance Company of Canada.",
	  "textLine2": "©{year} Medavie Blue Cross"
    },
    "footerLink": {
      "legal": "Legal",
      "privacy": "Privacy",
	  "cookies": "Cookies",
	  "accessibility": "Accessibility"
	},
	"modal": {
		"cookie": "Cookies",
		"legal": "Legal Notice",
		"accessibility": "Accessibility",
		"close": "Close"
	}
	},
	"fr": {
    "a1ly": {
			"logo": "BCBS"
		},
		"trademark": {
      "textLine1": "<sup>MC</sup> Le symbole et le nom Croix Bleue sont des marques de commerce déposées de l'Association canadienne des Croix Bleue, utilisées sous license par Croix Bleue Medavie, titulaire de licence indépendant de l'Association canadienne des Croix Bleue. * Marque déposée de l'Association canadienne des Croix Bleue. † Marque déposée de la Blue Cross Blue Shield Association. Les garanties d'assurance vie, d'assurance décès et mutilation accidentels et en cas de maladies graves sont établies par la Compagnie d'Assurance vie Croix Bleue du Canada. Les autres garanties sont établies par Medavie Inc., qui agit sous la raison sociale Croix Bleue Medavie.",
      "textLine2": "©{year} Croix Bleue Medavie"
    },
    "footerLink": {
      "legal": "Mentions légales",
      "privacy": "Protection des renseignements personnels",
	  "cookies": "Témoins",
	  "accessibility": "Accessibilité"
	},
	"modal": {
		"cookie": "Témoins",
		"legal": "Mentions légales",
		"accessibility": "Accessibilité",
		"close": "Fermer"
	}
	}
}
</i18n>
