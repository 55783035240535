import { Model } from '@vuex-orm/core';

/**
 * This class represent a Member
 */
export default class Page extends Model {
	static entity = 'page';

	static loaded = false;

	/**
	 * Page fields
	 */
	static primarykey = 'id';
	static fields() {
		return {
			id: this.string(),
			email: this.string(),
			claimFlowId: this.string(),
			claims: this.attr(),
			coordinationOfBenefits: this.attr()
		};
	}

	/**
	 * Create a new page object with the page name as the ID, and a members email to be tied to the claim objects.
	 * @param {String} pageName
	 * @param {String} email
	 * @returns Page object
	 */
	static async createNewPage(pageName, email) {
		await super.insert({
			data: {
				id: pageName,
				email: email,
				claimFlowId: email,
				claims: [],
				coordinationOfBenefits: []
			}
		});
		return await super.query().withAll().where('id', pageName).first();
	}

	/**
	 * Get the page based on the id.
	 * @param {String} pageId
	 * @returns page object
	 */
	static async getPageById(pageId) {
		return await super.query().withAll().where('id', pageId).first();
	}

	/**
	 * returns the latest claim object from the last page created.
	 * @returns Claim object
	 */
	static async getClaimFromLastPage() {
		const pages = super.query().withAll().all();
		return pages[pages.length - 1].claims[0];
	}

	/**
	 * Updates the page with the newest claim info.
	 * @param {Page} page
	 * @param {Claim} claim
	 * @returns
	 */
	static async updatePage(page, claim) {
		// Check if the claim already exists in the page.claims object.
		// If it does then update it, else insert a new claim.
		const i = page.claims.findIndex((_claim) => _claim.id === claim.id);
		if (i > -1) page.claims[i] = claim;
		else page.claims.push(claim);
		// Add the page Id to the claim so the claim can be linked to the page.
		page.claims[0].pageId = page.id;
		const updatedPage = await super.update({
			where: (pages) => {
				return pages.id === page.id;
			},
			data: {
				claims: page.claims,
				coordinationOfBenefits: claim.coordinationOfBenefits
			}
		});
		return updatedPage;
	}

	/**
	 * Delete a page based on the ID.
	 * ex. Used when navigating back a page.
	 * @param {String} id
	 */
	static async deleteById(id) {
		await super.delete(id);
	}

	/**
	 * Delete all pages.
	 * ex. Used when leaving the claim flow, or starting a new claim flow.
	 */
	static async clearPages() {
		await super.deleteAll();
	}
}
