<!-- eslint-disable vue/no-v-html 
		Added because certain word are bolded for errors and labels
		-->
<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<ValidationProvider
		ref="provider"
		v-slot="{ validated, invalid, failedRules }"
		:vid="validationId"
		:immediate="validateImmediately"
		:skip-if-empty="!validateIfEmpty"
		:rules="validationRules"
		:mode="validationMode"
		:disabled="validationDisabled"
		slim
	>
		<BFormGroup :id="getUniqueId('formGroupId')" :label-for="inputId">
			<div class="label-div d-flex w-100 align-items-baseline">
				<!-- added aria-hidden="true" to prevent NVDA label double reading, as input has aria-label attribute -->
				<label aria-hidden="true">{{ label }}</label>
				<span v-if="optionalLabel" class="optLabel" for="inputId" aria-hidden="true">{{
					optionalLabel
				}}</span>
				<slot name="labelButton"></slot>
			</div>
			<label
				v-if="htmlLabel"
				class="htmlLabel"
				for="inputId"
				@click="focusInput"
				v-html="htmlLabel"
			></label>
			<BInputGroup>
				<BInputGroupPrepend>
					<slot name="prepend"></slot>
				</BInputGroupPrepend>
				<BFormInput
					:id="inputId"
					ref="input"
					v-bind="$attrs"
					:state="getState(validated, invalid)"
					:value="value"
					:placeholder="placeholder"
					:aria-invalid="invalid"
					:aria-label="fullLabel"
					:required="required"
					:aria-describedby="invalid ? feedbackId : null"
					:data-test-automation-id="getAutomationId('input')"
					:data-tracking-id="getAutomationId('input')"
					:maxlength="maxLength"
					@input="onInput"
					@change="onChange"
					@blur="onBlur"
					@keyup="onKeyUp(inputId)"
					@focus="onFocus($event)"
				/>
				<BInputGroupAppend>
					<slot name="append"></slot>
				</BInputGroupAppend>
			</BInputGroup>
			<BFormInvalidFeedback
				:id="feedbackId"
				:force-show="false"
				:state="getState(validated, invalid)"
				:data-test-automation-id="getAutomationId('feedback')"
				v-html="getFeedback(validated, failedRules)"
			>
			</BFormInvalidFeedback>
		</BFormGroup>
	</ValidationProvider>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import hasRequiredRule from '@/utils/hasRequiredRule';
import IdMixin from '@/mixins/id';
import {
	BFormGroup,
	BFormInput,
	BFormInvalidFeedback,
	BInputGroup,
	BInputGroupAppend,
	BInputGroupPrepend
} from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate';
// @vue/component
@Component({
	name: 'BaseInput',
	components: {
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		ValidationProvider,
		BInputGroupAppend,
		BInputGroup,
		BInputGroupPrepend
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		id: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		optionalLabel: {
			type: String,
			default: ''
		},
		/**
		 * Use this when the label contains html tags.  Ex: "This is <strong>great!</strong>"
		 */
		htmlLabel: {
			type: String,
			default: null
		},
		labelAria: {
			type: String,
			default: null
		},
		/**
		 * Label used by the percentageInput component to allow the name of a contingent/beneficiary
		 * to have different css than the label.
		 */
		percentLabelPartOne: {
			type: String,
			default: null
		},
		/**
		 * Label used by the percentageInput component to allow the name of a contingent/beneficiary
		 * to have different css than the label.
		 */
		percentLabelPartTwo: {
			type: String,
			default: null
		},

		placeholder: {
			type: String,
			default: undefined
		},
		value: {
			type: [String, Number],
			default: ''
		},
		/**
		 * this prop set the maximum allowed characters per inputs
		 */
		maxLength: {
			type: Number,
			default: 255
		},
		/**
		 * This prop will add a Icon, or a string beside the input field.
		 */
		icon: {
			type: String,
			default: null
		},
		/**
		 * This prop controls if the field is validated immediatly
		 */
		validateImmediately: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls if the validate if the field is empty
		 */
		validateIfEmpty: {
			type: Boolean,
			default: true
		},
		/**
		 * This prop controls the validation id. This is needed when cross field validation
		 */
		validationId: {
			type: String,
			default: ''
		},
		/**
		 * This prop controls the validation rules.
		 */
		validationRules: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls the validation messages for each rules.
		 */
		validationMessages: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationMode: {
			type: String,
			default: 'eager'
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationDisabled: {
			type: Boolean,
			default: false
		},
		/**
		 * A string to appear after (on the right of...) the input field
		 */
		inputSuffix: {
			type: String,
			default: null
		},
		/**
		 * This prop is used for test automation Id's
		 */
		automationId: {
			type: String,
			required: false,
			default: `baseInput${Math.floor(Math.random() * 100)}`
		}
	}
})
export default class BaseInput extends Vue {
	/**
	 * The unique id for the input
	 * @return {string}
	 */
	inputId = this.id !== '' ? this.id : this.getUniqueId('input');
	/**
	 * The unique id for the feedback
	 * @return {string}
	 */
	feedbackId = this.getUniqueId('feedback');

	formgroupId = this.getUniqueId('formGroupId');

	/* This computed property gets concatenates the label and optional
		label to be read by the screen reader.
	*/
	get fullLabel() {
		if (this.label) {
			// Check if the input is using an html Label.
			if (this.htmlLabel) {
				// Remove the html tags from the label so that the screen reader doesn't read it.
				const newLabel = this.htmlLabel.replace(/<[^>]*>?/g, '');
				return newLabel;
			} else {
				return this.label + ' ' + this.optionalLabel;
			}
		} else {
			if (this.labelAria) {
				return this.labelAria;
			}
		}
		return null;
	}
	/**
	 * Check if validationRules include required
	 */
	get required() {
		return hasRequiredRule(this.validationRules);
	}
	/**
	 * Returns the feedback
	 *
	 * @param validated {Boolean} Flag is field is validated.
	 * @param failedRules {Object} The object of failed rules.
	 */
	getFeedback(validated, failedRules) {
		let message = null;
		if (validated && failedRules) {
			message = this.validationMessages[Object.keys(failedRules)[0]];
		}
		return message;
	}
	/**
	 * Returns the state
	 *
	 * @param validated {Boolean} Flag indicating if field is validated.
	 * @param invalid {Object} Flag indicating if field is valid
	 */
	getState(validated, invalid) {
		return validated && invalid ? false : null;
	}
	/**
	 * Handle input event.
	 *
	 * @param value {String} Checked value.
	 */
	onInput(value) {
		this.$emit('input', value);
	}

	onChange(value) {
		this.$emit('change', value);
	}

	onBlur(evt) {
		this.$emit('blur', evt);
	}

	onKeyUp(inputId) {
		this.$emit('keyup', inputId);
	}
	focusInput() {
		document.getElementById(this.inputId).focus();
	}
	onFocus(event) {
		this.$emit('focus', event);
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
.form-control {
	font-family: 'Lato', sans-serif;
	box-shadow: none;
	font-weight: 400;
	background-color: $input-background-white;
	border-color: $input-border-white;
	border-radius: 5px;

	&::placeholder,
	&::-ms-input-placeholder,
	&::-webkit-input-placeholder {
		color: $placeholder-color;
	}
	&:focus {
		border-color: $input-border-focus-blue;
	}
	&.is-valid,
	&.is-invalid {
		background-image: none;
	}
	&.is-invalid {
		border-color: $error-red;
		box-shadow: none;
		padding-right: 10px !important;
	}
}
* {
	& ::v-deep .input-group > .custom-select:not(:last-child),
	& ::v-deep.input-group > .form-control:not(:last-child) {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.form-group {
	margin-bottom: 1.5rem;

	& ::v-deep label {
		font-family: 'Josefin Sans', sans-serif;
		font-size: 18px;
		font-weight: 400;
		color: $label-grey;
		display: inline;
	}

	& .htmlLabel {
		display: block;
		float: none;
	}
}

.optLabel {
	color: $label-grey;
	font-weight: 300;
	font-size: 15px;
	margin-left: 5px;
}

.invalid-feedback {
	text-align: left;
	font-size: 14px;
	color: $error-red;
	line-height: 17px;
	font-weight: 400;
	background-color: $error-background-pink;
	border-color: $red;
	padding: 10px;
	height: auto;
	border-radius: 5px;
	margin-top: 0;
}

.input-append {
	display: inline;
}

.icon {
	display: inline-block;
	padding-top: 7px;
	padding-left: 7px;
	font-weight: bolder;
}
</style>
