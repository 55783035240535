export default `<section>
	<div>
		<p>Medavie Blue Cross operates a number of websites, including medaviebc.ca, medavie.bluecross.ca, joinblue.ca, livelovegrow.ca and others. Like most websites, we use small text files called cookies. This statement provides information on what cookies are and how they can be controlled.
		\nIf you wish to restrict or block cookies, you can set your internet browser to do so.</p>
		<h2>What are cookies?</h2>
		<p>Cookies are small text files containing tiny amounts of information that is saved on your device almost every time you visit a website. On each subsequent visit to a website, if your internet browser has a cookie saved it will send it back to the website. Cookies allow websites to recognize you when you return and provide enhanced functionality.</p>
		<h2>How does Medavie Blue Cross use cookies?</h2>
		<p>Medavie Blue Cross uses cookies for the following purposes:</p>
		<ul>
			<li>To collect statistical data on the use of our websites in order to monitor their performance and to make incremental improvements;</li>
			<li>To monitor marketing campaigns, and how successful they have been; and</li>
			<li>To track conversion from social media properties.</li>
		</ul>
		<h2>How can you control cookies?</h2>
		<p>If you do not wish to use cookies you can set your internet browser to restrict or block them. All modern browsers allow you to change your cookie settings. These settings are usually found in the "options" or "preferences" menu of your internet browser.
		</p>
		<p>It is important to note, however, that if you disable cookies, our websites (as well as others) may not be able to provide you a full experience.
		</p>
	</div>
</section>`;
