/**
 * Handler for payment history apis
 */
// import payment_history_en from './../../objects/payment_history.json';
// import payment_history_fr from './../../objects/payment_history_fr.json';

import payment_history_dash_en from './../../objects/payment_history_dash.json';
import payment_history_dash_fr from './../../objects/payment_history_dash_fr.json';

import payment_history_details_en from './../../objects/payment_details.json';
import payment_history_details_fr from './../../objects/payment_details_fr.json';
import { Response } from 'miragejs';
import { modifyTheData, sortTheData } from '@/mirage/helpers';

export default function (server) {
	/**
	 * Get list of payment hisotry for set date search
	 */
	server.get('/members/:id/payment-histories', (schema, request) => {
		let dataToSendBack = '';
		if (request.requestHeaders['Accept-Language'] === 'en') {
			dataToSendBack = payment_history_dash_en;
		} else {
			dataToSendBack = payment_history_dash_fr;
		}
		modifyTheData(dataToSendBack.paymentLines, 'dateProcessed', 5, 0);
		sortTheData(dataToSendBack.paymentLines, 'dateProcessed');
		return dataToSendBack;
	});

	server.get('members/:id/payment-details', (schema, request) => {
		if (request.queryParams.paymentId === '2023313T00051') {
			return request.requestHeaders['Accept-Language'] === 'en'
				? payment_history_details_en
				: payment_history_details_fr;
		}
		return new Response(400);
	});
}
