import { createServer } from 'miragejs';
import apiAccountsRouteHandlers from './routes/accounts';
import apiMembersRouteHandlers from './routes/members';
import apiSuppressedCoveragesRouteHandlers from './routes/suppressed-coverages';

export function makeServer({ environment = 'development' } = {}) {
	let server = createServer({
		environment,

		routes() {
			this.urlPrefix = 'https://mc-rainforest.dev.medavie.ca';
			this.namespace = 'rainforest';

			apiAccountsRouteHandlers(this);
			apiMembersRouteHandlers(this);
			apiSuppressedCoveragesRouteHandlers(this);

			// My Account / Manage your Account / Review and update premium payment method
			// Only api call on save?

			// Submit a Claim
			// Only api call on save?

			// Allow unhandled requests to pass through
			// Handles things like hotjar requests https://script.hotjar.com/sentry.472495a0c190fd25675c.js
			this.passthrough();

			// passthrough for other domains
			this.passthrough('https://appd-eum.medavie.ca/**');
			this.passthrough('http://col.eum-appdynamics.com/**');
			this.passthrough('https://dev.visualwebsiteoptimizer.com/**');
			this.passthrough('https://mobileassets.medaviebc.ca/**');
		}
	});

	return server;
}
