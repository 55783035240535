/**
 * My Account / Manage your Account / Review and print your premium statements
 *
 * The full call with live API is /members/:id/premiums?fromDate=20200101&toDate=20211106
 * In our case, we don't bother with data params as we return static data.
 */
export default function (server) {
	server.get('/members/:id/premiums', () => {
		return {
			totalAmount: 588.4,
			premiums: [
				{
					transactionDate: '20200625',
					type: 'Payment',
					amount: 163.54
				},
				{
					transactionDate: '20210222',
					type: 'Payment',
					amount: 81.77
				},
				{
					transactionDate: '20210416',
					type: 'Payment',
					amount: 81.77
				},
				{
					transactionDate: '20210728',
					type: 'Payment',
					amount: 261.32
				}
			]
		};
	});
}
