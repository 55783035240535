import {
	required,
	confirmed,
	length,
	email,
	max_value,
	digits,
	max
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', required);
extend('equal_to', confirmed);
extend('length', length);
extend('email', email);
extend('max_value', max_value);
extend('digits', digits);
extend('max', max);
