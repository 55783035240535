/**
 * My Coverage
 */
export default function (server) {
	server.get('/members/:id/benefits', () => {
		return {
			DENTAL: 'Dental',
			DRUGS: 'Drugs',
			EHB: 'Extended Health Benefit',
			HSA: 'HSA',
			HOSPITAL: 'Hospital',
			LIFEDIS: 'Life & Disability',
			OPTBEN: 'Optional Benefits',
			ONLINEDOCTOR: 'Online Doctors',
			PWA: 'Personal Welness Account',
			TRAVEL: 'Travel'
		};
	});
}
