<template>
	<BaseButton
		:label="label"
		variant="link"
		class="pl-0 h-100"
		:pill="false"
		:icon="icon"
		icon-position="left"
		:automation-id="getAutomationId('downloadLink')"
		@click="HasClicked"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
// @vue/component
@Component({
	name: 'DownloadLink',
	components: {
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		label: {
			type: String,
			default: null
		},
		icon: {
			type: Array,
			default: () => ['fal', 'file-alt'],
			required: false
		}
	}
})
export default class DownloadLink extends Vue {
	HasClicked() {
		this.$emit('click');
	}
}
</script>
<style lang="scss" scoped>
.small-body {
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	font-size: $body-small-font-size;
}
::v-deep .button-label {
	margin-left: 8px;
}
</style>
