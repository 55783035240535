import Vuex, { Store } from 'vuex';

import Authentication from '@/models/Authentication';
import BenefitProvider from '@/models/claims/BenefitProvider';
import BenefitServices from '@/models/claims/BenefitService';
import ManualClaimServiceItems from '@/models/claims/ManualClaimServiceItems';
import HsaEligibleClaims from '@/models/claims/HsaClaims';
import Claims from '@/models/claims/Claims';
import Page from '@/models/claims/Page';
import ClaimPaymentHistory from '@/models/claims/History';
import ContactInfo from '@/models/ContactInfo';
import CoordinationOfBenefits from '@/models/claims/CoordinationOfBenefits';
import Dependent from '@/models/Dependent';
import MemberCardInfo from '@/models/MemberCardInfo';
import MemberPlan from '@/models/MemberPlan';
import PaymentHistory from '@/models/PaymentHistory';
import PaymentDetails from '@/models/claims/PaymentDetails';
import PaymentDetailsScepter from '@/models/PaymentDetailsScepter';
import RecentClaims from '@/models/RecentClaims.js';
import SecurityPolicy from '@/models/SecurityPolicy';
import ClaimTypes from '@/models/claims/ClaimTypes';
import TermsConditions from '@/models/TermsCondition';
import Benefits from '@/models/coverage/Benefits';
import BenefitsScepter from '@/models/coverage/BenefitsScepter';
import DentalBenefit from '@/models/coverage/DentalBenefits';
import ExtendedHealthBenefits from '@/models/coverage/ExtendedHealthBenefits';
import ExtendedHealthBenefitDetails from '@/models/coverage/ExtendedHealthBenefitDetails';
import ExtendedHealthBenefitsScepter from '@/models/coverage/ExtendedHealthBenefitsScepter';
import ExtendedHealthBenefitDetailsScepter from '@/models/coverage/ExtendedHealthBenefitDetailsScepter';
import ehb from '@/models/suppressed-coverages/ehb';
import SavingSearchHistoryDemoModel from '@/models/demo/SavingSearchHistoryDemoModel.js';
import HospitalBenefits from '@/models/coverage/HospitalBenefits';
import HospitalBenefitDetails from '@/models/coverage/HospitalBenefitDetails';
import HospitalBenefitsScepter from '@/models/coverage/HospitalBenefitsScepter';
import HospitalBenefitDetailsScepter from '@/models/coverage/HospitalBenefitDetailsScepter';
import hspt from '@/models/suppressed-coverages/hspt';
import DentalSuppressCoverage from '@/models/suppressed-coverages/dental.js';
import ProviderSearchCalls from '@/models/ProviderSearchCalls';
import ClaimHistory from '@/models/ClaimHistory.js';
import Vue from 'vue';
import VuexORM from '@vuex-orm/core';
import breadcrumbModule from './modules/breadcrumbModule';
import createPersistedState from 'vuex-persistedstate';
import getError from '@/utils/error';
import i18n from './modules/i18n';
import DrugCoverage from '@/models/coverage/DrugCoverage';
import DrugCoverageGeneric from '@/models/coverage/DrugCoverageGeneric';
import DrugCoverageComparable from '@/models/coverage/DrugCoverageComparable';
import BenefitsBooklet from '@/models/coverage/BenefitsBooklet.js';
import RHIP from '@/models/coverage/RHIP';

// Create new instance of Database.
const database = new VuexORM.Database();

// Vuex modules

// Register Models.
database.register(Dependent);
database.register(MemberCardInfo);
database.register(MemberPlan);
database.register(Authentication);
database.register(TermsConditions);
database.register(ContactInfo);
database.register(SecurityPolicy);
database.register(Claims);
database.register(Page);
database.register(BenefitServices);
database.register(BenefitProvider);
database.register(CoordinationOfBenefits);
database.register(ClaimTypes);
database.register(PaymentHistory);
database.register(PaymentDetails);
database.register(PaymentDetailsScepter);
database.register(Benefits);
database.register(BenefitsScepter);
database.register(DentalBenefit);
database.register(ExtendedHealthBenefits);
database.register(ExtendedHealthBenefitDetails);
database.register(ExtendedHealthBenefitsScepter);
database.register(ExtendedHealthBenefitDetailsScepter);
database.register(ehb);
database.register(HsaEligibleClaims);
database.register(SavingSearchHistoryDemoModel);
database.register(HospitalBenefits);
database.register(HospitalBenefitDetails);
database.register(HospitalBenefitsScepter);
database.register(HospitalBenefitDetailsScepter);
database.register(hspt);
database.register(ClaimHistory);
database.register(ProviderSearchCalls);
database.register(DentalSuppressCoverage);
database.register(DrugCoverage);
database.register(DrugCoverageGeneric);
database.register(DrugCoverageComparable);
database.register(BenefitsBooklet);
database.register(RHIP);
database.register(RecentClaims);
database.register(ClaimPaymentHistory);
database.register(ManualClaimServiceItems);

Vue.use(Vuex);

export default new Store({
	modules: {
		i18n,
		breadcrumbModule
	},
	plugins: [
		VuexORM.install(database),
		createPersistedState({
			paths: ['i18n']
		})
	],
	state: {
		memberService: undefined,
		disableNext: false,
		error: {
			name: '',
			text: null,
			isCustomAPIMsg: false
		},
		loggedIn: false,
		backgroundImage: false,
		loading: false,
		loadingPageAnimation: false,
		loginAttempts: 0,
		sessionuser: '',
		token: '',
		successBanner: {
			show: false,
			text: '',
			headerText: ''
		},
		dashboardInfoBanner: {
			show: false,
			text: '',
			headerText: ''
		},
		fluidBodyContainer: false,
		isLocaleToggleDisabled: false, // Used by claim wizard to determine if the menu EN/FR toggle is disabled.
		isOkToLeaveClaimSubmission: false, // Used by claim wizard to determine if we display a warning or not.
		submitClaim: {
			COB: {},
			attachedFiles: [],
			additionalQuestions: {},
			additionalDocuments: null
		},
		HSAClaimSelectDate: null,
		coverage: {
			ehb: {
				searchKey: '',
				selectedParticipant: null,
				selectedParticipantIndex: 0,
				searchMatches: [],
				accordionsOpen: []
			},
			hospital: {
				searchKey: '',
				selectedParticipant: null,
				selectedParticipantIndex: 0,
				searchMatches: [],
				accordionsOpen: []
			}
		},
		previousRoute: null,
		history: null
	},
	mutations: {
		enrolmentApp(state, value) {
			state.enrolmentApp = value;
		},
		disableNext(state, value) {
			state.disableNext = value;
		},
		error(state, value) {
			state.error.name = value.name;
			state.error.text = value.text;
			state.error.title = value.title;
			state.error.isCustomAPIMsg = value.isCustomAPIMsg;
		},
		backgroundImage(state, value) {
			state.backgroundImage = value;
		},
		loginAttempts(state, value) {
			state.loginAttempts = value;
		},
		loading(state, value) {
			state.loading = value;
		},
		loadingPageAnimation(state, value) {
			state.loadingPageAnimation = value;
		},
		loggedIn(state, value) {
			state.loggedIn = value;
		},
		sessionuser(state, value) {
			state.sessionuser = value;
		},
		token(state, value) {
			state.token = value;
		},
		successBanner(state, value) {
			state.successBanner = value;
		},
		dashboardInfoBanner(state, value) {
			state.dashboardInfoBanner = value;
		},
		fluidBodyContainer(state, value) {
			state.fluidBodyContainer = value;
		},
		isLocaleToggleDisabled(state, value) {
			state.isLocaleToggleDisabled = value;
		},
		isOkToLeaveClaimSubmission(state, value) {
			state.isOkToLeaveClaimSubmission = value;
		},
		submitClaimCOB(state, value) {
			state.submitClaim.COB = value;
		},
		submitClaimFiles(state, value) {
			state.submitClaim.attachedFiles = value;
		},
		submitClaimDocsRequired(state, value) {
			state.submitClaim.attachDocsRequired = value;
		},
		submitClaimAdditionalQs(state, value) {
			state.submitClaim.additionalQuestions = value;
		},
		HSAClaimSelectDate(state, value) {
			state.HSAClaimSelectDate = value;
		},
		submitClaimAdditionalDocuments(state, value) {
			state.submitClaim.additionalDocuments = value;
		},
		ehbUpdate(state, value) {
			state.coverage.ehb = value;
		},
		hospitalUpdate(state, value) {
			state.coverage.hospital = value;
		},
		previousRouteUpdate(state, value) {
			state.previousRoute = value;
		},
		historyUpdate(state, value) {
			state.history = value;
		}
	},
	actions: {
		memberService(store, value) {
			store.commit('memberService', value.app);
		},
		disableNext(store, value) {
			store.commit('disableNext', value.disabled);
		},
		error(store, value) {
			if (!value) {
				store.commit('error', getError('generic', '', ''));
			} else if (value.title) {
				store.commit('error', getError(value.name, value.err, value.title));
			} else {
				store.commit('error', getError(value.name, value.err));
			}
		},
		clearError(store) {
			store.commit('error', { name: '', text: null, isCustomAPIMsg: false });
		},
		incrementLoginAttempt(store, value) {
			store.commit('loginAttempts', value);
		},
		resetLoginAttempts(store) {
			store.commit('loginAttempts', 0);
		},
		updateBackgroundImageStatus(store, value) {
			store.commit('backgroundImage', value);
		},
		async updateLoading(store, value) {
			store.commit('loading', value);
			// bootstrap bug for modals
			// class modal-open and attribute 'data-modal-open-count' not always
			// getting removed/reset
			if (!value) {
				const body = document.getElementsByTagName('body')[0];
				body.classList.remove('modal-open');
				body.dataset.modalOpenCount = '0';
			}
		},
		updatePageLoadingAnimation(store, value) {
			store.commit('loadingPageAnimation', value);
		},
		UpdateLogin(store, value) {
			store.commit('loggedIn', value);
		},
		updateSessionUser(store, value) {
			store.commit('sessionuser', value);
		},
		updateToken(store, value) {
			store.commit('token', 'Bearer ' + value);
		},
		clearSession(store) {
			store.commit('sessionuser', '');
			store.commit('token', '');
			store.commit('loggedIn', false);
		},
		successBanner(store, value) {
			store.commit('successBanner', value);
		},
		dashboardInfoBanner(store, value) {
			store.commit('dashboardInfoBanner', value);
		},
		fluidBodyContainer(store, value) {
			store.commit('fluidBodyContainer', value);
		},
		isLocaleToggleDisabled(store, value) {
			store.commit('isLocaleToggleDisabled', value);
		},
		isOkToLeaveClaimSubmission(store, value) {
			store.commit('isOkToLeaveClaimSubmission', value);
		},
		submitClaimCOB(store, value) {
			store.commit('submitClaimCOB', value);
		},
		submitClaimFiles(store, value) {
			store.commit('submitClaimFiles', value);
		},
		submitClaimDocsRequired(store, value) {
			store.commit('submitClaimDocsRequired', value);
		},
		submitClaimAdditionalQs(store, value) {
			store.commit('submitClaimAdditionalQs', value);
		},
		HSAClaimSelectDate(store, value) {
			store.commit('HSAClaimSelectDate', value);
		},
		submitClaimAdditionalDocuments(store, value) {
			store.commit('submitClaimAdditionalDocuments', value);
		},
		ehbUpdate(store, value) {
			store.commit('ehbUpdate', value);
		},
		hospitalUpdate(store, value) {
			store.commit('hospitalUpdate', value);
		},
		previousRouteUpdate(store, value) {
			store.commit('previousRouteUpdate', value);
		},
		historyUpdate(store, value) {
			store.commit('historyUpdate', value);
		}
	}
});
