// Trigger the loading modal spinner

import store from '@/store';

export function startSpinner() {
	store.dispatch('updateLoading', true);
}

export function stopSpinner() {
	store.dispatch('updateLoading', false);
}
