<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<main id="main">
		<BaseSkeletonWrapper :automation-id="getAutomationId('mss')">
			<template #loading>
				<BaseSkeleton
					width="100%"
					height="80vh"
					:automation-id="getAutomationId('mss')"
				></BaseSkeleton>
			</template>
			<BaseBreadcrumbs
				:items="breadcrumbs"
				class="mt-1"
				:automation-id="getAutomationId('app-body')"
			/>
			<SuccessBanner
				v-if="$store.state.successBanner.show"
				automation-id="successBanner"
				:lead-text="$store.state.successBanner.text"
				:header-text="$store.state.successBanner.headerText"
			/>
			<DashboardInfoBanner
				v-if="$store.state.dashboardInfoBanner.show"
				automation-id="dashboardInfoBanner"
			/>
			<BContainer :fluid="$store.state.fluidBodyContainer">
				<slot></slot>
			</BContainer>
			<LoadingModal
				modal-text=""
				:automation-id="getAutomationId('login-loading-modal')"
				@change="change"
			/>
		</BaseSkeletonWrapper>
	</main>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BContainer } from 'bootstrap-vue';
import BaseBreadcrumbs from '@/components/common/base/BaseBreadcrumbs';
import IdMixin from '@/mixins/id';
import LoadingModal from '@/components/common/LoadingModal';
import BaseSkeleton from '@/components/common/base/BaseSkeleton';
import BaseSkeletonWrapper from '@/components/common/base/BaseSkeletonWrapper';
import SuccessBanner from '@/components/common/SuccessBanner.vue';
import DashboardInfoBanner from '@/components/dashboard/DashboardInfoBanner';

// @vue/component
@Component({
	name: 'AppBody',
	components: {
		BContainer,
		BaseBreadcrumbs,
		LoadingModal,
		BaseSkeleton,
		BaseSkeletonWrapper,
		SuccessBanner,
		DashboardInfoBanner
	},
	mixins: [IdMixin]
})
export default class AppBody extends Vue {
	get breadcrumbs() {
		return this.$store.state.breadcrumbModule.breadcrumbs;
	}
	get isLoading() {
		return this.$store.state.loading;
	}

	change(isVisible) {
		this.$store.dispatch('updateLoading', isVisible);
	}
}
</script>
