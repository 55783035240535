/**
 * Submit Claim / Claim Type
 *
 * Note: PWA and HSA are benefits under LOB 03.  The same as chiro, physio etc
 */
export default function (server) {
	server.get('/members/:id/service-types', () => {
		return [];
	});
}
