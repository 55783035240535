<template>
	<ErrorModal
		class="timeout-modal"
		:data-test-automation-id="getAutomationId('TimeoutModal')"
		:automation-id="getAutomationId('TimeoutModal')"
		:modal-show="modalShow"
		:error-title="$t('stillHere')"
		:error-message="$t('sessionExpire')"
		:cant-close-on-esc="true"
		:has-close-button="false"
		no-hide-on-blur
	>
		<div>
			<p>{{ $t('notDone') }}</p>
			<BaseForm
				ref="timeout-form"
				:data-tracking-id="getAutomationId('timeout-form')"
				:automation-id="getAutomationId('timeout-form')"
				@keydown.enter="timeoutExtention"
				@submit="timeoutExtention"
			>
				<PasswordInput
					:key="resetComponent"
					v-model="values"
					:min="null"
					type="password"
					class="mr-4"
					password
					:automation-id="getAutomationId('TimeoutModal')"
					:label="$t('password')"
					@input="updatePassword"
				></PasswordInput>
				<BaseButton
					:id="getUniqueId('TimeoutModal')"
					class="mr-2 mb-2"
					:automation-id="getAutomationId('TimeoutModal')"
					variant="primary"
					:label="btnLabel"
					@click="timeoutExtention"
				>
				</BaseButton>
				<BaseButton
					class="mb-2"
					:label="$t('btn.logout')"
					:automation-id="getAutomationId('TimeoutModal')"
					@click="toLogOut"
				/>
			</BaseForm>
		</div>
	</ErrorModal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import ErrorModal from '@/components/common/ErrorModal.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseForm from '@/components/common/base/BaseForm';
import PasswordInput from '@/components/common/PasswordInput';
import Authentication from '@/models/Authentication';
import * as Routes from '@/constants/Routes.js';
// @vue/component
@Component({
	name: 'TimeOutModal',
	components: {
		BaseButton,
		ErrorModal,
		PasswordInput,
		BaseForm
	},
	mixins: [IdMixin],
	props: {
		/**
		 *
		 */
		modalShow: {
			type: Boolean,
			default: false
		},
		/**
		 * Test automation id is used by the test automation Id suite to identify specfic components.
		 */
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class TimeOutModal extends Vue {
	values = null;
	resetComponent = false;

	get modalTitle() {
		return this.$t('title');
	}

	get btnLabel() {
		return this.$t('btn.label');
	}

	updatePassword(password) {
		this.$emit('input', { ...this.value, password });
	}

	async timeoutExtention() {
		let email = sessionStorage.getItem('email');
		this.$store.dispatch('updateLoading', true);
		let brand = await JSON.parse(sessionStorage.getItem('securityPolicy')).brand;
		Authentication.login(email, this.values, this.$store.state.i18n.locale).then((response) => {
			if (response?.status === 200) {
				this.$emit('click');
			} else {
				this.$emit('close');
				// This dispatch is here so if the user times out in the claim submission they won't recieve the claim modal asking if they want to abandon the claim.
				this.$store.dispatch('isOkToLeaveClaimSubmission', true);
				this.$router.push({
					name: Routes.LOGIN,
					params: { locale: this.$router.currentRoute.params.locale },
					query: { brand: brand.toLowerCase(), status: 'INVALIDLOGIN' }
				});
			}
			this.$store.dispatch('updateLoading', false);
			// This is used by the "key" prop to reset the component.
			// Whenever this value updates the component gets refreshed, which will clear out the users password.
			this.resetComponent = !this.resetComponent;
			// Set the values so password isn't saved the next time the modal pops up.
			this.values = null;
		});
	}

	async toLogOut() {
		this.$emit('close');
		// This dispatch is here so if the user logs out in the claim submission they won't recieve the claim modal asking if they want to abandon the claim.
		this.$store.dispatch('isOkToLeaveClaimSubmission', true);
		let brand = await JSON.parse(sessionStorage.getItem('securityPolicy')).brand;
		this.$router.push({
			name: Routes.LOGIN,
			params: { locale: this.$router.currentRoute.params.locale },
			query: { brand: brand.toLowerCase(), status: 'LOGGEDOUT' }
		});
	}
}
</script>
<style lang="scss" scoped>
.small-body {
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	font-size: $body-small-font-size;
}
.modal-icon {
	font-size: 32px;
	margin-bottom: 15px;
	& path {
		color: $orange-dark;
	}
}
.timeout {
	width: 540px;
}
</style>
<i18n>
{
    "en": {
        "title":  "You’ll be logged out in one minute due to inactivity.",
        "stillHere": "Are you still there?",
        "sessionExpire": "Your session is about to expire due to inactivity. We do this to protect your privacy.",
        "notDone": "Not done yet? Enter your password to continue.",
		"password": "Password",
        "btn": {
            "label": "Continue your session",
			"logout": "Log out"
        }
    },
    "fr": {
        "title": "Votre session sera déconnectée dans une minute en raison d’une période d’inactivité.",
      	"stillHere": "Êtes-vous toujours là?",
        "sessionExpire": "Votre session est sur le point d’expirer en raison de votre inactivité. Nous fermons votre session par souci de protection de vos renseignements.",
        "notDone": "Vous n’avez pas encore fini? Entrez votre mot de passe pour continuer.",
		"password": "Mot de passe",
        "btn": {
            "label": "Continuer votre session",
			"logout": "Sortir"
          }
    }
}
</i18n>
