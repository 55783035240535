export default `<section>
	<div>
		<p>Croix Bleue Medavie opère un certain nombre de sites Web, notamment <em>medaviebc.ca, cbmedavie.ca, joinblue.ca, </em><em>medavie.croixbleue.ca, livelovegrow.ca</em> et <em>vivreaimerevoluer.ca</em>. Comme c’est le cas pour la plupart des sites Web, nous utilisons de petits fichiers texte appelés témoins. Le présent fournit des renseignements sur ce que sont les témoins et sur la façon de les contrôler.
		</p>
		<p>Si vous souhaitez restreindre ou bloquer les témoins, vous pouvez configurer votre navigateur Web en conséquence.
		</p>
		<h2>Qu’est-ce qu’un témoin?</h2>
		<p>Les témoins sont de petits fichiers texte contenant une petite quantité d’information qui est sauvegardée sur votre appareil presque chaque fois que vous visitez un site Web. Lors des visites subséquentes sur un site Web, si votre navigateur Web a un témoin de sauvegardé, les informations de ce dernier seront renvoyées au site Web. Les témoins permettent aux sites Web de vous reconnaître lorsque vous revisitez le site et d’offrir des fonctionnalités améliorées.
		</p>
		<h2>De quelle façon est-ce que Croix Bleue Medavie utilise les témoins?</h2>
		<p>Croix Bleue Medavie utilise les témoins dans le but de :</p>
		<ul>
			<li>recueillir les données statistiques sur l’utilisation de notre site Web (afin de faire le suivi de son rendement et de son utilisation, et d’apporter des améliorations progressives);
			</li>
			<li>faire le suivi de campagnes de marketing, et d’évaluer leur succès;</li>
			<li>suivre des conversations provenant des médias sociaux.</li>
		</ul>
		<h2>Comment pouvez-vous contrôler les témoins?</h2>
		<p>Si vous ne souhaitez pas utiliser les témoins, vous pouvez configurer votre navigateur Web de façon à les restreindre ou les bloquer. Tous les navigateurs Web récents vous permettent de changer les paramètres liés aux témoins. Ces paramètres se trouvent habituellement dans le menu « Options » ou « Préférences » de votre navigateur Web.
		</p>
		<p>Il est toutefois important de noter que si vous désactivez les témoins, notre site Web (ainsi que d’autres sites Web) pourrait ne pas pouvoir vous offrir une expérience complète.
		</p>
	</div>
</section>`;
