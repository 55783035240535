/**
 * Extended Health Coverage
 */
export default function (server) {
	server.get('/members/:id/coverages/extended-health-benefits', () => {
		return [
			{
				shortDesc:
					'Ambulance Services (some ambulance benefits may be covered under Hospital and/or Extended Health Benefits)',
				benefits: [
					{
						shortDesc: 'Air transportation stretcher case in Canada',
						benefitCode: '0730IC'
					},
					{
						shortDesc: 'Ground transportation in province',
						benefitCode: '0731IP'
					},
					{
						shortDesc: 'Ground transportation out of province',
						benefitCode: '0731OP'
					},
					{
						shortDesc: 'Special ambulance attendant in Canada',
						benefitCode: '0733IC'
					}
				]
			},
			{
				shortDesc: 'Braces/Splints/Stockings/Supports',
				benefits: [
					{
						shortDesc: 'Back brace purchase',
						benefitCode: '0507PB'
					},
					{
						shortDesc: 'Back brace rental',
						benefitCode: '0507RB'
					},
					{
						shortDesc: 'Back brace repair and adjustment',
						benefitCode: '0507BA'
					},
					{
						shortDesc: 'Back brace replacement - when due to a change in your condition',
						benefitCode: '0507BR'
					},
					{
						shortDesc: 'Casts and splints',
						benefitCode: '0301'
					}
				]
			},
			{
				shortDesc: 'Hearing Devices',
				benefits: [
					{
						shortDesc: 'Hearing aid as the result of ear surgery',
						benefitCode: '0304S'
					},
					{
						shortDesc: 'Hearing aid purchase',
						benefitCode: '0304'
					}
				]
			},
			{
				shortDesc:
					'Hospital Accommodations (some hospital benefits may be covered under Hospital and/or Extended Health Benefits)',
				benefits: [
					{
						shortDesc: 'Ground transportation in province',
						benefitCode: '0731IP'
					},
					{
						shortDesc: 'Ground transportation out of province',
						benefitCode: '0731OP'
					}
				]
			},
			{
				shortDesc:
					'Medical Equipment/Supplies (some diabetic equipment/supplies and colostomy/ostomy/supplies may be covered under Extended Health Benefits and/or Drugs)',
				benefits: [
					{
						shortDesc: 'Adjustable axle plate purchase',
						benefitCode: '0506P'
					},
					{
						shortDesc: 'Adjustable axle plate repair and adjustment',
						benefitCode: '0506A'
					}
				]
			},
			{
				shortDesc: 'Nursing Care',
				benefits: [
					{
						shortDesc: 'Licensed nursing assistant services in residence',
						benefitCode: '0202R'
					},
					{
						shortDesc: 'Licensed practical nurse services in residence',
						benefitCode: '0200R'
					}
				]
			},
			{
				shortDesc: 'Orthopedic Shoes/Supplies',
				benefits: [
					{
						shortDesc: 'Custom-made orthotics',
						benefitCode: '0511P'
					},
					{
						shortDesc: 'Custom-made orthotics replacement - when due to a change in your condition',
						benefitCode: '0511R'
					},
					{
						shortDesc: 'Orthopedic shoes purchase',
						benefitCode: '0512P'
					}
				]
			},
			{
				shortDesc: 'Practitioner Services',
				benefits: [
					{
						shortDesc: 'Acupuncture treatment',
						benefitCode: '0243'
					},
					{
						shortDesc: 'Athletic therapist',
						benefitCode: '0244'
					},
					{
						shortDesc: 'Chiropodist services',
						benefitCode: '0221'
					},
					{
						shortDesc: 'Chiropodist x-rays',
						benefitCode: '0221X'
					},
					{
						shortDesc: 'Chiropractor X-rays',
						benefitCode: '0222X'
					}
				]
			},
			{
				shortDesc: 'Vision Care',
				benefits: [
					{
						shortDesc: 'Contact lenses',
						benefitCode: '0604C'
					},
					{
						shortDesc: 'Contact lenses as the result of cataract surgery',
						benefitCode: '0604S'
					},
					{
						shortDesc: 'Contact lenses as the result of disease',
						benefitCode: '0604D'
					}
				]
			},
			{
				shortDesc: 'Other Products/Services',
				benefits: [
					{
						shortDesc: 'Accidental dental',
						benefitCode: '0760'
					},
					{
						shortDesc: 'Accidental dental deferred',
						benefitCode: '0760D'
					}
				]
			}
		];
	});
}

/*

Sample FR:

    [
      {
        "shortDesc": "Services ambulanciers (certains services ambulanciers peuvent être assurés en vertu de la protection hospitalisation et (ou) de la protection de soins de santé complémentaires)",
        "benefits": [
          {
            "shortDesc": "Ambulance auxiliaire spéciale au Canada",
            "benefitCode": "0733IC"
          },
          {
            "shortDesc": "Transport aérien en civière au Canada",
            "benefitCode": "0730IC"
          },
          {
            "shortDesc": "Transport terrestre dans la province",
            "benefitCode": "0731IP"
          },
          {
            "shortDesc": "Transport terrestre à l'extérieur de la province",
            "benefitCode": "0731OP"
          }
        ]
      },
      {
        "shortDesc": "Supports orthopédiques/attelles/bas médicaux/supports",
        "benefits": [          
          {
            "shortDesc": "Modification et réparation d'un support orthopédique fait sur mesure pour le pied/la cheville droit(e)",
            "benefitCode": "0507RCAA"
          },
          {
            "shortDesc": "Modification et réparation d'un support orthopédique fait sur mesure pour le pied/la cheville gauche",
            "benefitCode": "0507LCAA"
          },       
          {
            "shortDesc": "Plâtres et attelles",
            "benefitCode": "0301"
          },
          {
            "shortDesc": "Remplacement d'attelle de Brown - en raison d'un changement de votre état de santé",
            "benefitCode": "0527R"
          },
          {
            "shortDesc": "Remplacement d'un collet cervical dur - en raison d'un changement de votre état de santé",
            "benefitCode": "0508R"
          },
          {
            "shortDesc": "Remplacement d'un collet cervical mou - en raison d'un changement de votre état de santé",
            "benefitCode": "0509R"
          },
          {
            "shortDesc": "Remplacement d'un support orthopédique pour la cheville droite - en raison d'un changement de votre état de santé.",
            "benefitCode": "0507RAR"
          },
    
          {
            "shortDesc": "Remplacement d'une orthèse dorsale - en raison d'un changement de votre état de santé",
            "benefitCode": "0507BR"
          },
          {
            "shortDesc": "Remplacement de bandages herniaires - en raison d'un changement de votre état de santé",
            "benefitCode": "0510R"
          }
        ]
      },
      {
        "shortDesc": "Appareils auditifs (prothèses auditives)",
        "benefits": [
          {
            "shortDesc": "Achat d'un appareil auditif",
            "benefitCode": "0304"
          },
          {
            "shortDesc": "Achat d'un appareil auditif après une chirurgie à l'oreille",
            "benefitCode": "0304S"
          }
        ]
      },
      {
        "shortDesc": "Hospitalisation (certains services hospitaliers peuvent être assurés en vertu de la protection hospitalisation et (ou) de la protection de soins de santé complémentaires)",
        "benefits": [
          {
            "shortDesc": "Transport terrestre dans la province",
            "benefitCode": "0731IP"
          },
          {
            "shortDesc": "Transport terrestre à l'extérieur de la province",
            "benefitCode": "0731OP"
          }
        ]
      },
      {
        "shortDesc": "Appareils médicaux et fournitures (certaines pièces d'équipement/fournitures pour diabétiques et pour colostomisés/stomisés peuvent être assurées en vertu de la protection de soins de santé complémentaires et (ou) de médicaments sur ordonnance)",
        "benefits": [
          {
            "shortDesc": "Achat d'oxygène gazeux au Canada",
            "benefitCode": "0399IC"
          },
          {
            "shortDesc": "Achat d'un fauteuil roulant électrique",
            "benefitCode": "0302E"
          },
          {
            "shortDesc": "Achat d'un humidificateur (utilisé avec un appareil C-pap ou B-pap)",
            "benefitCode": "0311H"
          },
          {
            "shortDesc": "Tampons d'alcool pour diabétiques",
            "benefitCode": "0404A"
          },
          {
            "shortDesc": "lecteur de glycémie intégré à un doseur d'insuline",
            "benefitCode": "0404ID"
          }
        ]
      },
      {
        "shortDesc": "Soins Infirmiers",
        "benefits": [
          {
            "shortDesc": "Dispensateur de soins personnels à domicile",
            "benefitCode": "0247"
          },
          {
            "shortDesc": "Services d'un(e) aide infirmier(ère) autorisé(e) à domicile",
            "benefitCode": "0206R"
          },
          {
            "shortDesc": "Services d'un(e) infirmier(ère) autorisé(e) à domicile",
            "benefitCode": "0205R"
          },
          {
            "shortDesc": "Services d'un(e) infirmier(ère) auxiliaire autorisé(e) à domicile",
            "benefitCode": "0202R"
          },
          {
            "shortDesc": "Services d'un(e) infirmier(ère) de  l'Ordre de Victoria à domicile",
            "benefitCode": "0207R"
          },
          {
            "shortDesc": "Services d'un(e) infirmier(ère) praticien(ne) agréé(e) à domicile",
            "benefitCode": "0200R"
          }
        ]
      },
      {
        "shortDesc": "Chaussures/fournitures orthopédiques",
        "benefits": [
          {
            "shortDesc": "Achat de chaussures orthopédiques",
            "benefitCode": "0512P"
          },
          {
            "shortDesc": "Achat de fournitures orthopédiques",
            "benefitCode": "0513P"
          },
          {
            "shortDesc": "Modification et réparation de chaussures orthopédiques",
            "benefitCode": "0512A"
          },
          {
            "shortDesc": "Orthèses faites sur mesure",
            "benefitCode": "0511P"
          },
          {
            "shortDesc": "Remplacement d'orthèses faites sur mesure - en raison d'un changement de l'état de santé",
            "benefitCode": "0511R"
          },
          {
            "shortDesc": "Remplacement de chaussures orthopédiques - en raison d'un changement de votre état de santé",
            "benefitCode": "0512R"
          },
          {
            "shortDesc": "Souliers orthopédiques faits sur mesure",
            "benefitCode": "0514P"
          }
        ]
      },
      {
        "shortDesc": "Services paramédicaux",
        "benefits": [
          {
            "shortDesc": "Conseiller",
            "benefitCode": "0264"
          },
          {
            "shortDesc": "Ergothérapeute",
            "benefitCode": "0242"
          },
          {
            "shortDesc": "Orthophoniste",
            "benefitCode": "0230"
          },
          {
            "shortDesc": "Services Spécialisés",
            "benefitCode": "0228"
          },
          {
            "shortDesc": "Services d'un chiropraticien - Services ultérieurs",
            "benefitCode": "02222"
          },
          {
            "shortDesc": "Services d'un chiropraticien - Évaluation initiale",
            "benefitCode": "02221"
          },
          {
            "shortDesc": "Services d'un massothérapeute - Massage intramusculaire",
            "benefitCode": "02802"
          },
          {
            "shortDesc": "Services d'un massothérapeute - Massage intégré",
            "benefitCode": "02803"
          },
          {
            "shortDesc": "Services d'un massothérapeute - Massage thérapeutique",
            "benefitCode": "02801"
          },
          {
            "shortDesc": "Services de physiothérapeute - Visite/évaluation initiale",
            "benefitCode": "02261"
          },
          {
            "shortDesc": "Thérapeute du sport",
            "benefitCode": "0244"
          },
          {
            "shortDesc": "Traitement d'acuponcture",
            "benefitCode": "0243"
          },
          {
            "shortDesc": "Travailleur(se) social(e)",
            "benefitCode": "0240"
          }
        ]
      },
      {
        "shortDesc": "Prothèses/Fournitures",
        "benefits": [
          {
            "shortDesc": "Achat d''une prothèse nasale",
            "benefitCode": "0524"
          },
          {
            "shortDesc": "Achat d'un bras droit",
            "benefitCode": "0500RP"
          },
          {
            "shortDesc": "Remplacement d'un oeil gauche - en raison d'un changement de votre état de santé",
            "benefitCode": "0502LR"
          },
          {
            "shortDesc": "Remplacement d'un pied droit - en raison d'un changement de votre état de santé",
            "benefitCode": "0503RR"
          },
          {
            "shortDesc": "Remplacement d'un pied gauche - en raison d'un changement de votre état de santé",
            "benefitCode": "0503LR"
          },
          {
            "shortDesc": "Remplacement d'une cavité articulaire pour bras droit - en raison d'un changement de votre état de santé",
            "benefitCode": "0500RSR"
          },
          {
            "shortDesc": "Remplacement d'une cavité articulaire pour jambe droite (genou) - en raison d'un changement de votre état de santé",
            "benefitCode": "0505RSR"
          },        
          {
            "shortDesc": "Remplacement d'une prothèse mammaire gauche - en raison d'un changement de votre état de santé",
            "benefitCode": "0501LR"
          },
          {
            "shortDesc": "Remplacement de gant pour main esthétique - en raison d'un changement de votre état de santé",
            "benefitCode": "0521R"
          },
          {
            "shortDesc": "Soutiens-gorge postmastectomie",
            "benefitCode": "0411"
          }
        ]
      },
      {
        "shortDesc": "Soins de la vue",
        "benefits": [
          {
            "shortDesc": "Chirurgie correctrice au laser",
            "benefitCode": "0600LCS"
          },
          {
            "shortDesc": "Soins de la vue pour examen et verres correcteurs",
            "benefitCode": "0600V"
          },
          {
            "shortDesc": "Soins de la vue résultant d'une chirurgie pour cataracte",
            "benefitCode": "0603S"
          },
          {
            "shortDesc": "Verres de contact",
            "benefitCode": "0604C"
          },
          {
            "shortDesc": "Verres de contact suite à une maladie",
            "benefitCode": "0604D"
          },
          {
            "shortDesc": "Verres de contact à la suite d'une chirurgie pour cataracte",
            "benefitCode": "0604S"
          }
        ]
      },
      {
        "shortDesc": "Autres produits/services",
        "benefits": [
          {
            "shortDesc": "Soins dentaires par suite d'un accident",
            "benefitCode": "0760"
          },
          {
            "shortDesc": "Soins dentaires par suite d'un accident - différés",
            "benefitCode": "0760D"
          }
        ]
      },
      {
        "shortDesc": "Compte Gestion-santé",
        "benefits": [
          {
            "shortDesc": "Compte de dépenses",
            "benefitCode": "BANK"
          }
        ]
      }
    ]

*/
