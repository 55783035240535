import { render, staticRenderFns } from "./SkipToContentLink.vue?vue&type=template&id=1a9a7452&scoped=true&"
var script = {}
import style0 from "./SkipToContentLink.vue?vue&type=style&index=0&id=1a9a7452&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a9a7452",
  null
  
)

/* custom blocks */
import block0 from "./SkipToContentLink.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports