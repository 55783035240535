export default `<section>
<section>
    <div>
        <p>At Medavie Blue Cross, we understand that accessible customer service means providing a barrier-free experience for our customers and clients. It's important to us that all Canadians have the ability to equally access our products and services.</p> 
        <h2>Accessibility for Ontarians with Disabilities Act (AODA)</h2>
        <p>The Accessibility for Ontarians with Disabilities Act (AODA) establishes the accessibility standards for information, communications, employment and transportation. It applies not just to Ontario, but to all of Canada.</p>
        <p>Medavie Blue Cross is compliant with the Customer Service Standard, which requires us to develop policies, practices and training related to making our goods and services accessible to persons with disabilities.</p>
        <p>Find out more: read our <a href="https://docs.medaviebc.ca/AccessibleCustomerServicePolicy.EN.2016.pdf?mtime=20170221133710" target="_blank">Accessible Customer Service Policy Ontario</a> or <a href="https://www.medaviebc.ca/en/customer-service" target="_blank">contact<a/> one of our offices for more information.</p>
        <p>To find out more about Accessibility for Ontarians with Disabilities Act, visit the <a href="https://www.ontario.ca/page/accessibility-in-ontario" target="_blank">Ontario Ministry of Community and Social Services website</a>.</p>
    </div>
</section>`;
