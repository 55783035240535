/**
 * Member coverage Hospital
 */

import hospital_EN from './../../objects/benefit_hospital.json';
import hospital_FR from './../../objects/benefit_hospital_fr.json';

import hospital_details_EN from './../../objects/benefit_hospital_details.json';
import hospital_details_FR from './../../objects/benefit_hospital_details_fr.json';

export default function (server) {
	server.get('members/:id/coverages/hospital', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en' ? hospital_EN : hospital_FR;
	});
	server.get('/members/:id/coverages/hospital/:code/details', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en'
			? hospital_details_EN
			: hospital_details_FR;
	});
}
