/**
 * Handlers for the accounts api
 */

import { Response } from 'miragejs';

export default function (server) {
	/**
	 * Part of digital registration
	 */
	server.post('/accounts/digital-register', () => {
		const httpStatusCode = 201;
		const headers = {};
		const data = {
			message: 'Registration successful'
		};

		return new Response(httpStatusCode, headers, data);
	});

	/**
	 * Part of login flow
	 *
	 * The returned token is modified to expire in 2032.
	 *
	 * By default, miragejs returns a 201 on a POST.  Since the MSS ui looks for a 200 return code, we need to use
	 * the Response method to return a specific http code.
	 */
	server.post('/accounts/login', () => {
		const httpStatusCode = 200;
		const headers = {
			'x-auth':
				'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJCQ00iLCJpc3MiOiJNZWRhdmllIEluYy4iLCJleHAiOjE5NjkzMzc0MDgsInBvbGljeUlkIjoiMDAxNjU1MzAwMSIsImNlcnRpZmljYXRlSWQiOiIwMDAwMDAwMDgwMSIsInVzZXJuYW1lIjoiam9lLnNtaXRoQG1lZGF2aWUuYmx1ZWNyb3NzLmNhIiwiZ3JvdXBzIjpbIk1FTUJFUiJdfQ.3w6ZvRaIUvFJgSfBJ4IVBXjIxOBc9JH5kfx9Eaw5IrE'
		};
		const data = {
			message: 'Login successful'
		};

		return new Response(httpStatusCode, headers, data);
	});

	/**
	 * Use this to simulate an error during login.  For example, a bad password.
	 */
	// server.post(
	// 	'/accounts/login',
	// 	() => {
	// 		const httpStatusCode = 401;
	// 		const headers = {};
	// 		const data = {
	// 			message: 'INVALID'
	// 		};

	// 		return new Response(httpStatusCode, headers, data);
	// 	}
	// 	// { timing: 3000 } // Simlate a slow response (3000 = 3 seconds)
	// );

	/**
	 * Part of login flow
	 *
	 * The returned token is modified to expire in 2032.
	 */
	server.get('/accounts/:id/jwt', () => {
		return {
			token:
				'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJCQ00iLCJpc3MiOiJNZWRhdmllIEluYy4iLCJleHAiOjE5NjkzMzc0MDgsInBvbGljeUlkIjoiMDAxNjU1MzAwMSIsImNlcnRpZmljYXRlSWQiOiIwMDAwMDAwMDgwMSIsInVzZXJuYW1lIjoiam9lLnNtaXRoQG1lZGF2aWUuYmx1ZWNyb3NzLmNhIiwiZ3JvdXBzIjpbIk1FTUJFUiJdfQ.3w6ZvRaIUvFJgSfBJ4IVBXjIxOBc9JH5kfx9Eaw5IrE'
		};
	});
}
