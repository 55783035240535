<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BButton
		:class="lastClass"
		:pill="pill"
		:variant="buttonVariant"
		v-bind="$attrs"
		:aria-label="ariaLabelComp"
		role="button"
		:size="size"
		:data-test-automation-id="getAutomationId('button')"
		:data-tracking-id="getAutomationId('button')"
		:disabled="disabled"
		:aria-expanded="ariaExpanded"
		@click="onClick($event)"
	>
		<div v-if="!icon && !iconTwo" :class="[mobileClass, 'button-label']">
			<!-- eslint-disable-next-line -->
			<span v-if="htmlLabel" v-html="label" />
			<span v-else>{{ label }}</span>
		</div>
		<div v-else class="d-flex">
			<div
				v-if="icon"
				:class="[iconPosition === 'left' ? 'pr-1' : 'order-1', { 'pl-2': iconPadding }]"
			>
				<FontAwesomeIcon
					:icon="icon"
					:size="iconSize"
					:rotation="iconRotation"
					aria-hidden="true"
				/>
			</div>
			<div
				v-if="iconTwo"
				:class="[
					iconTwoPosition === 'left' ? 'pr-2' : 'pl-2 order-1',
					{ 'pl-2': iconPadding },
					'icon-two'
				]"
			>
				<FontAwesomeIcon
					:icon="iconTwo"
					:size="iconSize"
					:rotation="iconTwoRotation"
					aria-hidden="true"
				/>
			</div>
			<div :class="[mobileClass, 'button-label']">
				<!-- eslint-disable-next-line -->
				<span v-if="htmlLabel" v-html="label" />
				<span v-else>{{ label }}</span>
			</div>
		</div>
	</BButton>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BButton } from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/**
 * This component renders a button.
 */
// @vue/component
@Component({
	name: 'BaseButton',
	components: {
		BButton,
		FontAwesomeIcon
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: ''
		},
		htmlLabel: {
			type: Boolean,
			default: false
		},
		ariaLabel: {
			type: String,
			default: null
		},
		variant: {
			default: '',
			validator: (prop) =>
				[
					'secondary',
					'link',
					'primary',
					'danger',
					'modal',
					'outline-secondary',
					'outline-primary',
					'outline-success',
					'outline-danger',
					'modal',
					'outline-warning',
					'outline-dark',
					''
				].includes(prop)
		},
		icon: {
			type: Array,
			default: null
		},
		iconTwo: {
			type: Array,
			default: null
		},
		iconPosition: {
			type: String,
			default: 'right'
		},
		iconTwoPosition: {
			type: String,
			default: 'left'
		},
		automationId: {
			type: String,
			required: true
		},
		iconSize: {
			type: String,
			default: null
		},
		/**
		 * set rotation on the fontawesome icon
		 * this prop does not take zero as an input.
		 * To return the icon to it's original state pass in null
		 */
		iconRotation: {
			type: Number,
			default: null
		},
		iconTwoRotation: {
			type: Number,
			default: null
		},
		pill: {
			type: Boolean,
			default: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		compressMobile: {
			type: Boolean,
			default: false
		},
		last: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'md'
		},
		ariaExpanded: {
			type: String,
			default: null
		},
		iconPadding: {
			type: Boolean,
			default: true
		}
	}
})
export default class BaseButton extends Vue {
	mobileClass = '';
	lastClass = '';

	/**
	 * Using a computed prop for the aria label. To make sure the label gets updated when the member toggles the locale.
	 */
	get ariaLabelComp() {
		return this.ariaLabel ? this.ariaLabel : this.label;
	}

	created() {
		if (this.compressMobile) {
			this.mobileClass = 'mobile';
		}
		if (this.last) {
			this.lastClass = 'last';
		}
	}

	/**
	 * Returns the button variant
	 * @returns {String}
	 */
	get buttonVariant() {
		switch (this.variant) {
			case 'link':
				return 'link';
			case 'primary':
				return 'primary';
			case 'danger':
				return 'danger';
			case 'outline-primary':
				return 'outline-primary';
			case 'outline-success':
				return 'outline-success';
			case 'outline-danger':
				return 'outline-danger';
			case 'outline-warning':
				return 'outline-warning';
			case 'outline-dark':
				return 'outline-dark';
			default:
				return 'outline-secondary';
		}
	}

	/**
	 * Handle on click event.
	 */
	onClick(evt) {
		this.$emit('click', evt);
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
::v-deep div {
	// bootstrap overrides width of all divs in the stacked table css
	width: auto !important;
}

.mobile {
	@media all and (max-width: 500px) {
		display: none;
	}
}

.last {
	padding-right: 0;
}

.btn {
	align-items: center;
	justify-content: center;
	white-space: normal;
	word-wrap: break-word;
	& p {
		font-family: 'Josefin Sans', sans-serif;
		font-weight: 400;
		color: $blue;
	}

	&.btn-primary {
		padding: 0.6rem 1.5rem;
		background-color: $blue;
		border-color: $blue;
		& p {
			color: $white;
		}
		/* Font Awesome icon color */
		& svg path {
			color: $white;
		}
		&:hover,
		&:focus,
		&:active,
		&:active:focus {
			border: 1px solid $dark-blue;
			background-color: $dark-blue;
			color: $white;
		}
	}

	&.btn-primary.disabled {
		cursor: default;
		pointer-events: none;
	}
	&.btn-danger {
		padding: 0.6rem 1.5rem;
		& p {
			color: $white;
		}
	}
	&.btn-outline-secondary > p {
		color: $blue;
	}
	&.btn-link {
		& p {
			color: $blue;
			&:hover {
				color: $dark-blue;
				text-decoration: underline;
				text-decoration-thickness: 1px;
				text-underline-offset: 2px;
			}
		}
		& svg path {
			color: $blue;
		}
		&:hover {
			color: $dark-blue;
			text-decoration: underline;
			text-decoration-thickness: 1px;
			text-underline-offset: 2px;
		}
		&:focus {
			box-shadow: none;
			text-decoration-thickness: 1px;
			text-underline-offset: 2px;
		}
		&:focus-visible {
			box-shadow: 0 0 0 0.2rem rgb(38 108 137 / 50%);
		}
	}
	&.btn-outline-secondary {
		padding: 0.6rem 1.5rem;
		border: 1px solid $blue;
		background-color: $very-light-grey;
		color: $blue;
		/* Font Awesome icon color */
		& svg path {
			color: $blue;
		}
		&:hover,
		&:focus,
		&:active,
		&:active:focus {
			border: 1px solid $dark-blue;
			background-color: $dark-blue;
			color: $white;
			& p {
				color: $white;
			}
			& svg path {
				color: $white;
			}
		}
	}
}
::v-deep .disabled {
	opacity: 0.25;
}
</style>
