import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class RHIP extends Model {
	static entity = 'rhip';
	static primaryKey = 'id';

	static fields() {
		return {
			// store,
			id: this.string(null).nullable(),
			amount: this.number(null).nullable()
		};
	}

	static async getUser(email) {
		return await super.query().where('id', email).first();
	}

	static getAmount(username, token, locale) {
		// api call to get the users rhip aount
		try {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/retirement-health-insurance-program-benefits/amounts`;
			return axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: 'Bearer ' + token
					}
				})
				.then((rhipResponse) => {
					super.insertOrUpdate({
						data: {
							id: username,
							amount: rhipResponse.data.amount
						}
					});
					return rhipResponse.data.amount;
				})
				.catch((error) => {
					this.store().dispatch('error', { name: 'rhip-amount', error });
				});
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	static getReport(username, token, locale) {
		// api call to get users rhip report
		try {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/retirement-health-insurance-program-benefits/benefits/reports/annual/pdf`;
			return axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: 'Bearer ' + token
					},
					responseType: 'blob'
				})
				.then((rhipResponse) => {
					if (rhipResponse?.status < 201) {
						this._downloadReport(rhipResponse.data, locale);
						return true;
					}
					return false;
				})
				.catch((error) => {
					this.store().dispatch('error', { name: 'rhip-report', error });
				});
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Create a temp link in the DOM.  Then programmatically "click" that link to download the PDF in the
	 * member's browser.
	 */
	static _downloadReport(data, locale) {
		const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
		const fileLink = document.createElement('a');
		const fileName = locale === 'fr' ? 'sommaire-du-RAMR.pdf' : 'RHIP-summary.pdf';

		fileLink.href = fileURL;
		fileLink.setAttribute('download', fileName);

		document.body.appendChild(fileLink);

		fileLink.click();
		fileLink.remove();
	}
}
