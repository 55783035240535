/**
 * Navigation mixin
 *
 * Add to each page and use the navigateNext() method to go to the next page and the navigationBack()
 * to go in the reverse direction.
 */
import Authentication from '../models/Authentication';
import * as Routes from '../constants/Routes.js';
import * as Status from '../constants/Status.js';
import jwtDecode from 'jwt-decode';

const pageArray = [Routes.LOGIN];
export default {
	methods: {
		/**
		 * Handle the user "Next" click.
		 *
		 * Add the current route to the navigation array and go to the next page.
		 *
		 * because the navigation guard "cancels" the navigation, it's trapped as an error;
		 *
		 * Both push and replace now return a promise in vue-router.  If a navigation failure (anything that
		 * cancels a navigation like a next(false) or next('/other') also counts) is not caught, you will see
		 * an error in the console.  Since we use navigation guards in the project, we ofter cancel the
		 * navigation (for example if a member does not have children).  These cancelled navigations return
		 * a rejected promise and we catch them to remove the errors in the console.
		 *
		 * See https://github.com/vuejs/vue-router/issues/2873 for a full explanation.
		 */
		async navigateNext() {
			try {
				if (!this.$store.state.error.text) {
					let currentIndex = pageArray.indexOf(this.$route.name);
					this.$router.push({ name: pageArray[currentIndex + 1], query: { nav: 'next' } });
				}
			} catch (err) {
				// check if was redirect, cancels, other stupid routing codes
				if (!err._isRouter) {
					this.$store.dispatch('addErrorMessage', 'EDIT_ERROR');
				}
				if (err && err.type !== 1) {
					this.$store.dispatch('error', { name: 'navigation', err });
				}
				this.$router.push({ name: Routes.NOT_FOUND });
			}
		},

		/**
		 * Handle the user "Back" click.
		 *
		 * Get the last route name from the NavigationLink Vuex array (the previous page).
		 * Navigate to the previous page and remove this entry from the array.
		 * This follows the LIFO pattern (Last In First Out).  In our case we use the vuex-orm methods
		 * instead of directly doing push/pop on the Vuex array.
		 */
		async navigateBack() {
			try {
				let currentIndex = pageArray.indexOf(this.$route.name);
				this.$router.push({ name: pageArray[currentIndex - 1], query: { nav: 'back' } });
			} catch (error) {
				// check if was redirect, cancels, other stupid routing codes
				if (!error._isRouter) {
					this.$store.dispatch('addErrorMessage', 'EDIT_ERROR');
				}
				if (error && error.type !== 1) {
					throw new Error(`Navigation error in navigateBack(): ${error}`);
				}
				this.$router.push({ name: Routes.NOT_FOUND });
			}
		},

		/**
		 * When the member navigates to the previous page ("Back"), these are the rules:
		 *
		 *   - Do NOT save any data the member may have added or modified on the page.
		 *   - Do NOT validate any data the member may have added or modified on the page.
		 *   - Get fresh application data from the DB (api), just in case the member modified the page.
		 *   - Save destination page (If the member returns to complete the application, this saved page will be where the app opens).
		 */
		async navigationBack() {
			// Set the page where the member is going.
			this.enrolmentApplication.application.currentPage = await this.getBackPage();
			this.navigateBack();
		},

		async isAuthTokenExpired() {
			try {
				if (!sessionStorage.getItem('token')) {
					this.$router.push({
						name: Routes.LOGIN,
						params: { locale: this.$router.currentRoute.params.locale },
						query: { status: Status.NO_TOKEN }
					});
				} else {
					let token = jwtDecode(sessionStorage.getItem('token'));
					let application = await Authentication.findWithToken(sessionStorage.getItem('token'));
					if (token.exp < Math.round(+new Date() / 1000) && application) {
						this.$router.push({
							name: Routes.LOGIN,
							params: { locale: this.$router.currentRoute.params.locale }
						});
					} else if (token.exp < Math.round(+new Date() / 1000) && !application) {
						this.$router.push({
							name: Routes.LOGIN,
							params: { locale: this.$router.currentRoute.params.locale },
							query: { status: Status.EXPIRED }
						});
					}
				}
			} catch {
				this.$router.push({ name: Routes.NOT_FOUND });
			}
		},
		/**
		 * User has timeout passed the pre-determined time and will be sent back to login
		 */
		async timeoutRedirect() {
			if (sessionStorage.getItem('token')) {
				this.$router.push({
					name: Routes.LOGIN,
					params: { locale: this.$router.currentRoute.params.locale },
					query: { status: Status.TIMEOUT }
				});
				if (sessionStorage.getItem('token')) {
					this.$store.dispatch('clearError');
					sessionStorage.clear();
				}
			} else {
				this.$router.push({
					name: Routes.LOGIN,
					params: { locale: this.$router.currentRoute.params.locale },
					query: { status: Status.NO_TOKEN }
				});
			}
		},
		/**
		 * Checks see if the user has already passed the next page or not
		 * @param {string} currentPage
		 * @param {string} nextPage
		 * @returns {Boolean}
		 */
		checkNextnavigation(currentPage, nextPage) {
			const currentPageIndex = pageArray.indexOf(currentPage);
			const nextPageIndex = pageArray.indexOf(nextPage);
			if (currentPageIndex <= nextPageIndex) {
				return true;
			}

			return false;
		},
		redirectToLoginPage(status) {
			this.$router.push({
				name: Routes.LOGIN,
				params: { locale: this.$router.currentRoute.params.locale },
				query: { status }
			});
		}
	}
};
