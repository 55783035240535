/**
 * Common Axios processing for enrolment.
 */
import axios from 'axios';
//import moment from 'moment';

export let apiClient = {};

/**
 * Create an Axios instance for Enrolments
 */
export function initMemberServiceApiClient() {
	// Open an Axios connection
	apiClient = axios.create({
		baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_ENROL,
		timeout: 120000,
		withCredentials: false,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		}
	});
	// Configure the request
	apiClient.interceptors.request.use((config) => {
		// config.headers['Access-Control-Allow-Headers'] = 'jwt';
		config.headers['Locale'] = document.documentElement.lang;
		return config;
	});
}
