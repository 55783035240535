// check if prop's validate rules includes required rule
export default function (validationRules) {
	if (
		(typeof validationRules === 'object' && validationRules.required === true) ||
		(typeof validationRules === 'string' && validationRules.includes('required'))
	) {
		return true;
	} else {
		return false;
	}
}
