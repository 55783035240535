/**
 * Submit Claim / Provider Type
 */
export default function (server) {
	server.get('/members/:id/provider-types', () => {
		return [
			{
				claimType: 'AC-PARA',
				description: 'Acupuncturist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'AT-PARA',
				description: 'Athletic therapist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'CH-PARA',
				description: 'Chiropractor',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'CR-PARA',
				description: 'Counselling therapist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'DI-INDI',
				description: 'Dietitian',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'HO-INDI',
				description: 'Homeopath',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'MT-PARA',
				description: 'Massage therapist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'ND-INDI',
				description: 'Naturopath/naturotherapist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'OT-PARA',
				description: 'Occupational therapist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'OS-PARA',
				description: 'Osteopath',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'PT-PARA',
				description: 'Physical rehabilitation therapist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'PH-PARA',
				description: 'Physiotherapist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'PO-PARA',
				description: 'Podiatrist/chiropodist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'PC-PARA',
				description: 'Psychologist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'SW-PARA',
				description: 'Social worker',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'HS-PARA',
				description: 'Speech language pathologist',
				lob: '03',
				isRta: true,
				isDisabledForProv: false
			},
			{
				claimType: 'XX-VISI',
				description: 'Vision care',
				lob: '03',
				isRta: false,
				isDisabledForProv: false
			},
			{
				claimType: 'OTHER',
				description: 'Other',
				lob: '03',
				isRta: false,
				isDisabledForProv: false
			}
		];
	});
}
