/**
 * Submit Claim / claim details / recent providers
 */
import recentProvider from './../../objects/recent-providers.json';

export default function (server) {
	server.get(`/members/:id/recent-providers`, () => {
		return recentProvider;
	});
}
