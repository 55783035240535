/**
 * Digital registration
 */
export default function (server) {
	server.get('/members/:id/resource-urls', () => {
		return {
			policyId: '0016702',
			divisionId: '001',
			json_travelContactUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/travel.json',
			contactUsUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/contact',
			hsaDisclaimerUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/hsadisclaimer',
			json_eulaUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/eula.json',
			json_claimTermsUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/terms.json',
			hsaInfoUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/hsainfo',
			privacyUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/privacy',
			travelContactUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/travelcontact',
			json_hsaInfoUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/hsainfo.json',
			json_privacyUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/privacy.json',
			json_legalUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/legal.json',
			healthResourcesUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/healthresources',
			claimTermsUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/terms',
			json_contactUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/contact.json',
			claimRequirementsUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/requirements',
			helpUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/help',
			json_hsaDisclaimerUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/hsadisclaimer.json',
			eulaUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/eula',
			json_helpUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/help.json',
			legalUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/legal',
			json_psaInfoUrl: 'http://mobileassets.medaviebc.ca/MEDAVIE/psainfo.json'
		};
	});
}
