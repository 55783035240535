import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class Authentication extends Model {
	static entity = 'Authentication';

	static primaryKey = 'email';
	static fields() {
		return {
			// store,
			email: this.string(null).nullable(),
			token: this.string(null).nullable(),
			failedAttemps: this.number().nullable()
		};
	}

	/**
	 * Login call
	 * call api service
	 */
	static async login(email, password, locale) {
		let errorCount;
		try {
			let response = await axios.post(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/login`,
				{
					email: email,
					password: password
				},
				{
					headers: {
						'content-type': 'application/json',
						'Accept-language': locale
					},
					data: {}
				}
			);
			if (response.headers && response.headers['x-auth']) {
				super.insertOrUpdate({
					data: {
						email: email,
						token: response.headers['x-auth']
					}
				});
			}
			return response;
		} catch (err) {
			errorCount = this.store().state.loginAttempts;
			this.store().dispatch('incrementLoginAttempt', errorCount++, { root: true });
			return err.response;
		}
	}

	/**
	 * refreshToken call
	 * call api service
	 */

	static async refreshToken(email, token, locale) {
		try {
			let result = await axios
				.get(`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/${email}/jwt`, {
					headers: {
						Authorization: token,
						'Accept-Language': locale
					}
				})
				.then(async (response) => {
					if (response.headers && response.headers['x-auth']) {
						await super.insertOrUpdate({
							data: {
								email: email,
								token: response.headers['x-auth']
							}
						});
						let noBearer = response.data.token.split('Bearer ');
						sessionStorage.setItem('token', noBearer[1]);
						sessionStorage.setItem('apiToken', response.data.token);
					}
				})
				.catch(() => {
					//TODO Re-add error modal.
					// Currently just hiding it so a empty modal does not appear.
				});

			return result;
		} catch (err) {
			//TODO Re-add error modal.
			// Currently just hiding it so a empty modal does not appear.
		}
	}
	/**
	 *
	 * @param {*} token uses token send through email
	 * @param {*} locale
	 */
	static async refreshExpiredToken(token, locale) {
		try {
			let result = await axios
				.post(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/token`,
					{
						token: token
					},
					{
						headers: {
							'content-type': 'application/json',
							locale: locale
						},
						data: {}
					}
				)
				.then(async (response) => {
					return response;
				})
				.catch((err) => {
					this.store().dispatch('error', err);
				});

			return result;
		} catch (err) {
			this.store().dispatch('error', err);
		}
	}
	/**
	 * registration method to post user data
	 * @param {*} member user trying to register
	 * @param {*} locale current locale
	 */
	static async register(member, locale) {
		try {
			let response = await axios
				.post(
					window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT,
					{
						email: member.email,
						dateOfBirth: member.dob.split('/').reverse().join(''),
						certificateId: member.memberCardId,
						policyId: member.policyId,
						password: member.password,
						preferredLang: locale
					},
					{
						headers: {
							'content-type': 'application/json',
							Locale: locale
						},
						data: {}
					}
				)
				.then((response) => {
					return response;
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error', err);
		}
	}

	/**
	 * This method will call the API with the members token from msl, and their date of birth and password they set to register the user.
	 * @param {String} mslToken
	 * @param {String} dateOfBirth
	 * @param {String} password
	 * @returns
	 */
	static async registerDigitalWelcome(mslToken, dateOfBirth, password, locale) {
		try {
			let response = await axios
				.post(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/digital-register`,
					{
						mslToken: mslToken,
						dateOfBirth: dateOfBirth.split('/').reverse().join(''),
						password: password
					},
					{
						headers: {
							'content-type': 'application/json',
							Locale: locale
						},
						data: {}
					}
				)
				.then((response) => {
					return response;
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This function is called to update the members password with a new one when the member goes throught the
	 * forgot password flow.
	 * @param {String} newPassword
	 * @param {String} token
	 * @param {String} locale
	 * @returns Response
	 */
	static async ForgotPasswordReset(newPassword, token, locale) {
		try {
			let response = await axios
				.put(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/password`,
					{
						newPassword: newPassword,
						token: token
					},
					{
						headers: {
							'content-type': 'application/json',
							Locale: locale
						},
						data: {}
					}
				)
				.then((response) => {
					return response;
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method calls the API using certificate and policy numbers to request a reset token for a user.
	 * @param {Object} member
	 * @param {String} locale
	 * @returns
	 */
	static async PasswordResetWPolCert(member, locale) {
		try {
			const response = await axios.post(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/password/token`,
				{
					certificateId: member.certificateId,
					policyId: member.policyId,
					lang: locale
				},
				{
					headers: {
						'content-type': 'application/json',
						Locale: locale
					},
					data: {}
				}
			);
			return response;
		} catch (err) {
			return err.response;
		}
	}

	/**
	 * This method calls the API using an email to request a reset token for a user.
	 * @param {Object} member
	 * @param {String} locale
	 * @returns
	 */
	static async PasswordResetWEmail(member, locale) {
		try {
			const response = await axios.post(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/${member.email}/password-token`,
				{
					lang: locale
				},
				{
					headers: {
						'content-type': 'application/json',
						'Accept-Language': locale
					},
					data: {}
				}
			);
			return response;
		} catch (err) {
			return err.response;
		}
	}

	/**
	 * This method will call the api with a token, and will be returned the action in that token to determine
	 * what flow the member is using.
	 * @param {String} token
	 * @param {String} locale
	 * @returns Response
	 */
	static async confirmReRegistrationEmail(confirmation, token, locale) {
		try {
			let response = await axios
				.post(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}` +
						'/confirmation?confirmed=' +
						confirmation,
					{
						token: token
					},
					{
						headers: {
							'content-type': 'application/json',
							Locale: locale
						}
					}
				)
				.then((response) => {
					if (response) {
						return response;
					}
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api with a token, and will be returned the action in that token to determine
	 * what flow the member is using.
	 * @param {String} token
	 * @param {String} locale
	 * @returns Response
	 */
	static async validateToken(token, locale) {
		try {
			let response = await axios
				.put(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}` + '/token',
					{
						token: token
					},
					{
						headers: {
							'content-type': 'application/json',
							'Accept-language': locale
						}
					}
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api with a token, which will send the member a new link for registration/reset password.
	 * @param {String} token
	 * @param {String} locale
	 * @returns Response
	 */
	static async renewToken(token, locale) {
		try {
			let response = await axios
				.post(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}` + '/token',
					{
						token: token
					},
					{
						headers: {
							'content-type': 'application/json',
							Locale: locale
						}
					}
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api with a token for digital welcome, and will be returned the brand and language to use to be displayed to the member.
	 * @param {String} token
	 * @param {String} locale
	 * @returns Response
	 */
	static async getDigitalWelcomeTokenValues(mslToken) {
		try {
			const encodedMslToken = encodeURIComponent(mslToken);
			const response = await axios
				.get(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}` +
						'/digital-token-values?&mslToken=' +
						encodedMslToken
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api with a token for digital welcome, and will be returned the action in that token to determine
	 * what flow the member is using.
	 * @param {String} token
	 * @param {String} locale
	 * @returns Response
	 */
	static async validateMslToken(mslToken) {
		try {
			var encodedMslToken = encodeURIComponent(mslToken);
			let response = await axios
				.get(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}` +
						'/validate-digital-expiry?&mslToken=' +
						encodedMslToken
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api with a token for digital welcome, and will be returned the action in that token to determine
	 * what flow the member is using.
	 * @param {String} token
	 * @param {String} locale
	 * @returns Response
	 */
	static async validateDigitalRegistrationMember(mslToken, dateOfBirth, postalCode) {
		try {
			var encodedMslToken = encodeURIComponent(mslToken);
			let response = await axios
				.get(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}` +
						'/validate-digital-member?&mslToken=' +
						encodedMslToken +
						'&dateOfBirth=' +
						dateOfBirth +
						'&postalCode=' +
						postalCode
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Call is used to resend a verification Email for a user
	 * @param {String} username
	 */
	static async resendRegistrationEmail(username) {
		try {
			let response = await axios
				.post(`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/${username}/activation`)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * find authentication information using email
	 *
	 * @param {string} id The primary key
	 * @param {object} data The data
	 * @returns {error} return error or null if everything went well
	 */
	static async find(email) {
		return await super.query().withAll().where('$id', email).first();
	}
	/**
	 * find authentication information using token
	 *
	 * @param {string} id The primary key
	 * @param {object} data The data
	 * @returns {error} return error or null if everything went well
	 */
	static async findWithToken(token) {
		return await super.query().withAll().where('token', token).first();
	}
	/**
	 * obtain the users token to use connected care
	 *
	 * @param {string} email
	 * @param {string} token
	 * @param {string} locale
	 */
	static async getConnectedCareToken(email, token, locale) {
		try {
			let response = await axios.get(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/connected-care-tokens`,
				{
					headers: {
						'content-type': 'application/json',
						'Accept-language': locale,
						Authorization: token
					}
				}
			);
			sessionStorage.setItem(`cc_${locale}`, response.data.jwt);
			return response.data.jwt;
		} catch (err) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}
}
