/**
 * Returns a list of coverages for a member.
 *
 * Sample api response:
 *
 *   {
 *     "TRAVEL": "Travel",
 *     "LIFEDIS": "Life & Disability",
 *     "OPTBEN": "Optional Benefits",
 *     "HOSPITAL": "Hospital",
 *     "DRUGS": "Drugs",
 *     "DENTAL": "Dental",
 *     "EHB": "Extended Health Benefit",
 * 	   "RHIP": "Retired Health Insurance Plan"
 *   }
 *
 */

import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class BenefitScepter extends Model {
	static entity = 'benefitsScepter';

	static primaryKey = ['id', 'locale'];

	static fields() {
		return {
			id: this.string(),
			locale: this.string(),
			benefits: this.attr(null).nullable()
		};
	}

	static async hasBenefits(email, locale) {
		const benefits = await this.find([email, locale]);
		return benefits ? true : false;
	}

	static async getBenefits(username, token, locale) {
		// Check to see if the benefits are in vuex-orm.
		const benefits = await BenefitScepter.find([username]);

		if (benefits) {
			return benefits.benefits;
		}

		// Nothing found in vuex-orm, make an api call to get the benefits for the member.
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/benefits`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			if (response?.status === 200) {
				// Api call is good, add data to vuex-orm.
				await this.insert({
					data: {
						id: username,
						locale,
						benefits: response.data
					}
				});
				return response.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static async getBenefitsCheckNoContent(username, token, locale) {
		// Check to see if the benefits are in vuex-orm.
		const benefits = await BenefitScepter.find([username, locale])?.benefits;

		if (benefits) {
			return benefits;
		}

		// Nothing found in vuex-orm, make an api call to get the benefits for the member.
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/benefits`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			return response.data;
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static async getLifeAndDisability(username, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/life-disability-benefits`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				return response.data;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	static async getOptionalBenefits(username, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/optional-benefits`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				return response.data;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	//****************************************** */
	// DENTAL API CALLS
	//****************************************** */

	/**
	 * This method retrieves a list of provinces to be displayed on the dental coverage screen.
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @returns APIResponse Object
	 */
	static async getDentalProvinces(username, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/dental/provinces`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				return response.data;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	/**
	 * This method retrieves a list of specialties to be displayed on the dental coverage screen.
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @returns APIResponse Object
	 */
	static async getDentalSpecialties(username, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/dental/specialties`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				return response.data;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}

	/**
	 * Search the api for a list if dental codes with the specality and province.
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {String} specialty
	 * @param {String} province
	 * @param {Array} dentalCodes
	 * @returns API Response Object
	 */
	static async searchDental(username, token, locale, specialty, province, dentalCodes) {
		// Clean up dental codes to remove any empty string values.
		dentalCodes = dentalCodes.filter((n) => n);
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/dental`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				},
				params: {
					specialty: specialty,
					province: province,
					codes: `${dentalCodes.toString()}`
				}
			});

			if (response?.status === 200) {
				return response;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (error) {
			if (
				error.response?.status === 400 &&
				error.response?.data?.coverages &&
				error.response?.data?.coverages.length > 0
			) {
				return error.response;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		}
	}

	/**
	 * Search the api for a list if dental codes with the specality and province.
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @returns API Response Object
	 */
	static async getOnlineDoctors(username, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/online-doctors`;

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				return response;
			} else {
				this.store().dispatch('error', { name: 'generic' });
			}
		} catch (error) {
			this.store().dispatch('error', { name: 'generic' });
		}
	}
}
