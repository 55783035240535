import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class SecurityPolicy extends Model {
	static entity = 'securityPolicies';

	static loaded = false;

	/**
	 * Member fields
	 */
	static primarykey = 'id';
	static fields() {
		return {
			id: this.string(),
			demo: this.boolean(null),
			forms: this.boolean(),
			banking: this.boolean(),
			updateMemberProfile: this.boolean(),
			submitClaim: this.boolean(),
			easyHsa: this.boolean(),
			analytics: this.boolean(),
			benefitUsage: this.boolean(),
			coverage: this.boolean(),
			memberProfile: this.boolean(),
			statements: this.boolean(),
			rta: this.boolean(),
			drugAdherence: this.boolean(),
			suppressCoverage: this.boolean(),
			filterOnKeywords: this.boolean(),
			updateBanking: this.boolean(),
			storeFront: this.boolean(),
			premiumPayment: this.boolean(),
			beneficiary: this.boolean(),
			premiums: this.boolean(),
			autoHSA: this.boolean(),
			medicalExclusion: this.boolean(),
			brand: this.string()
		};
	}

	static async getUserSecurityPolicies(email, token, locale) {
		try {
			let response = await axios.get(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/security-policy`,
				{
					headers: {
						'content-type': 'application/json',
						'Accept-language': locale,
						Authorization: token
					}
				}
			);
			if (response?.status < 201) {
				super.insertOrUpdate({
					data: {
						id: email,
						demo: response.data.demo,
						forms: response.data.forms,
						banking: true,
						updateMemberProfile: response.data.updateMemberProfile,
						submitClaim: response.data.submitClaim,
						easyHsa: response.data.easyHsa,
						analytics: response.data.analytics,
						benefitUsage: response.data.benefitUsage,
						coverage: response.data.coverage,
						memberProfile: response.data.memberProfile,
						statements: response.data.statements,
						isRta: response.data.isRta,
						drugAdherence: response.data.drugAdherence,
						suppressCoverage: response.data.suppressCoverage,
						filterOnKeywords: response.data.filterOnKeywords,
						updateBanking: response.data.updateBanking,
						storeFront: response.data.storeFront,
						premiumPayment: response.data.premiumPayment,
						beneficiary: response.data.beneficiary,
						premiums: response.data.premiums,
						autoHSA: response.data.autoHSA,
						medicalExclusion: response.data.medicalExclusion,
						brand: response.data.brand
					}
				});
				sessionStorage.setItem('updateMemberProfile', response.data.updateMemberProfile);
				sessionStorage.setItem('securityPolicy', JSON.stringify(response.data));
				return response;
			}
		} catch (err) {
			return err.response;
		}
	}

	static async getSecurityPolicy(email) {
		return await super.query().where('id', email).first();
	}
}
