/**
 * Vuex-orm model for saving drug search parameters and search results.
 */
import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class DrugCoverage extends Model {
	static entity = 'drugCoverage';

	static fields() {
		return {
			id: this.string(null).nullable(), // Member email
			drugSearch: this.attr(null).nullable(), // Saved drug search results
			searchParameters: this.attr(null).nullable() // Saved search parameters
		};
	}

	static async getSavedSearchParameters(username) {
		return await DrugCoverage.find(username)?.searchParameters;
	}

	static async getSavedSearchResults(username) {
		return await DrugCoverage.find(username)?.drugSearch;
	}

	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {Object} searchParameters
	 * @returns {Array} drug for a member.
	 */
	static async getDrugs(username, token, locale, searchParameters) {
		// Member clicked "search" without entering all search parameters (should be 4 in this case).
		// Note: The page should perform validation and not allow this to happen.
		if (this._isMissingSearchParameters(searchParameters, 2)) {
			return [];
		}

		const { participant, drugNameOrDin } = searchParameters;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/drugs?participant=${participant}&drugName=${drugNameOrDin}`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200 || response?.status === 204) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await DrugCoverage.create({
					data: {
						id: username,
						drugSearch: response?.data,
						searchParameters
					}
				});
				// Return response instead of querying Vuex-orm for better performance.
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			return error.response;
		}
	}
	/**
	 * Utility function to check if there are any missing search parameters.
	 *
	 * @param {Object} searchParameters
	 * @param {Number} expectedNumberOfParameters - How many parameters should there be?
	 * @returns {Boolean}
	 * @example _isMissingSearchParameters({ participant: '03'}, 1)
	 */
	static _isMissingSearchParameters(searchParameters, expectedNumberOfParameters) {
		return Object.keys(searchParameters).length < expectedNumberOfParameters;
	}
	/**
	 * obtain string content for user suppresed drug coverage
	 *
	 * @param {string} username
	 * @param {string} token
	 * @param {string} locale
	 * @returns {string}
	 */
	static async getSuppressedDrugDetails(username, token, locale) {
		// api call to get the suppress drug coverage details
		try {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/suppressed-coverages/drug`;
			let response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: 'Bearer ' + token
					}
				})
				.catch((error) => {
					return error.response;
				});
			return response.data;
		} catch (err) {
			this.store().dispatch('error');
		}
	}
}
