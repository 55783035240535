import { computed, watch } from 'vue';
import store from '@/store/index';

export function useBreadcrumbs(linksEn, linksFr) {
	const breadcrumbs = computed(() => store.state.breadcrumbModule.breadcrumbs);

	function setBreadcrumbPath() {
		store.commit('breadcrumbModule/empty');
		if (store.state.i18n.locale === 'fr') {
			store.commit('breadcrumbModule/set', linksFr);
		} else {
			store.commit('breadcrumbModule/set', linksEn);
		}
	}

	watch(() => store.state.i18n.locale, setBreadcrumbPath);

	setBreadcrumbPath(); // initialize breadcrumbs on mount

	return { breadcrumbs };
}
