<template>
	<div class="error-modal">
		<BaseModal
			:id="getUniqueId('error-modal')"
			class="error-modal"
			:show-title="true"
			css-body-class="error-modal-body"
			css-dialog-class="error-modal-dialog"
			css-header-class="error-modal-header"
			:cant-close-on-esc="cantCloseOnEsc"
			:no-hide-on-blur="noHideOnBlur"
			:close-button="hasCloseButton"
			:is-static="true"
			name="error"
			:modal-show="modalShow"
			:automation-id="getAutomationId('error-modal')"
			@change="change"
		>
			<template #contentBody>
				<FontAwesomeIcon
					class="modal-icon"
					:icon="['fal', 'exclamation-triangle']"
					aria-hidden="true"
				/>
				<p class="modal-header">{{ errorTitle }}</p>
				<!-- eslint-disable-next-line vue/no-v-html -->
				<p class="modal-body" v-html="errorMessage"></p>
				<slot></slot>
			</template>
		</BaseModal>
	</div>
</template>

<script>
/**
 * This component renders a error modal. It will display an icon to show that it is an error
 * and some text to explain what has happened.
 *
 * It has a slot so if can be used to add other buttons/text/something else if needed in the future, or
 * by other components.
 */
import Vue from 'vue';
import IdMixin from '@/mixins/id';
import Component from 'vue-class-component';
import BaseModal from '@/components/common/base/BaseModal';

// @vue/component
@Component({
	name: 'ErrorModal',
	components: {
		BaseModal
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		/**
		 * Error text to be displayed above the body/below the icon.
		 * It will be bolded and a bigger font size.
		 */
		errorTitle: {
			type: String,
			default: null
		},
		/**
		 * Error text to be dispayed below the title.
		 * It will be a smaller font size, and not bold.
		 */
		errorMessage: {
			type: String,
			default: null
		},
		/**
		 * prop to determine if the modal is to be displayed.
		 */
		modalShow: {
			type: Boolean,
			default: false
		},
		/**
		 * prop to determine if the modal should have the close button in the
		 * top right corner.
		 */
		hasCloseButton: {
			type: Boolean,
			default: true
		},
		noHideOnBlur: {
			type: Boolean,
			default: false
		},
		/**
		 * Enables or disbaled the use of escape key
		 */
		cantCloseOnEsc: {
			type: Boolean,
			default: false
		}
	}
})
export default class ErrorModal extends Vue {
	/**
	 * method to emit a change event to tell the parent when to show/hide the modal.
	 */
	change(isVisible) {
		this.$emit('change', isVisible);
	}
}
</script>

<style lang="scss" scoped>
.modal-header {
	margin: 0em;
}

.modal-body {
	margin: 0em;
	padding-left: 0em;
	padding-top: 0.5em;
}
div ::v-deep .error-modal-body {
	padding: 0em;
	margin-left: 30px;
	margin-bottom: 30px;
	margin-right: 30px;
	width: 88%;
}

.modal-icon {
	font-size: 32px;
	margin-bottom: 15px;
	& ::v-deep path {
		color: $orange-dark;
	}
}

.error-modal ::v-deep .btn-light {
	font-size: 17px;
	margin-top: 0em;
}

.error-modal ::v-deep .error-modal-header {
	margin: 0em;
	height: 30px;
}

.error-modal ::v-deep .error-modal-dialog {
	max-width: 540px;
	width: 90%;
	margin: auto;
}

::v-deep .btn.close-button {
	margin-top: 10px;
}
</style>
