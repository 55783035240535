import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class ClaimPaymentHistory extends Model {
	static entity = 'claimPaymentHistory';

	static primaryKey = [
		'email',
		'locale',
		'participantId',
		'historyType',
		'fromDate',
		'toDate',
		'benefitCode'
	];

	static fields() {
		return {
			email: this.string(),
			locale: this.string(),
			participantId: this.string(),
			historyType: this.string(),
			fromDate: this.string(),
			toDate: this.string(),
			benefitCode: this.string(),
			data: this.attr(null)
		};
	}

	static async getHistoryPDF(
		email,
		token,
		locale,
		historyType,
		participantId,
		fromDate = 'recent',
		toDate = 'recent',
		benefitCode = 'ALL'
	) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/claims/pdf`;

		try {
			const params = {
				participantId:
					participantId.length > 1 || participantId.length === 0 ? null : participantId[0],
				benefitCode: benefitCode === 'ALL' ? null : benefitCode
			};

			if (historyType === 'recent') {
				params.sortBy = 'processedDate';
				params.limit = 5;
			} else if (historyType === 'processed') {
				params.processedDateFrom = fromDate;
				params.processedDateTo = toDate;
				params.sortBy = 'processedDate';
			} else {
				params.serviceDateFrom = fromDate;
				params.serviceDateTo = toDate;
				params.sortBy = 'serviceDate';
			}

			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/pdf',
					'Accept-Language': locale,
					Authorization: token
				},
				responseType: 'blob',
				params
			});

			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static async hasHistory(
		email,
		participantId,
		locale,
		historyType,
		fromDate = 'recent',
		toDate = 'recent',
		benefitCode = 'ALL'
	) {
		const history = participantId
			.map(
				(pId) => this.find([email, locale, pId, historyType, fromDate, toDate, benefitCode])?.data
			)
			.filter((h) => h !== undefined);
		return history.length > 0 ? true : false;
	}

	static async getHistory(
		email,
		token,
		locale,
		historyType,
		participantId,
		fromDate = 'recent',
		toDate = 'recent',
		benefitCode = 'ALL'
	) {
		let historyFound = false;
		const retrieve = () =>
			participantId
				.map(
					(pId) => this.find([email, locale, pId, historyType, fromDate, toDate, benefitCode])?.data
				)
				.filter((h) => {
					if (h) {
						historyFound = true;
					}
					return h;
				})
				.flat();
		const history = retrieve();

		if (history.length > 0 || historyFound) {
			return history;
		}

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/claims`;

		try {
			const params = {
				participantId:
					participantId.length > 1 || participantId.length === 0 ? null : participantId[0],
				benefitCode: benefitCode === 'ALL' ? null : benefitCode
			};

			if (historyType === 'recent') {
				params.sortBy = 'processedDate';
				params.limit = 5;
			} else if (historyType === 'processed') {
				params.processedDateFrom = fromDate;
				params.processedDateTo = toDate;
				params.sortBy = 'processedDate';
			} else {
				params.serviceDateFrom = fromDate;
				params.serviceDateTo = toDate;
				params.sortBy = 'serviceDate';
			}

			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				},
				params
			});
			switch (response?.status) {
				case 200: {
					// loop through data to add each participant
					response.data.forEach((data) => {
						this.insertOrUpdate({
							data: {
								email,
								locale,
								participantId: data.participantInfo.participantId,
								historyType,
								fromDate,
								toDate,
								benefitCode,
								data
							}
						});
					});
					return retrieve();
				}
				case 204:
					participantId.forEach(async (p) => {
						await this.insert({
							data: {
								email,
								locale,
								participantId: p,
								historyType,
								fromDate,
								toDate,
								benefitCode,
								data: []
							}
						});
					});
					return [];
				default:
					throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}
}
