import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class MemberCardInfo extends Model {
	static entity = 'memberCardInfo';

	static loaded = false;

	/**
	 * Member fields
	 */
	static primarykey = 'id';
	static fields() {
		return {
			id: this.string(),
			firstName: this.string(null),
			lastName: this.string(null),
			policyId: this.string(null),
			certificateId: this.string(null),
			participantId: this.string(null),
			preferredLanguage: this.string(null),
			businessOwner: this.string(null),
			regional: this.boolean(null),
			bookOfBusiness: this.string(null),
			msId: this.string(null),
			provinceOfAdjudication: this.string(null)
		};
	}

	static async hasMember(email) {
		return await super.query().where('id', email).exists();
	}

	static async getMemberDetail(email, token, locale) {
		if (!this.getMember(email).policyId) {
			let response = await axios.get(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/cardholder-details`,
				{
					headers: {
						'content-type': 'application/json',
						Locale: locale,
						Authorization: token
					}
				}
			);
			if (response && response.status < 201) {
				await super.insertOrUpdate({
					data: {
						id: email,
						firstName: response.data.firstName,
						lastName: response.data.lastName,
						policyId: response.data.policyNumber,
						certificateId: response.data.idNumber,
						participantId: response.data.participantId,
						preferredLanguage: response.data.preferredLanguage,
						businessOwner: response.data.businessOwner,
						regional: response.data.regional,
						bookOfBusiness: response.data.bookOfBusiness,
						msId: response.data.msId,
						provinceOfAdjudication: response.data.provinceOfAdjudication
					}
				});
			}
		}
		return await super.query().where('id', email).first();
	}

	static async getMember(email) {
		return await super.query().where('id', email).first();
	}

	static MemberExists(email) {
		return super.query().where('id', email).exists();
	}

	static async getPreferredLanguage(email) {
		const member = await super.query().where('id', email).first();
		return member.preferredLanguage;
	}

	/**
	 * Note that we don't use try/catch.  We bubble up the error msg to let the calling method perform error handling.
	 * This way the calling component can take care of the error msgs and localization.
	 */
	static async updatePreferredLanguage(email, token, locale, preferredLanguage) {
		// Update the database
		const response = await axios.put(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/preferred-language`,
			{
				preferredLanguage: preferredLanguage.toUpperCase()
			},
			{
				headers: {
					'content-type': 'application/json',
					Authorization: token,
					'Accept-Language': locale
				}
			}
		);

		// Update the local state (model).  If the axios call fails, this update does not get executed.
		await super.insertOrUpdate({
			data: {
				id: email,
				preferredLanguage: preferredLanguage
			}
		});

		return response;
	}

	/**
	 *
	 * @param {*} oldPassword
	 * @param {*} newPassword
	 */
	static async updateUserPassword(username, token, locale, oldPassword, newPassword) {
		try {
			let response = await axios
				.put(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/` + username + '/password',
					{
						oldPassword: oldPassword,
						newPassword: newPassword
					},
					{
						headers: {
							'content-type': 'application/json',
							Authorization: token,
							'Accept-Language': locale
						}
					}
				)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					return err.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error', { name: 'changePassword', err });
		}
	}

	/**
	 * User a digital policy members token from msl to retrieve their resource urls to display on the custom contact page.
	 * @param {String} mslToken
	 * @returns
	 */
	static async getResourceUrls(mslToken, language) {
		try {
			var encodedMslToken = encodeURIComponent(mslToken);
			let response = await axios
				.get(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}` +
						'/resource-urls?mslToken=' +
						encodedMslToken,
					{
						headers: {
							'content-type': 'application/json',
							'Accept-Language': language
						}
					}
				)
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Gets the resource urls tied to a specific account using email.
	 * @param {String} mslToken
	 * @returns
	 */
	static async getResourceUrlsByEmail(username, token, locale) {
		try {
			let response = await axios
				.get(`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/resource-urls`, {
					headers: {
						'content-type': 'application/json',
						Authorization: token,
						'Accept-Language': locale
					}
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	*************************************************************************************
										MEMBER CARD
	*************************************************************************************
	 */

	/**
	 * This method will send out member cards to a list of emails the member types in.
	 * @param {Array} emails
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async shareMemberCard(emails, username, token, locale) {
		try {
			var url =
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + username + '/member-cards?emails=';
			for (var i = 0; i < emails.length; i++) {
				if (i === 0) {
					url = url + emails[i];
				} else {
					url = url + ',' + emails[i];
				}
			}
			let response = await axios
				.post(
					url,
					{
						email: emails
					},
					{
						headers: {
							'content-type': 'application/json',
							Authorization: token,
							'Accept-Language': locale
						},
						data: {}
					}
				)
				.catch((error) => {
					var errorCount = this.store().state.loginAttempts;
					this.store().dispatch('incrementLoginAttempt', errorCount++, { root: true });
					return error.response;
				});

			return response;
		} catch (err) {
			var errorCount = this.store().state.loginAttempts;
			this.store().dispatch('incrementLoginAttempt', errorCount++, { root: true });
		}
	}

	/**
	 * This method will call the api to return all the information needed to display the members card.
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async getMemberCardDetails(username, token, locale) {
		try {
			var url =
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + username + '/member-cards/details';
			let response = await axios
				.get(url, {
					headers: {
						'content-type': 'application/json',
						Authorization: token,
						'Accept-Language': locale
					},
					data: {}
				})
				.catch((error) => {
					return error.response;
				});
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method will call the api to return a pdf of the member card.
	 * @param {String} username
	 * @param {String} locale
	 * @returns
	 */
	static async getMemberCardPdf(username, token, locale) {
		try {
			const apiClientPDF = axios.create({
				baseURL: window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS,
				timeout: 120000,
				withCredentials: false,
				headers: {
					'Content-Type': 'application/pdf',
					Accept: 'application/pdf',
					Authorization: token,
					'Accept-Language': locale
				},
				responseType: 'blob'
			});

			const response = await apiClientPDF.get(`/${username}/member-cards`);
			return response;
		} catch (err) {
			this.store().dispatch('error');
		}
	}

	/**
	 * This method takes in data passed to it to generate a pdf, and download it for the member.
	 * @param {String} data
	 */
	static async downloadMemberCardPdf(data) {
		const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
		const fileLink = document.createElement('a');

		fileLink.href = fileURL;
		fileLink.setAttribute('download', `Identification.pdf`);
		document.body.appendChild(fileLink);

		fileLink.click();
	}
}
