<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BaseButton
		:id="getUniqueId('localeToggleButton')"
		variant="link"
		:label="$t('label.lang')"
		:icon="['fal', 'globe']"
		:pill="false"
		icon-position="left"
		:class="`btn-locale text-decoration-none ${className}`"
		data-tracking-id="localeToggleButton"
		automation-id="localeToggleButton"
		:aria-label="$t('label.langSwitch')"
		@click="toggleLocale($event)"
	/>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import Vue from 'vue';
import Component from 'vue-class-component';

/**
 * This component is responsible switching the locale.
 */
// @vue/component
@Component({
	name: 'AppHeaderLocaleToggle',
	components: {
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		compressMobile: {
			type: Boolean,
			default: false
		},
		className: {
			type: String,
			default: ''
		}
	}
})
export default class AppHeaderLocaleToggle extends Vue {
	mobileClass = 'm-0';
	/**
	 * Sets the locale on load
	 */
	created() {
		this.$store.dispatch('i18n/changeLocale', this.$store.state.i18n.locale);
		if (this.compressMobile) {
			this.mobileClass = ['mobile', 'm-0'];
		}
	}

	/**
	 * Toggle locale
	 */
	toggleLocale() {
		// toggle existing locale
		const locale = this.$store.state.i18n.locale === 'fr' ? 'en' : 'fr';
		// override the locale param in the URL.
		const params = { ...this.$route.params, locale };
		if (this.$route.query) {
			this.$router.push({ params, query: this.$route.query });
		} else {
			this.$router.push({ params });
		}
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
.btn-locale {
	padding: 2px 16px 0 16px;
}
.mobile {
	@media all and (max-width: 500px) {
		display: none;
	}
}
svg {
	margin-right: 0.4em;
}

.button-locale span {
	font-family: 'Josefin Sans', sans-serif;
}

.button-locale {
	display: inline-block;
}

.no-show {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.btn ::v-deep p {
	color: $blue;
}
.btn-link ::v-deep p:hover {
	text-decoration: none;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
	"en": {
		"label": {
			"lang": "Français",
			"langSwitch": "Français. Cliquez sur ce bouton pour accéder à la version française de ce site web"
		}
	},
	"fr": {
		"label": {
			"lang": "English",
			"langSwitch": "English. Click on this button to use the English version of this website"
		}
	}
}
</i18n>
