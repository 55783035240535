import axios from 'axios';

/**
 * Load application version information when the appliation starts.
 */

/**
 * Set a list of environment variables on the browser's window.VERSION scope.
 *
 * @params {configData} JSON object containing a list of verions information variables in key:value pairs.
 * @return {Promise} A Promise is returned.  The Promise is resolved when the variables are set.
 *
 * @example window.VERSION
 *
 *  {
 *    "PACKAGE_VERSION": "0.1.1",
 *    "BUILD_NUMBER": "bld52551",
 *    "BUILD_DATE": "2020-10-18"
 *  }
 *
 */
function setEnvironmentVariables(configData) {
	return new Promise((resolve) => {
		window.VERSION = configData;

		resolve(); // Resolve the Promise.
	});
}

/**
 * Read a JSON formatted file which contains version data to be used by the application at runtime.
 *
 * @return {object} A JSON object containing the version information.
 */
async function getEnvironmentVariables() {
	try {
		const configData = await axios.get(`/assets/version.json`);
		return configData.data;
	} catch (error) {
		return false;
	}
}

export async function initEnvVars() {
	const envVariables = await getEnvironmentVariables();

	if (envVariables) {
		await setEnvironmentVariables(envVariables);
	}
}
