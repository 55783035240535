/**
 * handerl for cob-reason api
 */

export default function (server) {
	/**
	 *
	 */
	server.get('/members/:id/cob-reasons', () => {
		[
			{
				id: 1,
				reason: 'Maximum dollar amount reached',
				cancelsRTA: false,
				cancelsClaim: false,
				doesRequireNote: false
			},
			{
				id: 2,
				reason: 'Maximum frequency met',
				cancelsRTA: false,
				cancelsClaim: false,
				doesRequireNote: false
			},
			{
				id: 3,
				reason: 'Not an eligible benefit',
				cancelsRTA: false,
				cancelsClaim: false,
				doesRequireNote: false
			},
			{
				id: 4,
				reason: 'Benefit requires a prescription',
				cancelsRTA: true,
				cancelsClaim: true,
				cancelsMessage: 'A prescription is required to complete this claim submission.',
				doesRequireNote: false
			},
			{
				id: 5,
				reason: 'Benefit requires prior authorization',
				cancelsRTA: true,
				cancelsClaim: true,
				cancelsMessage:
					"Prior authorization is required to complete this claim submission.\n\nPrior authorization is a pre-approval process to determine if certain benefits will be reimbursed under a member's plan. You must obtain prior authorization first and submit to your other carrier before this claim can be considered.",
				doesRequireNote: false
			},
			{
				id: 6,
				reason: 'Other',
				cancelsRTA: true,
				cancelsClaim: false,
				doesRequireNote: true
			}
		];
	});
}
