import * as Routes from '@/constants/Routes.js';
// import CryptoJS from 'crypto-js';

export default {
	path: Routes.REGISTRATION,
	component: () => import(/* webpackChunkName: "registration" */ '@/pages/Members.vue'),
	children: [
		{
			path: '/:locale/' + Routes.REGISTRATION,
			name: Routes.REGISTRATION,
			meta: { menuHidden: true },
			component: () =>
				import(/* webpackChunkName: "registration"*/ '@/pages/registration/Registration.vue')
		},
		{
			path: '/:locale/' + Routes.SUCCESS_REGISTRATION,
			name: Routes.SUCCESS_REGISTRATION,
			meta: { menuHidden: true },
			component: () =>
				import(/* webpackChunkName: "registration"*/ '@/pages/registration/SuccessRegistration.vue')
		},
		{
			path: '/:locale/' + Routes.REGISTRATION_ACTIVE,
			name: Routes.REGISTRATION_ACTIVE,
			meta: { menuHidden: true },
			component: () =>
				import(/* webpackChunkName: "registration" */ '@/pages/registration/ActivateResult.vue')
		},
		{
			path: '/:locale/' + Routes.INVALID_EMAIL,
			name: Routes.INVALID_EMAIL,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "registration"*/ '@/pages/registration/AlreadyExistsRegistration.vue'
				)
		},
		{
			path: '/:locale/' + Routes.CONFIRM_EMAIL_CHANGE,
			name: Routes.CONFIRM_EMAIL_CHANGE,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "registration"*/ '@/pages/registration/RegistrationConfirmEmail.vue'
				)
		},
		{
			path: '/:locale/' + Routes.DIGITAL_VERIFICATION,
			name: Routes.DIGITAL_VERIFICATION,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "digitalwelcome"*/ '@/pages/registration/digitalPolicyRegistration/DigitalRegistrationVerification.vue'
				),
			beforeEnter: async (to, from, next) => {
				if (sessionStorage.getItem('mslToken')) {
					next();
				} else {
					next({
						name: Routes.DIGITAL_ERROR,
						params: { locale: to.params.locale }
					});
				}
			}
		},
		{
			path: '/:locale/' + Routes.DIGITAL_PASSWORD,
			name: Routes.DIGITAL_PASSWORD,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "digitalwelcome"*/ '@/pages/registration/digitalPolicyRegistration/DigitalRegistrationPassword.vue'
				),
			beforeEnter: async (to, from, next) => {
				if (sessionStorage.getItem('mslToken')) {
					next();
				} else {
					next({
						name: Routes.DIGITAL_ERROR,
						params: { locale: to.params.locale }
					});
				}
			}
		},
		{
			path: '/:locale/' + Routes.DIGITAL_SUCCESS,
			name: Routes.DIGITAL_SUCCESS,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "digitalwelcome"*/ '@/pages/registration/digitalPolicyRegistration/DigitalRegistrationSuccess.vue'
				)
		},
		{
			path: '/:locale/' + Routes.DIGITAL_EXPIRED,
			name: Routes.DIGITAL_EXPIRED,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "digitalwelcome"*/ '@/pages/registration/digitalPolicyRegistration/DigitalRegistrationExpired.vue'
				)
		},
		{
			path: '/:locale/' + Routes.DIGITAL_ERROR,
			name: Routes.DIGITAL_ERROR,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "digitalwelcome"*/ '@/pages/registration/digitalPolicyRegistration/DigitalRegistrationError.vue'
				)
		},
		{
			path: '/:locale/' + Routes.DIGITAL_LOCKED,
			name: Routes.DIGITAL_LOCKED,
			meta: { menuHidden: true },
			component: () =>
				import(
					/* webpackChunkName: "digitalwelcome"*/ '@/pages/registration/digitalPolicyRegistration/DigitalRegistrationLocked.vue'
				)
		}
	]
};
