export const ACTIVATION_EXPIRED = 'ACTIVATIONEXPIRED';
export const PASSWORD_RESET_EXPIRED = 'PASSWORDRESETEXPIRED';
export const ACTIVATION = 'ACTIVATION';
export const CONFIRMATION = 'CONFIRMATION';
export const UPDATE_EMAIL = 'UPDATEEMAIL';
export const PASSWORD_RESET = 'PASSWORDRESET';
export const REGISTRATION = 'REGISTRATION';
export const EXPIRED = 'EXPIRED';
export const UPDATE_EMAIL_EXPIRED = 'UPDATEEMAILEXPIRED';
export const INVALID = 'INVALID';
export const CONFIRMATION_USED = 'CONFIRMATIONUSED';
export const ACTIVATION_USED = 'ACTIVATIONUSED';
