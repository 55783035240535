<!--
	SuccessBanner is a jumbotron with blue background and centered white text.  Used to indicate success to a 
  user after things such as saving information.
-->
<template>
	<BJumbotron
		id="successBanner"
		class="text-center"
		fluid
		bg-variant="primary"
		text-variant="white"
		role="alert"
		:aria-label="`${headerText}, ${leadText}`"
	>
		<template #header>
			<div aria-hidden="true" :class="!leadText ? 'header-class' : ''">
				<FontAwesomeIcon :icon="['fal', 'check-circle']" class="success-icon" />
				<h1>{{ headerText }}</h1>
			</div>
		</template>

		<template #lead>
			<p aria-hidden="true">{{ leadText }}</p>
		</template>
	</BJumbotron>
</template>

<script>
import { BJumbotron } from 'bootstrap-vue';

export default {
	name: 'SuccessBanner',
	components: {
		BJumbotron
	},
	props: {
		automationId: {
			type: String,
			default: '',
			required: true
		},
		headerText: {
			type: String,
			default() {
				return this.$t('headerText');
			},
			required: false
		},
		leadText: {
			type: String,
			default: null,
			required: false
		}
	}
};
</script>

<style lang="scss" scoped>
.jumbotron {
	height: 204px;
	padding: 0;
	margin-bottom: 1em;
}
.header-class {
	padding-top: 22px;
}
.success-icon {
	height: 42px;
	width: 42px;
}
</style>

<i18n>
{
  "en": {
    "headerText": "Success!"
  },
  "fr": {
    "headerText": "C’est fait!"
  }
}
</i18n>
