export const PRACTITIONER = 'XX-PRAC';
export const HEALTH = 'XX-HEAL';
export const DENTAL = 'XX-DENT';
export const DRUGS = 'XX-PHAR';
export const OTHER = 'OTHER';
export const TRAVEL = 'XX-TRAV';
export const PWA = 'XX-PSA';
export const HSA = 'XX-HSA';
export const DCI = 'XX-DCI';
export const VISION = 'XX-VISI';
export const LADD = 'XX-LADD';
