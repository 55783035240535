/**
 * Submit Claim / Find Health Professional
 */
export default function (server) {
	server.get('/members/:id/health-professionals', () => {
		return [
			{
				pauthId: '450146',
				prnId: 'RTA001',
				firstName: 'TEST',
				lastName: 'CHIRO',
				addressLine1: '644 Main St',
				city: 'Moncton',
				province: 'NB',
				postalCode: 'E1C8L3',
				lng: '-64.7747855',
				lat: '46.0889982',
				distanceFromOrigin: '0.297108859825446',
				regionCode: 'AT',
				providerType: 'CH-PARA',
				providerTypeName: 'Chiropractor',
				phoneNumber: '5065555555',
				isDirectPay: true,
				aimId: 'A214337',
				status: 'ACTIVE',
				cancelsRTA: false,
				searchRanking: 9,
				sortNameKey: 'CHIROTEST'
			},
			{
				pauthId: '450156',
				prnId: 'RTA011',
				firstName: 'TEST',
				lastName: 'OCCUP',
				addressLine1: '644 Main St',
				city: 'Toronto',
				province: 'ON',
				postalCode: 'L0J1C0',
				regionCode: 'ON',
				providerType: 'OT-PARA',
				providerTypeName: 'Occupational Therapist',
				phoneNumber: '1111111111',
				isDirectPay: true,
				aimId: 'A214347',
				status: 'ACTIVE',
				cancelsRTA: false,
				searchRanking: 8,
				sortNameKey: 'OCCUPTEST'
			},
			{
				pauthId: '446959',
				prnId: 'PCP-026',
				firstName: 'SK',
				lastName: 'Guy',
				addressLine1: '1 main st',
				city: 'saskatoon',
				province: 'SK',
				postalCode: 'S4S3R6',
				lng: '-106.638931',
				lat: '52.1168327',
				distanceFromOrigin: '3083.83158775974',
				regionCode: 'PR',
				providerType: 'CH-PARA',
				providerTypeName: 'Chiropractor',
				phoneNumber: '1111111111',
				isDirectPay: true,
				aimId: 'A203376',
				status: 'ACTIVE',
				cancelsRTA: false,
				searchRanking: 7,
				sortNameKey: 'GuySK'
			},
			{
				pauthId: '446660',
				prnId: 'TTCHNB',
				firstName: 'TEST',
				lastName: 'CHIRO',
				addressLine1: '644 MAIN STREET',
				city: 'MONCTON',
				province: 'NB',
				postalCode: 'E1C8L3',
				lng: '-64.7749212',
				lat: '46.0890416',
				distanceFromOrigin: '0.289966415197408',
				regionCode: 'AT',
				providerType: 'CH-PARA',
				providerTypeName: 'Chiropractor',
				isDirectPay: false,
				status: 'ACTIVE',
				cancelsRTA: false,
				searchRanking: 6,
				sortNameKey: 'CHIROTEST'
			},
			{
				pauthId: '449407',
				prnId: 'PCP-810',
				firstName: 'John',
				lastName: 'Lennon',
				addressLine1: '345 Song St.',
				city: 'Regina',
				province: 'SK',
				postalCode: 'S1S1S1',
				regionCode: 'PR',
				providerType: 'PC-PARA',
				providerTypeName: 'Psychologist',
				phoneNumber: '1111111111',
				isDirectPay: false,
				status: 'ACTIVE',
				cancelsRTA: false,
				searchRanking: 6,
				sortNameKey: 'LennonJohn'
			},
			{
				pauthId: '449452',
				prnId: '6021',
				firstName: 'Sylvie',
				lastName: 'Test',
				addressLine1: '1234567 main street',
				city: 'moncton',
				province: 'NB',
				postalCode: 'E1C9W4',
				lng: '-64.7768931',
				lat: '46.0886304',
				distanceFromOrigin: '0.137462318335516',
				regionCode: 'AT',
				providerType: 'CH-PARA',
				providerTypeName: 'Chiropractor',
				isDirectPay: false,
				status: 'ACTIVE',
				cancelsRTA: false,
				searchRanking: 6,
				sortNameKey: 'TestSylvie'
			}
		];
	});
}
