/**
 * handler for Health spending account api
 */

import { Response } from 'miragejs';

export default function (server) {
	/**
	 * get member year to date pdf
	 */
	server.get('/members/:id/coverages/health-spending-accounts/reports/daily/pdf', () => {
		return new Response(200);
	});

	/**
	 * get member previous year pdf
	 */
	server.get('/members/:id/coverages//health-spending-accounts/reports/annual/pdf', () => {
		return new Response(200);
	});
}
