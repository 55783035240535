export default function (server) {
	// Payment history details
	server.get('/members/:email/claims/:claimId/payment-details', () => {
		return {
			paymentInfo: {
				dateProcessed: '2023-03-17',
				paymentMethod: 'Pay Provider',
				payeeName: 'Rachelle Savoie',
				payeeAddress: {
					addressLine1: '83 Roseberry St',
					postalCode: 'E3N2G6',
					province: 'New Brunswick',
					city: 'Campbellton'
				}
			},
			participantPayments: [
				{
					participantInfo: {
						participantId: '00',
						firstName: 'Bentley',
						lastName: 'Holecek',
						dateOfBirth: '1963-06-18'
					},
					paymentLines: [
						{
							serviceInfo: {
								code: '0600V',
								description: 'Exam and prescription eye glasses'
							},
							claimServices: [
								{
									claimId: '113347914',
									hasPaymentDetails: true,
									benefitCode: 'CHLTH',
									dateProcessed: '2023-03-17',
									serviceDate: '2023-03-17',
									serviceInfo: {
										code: '0600V',
										description: 'Exam and prescription eye glasses'
									},
									coinsurancePercent: 70,
									submittedAmount: 894,
									paidAmount: 300.0,
									deductibleAmount: 0.0,
									excludedAmount: 0.0
								}
							]
						}
					]
				}
			],
			totalSubmittedAmount: 894.0,
			totalDeductibleAmount: 0.0,
			totalExcludedAmount: 0.0,
			totalPaidAmount: 300.0
		};
	});
}
