/**
 * Vuex-orm model to save generic drug search parameters and search results.
 */
import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default class DrugCoverageGeneric extends Model {
	static entity = 'drugCoverageGeneric';
	static primaryKey = ['locale', 'id', 'participant'];

	static fields() {
		return {
			locale: this.string(null).nullable(),
			id: this.string(null).nullable(), // Member email
			participant: this.string(null).nullable(),
			drugDetailsResult: this.attr(null).nullable(), // Saved search parameters
			searchParameters: this.attr(null).nullable()
		};
	}

	/**
	 * Make a service call to get drug details.  Store the reponse in the model.
	 * @param {String} drugDin
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {Object} searchParameters
	 * @returns {Array} Claims for a member.
	 */
	static async getGrugCoverageDetail(username, token, locale, searchParameters) {
		if (this._isMissingSearchParameters(searchParameters, 3)) {
			return [];
		}

		const { participant, drugDin, provinceOfAdjudication } = searchParameters;

		const localizedDrugDetails = await DrugCoverageGeneric.find([locale, drugDin, participant]);
		if (localizedDrugDetails) {
			return localizedDrugDetails.drugDetailsResult;
		}

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/drug-details?dins=${drugDin}&participant=${participant}&province=${provinceOfAdjudication}`;

		startSpinner();

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			stopSpinner();
			if (response?.status === 200) {
				// Api call is good, transform data and add it to vuex-orm.
				const drugDetails = this._transformData(response.data, searchParameters);

				await DrugCoverageGeneric.insertOrUpdate({
					data: {
						locale: locale,
						id: drugDin,
						participant: participant,
						drugDetailsResult: drugDetails,
						searchParameters
					}
				});
				stopSpinner();
				return drugDetails;
			} else if (response?.status === 204) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await DrugCoverageGeneric.insertOrUpdate({
					data: {
						locale: locale,
						id: drugDin,
						participant: participant,
						searchResult: response?.data,
						searchParameters: searchParameters
					}
				});
				// Return response instead of querying Vuex-orm for better performance.
				stopSpinner();
				return response?.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			return error.response;
		}
	}

	static _transformData(data, searchParameters) {
		let transformed = {};

		transformed.comparableDrugs = data?.comparableDrugs || null;
		transformed.drugChemName = data?.drugChemName || null;
		transformed.drugName = data?.drugName || null;
		transformed.drugDin = data?.drugDin || null;
		transformed.eligibility = data?.eligibility || null;
		transformed.genericEquivalents = data?.genericEquivalents || null;
		transformed.isGeneric = data?.isGeneric || null;
		transformed.condCopay = data?.condCopay || null;
		transformed.copayVary = data?.copayVary || null;
		transformed.hasMac = data?.hasMac || null;
		transformed.ramq = data?.ramq || null;
		transformed.tier = data?.tier || null;
		transformed.webCode = data?.webCode || null;
		transformed.index =
			searchParameters.index === 0 || searchParameters.index ? searchParameters.index : null;

		return transformed;
	}

	/**
	 * Utility function to check if there are any missing search parameters.
	 *
	 * @param {Object} searchParameters
	 * @param {Number} expectedNumberOfParameters - How many parameters should there be?
	 * @returns {Boolean}
	 * @example _isMissingSearchParameters({ participant: '03'}, 1)
	 */
	static _isMissingSearchParameters(searchParameters, expectedNumberOfParameters) {
		return Object.keys(searchParameters).length < expectedNumberOfParameters;
	}
}
