<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<ValidationObserver ref="observer" v-slot="{ validate, reset, errors, invalid }" slim>
		<BForm :novalidate="true" class="base-form" @submit.prevent="onSubmit">
			<slot :validate="validate" :reset="reset" :errors="errors" :invalid="invalid"></slot>
		</BForm>
	</ValidationObserver>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver } from 'vee-validate';
import { BForm } from 'bootstrap-vue';

/**
 * Component renders a form.
 */
// @vue/component
@Component({
	name: 'BaseForm',
	components: {
		BForm,
		ValidationObserver
	}
})
export default class BaseForm extends Vue {
	/**
	 * Handle onsubmit event
	 * @param {function} validate The validate function
	 */
	async onSubmit() {
		const valid = await this.$refs.observer.validate();
		if (valid) {
			this.$emit('submit');
		} else {
			let firstError = Object.entries(this.$refs.observer.errors).find((entry) => {
				const [key, value] = entry;
				return key && value && value.length > 0;
			});
			if (firstError) {
				const [refKey] = firstError;
				if (this.$refs.observer.refs[refKey]) {
					if (this.$refs.observer.refs[refKey].$el.querySelector('.form-control')) {
						this.$refs.observer.refs[refKey].$el.querySelector('.form-control').focus();
					} else if (this.$refs.observer.refs[refKey].$el.querySelector('.custom-control-input')) {
						this.$refs.observer.refs[refKey].$el.querySelector('.custom-control-input').focus();
					}
				} else {
					// If the first attempt at display the first error doesn't work find it based on the class name.
					// This is needed for the beneficiary/contingent screens because of a nested validation Observer.
					for (let i = 0; i < this.$refs.observer.$el.length; i++) {
						if (this.$refs.observer.$el[i].className.includes('is-invalid')) {
							firstError = this.$refs.observer.$el[i];
							break;
						}
					}
					if (firstError) {
						firstError.focus();
					}
				}
			}
		}
	}
}
</script>
