<template>
	<BNavbar :id="id" class="list-unstyled">
		<BNavbarNav>
			<BNavItem
				v-if="securityPolicy && securityPolicy.coverage"
				id="menu-coverage-nav-button"
				class="menu-item menu-nav-link"
				@click="toMyCoverage"
				>{{ $t('nav.title.coverage') }}
			</BNavItem>
			<BNavItemDropdown
				id="menu-claims"
				:data-test-automation-id="getAutomationId('menu-claims')"
				class="menu-item"
				no-caret
			>
				<template #button-content>
					{{ $t('nav.title.claims') }}
					<FontAwesomeIcon
						:icon="['fal', 'angle-down']"
						icon-size="sm"
						class="ml-2"
						:data-test-automation-id="getAutomationId('icon')"
					/>
				</template>
				<BDropdownItem
					v-if="securityPolicy && securityPolicy.submitClaim"
					id="submit-claim-button"
					class="menu-item"
					@click="toSubmitClaim"
					>{{ $t('nav.content.submitClaim') }}</BDropdownItem
				>
				<hr class="separator" role="none" />
				<template v-if="!isMaax">
					<BDropdownItem id="view-claim-button" class="menu-item" @click="toClaimHistory">
						{{ $t('nav.content.viewClaim') }}
					</BDropdownItem>
					<hr class="separator" role="none" />
					<BDropdownItem id="view-payment-button" class="menu-item" @click="toPaymentHistory">
						{{ $t('nav.content.viewPaymentHistory') }}
					</BDropdownItem>
				</template>
				<BDropdownItem v-else id="view-history-button" class="menu-item" @click="toHistory">
					{{ $t('nav.content.viewHistory') }}
				</BDropdownItem>
			</BNavItemDropdown>
			<BNavItem
				v-if="securityPolicy && securityPolicy.forms"
				id="find-form-nav-button"
				class="menu-item"
				@click="toFindAForm"
				>{{ $t('nav.title.findForm') }}</BNavItem
			>
			<BNavItem
				v-if="securityPolicy && securityPolicy.brand !== 'SBC'"
				id="health-nav-button"
				class="menu-item"
				@click="toHealthProfessional"
				>{{ $t('nav.title.healthProf') }}</BNavItem
			>
			<BNavItem
				v-if="securityPolicy && securityPolicy.storeFront && isConnectedCareAvailable"
				id="exclusive-offer-nav-button"
				class="menu-item"
				@click="toConnectedCare"
			>
				{{ $t('nav.title.exclusiveOffer') }}
			</BNavItem>
			<BNavItemDropdown id="menu-help" no-caret class="menu-item">
				<template #button-content class="p-0">
					<span class="menu-item">
						{{ $t('nav.title.needHelp') }}

						<FontAwesomeIcon
							:icon="['fal', 'angle-down']"
							icon-size="sm"
							:data-test-automation-id="getAutomationId('icon')"
						/>
					</span>
				</template>
				<BDropdownItem v-if="isHelpCenterAvailable" class="menu-item" @click="toHelpCentre">{{
					$t('nav.content.helpCenter')
				}}</BDropdownItem>
				<hr class="separator" role="none" />
				<BDropdownItem
					class="menu-item"
					:disabled="linkDisabled('contact-us')"
					@click="toContactUs"
					>{{ $t('nav.content.contactUs') }}</BDropdownItem
				>
			</BNavItemDropdown>
		</BNavbarNav>
	</BNavbar>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BNavbar, BNavbarNav, BNavItem, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue';
import * as Routes from '@/constants/Routes.js';
import Authentication from '@/models/Authentication';

// @vue/component
@Component({
	name: 'MenuSubHeader',
	components: {
		BNavItem,
		BNavbar,
		BNavbarNav,
		BNavItemDropdown,
		BDropdownItem
	},
	mixins: [IdMixin],
	props: {
		id: {
			type: String,
			default: ''
		},
		autoationId: {
			type: String,
			default: ''
		}
	}
})
export default class MenuSubHeader extends Vue {
	securityPolicy = null;

	get isMaax() {
		if (sessionStorage.getItem('securityPolicy')) {
			const { maaxClaims } = JSON.parse(sessionStorage.getItem('securityPolicy'));
			return maaxClaims;
		}
		return false;
	}

	get isHomeActive() {
		return this.$route.name === Routes.DASHBOARD ? true : false;
	}

	get isCoverageActive() {
		return this.$route.path.includes('coverage') ? true : false;
	}

	get homeName() {
		return { name: Routes.DASHBOARD, params: { locale: this.$root.$i18n?.locale } };
	}

	get branding() {
		if (sessionStorage.getItem('securityPolicy')) {
			return JSON.parse(sessionStorage.getItem('securityPolicy')).brand;
		}
		return '';
	}

	get isConnectedCareAvailable() {
		if (this.branding === 'SBC') {
			return false;
		}

		return true;
	}

	get isHelpCenterAvailable() {
		if (this.branding === 'OBC' || this.branding === 'QBC') {
			return false;
		}
		return true;
	}

	async created() {
		this.securityPolicy = await JSON.parse(sessionStorage.getItem('securityPolicy'));
	}

	linkDisabled(route) {
		return route === this.$route.name;
	}

	toSubmitClaim() {
		this.$emit('toSubmitClaim');
	}

	toClaimHistory() {
		this.$emit('toClaimHistory');
	}

	toHistory() {
		this.$emit('toHistory');
	}

	toPaymentHistory() {
		this.$emit('toPaymentHistory');
	}

	toMyCoverage() {
		this.$emit('toMyCoverage');
	}

	toContactUs() {
		this.$emit('toContactUs');
	}

	toFindAForm() {
		const locale = this.$store.state.i18n.locale;
		if (this.securityPolicy?.brand === 'SBC') {
			window.open('https://www.sk.bluecross.ca/customer-service/forms/', '_blank');
		} else if (this.branding === 'QBC') {
			locale === 'en'
				? window.open(
						'https://qc.bluecross.ca/client-care/member-services/health-claim-forms',
						'_blank'
				  )
				: window.open(
						'https://qc.croixbleue.ca/service-a-la-clientele/services-aux-membres/formulaires-assurance-sante?_gl=1*yq2zyw*_ga*MTUxMjg2Mzg3OS4xNTk0NjY4MDUy*_ga_K5L0PY8G9S*MTY2OTA1MDczMS4zNC4wLjE2NjkwNTA3MzIuNTkuMC4w&_ga=2.235093633.185945953.1669042840-1512863879.1594668052',
						'_blank'
				  );
		} else if (this.branding === 'OBC') {
			window.open(
				'https://on.bluecross.ca/client-care/member-services/health-claim-forms',
				'_blank'
			);
		} else {
			locale === 'en'
				? window.open('https://www.medaviebc.ca/en/members/forms', '_blank')
				: window.open('https://www.medaviebc.ca/fr/adherents/formulaires', '_blank');
		}
	}

	toHealthProfessional() {
		if (this.$store.state.i18n.locale === 'en') {
			if (this.branding && (this.branding === 'OBC' || this.branding === 'QBC')) {
				window.open('https://www.medaviebc.ca/en/members/find-health-professional?brand=gen');
			} else {
				window.open('https://www.medaviebc.ca/en/members/find-health-professional');
			}
		} else {
			if (this.branding && (this.branding === 'OBC' || this.branding === 'QBC')) {
				window.open(
					'https://www.medaviebc.ca/fr/adherents/trouvez-professionnel-de-la-sante?brand=gen'
				);
			} else {
				window.open('https://www.medaviebc.ca/fr/adherents/trouvez-professionnel-de-la-sante');
			}
		}
	}

	toHelpCentre() {
		const locale = this.$store.state.i18n.locale;
		if (this.securityPolicy.brand === 'SBC') {
			window.open('https://www.sk.bluecross.ca/build-your-knowledge/', '_blank');
		} else {
			locale === 'en'
				? window.open(`https://www.medaviebc.ca/en/help`, '_blank')
				: window.open('https://www.medaviebc.ca/fr/aide', '_blank');
		}
	}

	async toConnectedCare() {
		const locale = this.$store.state.i18n.locale;
		let connectedCareToken = sessionStorage.getItem(`cc_${locale}`);
		if (!connectedCareToken && connectedCareToken !== '') {
			this.$store.dispatch('updateLoading', true);
			connectedCareToken = await Authentication.getConnectedCareToken(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$store.state.i18n.locale
			);
			this.$store.dispatch('updateLoading', false);
		}
		if (locale === 'en') {
			window.open(
				`https://www.medaviebc.ca/connected-care?msf_token=${connectedCareToken}`,
				'_blank'
			);
		} else {
			window.open(
				`https://www.medaviebc.ca/fr/connexion-sante?msf_token=${connectedCareToken}`,
				'_blank'
			);
		}
	}
}
</script>
<style lang="scss" scoped>
hr {
	margin: 0;
}
.separator {
	height: 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
}
::v-deep .navbar-nav {
	height: 100%;
	margin-left: 5px;
}
.nav-item.menu-item,
.nav-item.dropdown {
	height: 100%;
	&:hover,
	&.show {
		background-color: $white;
		border-bottom: 3px solid $border-orange;
		padding-top: 3px;
		& > ::v-deep ul {
			margin-top: 3px;
		}
	}
}
::v-deep .navbar-nav .nav-link {
	height: 100%;
	padding: 0 13px;
	display: flex;
	align-items: center;
}
::v-deep .dropdown-menu {
	border-radius: 0;
	border: 1px solid $gray-light;
	padding: 0;
	margin-top: 0;
	& .menu-item {
		height: 45px;
		&:hover {
			background-color: $gray-very-light;
		}
		& > a {
			height: 100%;
			display: flex;
			align-items: center;
			color: $blue;
			&:hover {
				text-decoration: underline;
				color: $blue-dark;
				background-color: transparent;
			}
		}
	}
}
</style>

<i18n lang="json">
{
	"en": {
		"nav": {
			"title": {
				"coverage": "Coverage",
				"claims": "Claims",
				"findForm": "Find a Form",
				"healthProf": "Find a Health Professional",
				"exclusiveOffer": "Connected Care",
				"needHelp": "Need Help?"
			},
			"content": {
				"submitClaim": "Submit a Claim",
				"viewClaim": "View Claims History",
				"viewHistory": "Claim History",
				"viewPaymentHistory": "View Payment History",
				"helpCenter": "Help Centre",
				"contactUs": "Contact Us"
			}
		}
	},
	"fr": {
		"nav": {
			"title": {
				"coverage": "Votre couverture",
				"claims": "Demandes de règlement",
				"findForm": "Trouver un formulaire",
				"healthProf": "Trouvez un professionnel de la santé",
				"exclusiveOffer": "Connexion santé",
				"needHelp": "Besoin d'aide?"
			},
			"content": {
				"submitClaim": "Soumettre une demande de règlement",
				"viewClaim": "Consulter l'historique des demandes de règlement",
				"viewHistory": "Historique des demandes de règlement",
				"viewPaymentHistory": "Consulter l'historique des paiements",
				"helpCenter": "Centre d'aide",
				"contactUs": "Communiquez avec nous"
			}
		}
	}
}
</i18n>
