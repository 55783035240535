import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class PaymentDetails extends Model {
	static entity = 'paymentDetails';

	static primaryKey = ['email', 'locale', 'claimId'];

	static fields() {
		return {
			email: this.string(),
			locale: this.string(),
			claimId: this.string(),
			data: this.attr(null)
		};
	}

	static async hasPaymentDetails(email, locale, claimId) {
		const history = await this.find([email, locale, claimId]);
		return history ? true : false;
	}

	static async getPaymentDetailsPDF(email, token, locale, claimId) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/claims/${claimId}/payment-details/pdf`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/pdf',
					'Accept-Language': locale,
					Authorization: token
				},
				responseType: 'blob'
			});
			if (response.status === 200) {
				return response.data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static async getPaymentDetails(email, token, locale, claimId) {
		const paymentDetails = await this.find([email, locale, claimId])?.data;
		if (paymentDetails) return paymentDetails;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/claims/${claimId}/payment-details`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			switch (response?.status) {
				case 200: {
					const details = await this.insertOrUpdate({
						data: {
							email,
							locale,
							claimId,
							data: response.data
						}
					});
					return details.paymentDetails[0].data;
				}
				case 204:
					await this.insert({
						data: {
							email,
							locale,
							claimId,
							data: []
						}
					});
					return [];
				default:
					throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}
}
