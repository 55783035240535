import { Response } from 'miragejs';

export default function (server) {
	// submit claim
	server.post('/members/:id/multi-claims', () => {
		const httpStatusCode = 200;
		const headers = {};
		const data = {
			message: 'Claim submitted successfully'
		};

		return new Response(httpStatusCode, headers, data);
	});
}
