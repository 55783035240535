<template>
	<div v-if="showBreadcrumbs" class="breadcrumb-bg">
		<BContainer>
			<BBreadcrumb
				class="px-0 breadcrumbs"
				:items="items"
				:data-test-automation-id="getAutomationId('bread-crumb')"
			/>
			<BaseButton
				class="button"
				:label="mobileButtonLabel"
				variant="link"
				:icon="['fal', 'angle-left']"
				icon-position="left"
				icon-size="lg"
				:automation-id="getAutomationId('bread-crumb')"
				@click="navigateBack"
			/>
		</BContainer>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BContainer, BBreadcrumb } from 'bootstrap-vue';
import BaseButton from '@/components/common/base/BaseButton';
import store from '@/store/index';

// @vue/component
@Component({
	name: 'BaseBreadcrumbs',
	components: {
		BContainer,
		BBreadcrumb,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		items: {
			type: Array,
			default: () => []
		},
		automationId: {
			type: String,
			default: '',
			required: true
		}
	}
})
export default class BaseBreadcrumbs extends Vue {
	mobileButtonLabel = null;

	get showBreadcrumbs() {
		return store.state.breadcrumbModule.breadcrumbs?.length > 0;
	}

	/**
	 * Set the label value on create, and whenever the component is updated to
	 * make sure it shows the correct text per page.
	 */

	getText(items) {
		if (!items) return '';
		return items[items.length - 2]?.text;
	}

	created() {
		this.mobileButtonLabel = this.getText(this.items);
	}

	updated() {
		this.mobileButtonLabel = this.getText(this.items);
	}

	/**
	 * This method will navigate back to the the previous page of the breadcrumb array.
	 */
	navigateBack() {
		this.$router.push({
			name: this.items[this.items?.length - 2].to.name
		});
	}
}
</script>
<style lang="scss" scoped>
.breadcrumb-bg {
	background-color: $white;
	margin-bottom: 0;
	width: 100%;
	min-height: 44px;
}
.breadcrumb {
	background-color: $white;
	margin-bottom: 0;
}
.breadcrumb li,
.breadcrumb li a {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
}
.breadcrumbs {
	display: none;
	@media (min-width: 577px) {
		display: flex;
	}
}
.button {
	display: none;
	@media (max-width: 576px) {
		display: initial;
	}

	& ::v-deep .pr-1 {
		padding-right: 0.5em !important;
	}
}
</style>
