import { Response } from 'miragejs';

export default function (server) {
	/**
	 * My Account / Manage your Account / Direct Deposit (new setup)
	 *
	 * Return http code 204 and no data (not even {}) to simultate a new banking setup.
	 *
	 * Note: To test like a "new banking creation", you must comment out the next get that returns banking data.
	 */
	server.get('/members/:id/banking-details', () => {
		const httpStatusCode = 204;
		return new Response(httpStatusCode);
	});

	/**
	 * My Account / Manage your Account / Edit direct deposit (read)
	 *
	 * Note: Comment this to allow above banking-details to return the 204 that simulates missing banking info.
	 */
	server.get('/members/:id/banking-details', () => {
		return {
			branch: '12345',
			bank: '123',
			account: '654654654654'
		};
	});

	/**
	 * My Account / Manage your Account / Edit direct deposit (udpate)
	 */
	server.put('/members/:id/banking-details', () => {
		return {
			branch: '54321',
			bank: '321',
			account: '982298229822'
		};
	});
}
