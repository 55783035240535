import { Model } from '@vuex-orm/core';
import axios from 'axios';
import HospitalBenefit from './HospitalBenefits';

export default class HospitalBenefitDetails extends Model {
	static entity = 'HospitalBenefitDetails';

	static primaryKey = ['participantId', 'locale', 'code'];

	static fields() {
		return {
			id: this.uid(),
			participantId: this.string(),
			locale: this.string(),
			data: this.attr()
		};
	}

	static async hasDetails(participantId, code, locale) {
		let benefit = await super
			.query()
			.where((bd) => {
				return bd.participantId === participantId && bd.locale === locale && bd.code === code;
			})
			.first();
		return benefit ? true : false;
	}

	static async getBenefitDetails(email, participantId, code, token, locale) {
		let benefit = await super
			.query()
			.withAll()
			.where((bd) => {
				return bd.participantId === participantId && bd.locale === locale && bd.code === code;
			})
			.first();
		try {
			if (benefit) {
				benefit.benefit = await HospitalBenefit.getBenefitInfoByServiceCode(
					email,
					token,
					participantId,
					locale,
					code
				);
				benefit.relatedServices = await HospitalBenefit.getRelatedBenefits(
					participantId,
					locale,
					code
				);
				return benefit;
			}
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/${participantId}/benefits/hospital/${code}`;
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			if (response?.status === 200) {
				if (!('healthPricingType' in response.data)) {
					response.data.agreements = response.data.agreements.filter(
						(item) => item.type !== 'HEALTH_PRICING'
					);
				} else {
					if (!response.data.agreements.find((item) => item.type === 'HEALTH_PRICING')) {
						response.data.agreements.push({ type: 'HEALTH_PRICING', descriptions: [] });
					}
				}
				benefit = await this.insertOrUpdate({
					data: { participantId, locale, data: response.data }
				});
				benefit.HospitalBenefitDetails[0].data.benefit =
					await HospitalBenefit.getBenefitInfoByServiceCode(
						email,
						token,
						participantId,
						locale,
						code
					);
				benefit.HospitalBenefitDetails[0].data.relatedServices =
					await HospitalBenefit.getRelatedBenefits(participantId, locale, code);
				return benefit.HospitalBenefitDetails[0].data;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}
}
