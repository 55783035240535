// Login screen related
export const LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_YOUR_ACCOUNT = 'reset-your-account';
export const NEED_HELP = 'need-help';
export const SUCCESS_RESET_EMAIL = 'success-reset-email';
export const SUCCESS_RESET = 'success-reset';
export const PASSWORD_CHANGE = 'password-change';
export const TERMS_AND_CONDITIONS_FORM = 'terms-and-conditions-form';

// Route to determine validation tokens
export const VALIDATE = 'validate';
export const DIGITAL_VALIDATE = 'digital-validate';
export const EXPIREDTOKEN = 'expired-token';

// Contact Url
// Digital welcome - no dropdown menus etc
export const CUSTOM_CONTACT = 'custom-contact';
export const CUSTOM_CONTACT_NO_LOCALE = 'custom-contact-no-locale';
// Contact us with dropdowns.
export const CONTACT_US = 'contact-us';

// Registration Screens
export const REGISTRATION = 'registration';
export const SUCCESS_REGISTRATION = 'success-registration';
export const SUCCESS_RESET_LOGIN = 'login-reset';
export const INVALID_EMAIL = 'invalid-email';
export const REGISTRATION_ACTIVE = 'registration-active';
export const CONFIRM_EMAIL_CHANGE = 'confirm-email-change';
export const DIGITAL_VERIFICATION = 'digital-verification';
export const DIGITAL_PASSWORD = 'digital-password';
export const DIGITAL_SUCCESS = 'digital-success';
export const DIGITAL_EXPIRED = 'digital-expired';
export const DIGITAL_ERROR = 'digital-error';
export const DIGITAL_LOCKED = 'digital-locked';
// Dashboard screens
export const DASHBOARD = 'dashboard';

// Manage your account
export const MANAGE_YOUR_ACCOUNT = 'manage-your-account';
export const PERSONAL_INFORMATION = 'personal-information';
export const PEOPLE_ON_YOUR_PLAN = 'people-on-your-plan';
export const MEMBER_CARD = 'member-card';
export const SHARE_MEMBER_CARD = 'share-member-card';
export const CHANGE_PASSWORD = 'change-password';
export const CHANGE_PASSWORD_SUCCESS = 'change-password-success';
export const CHANGE_EMAIL = 'change-email';
export const CHANGE_EMAIL_VERIFY = 'change-email-verify';
export const CHANGE_EMAIL_CONFIRM = 'change-email-confirm';
export const CHANGE_EMAIL_RESULT = 'change-email-result';
export const CHANGE_ADDRESS = 'change-address';
export const CHANGE_LANGUAGE = 'change-language';
export const TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const PRIVACY_AND_TERMS = 'privacy-and-terms';

export const DIRECT_DEPOSIT = 'direct-deposit';
export const PREMIUM_PAYMENT_METHOD = 'premium-payment-method';
export const PREMIUM_STATEMENTS = 'premium-statements';
export const MONERIS = 'moneris';
export const MONERIS_IFRAME_BLANK = 'moneris-blank';

// TODO: These are deprecated.  Move to using SUBMIT_CLAIM object please
export const CLAIM_TYPE = 'claim-type';
export const PROVIDER_TYPE_CHOICE = 'provider-type-choice';
export const PROVIDER_SEARCH = 'provider-search';
export const PROVIDER_SEARCH_RESULTS = 'provider-search-results';

// Submit a claim
export const SUBMIT_CLAIM = {
	SUBMIT_CLAIM: 'submit-claim',
	CLAIM_TYPE: 'claim-type',
	BEFORE_START_GENERIC: 'before-start-health',
	BEFORE_START_DENTAL: 'before-start-dental',
	BEFORE_START_DRUGS: 'before-start-drugs',
	BEFORE_START_HSA: 'before-start-hsa',
	BEFORE_START_PWA: 'before-start-pwa',
	BEFORE_START_TRAVEL: 'before-start-travel',
	BEFORE_START_DCI_LADD: 'before-start-dci-ladd',
	TREATMENT_FOR_ACCIDENT: 'treatment-for-accident',
	PROVIDER_TYPE_CHOICE: 'provider-type-choice',
	PROVIDER_SEARCH: 'provider-search',
	PROVIDER_SEARCH_RESULTS: 'provider-search-results',
	VISION_OTHER: 'vision-other',
	SERVICE_AMOUNT: 'services',
	HSA: 'hsa',
	HSA_ELIGIBLE_CLAIMS: 'hsa-eligible-claims',
	SERVICE_SELECTION: 'service-selection',
	OTHER_COVERAGE: 'other-coverage',
	CLAIM_DETAILS: 'claim-details',
	SERVICE_LIST: 'service-list',
	DOCUMENTS_TO_ATTACH: 'documents-to-attach',
	ATTACH_DOCUMENTS: 'attach-documents',
	CONFIRMATION: 'confirmation',
	SUBMIT_CLAIM_SUCCESS: 'submit-claim-success',
	VERIFY_INFORMATION: 'verify-information',
	TRAVEL_CAN_NOT_PROCESS: 'travel-can-not-process',
	CLAIM_SUMMARY: 'claim-summary',
	COMMENTS: 'comments'
};

// Check My Coverage
export const COVERAGE = {
	COVERAGE: 'coverage',
	MY_COVERAGE: 'my-coverage',
	DENTAL: 'dental',
	HOSPITAL: 'hospital',
	HOSPITAL_DETAILS: 'hospital/detail',
	PWA: 'pwa-coverage',
	DRUGS: 'drugs',
	COMPARABLE_DRUGS: 'comparable-drugs',
	GENERIC_EQUIVALENT: 'generic-equivalent-drugs',
	LIFE_DISABILITY: 'life-disability',
	TRAVEL: 'travel',
	EXTENDED_HEALTH: 'extended-health',
	EXTENDED_HEALTH_DETAILS: 'extended-health/detail',
	OPTIONAL_BENEFITS: 'optional-benefits',
	HSA: 'hsa-coverage',
	ONLINE_DOCTORS: 'online-doctors',
	EFAP: 'efap',
	MEDICAL_EXCLUSION: 'medical-exclusion',
	RHIP: 'retirement-health-insurance-program'
};

// Payment History
export const PAYMENT_HISTORY = 'payment-history';
export const PAYMENT_HISTORY_DETAILS = 'payment-history-details';

// Claim History
export const CLAIM_HISTORY = 'claim-history';
export const HISTORY = 'history';
export const HISTORY_DETAILS = 'history/details';

// When Page is not found
export const NOT_FOUND = 'not-found';

// When Token is not valid
export const INVALID_TOKEN = 'invalid-link-token';
export const EXPIRED_TOKEN = 'expired-link-token';
export const ERROR_TOKEN_VALIDATION = 'error-validating-token';
