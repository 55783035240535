/**
 * My Account / Manage your Account
 */

export default function (server) {
	server.get('/members/:id/cardholder-details', () => {
		return {
			firstName: 'Joe',
			lastName: 'Smith',
			idNumber: '00000000801',
			policyNumber: '0016553001',
			preferredLanguage: 'en',
			regional: true,
			bookOfBusiness: '1',
			businessOwner: '1',
			msId: '502034622',
			provinceOfAdjudication: 'NB'
		};
	});
}
