import { Model } from '@vuex-orm/core';

/**
 * This class represent a Member
 */
export default class BenefitService extends Model {
	static entity = 'benefitServices';

	static loaded = false;

	/**
	 * benefit services fields
	 */
	static primaryKey = 'id';
	static fields() {
		return {
			id: this.uid(),
			claimId: this.string(),
			description: this.string(null).nullable(),
			programCd: this.string(null).nullable(),
			serviceId: this.string(null).nullable(),
			serviceRow: this.string(null).nullable(),
			categoryId: this.string(null).nullable(),
			categoryUiId: this.string(null).nullable(),
			isMasterService: this.boolean(null).nullable(),
			specialtyCode: this.string(null).nullable(),
			isRequirePrescription: this.boolean(null).nullable(),
			hasPrescription: this.boolean(null).nullable(),
			programId: this.string(null).nullable(),
			languageCode: this.string(null).nullable(),
			pocLobId: this.string(null).nullable(),
			specialtyId: this.string(null).nullable(),
			dateOfService: this.string(null).nullable(),
			pocLobCode: this.string(null).nullable(),
			amountPaid: this.string(null).nullable(),
			amountPaidByOtherCarrier: this.string(null).nullable(),
			wasSubmittedToOtherFirst: this.boolean(null).nullable(),
			descriptionRbKey: this.string(null).nullable(),
			isServiceTypeOther: this.boolean(null).nullable(),
			serviceCode: this.string(null).nullable(),
			cobOtherReason: this.string(null).nullable(),
			cobOtherReasonNote: this.string(null).nullable()
		};
	}

	static async createNewBenefitServiceWithEmail(email) {
		var benefit = await super.new();
		benefit.claimId = email;
		benefit.$save();
		return benefit;
	}

	static async createNewBenefitService(
		service,
		email,
		cost,
		specialtycode,
		dateOfService,
		rowIndex
	) {
		var benefit = await super.new();
		benefit.claimId = email;
		benefit.description = service.description;
		benefit.programCd = service.programCd;
		benefit.serviceId = service.serviceId;
		benefit.categoryId = service.categoryId;
		benefit.isMasterService = service.isMasterService;
		benefit.specialtyCode = specialtycode;
		benefit.categoryUiId = service.categoryUiId;
		benefit.isRequirePrescription = service.isRequirePrescription;
		benefit.programId = service.programId;
		benefit.languageCode = service.languageCode;
		benefit.pocLobId = service.pocLobId;
		benefit.specialtyId = service.specialtyId;
		benefit.dateOfService = dateOfService;
		benefit.pocLobCode = service.pocLobCode;
		benefit.amountPaid = cost;
		benefit.descriptionRbKey = service.descriptionRbKey;
		benefit.isServiceTypeOther = service.isServiceTypeOther;
		benefit.serviceCode = service.serviceCode;
		benefit.cobOtherReason = service.cobOtherReason;
		benefit.cobOtherReasonNote = service.cobOtherReasonNote;
		benefit.serviceRow = rowIndex;
		benefit.amountPaidByOtherCarrier = service.amountPaidByOtherCarrier;
		benefit.$save();
		return benefit;
	}

	static async getBenefitServiceByEmail(email) {
		return await super.query().withAll().where('claimId', email);
	}

	static async updateBenefitServiceByEmail(email, benefitService) {
		await super.update({
			where: (service) => {
				return service.claimId === email && service.id === benefitService.id;
			},
			data: {
				description: benefitService.description,
				programCd: benefitService.programCd,
				serviceId: benefitService.serviceId,
				categoryId: benefitService.categoryId,
				isMasterService: benefitService.isMasterService,
				specialtyCode: benefitService.specialtyCode,
				categoryUiId: benefitService.categoryUiId,
				isRequirePrescription: benefitService.isRequirePrescription,
				programId: benefitService.programId,
				languageCode: benefitService.languageCode,
				pocLobId: benefitService.pocLobId,
				specialtyId: benefitService.specialtyId,
				dateOfService: benefitService.dateOfService,
				pocLobCode: benefitService.pocLobCode,
				amountPaid: benefitService.amountPaid,
				descriptionRbKey: benefitService.descriptionRbKey,
				isServiceTypeOther: benefitService.isServiceTypeOther,
				serviceCode: benefitService.serviceCode,
				cobOtherReason: benefitService.cobOtherReason,
				cobOtherReasonNote: benefitService.cobOtherReasonNote,
				amountPaidByOtherCarrier: benefitService.amountPaidByOtherCarrier
			}
		});
	}

	static async updateBenefitServiceById(benefitService) {
		await super.update({
			where: benefitService.$id,
			data: {
				description: benefitService.description,
				programCd: benefitService.programCd,
				serviceId: benefitService.serviceId,
				categoryId: benefitService.categoryId,
				isMasterService: benefitService.isMasterService,
				specialtyCode: benefitService.specialtyCode,
				categoryUiId: benefitService.categoryUiId,
				isRequirePrescription: benefitService.isRequirePrescription,
				programId: benefitService.programId,
				languageCode: benefitService.languageCode,
				pocLobId: benefitService.pocLobId,
				specialtyId: benefitService.specialtyId,
				dateOfService: benefitService.dateOfService,
				pocLobCode: benefitService.pocLobCode,
				amountPaid: benefitService.amountPaid,
				descriptionRbKey: benefitService.descriptionRbKey,
				isServiceTypeOther: benefitService.isServiceTypeOther,
				serviceCode: benefitService.serviceCode,
				cobOtherReason: benefitService.cobOtherReason,
				cobOtherReasonNote: benefitService.cobOtherReasonNote,
				amountPaidByOtherCarrier: benefitService.amountPaidByOtherCarrier
			}
		});
	}

	static async removeBenefitService(email) {
		BenefitService.delete((service) => {
			return service.claimId === email;
		});
	}

	// Remove a specific benefit service using the ID.
	static async removeBenefitServiceById(id) {
		BenefitService.delete(id);
	}

	static async clearBenefitService() {
		await super.deleteAll();
	}
}
