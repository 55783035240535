import axios from 'axios';

/**
 * Load environment variables when the appliation starts.
 */

/**
 * Set a list of environment variables on the browser's window.ENV scope.
 *
 * @params {configData} JSON object containing a list of environment variables in key:value pairs.
 * @return {Promise} A Promise is returned.  The Promise is resolved when the variables are set.
 *
 * @example window.ENV
 *
 *  {
 *    API_HOST: "http://...",
 *    TOKEN_END_POINT: "http://...",
 *    LOGOUT_URL: "http://..."
 *  }
 *
 */
function setEnvironmentVariables(configData) {
	return new Promise((resolve) => {
		window.ENV = configData;

		resolve(); // Resolve the Promise.
	});
}

/**
 * Read a JSON formatted file which contains environment variables to be used by the application at runtime.
 *
 * @return {object} A JSON object containing the environment variables.
 */
async function getEnvironmentVariables() {
	try {
		const configData = await axios.get(`/assets/env-config.json`); // Ex: /assets/env-config.json
		return configData.data;
	} catch (error) {
		return false;
	}
}

export async function initEnvVars() {
	const envVariables = await getEnvironmentVariables();

	if (envVariables) {
		await setEnvironmentVariables(envVariables);
	}
}
