/**
 * Handlers for the coverage api
 *
 */
import dental from '../demo/members/coverages/dental';
import drugs from '../demo/members/coverages/drugs';
import hospital from '../demo/members/coverages/hopistal';
import extendedHealthBenefits from '../demo/members/coverages/extended-health-benefits';
import LifeAndDisability from './members/coverages/LifeAndDisability';
import onlineDoctor from './members/coverages/onlineDoctor';
import optionalBenefit from './members/coverages/optional-benefit';
import pwa from './members/coverages/pwa';
import hsa from './members/coverages/hsa';
import travel from './members/coverages/travel';

export default function (server) {
	dental(server);
	drugs(server);
	hospital(server);
	extendedHealthBenefits(server);
	LifeAndDisability(server);
	onlineDoctor(server);
	optionalBenefit(server);
	pwa(server);
	travel(server);
	hsa(server);
}
