import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class ClaimTypes extends Model {
	static entity = 'claimTypes';

	static loaded = false;

	/**
	 * benefit services fields
	 */
	static primarykey = 'id';
	static fields() {
		return {
			email: this.string(),
			claimType: this.string(),
			description: this.string(),
			maaxLob: this.string(null).nullable(),
			maaxBenefit: this.string(null).nullable(),
			lob: this.string(),
			isRta: this.boolean(),
			isDisabledForProv: this.boolean()
		};
	}

	static async loadClaimType(email, token, locale) {
		await super.deleteAll();
		await axios
			.get(`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + email + '/claim-types', {
				headers: {
					'content-type': 'application/json',
					'Accept-language': locale,
					Authorization: token
				}
			})
			.then(async (response) => {
				for (let i = 0; i < response.data.length; i++) {
					await super.insert({
						data: {
							email: email,
							claimType: response.data[i].claimType,
							description: response.data[i].description,
							maaxLob: response.data[i].maaxLob,
							maaxBenefit: response.data[i].maaxBenefit,
							lob: response.data[i].lob,
							isRta: response.data[i].isRta,
							isDisabledForProv: response.data[i].isDisabledForProv
						}
					});
				}
			});
		return await this.getClaimTypesByEmail(email);
	}

	static async getClaimTypesByEmail(email) {
		return await super.query().where('email', email).get();
	}
}
