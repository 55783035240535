<template>
	<BSkeleton
		v-bind="$attrs"
		:height="height"
		:width="width"
		:data-test-automation-id="getAutomationId('skeleton')"
	>
		<slot> </slot>
	</BSkeleton>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BSkeleton } from 'bootstrap-vue';

@Component({
	name: 'BaseSkeleton',
	components: {
		BSkeleton
	},
	inheritAttrs: false,
	mixins: [IdMixin],
	props: {
		width: {
			type: String,
			default: '100%'
		},
		height: {
			type: String,
			default: '100%'
		}
	}
})
export default class BaseSkeleton extends Vue {}
</script>
