/**
 * Vuex-orm model to demonstrate the MSS pattern for saving search parameters and search results.
 */
import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class MyClaim extends Model {
	static entity = 'myClaims';

	static fields() {
		return {
			id: this.string(null).nullable(), // Member email
			claimLines: this.attr(null).nullable(), // Saved claims search results
			searchParameters: this.attr(null).nullable() // Saved search parameters
		};
	}

	static async getSavedSearchParameters(username) {
		return await MyClaim.find(username)?.searchParameters;
	}

	static async getSavedSearchResults(username) {
		return await MyClaim.find(username)?.claimLines;
	}

	/**
	 * Make a service call to get claims data.  Store the reponse in the model.
	 *
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @param {Object} searchParameters
	 * @returns {Array} Claims for a member.
	 */
	static async getClaimHistory(username, token, locale, searchParameters) {
		// Member clicked "search" without entering all search parameters (should be 4 in this case).
		// Note: The page should perform validation and not allow this to happen.
		if (this._isMissingSearchParameters(searchParameters, 4)) {
			return [];
		}

		const { participant, claimType, fromDate, toDate } = searchParameters;

		const queryString = `participants=${participant}&claimTypes=${claimType}&fromDate=${fromDate}&toDate=${toDate}`;

		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/claim-histories?${queryString}`;

		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200 || response?.status === 204) {
				// Service call is good, add data to vuex-orm (even when empty response).
				await MyClaim.create({
					data: {
						id: username,
						claimLines: response?.data[0]?.claimLines,
						searchParameters
					}
				});
				// Return response instead of querying Vuex-orm for better performance.
				return response?.data[0]?.claimLines;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Utility function to check if there are any missing search parameters.
	 *
	 * @param {Object} searchParameters
	 * @param {Number} expectedNumberOfParameters - How many parameters should there be?
	 * @returns {Boolean}
	 * @example _isMissingSearchParameters({ participant: '03'}, 1)
	 */
	static _isMissingSearchParameters(searchParameters, expectedNumberOfParameters) {
		return Object.keys(searchParameters).length < expectedNumberOfParameters;
	}
}
