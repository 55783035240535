/**
 * claim health types
 */
import non_rta_health_types from './../../objects/non_rta_health_types.json';
// import non_rta_health_types_fr from './../../objects/non_rta_health_types_fr.json';

export default function (server) {
	server.get('https://d3h2ws3410wq1f.cloudfront.net/Claims/non_rta_health_types', () => {
		return non_rta_health_types;
	});
}
