import { Model } from '@vuex-orm/core';

/**
 * This class represent a Member
 */
export default class BenefitProvider extends Model {
	static entity = 'benefitProvider';

	static loaded = false;

	/**
	 * benefit services fields
	 */
	static primarykey = 'id';
	static fields() {
		return {
			id: this.number(),
			claimId: this.string(),
			addressLine1: this.string(),
			addressLine2: this.string(),
			aimId: this.string(),
			cancelsRTA: this.boolean(false),
			city: this.string(),
			firstName: this.string(),
			isDirectPay: this.boolean(),
			lastName: this.string(),
			pauthId: this.string(),
			phoneNumber: this.string(),
			postalCode: this.string(),
			providerType: this.string(),
			providerTypeName: this.string(),
			province: this.string(),
			regionCode: this.string(),
			searchRanking: this.number(),
			sortNameKey: this.string(),
			status: this.string(),
			prnId: this.string(),
			organizationName: this.string('')
		};
	}

	static async getBenefitProviderByEmail(email) {
		return await super.query().withAll().where('email', email);
	}
	static async insertBenefitProvider(provider, email) {
		let updateProvider = await super.insertOrUpdate({
			data: {
				claimId: email,
				addressLine1: provider.addressLine1,
				addressLine2: provider.addressLine2,
				aimId: provider.aimId,
				cancelsRTA: provider.cancelsRTA,
				city: provider.city,
				firstName: provider.firstName,
				isDirectPay: provider.isDirectPay,
				lastName: provider.lastName,
				pauthId: provider.pauthId,
				phoneNumber: provider.phoneNumber,
				postalCode: provider.postalCode,
				prnId: provider.prnId,
				providerType: provider.providerType,
				providerTypeName: provider.providerTypeName,
				province: provider.province,
				regionCode: provider.regionCode,
				searchRanking: provider.searchRanking,
				sortNameKey: provider.sortNameKey,
				status: provider.status,
				organizationName: provider.organizationName
			}
		});
		return updateProvider.benefitProvider[0];
	}

	static async clearBenefitProvider() {
		await super.deleteAll();
	}
}
