/**
 * Returns a list of extended health benefit families (EHB) for a member.
 *
 * Web service end point: {{rfURL}}members/{{username}}/coverages/extended-health-benefits
 *
 * Sample web service response:
 *
  [
    {
      "shortDesc": "Ambulance Services (some ambulance benefits may be covered under Hospital and/or Extended Health Benefits)",
      "benefits": [
        {
          "shortDesc": "Air transportation stretcher case in Canada",
          "benefitCode": "0730IC"
        }
      ]
    },
    {
      "shortDesc": "Braces/Splints/Stockings/Supports",
      "benefits": [
        {
          "shortDesc": "Back brace purchase",
          "benefitCode": "0507PB"
        }
      ]
    }
  ]
 *
 * Note: The model can store the EN and FR versions of the benefit families.
 *
 */

import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default class ExtendedHealthBenefitsScepter extends Model {
	static entity = 'extendedHealthBenefitsScepter';

	static primaryKey = 'locale';

	static fields() {
		return {
			locale: this.string(null).nullable(),
			benefitFamilies: this.attr(null).nullable()
		};
	}

	static async getBenefits(username, token, locale) {
		// Check to see if the benefits are in vuex-orm.

		const benefitFamilies = await ExtendedHealthBenefitsScepter.find(locale)?.benefitFamilies;

		if (benefitFamilies) {
			return benefitFamilies;
		}

		// Nothing found in vuex-orm, make an api call to get the benefits for the member.
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/coverages/extended-health-benefits`;

		startSpinner();

		try {
			let response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			stopSpinner();

			if (response?.status === 200) {
				// Api call is good, add data to vuex-orm.
				let benefitFamilies = response.data;

				benefitFamilies = benefitFamilies.map((benefitFamily) => {
					return this._transformData(benefitFamily);
				});

				await ExtendedHealthBenefitsScepter.insertOrUpdate({
					data: {
						locale,
						benefitFamilies
					}
				});

				return benefitFamilies;
			} else {
				throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	/**
	 * Transform data coming from the EHB web service.  Split the benefit family title into 2 parts (starting at the
	 * first parenthesis) and add spaces before/after any slashes to help the headings break properly on smaller screens.
	 *
	 * @param {Object} benefitFamily - An object with data from the service call.
	 * @returns {Object} - An object with transformed data.
	 */
	static _transformData(benefitFamily) {
		let transformed = {};

		const parenthesisPosition = benefitFamily.shortDesc.indexOf('(');

		if (parenthesisPosition > 0) {
			// Get text before parenthesis
			transformed.title = benefitFamily.shortDesc.substring(0, parenthesisPosition);
			// Get text after parenthesis
			transformed.subTitle = benefitFamily.shortDesc.substring(parenthesisPosition);
			// Capitalize first letter in sub title text.  Ex: "(Some ambulance benefits ..."
			transformed.subTitle = transformed.subTitle.replace(/\((.)/, (x) => x.toUpperCase());
		} else {
			// No parenthesis in the description, just use it as-is
			transformed.title = benefitFamily.shortDesc;
		}

		// Add space before and after any "/" to allow words to line break.
		transformed.title = transformed.title.replaceAll('/', ' / ');

		transformed.benefits = benefitFamily.benefits;

		return transformed;
	}
}

/*

  Sample transformed data:

    [
      {
        "title": "Ambulance Services ",
        "subTitle": "(Some ambulance benefits may be covered under Hospital and/or Extended Health Benefits)",
        "benefits": [
          {
            "shortDesc": "Air transportation stretcher case in Canada",
            "benefitCode": "0730IC"
          },
          {
            "shortDesc": "Special ambulance attendant in Canada",
            "benefitCode": "0733IC"
          }
        ]
      },
      {
        "title": "Braces / Splints / Stockings / Supports",
        "benefits": [
          {
            "shortDesc": "Back brace purchase",
            "benefitCode": "0507PB"
          }
        ]
      }
    ]

*/
