import { Model } from '@vuex-orm/core';
import axios from 'axios';
import { startSpinner, stopSpinner } from '@/mixins/spinner';

export default class hspt extends Model {
	static entity = 'hspt';

	static primaryKey = ['locale', 'benefitCode'];

	static fields() {
		return {
			locale: this.string(null).nullable(),
			benefitDetails: this.attr(null).nullable()
		};
	}

	static async getSuppressedBenefitDetails(username, token, locale, benefitCode) {
		// Check to see if the benefit details are in vuex-orm.
		const localizedBenefitDetails = await super.find([locale, benefitCode]);

		if (localizedBenefitDetails) {
			return localizedBenefitDetails.benefitDetails;
		}

		// Nothing found in vuex-orm, make an api call to get the benefit details.
		const urlBase = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/suppressed-coverages/hospital`;

		startSpinner();

		try {
			let response = await axios.get(urlBase, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});

			if (response?.status === 200) {
				// Api call is good, transform data and add it to vuex-orm.
				const benefitDetails = response.data;

				await super.insertOrUpdate({
					data: {
						locale,
						benefitCode,
						benefitDetails
					}
				});
				stopSpinner();
				return benefitDetails;
			} else {
				stopSpinner();
				throw new Error();
			}
		} catch (error) {
			stopSpinner();
			this.store().dispatch('error');
		}
	}
}
