import { render, staticRenderFns } from "./MenuSubHeader.vue?vue&type=template&id=3997b602&scoped=true&"
import script from "./MenuSubHeader.vue?vue&type=script&lang=js&"
export * from "./MenuSubHeader.vue?vue&type=script&lang=js&"
import style0 from "./MenuSubHeader.vue?vue&type=style&index=0&id=3997b602&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3997b602",
  null
  
)

/* custom blocks */
import block0 from "./MenuSubHeader.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports