import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represents member's recent claims data displayed in claims tracker tab
 */
export default class RecentClaims extends Model {
	static entity = 'recentClaims';

	static fields() {
		return {
			id: this.string(null).nullable(),
			recentClaims: this.attr(null).nullable()
		};
	}
	static async getSavedSearchResults(id) {
		return await RecentClaims.find(id)?.recentClaims;
	}
	static async getRecentClaims(id, locale, token) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${id}/recent-claims`;
		try {
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: 'Bearer ' + token
				},
				signal: AbortSignal.timeout(60000) //Aborts request after 1 minute
			});
			// If results were returned then save claims.
			if (response?.status === 200) {
				await RecentClaims.create({
					data: {
						id: id,
						recentClaims: response?.data
					}
				});
			}
			// return null to display error message on claims tab
			return response;
		} catch (error) {
			// return null to display error message on claims tab
			return error;
		}
	}
}
