<template>
	<ErrorModal
		:modal-show="showLogoutModal"
		:error-title="$t('title.areYouSure')"
		:has-close-button="false"
		automation-id="logoutModal"
		@change="closeLogoutModal"
	>
		<BaseButton
			automation-id="logoutButton"
			variant="primary"
			class="mr-2 mb-2"
			type="button"
			:label="$t('button.yes')"
			@click="logOut"
		/>
		<BaseButton
			automation-id="closeLogoutModal"
			variant="secondary"
			type="button"
			class="mb-2"
			:label="$t('button.no')"
			@click="closeLogoutModal"
		/>
	</ErrorModal>
</template>

<script>
import Vue from 'vue';
import IdMixin from '@/mixins/id';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes';

// @vue/component
@Component({
	name: 'LogoutModal',
	components: {
		BaseButton,
		ErrorModal
	},
	mixins: [IdMixin],
	props: {
		showLogoutModal: {
			type: Boolean,
			default: false
		}
	}
})
export default class LogoutModal extends Vue {
	closeLogoutModal() {
		this.$emit('close');
	}

	async logOut() {
		this.$emit('close');
		let brand = await JSON.parse(sessionStorage.getItem('securityPolicy')).brand;
		sessionStorage.clear();
		if (this.$demoServer) {
			this.$demoServer.shutdown();
			Vue.prototype.$demoServer = null;
		}
		this.$router.push({
			name: Routes.LOGIN,
			params: { locale: this.$router.currentRoute.params.locale },
			query: { brand: brand.toLowerCase(), status: 'LOGGEDOUT' }
		});
	}
}
</script>
<i18n>
{
  "en": {
    "title": {
      "areYouSure": "Are you sure you want to log out?"
    },
    "button": {
      "yes": "Yes, log me out",
      "no": "No, I'm not ready"
    }
  },
  "fr": {
    "title": {
      "areYouSure": "Voulez-vous vraiment fermer la session? "
    },
    "button": {
      "yes": "Oui, me déconnecter",
      "no": "Non, je n’ai pas fini"
    }
  }
}
</i18n>
