import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class DentalBenefit extends Model {
	static entity = 'dentalBenefits';

	static primaryKey = ['participantId', 'locale'];

	static fields() {
		return {
			participantId: this.string(),
			locale: this.string(),
			data: this.attr(null),
			provinces: this.attr(null),
			specialties: this.attr(null)
		};
	}

	static async hasBenefits(participantId, locale) {
		const benefits = await this.find([participantId, locale]);
		return benefits ? true : false;
	}

	static async checkBenefits(email, participantId, token, locale) {
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/${participantId}/benefits/dental`;
		const response = await axios
			.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			})
			.catch((error) => error.response);
		switch (response?.status) {
			case 200: {
				return true;
			}
			case 204:
				return false;
		}
	}

	static async getBenefits(email, participantId, token, locale, data) {
		let benefits;
		const querystring = `specialty=${data.specialty}&province=${
			data.province
		}&codes=${data.codes.join(',')}`;
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/${participantId}/benefits/dental?${querystring}`;
		try {
			const response = await axios
				.get(url, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					}
				})
				.catch((error) => error.response);
			switch (response?.status) {
				case 200: {
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, data: response.data.serviceDetails }
					});
					return { data: { results: benefits.dentalBenefits[0].data } };
				}
				case 204:
					return { data: { results: [] } };
				case 400: {
					const results = response.data.serviceDetails.filter((r) => 'service' in r);
					const errors = response.data.serviceDetails.filter((r) => 'errors' in r);
					const groupedErrors = errors.reduce((combinedErrors, error) => {
						if (!combinedErrors?.find((e) => e.error === error.errors[0])) {
							combinedErrors.push({ code: [error.code], error: error.errors[0] });
						} else {
							combinedErrors.find((e) => e.error === error.errors[0]).code?.push(error.code);
						}
						return combinedErrors;
					}, []);
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, data: results }
					});
					return {
						status: 400,
						data: { results: benefits.dentalBenefits[0].data, errors: groupedErrors }
					};
				}
				default:
					throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static async getProvinces(email, participantId, token, locale) {
		let benefits = await this.find([participantId, locale]);
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/benefits/dental/provinces`;
		try {
			if (benefits?.provinces) return benefits.provinces;
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			switch (response?.status) {
				case 200: {
					const provinces = [];
					for (const [key, value] of Object.entries(response.data)) {
						provinces.push({ value: key, label: value });
					}
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, provinces: provinces }
					});
					return benefits.dentalBenefits[0].provinces.sort((a, b) =>
						a.label.localeCompare(b.label)
					);
				}
				case 204:
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, provinces: [] }
					});
					return benefits.dentalBenefits[0].provinces;
				default:
					throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}

	static async getSpecialties(email, participantId, token, locale) {
		let benefits = await this.find([participantId, locale]);
		const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${email}/benefits/dental/specialties`;
		try {
			if (benefits?.specialties) return benefits.specialties;
			const response = await axios.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': locale,
					Authorization: token
				}
			});
			switch (response?.status) {
				case 200: {
					const specialties = [];
					for (const [key, value] of Object.entries(response.data)) {
						specialties.push({ value: key, label: value });
					}
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, specialties: specialties }
					});
					return benefits.dentalBenefits[0].specialties.sort((a, b) =>
						a.label.localeCompare(b.label)
					);
				}
				case 204:
					benefits = await this.insertOrUpdate({
						data: { participantId, locale, specialties: [] }
					});
					return benefits.dentalBenefits[0].specialties;
				default:
					throw new Error();
			}
		} catch (error) {
			this.store().dispatch('error');
		}
	}
}
