/**
 * Directive to scroll to a hidden element (or component) when it is added to the DOM by Vue.  For example, when a list of
 * search results is presented to a user but the list is not visible because there is too much other content on the screen.
 * 
 * Note 1: Smooth scrolling works in Firefox by default.  In Chrome, it is disabled by default.  The user
 * needs to enable it if they wish to have smooth scrolling.  Enter the following in the Chrome search bar to enable:
 * 
 *    chrome://flags/#smooth-scrolling
 * 
 * Note 2: A polyfill is required for "scrollIntoView" for Safari.  We use https://www.npmjs.com/package/seamless-scroll-polyfill
 * 
 * Sample directive usage:
 * 
      <button @click="showResults">Search</button>
      <div v-if="show">
        <h4>Search Results</h4>
        <ol>
          <li v-scroll-to>Coffee</li>
          <li>Tea</li>
          <li>Milk</li>
        </ol>
      </div>
 * 
 */

import { scrollIntoView } from 'seamless-scroll-polyfill';

export default {
	inserted(el) {
		scrollIntoView(el, { behavior: 'smooth', block: 'center' }); // Replaces el.scrollIntoView({ })

		// To help with accessibility, focus on the element so that screen readers announce the element.
		// Delay 1 second to allow the smooth scroll to finish.  The "focus" is an immediate (no smooth) action.
		setTimeout(() => {
			el.focus();
		}, 1000);

		window.scrollBy({
			top: 300,
			left: 0,
			behavior: 'smooth'
		});
	}
};
