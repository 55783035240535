import * as Routes from '@/constants/Routes.js';
import * as Tokens from '@/constants/Token.js';

import Authentication from '@/models/Authentication.js';
import store from '@/store';

export default {
	path: Routes.REGISTRATION,
	children: [
		{
			path: '/:locale/' + Routes.VALIDATE,
			name: Routes.VALIDATE,
			meta: { menuHidden: true },
			beforeEnter: async (to, from, next) => {
				try {
					store.dispatch('updatePageLoadingAnimation', true);
					Authentication.validateToken(to.query.token, to.params.locale).then((response) => {
						if (response && response.status <= 400 && response.data) {
							// If Activation token has expired then navigate to the expired token screen.
							if (
								response.data.action === Tokens.ACTIVATION_EXPIRED ||
								response.data.action === Tokens.PASSWORD_RESET_EXPIRED ||
								response.data.action === Tokens.UPDATE_EMAIL_EXPIRED
							) {
								next({
									name: Routes.EXPIRED_TOKEN,
									params: {
										token: to.query.token,
										locale: to.params.locale
									}
								});
							} else if (response.data.action === Tokens.ACTIVATION) {
								next({
									name: Routes.REGISTRATION_ACTIVE,
									params: {
										locale: to.params.locale
									}
								});
							} else if (response.data.action === Tokens.CONFIRMATION) {
								next({
									name: Routes.CONFIRM_EMAIL_CHANGE,
									// Had to add in this query value to skip over code in the beforeEach in index.js that is
									// replacing any params with ones from a previous screen if none exist.
									query: {
										token: to.query.token
									},
									params: {
										token: to.query.token,
										newUsername: response.data.newUsername,
										locale: to.params.locale
									}
								});
							} else if (response.data.action === Tokens.UPDATE_EMAIL) {
								next({
									name: Routes.CHANGE_EMAIL_RESULT,
									params: { newUser: response.data.newUsername }
								});
							} else if (response.data.action === Tokens.PASSWORD_RESET) {
								next({
									name: Routes.PASSWORD_CHANGE,
									params: { token: to.query.token, locale: to.params.locale }
								});
							} else if (response.data.action === Tokens.INVALID) {
								next({
									name: Routes.INVALID_TOKEN,
									params: { token: to.query.token, locale: to.params.locale }
								});
							} else if (
								response.data.action === Tokens.CONFIRMATION_USED ||
								response.data.action === Tokens.ACTIVATION_USED
							) {
								next({
									name: Routes.REGISTRATION_ACTIVE,
									params: {
										locale: to.params.locale
									}
								});
							} else {
								next({
									name: Routes.ERROR_TOKEN_VALIDATION,
									params: {
										locale: to.params.locale
									}
								});
							}
						} else {
							next({
								name: Routes.ERROR_TOKEN_VALIDATION,
								params: {
									locale: to.params.locale
								}
							});
						}
					});
					next();
				} catch (err) {
					//TO DO Push to error page.
				} finally {
					store.dispatch('updatePageLoadingAnimation', false);
				}
			}
		},
		{
			path: '/:locale/expiredtoken',
			name: Routes.EXPIREDTOKEN,
			component: () =>
				import(/* webpackChunkName: "validation"*/ '@/pages/tokenvalidation/ExpiredToken.vue')
		}
	]
};
