import { Model } from '@vuex-orm/core';
import axios from 'axios';

// import Member from '@/models/Member';

export default class Dependent extends Model {
	static entity = 'dependents';

	static fields() {
		return {
			id: this.string(null),
			data: this.attr([]),
			status: this.number(null),
			onlyActive: this.boolean(null)
		};
	}

	/**
	 * This method will call the api to return all the dependents on the members plan.
	 * @param {String} username
	 * @param {String} token
	 * @param {String} locale
	 * @returns
	 */
	static async getDependents(username, token, locale, onlyActive = false) {
		try {
			// If found then it was in vuex.
			const dependents = await this.getDependentsFromVuex(username);
			if (dependents && onlyActive === dependents.onlyActive) {
				return dependents;
			}
			// Nothing found in vuex
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/participants${
				onlyActive ? '?filter=active' : ''
			}`;
			let response = await axios.get(url, {
				headers: {
					'content-type': 'application/json',
					Authorization: token,
					'Accept-Language': locale
				},
				data: {}
			});
			// Only save the response if onlyActive is false. Saving only the active participants can cause issues on some screens.
			if (response && response.status < 201 && onlyActive === false) {
				await super.insertOrUpdate({
					data: {
						id: username,
						data: response.data,
						status: response.status,
						onlyActive
					}
				});
			}
			return response;
		} catch (err) {
			if (err.response) {
				return err.response;
			}
			this.store().dispatch('error');
		}
	}

	static async getDependent(username, token, locale, participantId) {
		const participants = await this.getDependents(username, token, locale, false);
		return participants.data.find((p) => p.participantId === participantId);
	}

	static async getActiveDependents(username, token, locale) {
		try {
			const url = `${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/participants?filter=active`;
			let response = await axios.get(url, {
				headers: {
					'content-type': 'application/json',
					Authorization: token,
					'Accept-Language': locale
				},
				data: {}
			});
			return response;
		} catch (err) {
			if (err.response) {
				return err.response;
			}
			this.store().dispatch('error');
		}
	}

	static async hasDependents(email) {
		const dependents = await this.find(email);
		return dependents ? true : false;
	}

	static async getDependentsFromVuex(email) {
		return await super.query().where('id', email).first();
	}
}
