/**
 *  handler for life and disability api
 */

import lifeAndDisability_en from './../../objects/benefit_life_and_disability.json';
import lifeAndDisability_fr from './../../objects/benefit_life_and_disability_fr.json';

export default function (server) {
	/**
	 *
	 */
	server.get('members/:id/coverages/life-disability-benefits', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en'
			? lifeAndDisability_en
			: lifeAndDisability_fr;
	});
}
