export const NO_TOKEN = 'NOTOKEN';
export const EXPIRED = 'EXPIRED';
export const TIMEOUT = 'TIMEOUT';
export const NOLOGIN = 'NOLOGIN';

export const BENEFICIARY_PRIMARY = 'PRIMARY';
export const BENEFICIARY_SECONDARY = 'SECONDARY';

export const COVEREDLIFE_CHILD = 'CHI';
export const COVEREDLIFE_SPOUSE = 'SPO';
export const COVEREDLIFE_SPOUSE_COMLAW = 'COMLW';
export const COVEREDLIFE_SPOUSE_MARRIED = 'MAR2';

export const EMPLOYEE_ONLY = 'EMPL';
export const EMPLOYEE_FAMILY = 'FMLY';
export const EMPLOYEE_SINGLE = 'SNGL';
