import * as Routes from '../constants/Routes.js';
import {
	SUBMIT_CLAIM,
	PAYMENT_HISTORY,
	PAYMENT_HISTORY_DETAILS,
	CLAIM_HISTORY,
	HISTORY,
	HISTORY_DETAILS
} from '@/constants/Routes.js';
import ManageYourAccount from './manage-your-account.js';
import Registration from './registration.js';
import SubmitClaim from './submit-claim.js';
import Coverage from './coverage.js';
import Validation from './validation.js';
import DigitalValidation from './digital-validation.js';
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Authentication from '@/models/Authentication.js';
import { useBreadcrumbs } from '@/composables/useBreadcrumbs';

Vue.use(VueRouter);

const routes = [
	// This route is for custom contact urls that do not have a locale.
	// It will retrieve the local from the token passed in and then redirect to the Custom contact route with the correct locale.
	{
		path: '/custom-contact',
		name: Routes.CUSTOM_CONTACT_NO_LOCALE,
		meta: { menuHidden: true },
		component: () =>
			import(/* webpackChunkName: "memberservicesite"*/ '@/pages/contact/CustomContact.vue'),
		props: true,
		beforeEnter: async (to, from, next) => {
			// replace all the spaces with +'s because the encoding will remove all +'s from the token param before hitting this.
			var token = to.query.token.replaceAll(' ', '+');
			// Call the API with the members token to check the status.
			Authentication.getDigitalWelcomeTokenValues(token).then((tokenValues) => {
				if (tokenValues.status === 200) {
					const language = tokenValues.data.language;
					sessionStorage.setItem('brand', tokenValues.data.brand);
					next({
						name: Routes.CUSTOM_CONTACT,
						params: { token: token, locale: language }
					});
				}
			});
		}
	},
	{
		path: '/',
		redirect: '/en/login',
		name: 'root'
	},
	{
		path: '/:locale',
		redirect: '/:locale/login',
		name: 'locale',
		props: true,
		meta: { menuHidden: true },
		component: {
			render: (h) => h('router-view')
		},
		children: [
			{
				path: '/:locale/' + Routes.LOGIN,
				name: Routes.LOGIN,
				component: () => import(/* webpackChunkName: "memberservicesite" */ '@/pages/Login.vue'),
				meta: { menuHidden: true }
			},
			{
				path: '/',
				redirect: '/:locale/login',
				name: 'root_with_locale'
			},
			{
				path: '/:locale/' + Routes.FORGOT_PASSWORD,
				name: Routes.FORGOT_PASSWORD,
				meta: { menuHidden: true },
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/password/ForgotPassword.vue')
			},
			{
				path: '/:locale/' + Routes.SUCCESS_RESET_EMAIL,
				name: Routes.SUCCESS_RESET_EMAIL,
				meta: { menuHidden: true },
				component: () =>
					import(
						/* webpackChunkName: "memberservicesite"*/ '@/pages/password/SuccessPasswordResetEmail.vue'
					)
			},
			{
				path: '/:locale/' + Routes.SUCCESS_RESET,
				name: Routes.SUCCESS_RESET,
				meta: { menuHidden: true },
				component: () =>
					import(
						/* webpackChunkName: "memberservicesite"*/ '@/pages/password/SuccessPasswordReset.vue'
					)
			},
			{
				path: '/:locale/' + Routes.PASSWORD_CHANGE,
				name: Routes.PASSWORD_CHANGE,
				meta: { menuHidden: true },
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/password/ChangePassword.vue')
			},
			{
				path: '/:locale/' + Routes.INVALID_TOKEN,
				name: Routes.INVALID_TOKEN,
				meta: { menuHidden: true },
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/InvalidToken.vue')
			},
			{
				path: '/:locale/' + Routes.ERROR_TOKEN_VALIDATION,
				name: Routes.ERROR_TOKEN_VALIDATION,
				meta: { menuHidden: true },
				component: () =>
					import(
						/* webpackChunkName: "memberservicesite"*/ '@/pages/error/ErrorTokenValidation.vue'
					)
			},
			{
				path: '/:locale/' + Routes.EXPIRED_TOKEN,
				name: Routes.EXPIRED_TOKEN,
				meta: { menuHidden: true },
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/error/ExpiredToken.vue')
			},
			{
				path: '/:locale/' + Routes.RESET_YOUR_ACCOUNT,
				name: Routes.RESET_YOUR_ACCOUNT,
				meta: { menuHidden: true },
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/resetLogin/ResetLogin.vue')
			},
			{
				path: '/:locale/' + Routes.NEED_HELP,
				name: Routes.NEED_HELP,
				meta: { menuHidden: true },
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/login/NeedHelp.vue')
			},
			{
				path: '/:locale/' + Routes.SUCCESS_RESET_LOGIN,
				name: Routes.SUCCESS_RESET_LOGIN,
				meta: { menuHidden: true },
				component: () =>
					import(
						/* webpackChunkName: "memberservicesite"*/ '@/pages/resetLogin/SuccessResetLogin.vue'
					)
			},
			{
				path: '/:locale/' + Routes.CHANGE_EMAIL_VERIFY,
				name: Routes.CHANGE_EMAIL_VERIFY,
				meta: { menuHidden: true },
				component: () =>
					import(
						/* webpackChunkName: 'change-email' */ '@/pages/manage-your-account/personal-information/ChangeEmailVerifyPage.vue'
					)
			},
			{
				path: '/:locale/' + Routes.CHANGE_EMAIL_RESULT,
				name: Routes.CHANGE_EMAIL_RESULT,
				meta: { menuHidden: true },
				props: true,
				component: () =>
					import(
						/* webpackChunkName: 'memberservicesite' */ '@/pages/manage-your-account/personal-information/ChangeEmailResult.vue'
					)
			},
			{
				path: '/:locale/' + Routes.DASHBOARD,
				name: Routes.DASHBOARD,
				component: () =>
					import(/* webpackChunkName: "dashboard"*/ '@/pages/dashboard/DashboardPage.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: '/:locale/' + Routes.TERMS_AND_CONDITIONS_FORM,
				name: Routes.TERMS_AND_CONDITIONS_FORM,
				component: () =>
					import(
						/* webpackChunkName: "memberservicesite"*/ '@/pages/termsandcondition/TermsAndConditionFormPage.vue'
					),
				props: true,
				meta: { requiresAuth: true, menuHidden: true }
			},
			{
				path: '/:locale/' + Routes.CONTACT_US,
				name: Routes.CONTACT_US,
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/contact/ContactUs.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: '/:locale/' + Routes.CUSTOM_CONTACT,
				name: Routes.CUSTOM_CONTACT,
				meta: { menuHidden: true },
				component: () =>
					import(/* webpackChunkName: "memberservicesite"*/ '@/pages/contact/CustomContact.vue')
			},
			/* For local development only.  Intended to demo components and styles. */
			{
				path: 'demo',
				name: 'Demo',
				meta: { requiresAuth: true, menuHidden: true },
				component: () => import(/* webpackChunkName: "demo" */ '@/pages/demo/MssDemo.vue'),
				beforeEnter: async (to, from, next) => {
					if (window.LOCAL_DEV) {
						next();
					} else {
						next({
							name: Routes.NOT_FOUND,
							params: { locale: to.params.locale }
						});
					}
				}
			},
			/* For local development only.  Intended to demo components and styles. */
			{
				path: 'demo-history',
				name: 'DemoHistory',
				meta: { requiresAuth: true },
				component: () =>
					import(
						/* webpackChunkName: "demoHistory" */ '@/pages/demo/SavingSearchHistoryDemoPage.vue'
					),
				beforeEnter: async (to, from, next) => {
					if (window.LOCAL_DEV) {
						next();
					} else {
						next({
							name: Routes.NOT_FOUND,
							params: { locale: to.params.locale }
						});
					}
				}
			},
			/* For local development only.  Intended to demo components and styles. */
			{
				path: 'demo-fuzzy',
				name: 'DemoFuzzySearch',
				meta: { requiresAuth: true },
				component: () =>
					import(/* webpackChunkName: "demoFuzzy" */ '@/pages/demo/FuzzySearchDemoPage.vue'),
				beforeEnter: async (to, from, next) => {
					if (window.LOCAL_DEV) {
						next();
					} else {
						next({
							name: Routes.NOT_FOUND,
							params: { locale: to.params.locale }
						});
					}
				}
			},
			//Importing other routes
			Registration,
			Validation,
			DigitalValidation,
			ManageYourAccount,
			// Submit Claim routes.  The claim type and success pages are "outside" the submit wizard.
			{
				path: SUBMIT_CLAIM.CLAIM_TYPE,
				name: SUBMIT_CLAIM.CLAIM_TYPE,
				component: () =>
					import(/* webpackChunkName: "submitClaim" */ '@/pages/submit-claim/ClaimTypePage.vue'),
				meta: { requiresAuth: true }
			},
			SubmitClaim,
			{
				path: SUBMIT_CLAIM.SUBMIT_CLAIM_SUCCESS,
				name: SUBMIT_CLAIM.SUBMIT_CLAIM_SUCCESS,
				component: () =>
					import(/* webpackChunkName: "submitClaim"*/ '@/pages/submit-claim/ClaimSuccessPage.vue'),
				meta: { requiresAuth: true },
				props: true
			},
			{
				path: SUBMIT_CLAIM.TRAVEL_CAN_NOT_PROCESS,
				name: SUBMIT_CLAIM.TRAVEL_CAN_NOT_PROCESS,
				component: () =>
					import(
						/* webpackChunkName: "travel-can-not"*/ '@/pages/submit-claim/TravelCanNotProcessPage.vue'
					),
				meta: { requiresAuth: true },
				props: true
			},
			Coverage,
			{
				path: PAYMENT_HISTORY,
				name: PAYMENT_HISTORY,
				component: () =>
					import(
						/* webpackChunkName: "payment-history"*/ '@/pages/payment-history/PaymentHistoryPage.vue'
					),
				meta: { requiresAuth: true }
			},
			{
				path: PAYMENT_HISTORY_DETAILS,
				name: PAYMENT_HISTORY_DETAILS,
				component: () =>
					import(
						/* webpackChunkName: "payment-history"*/ '@/pages/payment-history/PaymentHistoryDetailsPage.vue'
					),
				meta: { requiresAuth: true }
			},
			{
				path: CLAIM_HISTORY,
				name: CLAIM_HISTORY,
				component: () =>
					import(
						/* webpackChunkName: "claim-history"*/ '@/pages/claim-history/ClaimHistoryPage.vue'
					),
				meta: { requiresAuth: true }
			},
			{
				path: HISTORY,
				name: HISTORY,
				component: () => import(/* webpackChunkName: "history"*/ '@/pages/history/HistoryPage.vue'),
				meta: { requiresAuth: true }
			},
			{
				path: HISTORY_DETAILS,
				name: HISTORY_DETAILS,
				component: () =>
					import(/* webpackChunkName: "history"*/ '@/pages/history/HistoryDetails.vue'),
				meta: { requiresAuth: true }
			}
		]
	},
	{
		path: '/:locale/' + '*',
		name: Routes.NOT_FOUND,
		meta: { menuHidden: true },
		component: () => import('@/pages/NotFound.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	}
});

function hasQueryParams(route) {
	return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
	// The digital validate url does not have a locale, so the logic setting a default locale should be skipped.
	store.dispatch('previousRouteUpdate', from);
	if (to.name !== Routes.CUSTOM_CONTACT_NO_LOCALE) {
		store.dispatch('updatePageLoadingAnimation', true);
		store.dispatch('successBanner', { show: false, text: '' });
		store.dispatch('dashboardInfoBanner', { show: false });
		store.dispatch('fluidBodyContainer', false);
		const defaultLocale = 'en';
		let localeParam = defaultLocale;
		if (to.params?.locale === 'en' || to.params?.locale === 'fr') {
			localeParam = to.params.locale;
			const supportedLanguages = ['en', 'fr'];
			// Does the URL contained locale en or fr.
			if (!supportedLanguages.includes(localeParam)) {
				// if there was previously selected locale, let's use that one.
				if (store.state.i18n.locale) {
					return next(store.state.i18n.locale);
				}
				// if there first time accessing the application let's use navigation locale
				const navigatorLocale = navigator.language.split('-')[0];
				if (supportedLanguages.includes(navigatorLocale)) {
					return next(navigatorLocale);
				}
				// if there is no preferred locale from the navigator, let's default to english.
				return next(defaultLocale);
			}
			if (store.state.i18n.locale !== localeParam) {
				store.dispatch('i18n/changeLocale', localeParam);
			}
		} else {
			store.dispatch('updatePageLoadingAnimation', false);
			// invalid locale will redirect to 404.
			// Have to set this up with the locale in it so that it doesn't get stuck in an infinite loop where it keeps redirecting to the not found page.
			// Also have to set it up with /en/ before the not found page or the language toggle will not work.
			return next(`/en/${Routes.NOT_FOUND}`);
		}
		if (to.matched.some((record) => record.meta.requiresAuth)) {
			if (sessionStorage.getItem('loggedIn')) {
				store.dispatch('updatePageLoadingAnimation', false);
				return next();
			} else {
				store.dispatch('updatePageLoadingAnimation', false);
				return next('/');
			}
		}
		store.dispatch('updatePageLoadingAnimation', false);
		if (!hasQueryParams(to) && hasQueryParams(from)) {
			return next({ name: to.name, query: from.query, params: to.params });
		} else {
			return next();
		}
	} else {
		return next();
	}
	// make sure to always call next()!
});
router.afterEach((to, from) => {
	store.dispatch('updatePageLoadingAnimation', false);
	// if only changing locale, don't reset breadcrumbs
	if (
		to.path.split('/')[to.path.split('/').length - 1] !==
		from.path.split('/')[from.path.split('/').length - 1]
	) {
		useBreadcrumbs([], []);
	}
});

export default router;
