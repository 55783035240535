/**
 * My Account / Manage your Account / Member card
 */
export default function (server) {
	server.get('/members/:id/member-cards/details', () => {
		return {
			cardholder: {
				firstName: 'Joe',
				lastName: 'Smith',
				idNumber: '00000000801',
				policyNumber: '0016553001'
			},
			customerServiceNumber: '1-800-667-4511',
			othersOnPlanList: [
				{ idNumber: '00000000802', name: 'Monica Smith' },
				{ idNumber: '00000000803', name: 'Liam Smith' },
				{ idNumber: '00000000804', name: 'Debbie Smith' }
			]
		};
	});
}
