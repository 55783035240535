/**
 * Extended Health Coverage
 */

import extended_health_en from './../../objects/benefit_extended_health.json';
import extended_health_fr from './../../objects/benefit_extended_health_fr.json';

import extended_health_details_en from './../../objects/benefit_extended_health_details.json';
import extended_health_details_fr from './../../objects/benefit_extended_health_details_fr.json';

export default function (server) {
	server.get('/members/:id/coverages/extended-health-benefits', (schema, request) => {
		return request.requestHeaders['Accept-Language'] === 'en'
			? extended_health_en
			: extended_health_fr;
	});

	server.get(
		'/members/:id/coverages/extended-health-benefits/:benefitCode/details',
		(schema, request) => {
			return request.requestHeaders['Accept-Language'] === 'en'
				? extended_health_details_en
				: extended_health_details_fr;
		}
	);
}
