/**
 * recent claims for claims tracker
 */

import { modifyTheData, sortTheData } from '@/mirage/helpers';
import recentClaims_en from './../objects/recent_claims.json';
import recentClaims_fr from './../objects/recent_claims_fr.json';

export default function (server) {
	server.get('/members/:id/recent-claims', (schema, request) => {
		let dataToSendBack = '';
		if (request.requestHeaders['Accept-Language'] === 'en') {
			dataToSendBack = recentClaims_en;
		} else {
			dataToSendBack = recentClaims_fr;
		}
		modifyTheData(dataToSendBack, 'statusUpdate', 4, 0);
		modifyTheData(dataToSendBack, 'receivedDate', 10, 4);

		sortTheData(dataToSendBack, 'receivedDate');

		return dataToSendBack;
	});
}
