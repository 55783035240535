import { Model } from '@vuex-orm/core';
import axios from 'axios';

/**
 * This class represent a Member
 */
export default class ContactInfo extends Model {
	static entity = 'contactInfo';

	static loaded = false;

	/**
	 * Member fields
	 */
	static primarykey = 'id';
	static fields() {
		return {
			id: this.string(),
			addressLine1: this.string(''),
			addressLine2: this.string(''),
			addressLine3: this.string(''),
			postalCode: this.string(''),
			zipCode: this.string(''),
			province: this.string(''),
			state: this.string(''),
			city: this.string(''),
			country: this.string(''),
			phone: this.string(''),
			addressType: this.string('')
		};
	}

	static async hasContact(email) {
		return await super.query().where('id', email).exists();
	}

	static async getUserInfo(email, token, locale) {
		const user = await this.getUser(email);
		if (user) return user;

		let response = await axios.get(
			`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + email + '/contact-info-details',
			{
				headers: {
					'content-type': 'application/json',
					'Accept-language': locale,
					Authorization: token
				}
			}
		);
		if (response && response.status < 201) {
			await super.insertOrUpdate({
				data: {
					id: email,
					addressLine1: response.data.address.addressLine1,
					addressLine2: response.data.address.addressLine2,
					addressLine3: response.data.address.addressLine3,
					postalCode: response.data.address.postalCode,
					zipCode: response.data.address.zipCode,
					province: response.data.address.province,
					state: response.data.address.state,
					city: response.data.address.city,
					country: response.data.address.country,
					phone: response.data.phoneNumber,
					addressType: response.data.address.addressType
				}
			});
		}
		return await super.query().where('id', email).first();
	}

	static async getBrandedContactUsInfo(brand, locale) {
		let end = 'contact';
		// Add french file ending if locale is french
		if (locale && locale === 'fr') {
			end += '_fr.json';
		} else {
			end += '.json';
		}
		// Make sure the brand matches the correct URL
		if (brand) {
			if (brand.toUpperCase() === 'MBC') {
				brand = 'MEDAVIE';
			} else if (brand.toUpperCase() === 'SBC') {
				brand = 'SK';
			} else if (brand.toUpperCase() === 'QBC') {
				brand = 'CANASSURANCEQC';
			} else if (brand.toUpperCase() === 'OBC') {
				brand = 'CANASSURANCEON';
			}
		}
		const url = `${window.ENV.VUE_APP_RAINFOREST_CONTACT_BASE_URL}/${brand}/${end}`;
		const response = await axios.get(url);
		if (response?.status === 200) {
			return response.data;
		}
		return null;
	}

	static async updateUserInfo(email, token, locale, address, phoneNumber) {
		try {
			let response = await axios
				.put(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + email + '/contact-info-details',
					{
						address,
						phoneNumber
					},
					{
						headers: {
							'content-type': 'application/json',
							'Accept-language': locale,
							Authorization: token
						}
					}
				)
				.then((response) => {
					return response;
				})
				.catch((error) => {
					this.store().dispatch('error', { name: 'updateUserInfo', error });
					return error;
				});
			await super.insertOrUpdate({
				data: {
					id: email,
					addressLine1: address.addressLine1,
					addressLine2: address.addressLine2,
					addressLine3: address.addressLine3,
					postalCode: address.postalCode,
					zipCode: address.zipCode,
					province: address.province,
					state: address.state,
					city: address.city,
					country: address.country,
					phone: phoneNumber,
					addressType: address.addressType
				}
			});
			return response;
		} catch (error) {
			this.store().dispatch('error', { name: 'updateUserInfo', error });
		}
	}

	static async getUser(email) {
		return await super.query().where('id', email).first();
	}
	static userExists(email) {
		return super.query().where('id', email).exists();
	}

	static async updateEmail(email, newEmail, locale, token) {
		try {
			let answer = await axios.put(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` + email + '/email',
				{
					email: newEmail
				},
				{
					headers: {
						'content-type': 'application/json',
						'Accept-language': locale,
						Authorization: token
					}
				}
			);
			return answer;
		} catch (err) {
			this.store().dispatch('updateLoading', false);
			this.store().dispatch('error', { name: 'updateEmail', err });
		}
	}
	static async confirmEmail(oldEmail, newEmail, token, locale) {
		try {
			let answer = await axios
				.put(
					`${window.ENV.VUE_APP_RAINFOREST_SERVICE_ACCOUNT}/email`,
					{
						oldUsername: oldEmail,
						newUsername: newEmail,
						token: token
					},
					{
						headers: {
							'content-type': 'application/json',
							'Accept-language': locale
						}
					}
				)
				.then((response) => {
					return response;
				});
			return answer;
		} catch (err) {
			this.store().dispatch('error', err);
		}
	}
	static async sendContactUsEmail(username, message, token, locale) {
		try {
			let response = await axios.post(
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/${username}/contact-us-email`,
				{
					message: message
				},
				{
					headers: {
						'content-type': 'application/json',
						'Accept-Language': locale,
						Authorization: token
					}
				}
			);
			return response;
		} catch (err) {
			this.store().dispatch('error', {
				name: `sendContactUsEmail`,
				err
			});
		}
	}
}
