/* eslint-disable no-unused-vars */
import { Model } from '@vuex-orm/core';

export default class MemberPlan extends Model {
	static entity = 'memberPlans';

	static primaryKey = ['memberId', 'planId'];

	static fields() {
		return {
			memberId: this.string(),
			planId: this.string()
		};
	}
}
