import { Model } from '@vuex-orm/core';
import axios from 'axios';

export default class ProviderSearchCalls extends Model {
	static entity = 'ProviderSearchCalls';

	static primaryKey = 'id';

	static fields() {
		return {
			id: this.string(null).nullable(), // Member email
			ProviderResults: this.attr(null).nullable(), // Saved provider search results
			searchParameters: this.attr(null).nullable() // Saved search parameters
		};
	}

	static async searchProviders(type, nameOfPerson, phone, city, province, username, token, locale) {
		let url = null;
		if (phone) {
			var straightUpPhoneNo = phone.split('-').join('');
			straightUpPhoneNo = straightUpPhoneNo.split('(').join('');
			straightUpPhoneNo = straightUpPhoneNo.split(')').join('');
			straightUpPhoneNo = straightUpPhoneNo.split(' ').join('');
			url =
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` +
				username +
				`/health-professionals?type=` +
				type +
				`&name=` +
				nameOfPerson +
				`&phone=` +
				straightUpPhoneNo +
				`&city=` +
				city.toLowerCase() +
				`&province=` +
				province.toLowerCase();
		} else {
			url =
				`${window.ENV.VUE_APP_RAINFOREST_SERVICE_MEMBERS}/` +
				username +
				`/health-professionals?type=` +
				type +
				`&name=` +
				nameOfPerson +
				`&city=` +
				city.toLowerCase() +
				`&province=` +
				province.toLowerCase();
		}
		let response = await axios.get(url, {
			headers: {
				'content-type': 'application/json',
				'Accept-language': locale,
				Authorization: token
			}
		});
		if (response.status <= 201) {
			await super.insertOrUpdate({
				data: {
					id: username,
					ProviderResults: response.data,
					searchParameters: { type, nameOfPerson, phone, city, province, username, token, locale }
				}
			});
		}

		return response;
	}
	static async clearProviderSearch() {
		await super.deleteAll();
		return true;
	}

	static async providerSearch(email) {
		return await super.query().where('id', email).first();
	}
}
