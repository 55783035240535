<template>
	<ErrorModal
		:data-test-automation-id="getAutomationId('no-dd-for-claim-modal')"
		:class="'direct-deposit-modal'"
		:modal-show="showDirectDepositModal"
		:has-close-button="false"
		:error-title="$t('directDeposit.title')"
		:error-message="
			!updateBankingCapable ? $t('directDeposit.textTwo') : $t('directDeposit.textOne')
		"
		@change="closeModal"
	>
		<template v-if="!updateBankingCapable">
			<BaseButton
				variant="primary"
				pill
				is-right
				type="submit"
				:label="$t('directDeposit.form')"
				:automation-id="getAutomationId('participant-change')"
				@click="downloadForm()"
			/>
			<BaseButton
				variant="secondary"
				:class="!isEnglish ? 'button-ok-fr' : 'button-ok'"
				:label="$t('directDeposit.buttonOk')"
				pill
				is-right
				type="submit"
				:automation-id="getAutomationId('form-submit')"
				data-tracking-id="registration-form-submit-button"
				@click="closeModal"
			></BaseButton>
		</template>
		<template v-else>
			<BaseButton
				variant="primary"
				:class="!isEnglish ? 'button-setup-fr' : ''"
				:label="$t('directDeposit.buttonSetUp')"
				pill
				is-right
				type="submit"
				:automation-id="getAutomationId('form-submit')"
				data-tracking-id="registration-form-submit-button"
				@click="toDirectDeposit"
			></BaseButton>
			<BaseButton
				:class="!isEnglish ? 'button-later-fr' : 'button-later'"
				variant="secondary"
				:label="$t('directDeposit.buttonLater')"
				pill
				is-right
				type="submit"
				:automation-id="getAutomationId('form-submit')"
				data-tracking-id="registration-form-submit-button"
				@click="closeModal"
			></BaseButton>
		</template>
	</ErrorModal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton.vue';
import Forms from '@/models/Forms';
import DownloadLink from '@/components/common/DownloadLink';
import * as Routes from '@/constants/Routes.js';
import IdMixin from '@/mixins/id';

@Component({
	name: 'DirectDepositMissingModal',
	components: {
		BaseButton,
		ErrorModal,
		DownloadLink
	},
	mixins: [IdMixin],
	props: {
		showDirectDepositModal: {
			type: Boolean,
			default: false
		},
		updateBankingCapable: {
			type: Boolean,
			default: false
		}
	}
})
export default class DirectDepositMissingModal extends Vue {
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}
	toDirectDeposit() {
		this.closeModal();
		this.$router.push({ name: Routes.DIRECT_DEPOSIT });
	}
	downloadForm() {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		// Have to create this object here because IOS does not allow window.open() inside an async method.
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			var windowReference = window.open();
		}
		this.$store.dispatch('updateLoading', true);
		Forms.getForm(
			sessionStorage.getItem('email'),
			'DD',
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		).then((response) => {
			this.$store.dispatch('updateLoading', false);
			if (response && (response.status === 201 || response.status === 200)) {
				// Used the object created earlier to set the location of the pdf.
				if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
					windowReference.location = response.data;
				} else {
					window.open(response.data, '_blank');
				}
				this.$store.dispatch('clearError');
			}
		});
	}
	closeModal() {
		this.$emit('closeModal');
	}
}
</script>

<style lang="scss" scoped>
.download-link ::v-deep p {
	margin-bottom: 0px;
	text-align: left;
}
.direct-deposit-modal ::v-deep(.error-modal-dialog) {
	@media (min-width: 720px) {
		max-width: 40em;
	}
	width: 90%;
	margin: auto;
}
.direct-deposit-modal ::v-deep(.modal-body) {
	width: 93%;
	@media (max-width: 992px) {
		width: 88%;
	}
	line-height: 28px;
	padding-top: 1.3em;
	padding-bottom: 1.5em;
}
.direct-deposit-modal ::v-deep(.error-modal-body) {
	padding: 0;
}
.direct-deposit-modal ::v-deep(.download-link) {
	padding-bottom: 0;
}

.download-link ::v-deep .button-label {
	margin-left: 3px;
}
.download-link {
	text-align: left;
}
.button-later {
	margin-left: 1em;
	@media (max-width: 568px) {
		margin-left: 0em;
		margin-top: 1em;
	}
}
.button-later-fr {
	margin-left: 0.5em;
	@media (max-width: 992px) {
		margin-left: 0em;
		margin-top: 1em;
	}
	@media (max-width: 768px) {
		margin-left: 0.5em;
		margin-top: 0em;
	}
	@media (max-width: 720px) {
		margin-left: 0em;
		margin-top: 1em;
	}
	@media (max-width: 568px) {
		height: 3.5em;
	}
}
.button-setup-fr {
	@media (max-width: 992px) {
		margin-right: 0.5em;
	}
	@media (max-width: 568px) {
		height: 3.5em;
	}
}

.button-ok-fr {
	margin-left: 1em;
	@media (max-width: 532px) {
		margin-left: 0em;
		margin-top: 1em;
	}
}

.button-ok {
	margin-left: 1em;
	@media (max-width: 720px) {
		margin-left: 0em;
		margin-top: 1em;
	}
	@media (max-width: 568px) {
		margin-left: 0em;
		margin-top: 1em;
	}
}
.modal-body {
	margin: 0em;
	padding-left: 0em;
	padding-top: 0.5em;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
	{
    "en": {
        "directDeposit": {
            "title": "Sorry, there is currently no direct deposit banking information on your file.",
            "textOne": "If you want to submit your claims online and get reimbursements deposited directly into your bank account, add your banking info now.",
            "textTwo": "If you want to submit your claims online and get reimbursements deposited directly into your bank account, fill out the form below and submit it to your group administrator or human resources representative to get your changes processed.",
            "buttonOk": "Ok",
            "buttonSetUp": "Ok, let's set it up",
            "buttonLater": "No thanks, maybe later",
            "form": "Direct Deposit Banking Information Request Form"
        }
	},
	"fr": {
        "directDeposit": {
            "title": "Désolé, il n’y a présentement aucun renseignement bancaire sur le dépôt direct à votre dossier.",
            "textOne": "Si vous voulez soumettre vos demandes de règlement en ligne et recevoir vos remboursements directement dans votre compte bancaire, ajoutez vos renseignements bancaires maintenant.",
            "textTwo": "Si vous voulez soumettre vos demandes de règlement en ligne et recevoir vos remboursements directement dans votre compte bancaire, remplissez le formulaire ci-dessous et soumettez-le à votre administrateur de régime ou à votre représentant des Ressources humaines, qui traitera les modifications.",
            "buttonOk": "OK",
            "buttonSetUp": "D’accord, j’aimerais les ajouter",
            "buttonLater": "Non merci, peut-être plus tard",
            "form": "Formulaire de demande de dépôt direct"
        }
	}
}
</i18n>
